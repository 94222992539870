import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import LogoCollectionEntity from './entity';
import modelProperties from './modelProperties';

interface LogoCollectionAssociations extends AssociationConfig<any, any> {}

interface LogoCollection extends AutoGeneratedFunctions<LogoCollectionAssociations, LogoCollectionEntity, ComplexData<LogoCollectionEntity>> {}

// eslint-disable-next-line no-redeclare
class LogoCollection extends ComplexData<LogoCollectionEntity> {
    static Entity = LogoCollectionEntity;

    static modelProperties = modelProperties;
}

export default LogoCollection;
