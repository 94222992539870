export default class ValidationResult {
    public errors = [];

    mergeWith(otherValidationResult : ValidationResult) {
        this.errors = [...this.errors, ...otherValidationResult.errors];
    }

    get isValid() {
        return this.errors.length === 0;
    }
}
