const modelProperties = {
    modelName: 'ContactToAddress',
    tableName: 'ContactToAddress',
    minAppVersion: '7.0.0',
    associations: [{
        type: 'belongsTo',
        model: 'Contact',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'contact_id', fieldName: 'contact_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'Address',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'address_id', fieldName: 'address_id' } },
    }, {
        type: 'hasMany',
        model: 'Job',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'site_id', fieldName: 'site_id', sourceKeyName: 'id' } },
    },
    {
        type: 'hasMany',
        model: 'Action',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'site_id', fieldName: 'site_id', sourceKeyName: 'id' } },
    }],
};

export default modelProperties;
