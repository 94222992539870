const modelProperties = {
    modelName: 'MessageRecipient',
    tableName: 'MessageRecipient',
    minAppVersion: '9.0.0',
    associations: [{
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'belongsTo',
        model: 'CustomerMessage',
        parameters: { complexAssociationName: 'CustomerMessage', as: 'CustomerMessage', foreignKey: { name: 'message_id', fieldName: 'message_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'MessageTemplate',
        parameters: { complexAssociationName: 'messageTemplate', as: 'MessageTemplate', foreignKey: { name: 'message_template_id', fieldName: 'message_template_id' } },
        cascadeDelete: false,
    }, {
        type: 'belongsTo',
        cascadeDelete: false,
        model: 'Contact',
        parameters: { as: 'Contact', foreignKey: { name: 'contact_id', fieldName: 'contact_id' } },
    }],
    validations: { },
};

export default modelProperties;
