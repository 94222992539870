import Entity, { EntityWithCompanyId, field } from '../entity';
    
export default class UserTaxRateEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'string' })
    public name: string;
    
    @field({ type: 'float' })
    public rate: number;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;
    
    @field({ type: 'boolean', codeName: 'isEnabled' })
    public isenabled: boolean;
    
    @field({ type: 'boolean', codeName: 'isEditable' })
    public iseditable: boolean;

    @field({ type: 'string' })
    public display_rate: number;
}
