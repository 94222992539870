export default {
    SYSTEM_ERROR: {
        code: 1000,
        message: 'System Error',
    },
    AUTH_FAILED: {
        code: 1001,
        message: 'Authentication failed.',
        // statusCode: 401,
        severity: 1,
    },
    NO_CREDIT: {
        code: 1002,
        message: {
            prompt: 'ApiResponse.NO_CREDIT',
            default: 'No credits.',
        },
        severity: 1,
    },
    AUTHZ_FAILED: {
        code: 1003,
        message: 'Authorization failed.',
        // statusCode: 401,
        severity: 1,
    },
    USER_DELETED: {
        code: 1004,
        message: {
            prompt: 'ApiResponse.USER_DELETED',
            default: 'Sorry, this email address ( %s ) was previously a user on a Powered Now account that has now been deactivated. If you would like to register for Powered Now, please contact us.',
        },
        severity: 1,
    },
    USER_DELETED_FROM_COMPANY: {
        code: 1005,
        message: {
            prompt: 'ApiResponse.USER_DELETED_FROM_COMPANY',
            default: 'Sorry, user with %s email address is no longer exists.',
        },
        severity: 1,
    },
    USER_ALREADY_TAKEN: {
        code: 1006,
        message: {
            prompt: 'ApiResponse.USER_ALREADY_TAKEN',
            default: 'User id already taken.',
        },
        severity: 1,
    },
    USER_ALREADY_TAKEN_BY_SOCIAL: {
        code: 2001,
        message: {
            prompt: 'ApiResponse.USER_ALREADY_TAKEN_BY_SOCIAL',
            default: 'Looks like this social account is already registered with Powered Now.',
        },
        severity: 1,
    },
    INVALID_SOCIAL_ACCESS_TOKEN: {
        code: 2002,
        message: {
            prompt: 'ApiResponse.INVALID_SOCIAL_ACCESS_TOKEN',
            default: 'Your social media access token is invalid',
        },
        severity: 1,
    },
    NO_USER_WITH_SOCIAL_DATA: {
        code: 2003,
        message: {
            prompt: 'ApiResponse.NO_USER_WITH_SOCIAL_DATA',
            default: 'No user found for social login data.',
        },
        severity: 1,
    },
    USER_NOT_FOUND: {
        code: 2004,
        message: {
            prompt: 'ApiResponse.USER_NOT_FOUND',
            default: 'User not found.',
        },
        severity: 1,
    },
    USER_PROFILE_NOT_FOUND: {
        code: 2011,
        message: {
            prompt: 'ApiResponse.USER_PROFILE_NOT_FOUND',
            default: 'UserUserProfile is not defined.',
        },
        severity: 1,
    },
    INACTIVE_USER: {
        code: 1024,
        message: {
            prompt: 'Login.DeactivatedMsg',
            default: 'This account has been deactivated. If you think this is in error please contact the account owner.',
        },
        severity: 1,
    },
    NO_USER_WITH_EMAIL: {
        code: 1025,
        message: {
            prompt: 'ApiResponse.NO_USER_WITH_EMAIL',
            default: 'Sorry, there is no user known with the email address:\n%s',
        },
        // statusCode: 401,
        severity: 1,
    },
    WRONG_PASSWORD: {
        code: 1026,
        message: {
            prompt: 'ApiResponse.WRONG_PASSWORD',
            default: 'Sorry, the password is wrong!',
        },
        // statusCode: 401,
        severity: 2,
    },
    WRONG_TOKEN: {
        code: 1027,
        message: {
            prompt: 'ApiResponse.WRONG_TOKEN',
            default: 'Sorry, the token is wrong!',
        },
        // statusCode: 401,
        severity: 2,
    },
    TOKEN_EXPIRED: {
        code: 1028,
        message: {
            prompt: 'ApiResponse.TOKEN_EXPIRED',
            default: 'Sorry, the token is expired!',
        },
        // statusCode: 401,
        severity: 2,
    },
    DEVICE_ID_OVERFLOW: {
        code: 1029,
        message: {
            prompt: 'ApiResponse.DEVICE_ID_OVERFLOW',
            default: 'Login error! Please contact our technical team!',
        },
    },
    DEVICE_ID_MISMATCH: {
        code: 1031,
        message: {
            prompt: 'ApiResponse.DEVICE_ID_MISMATCH',
            default: 'DeviceId mismatch!',
        },
        severity: 2,
        statusCode: 200,
    },
    DEVICE_SWITCH_FORBIDDEN: {
        code: 1030,
        message: {
            prompt: 'ApiResponse.DEVICE_SWITCH_FORBIDDEN',
            default: 'Sorry, it looks as if you\'ve hit the limit on devices this month. Your subscription only allows for %s devices, upgrade to our team plan for unlimited devices and users.',
        },
        severity: 1,
    },
    NO_USERDEVICE_EXISTS: {
        code: 1041, // reference to client error to force logout (CONSTANTS.ERRORS.FIRST_LOGIN_SERVER_ERROR.code)
        // the error codes should match to force old clients an api-validated login (which creates a new userDevice)
        message: {
            prompt: 'ApiResponse.NoUserDeviceExists',
            default: 'No userDevice exists.',
        },
        severity: 2,
    },
    NO_APP_VERSION: {
        code: 1061,
        message: {
            prompt: 'ApiResponse.NO_APP_VERSION',
            default: 'App version is not found on the server!',
        },
        severity: 2,
    },
    WRONG_USERNAME_OR_PASSWORD: {
        code: 1100,
        message: {
            prompt: 'ApiResponse.WRONG_USERNAME_OR_PASSWORD',
            default: 'Sorry we can\'t find that account<br/>Check your email address and password were entered correctly and try again. If you\'re new to Powered Now, press the register button to create an account.',
        },
        severity: 2,
        // statusCode: 401,
    },
    DATA_DELETE_WAS_USED: {
        code: 1101,
        message: {
            prompt: 'ApiResponse.DATA_DELETE_WAS_USED',
            default: 'Data delete was already used once.',
        },
        severity: 1,
    },
    DATA_DELETION_EXPIRED: {
        code: 1104,
        message: {
            prompt: 'ApiResponse.DATA_DELETION_EXPIRED',
            default: 'Data deletion can only be used within 2 months after registration.',
        },
        severity: 1,
    },
    EMAIL_MISSING: {
        code: 1300,
        message: {
            prompt: 'ApiResponse.EMAIL_MISSING',
            default: 'The supplied email wasn\'t found in our records!',
        },
        severity: 2,
    },
    VERSION_STATUS: {
        RECENT: 'recent',
        OUTDATED: 'outdated',
        message_outdated: 'Please upgrade to version %s !',
    },
    EMAIL_SEND_FAILURE: {
        code: 1301,
        message: {
            prompt: 'ApiResponse.EMAIL_SEND_FAILURE',
            default: 'Email sending failed: %s',
        },
        severity: 4,
    },
    SMS_SEND_FAILURE: {
        code: 1302,
        message: {
            prompt: 'ApiResponse.SMS_SEND_FAILURE',
            default: 'SMS sending failed: %s',
        },
        severity: 4,
    },
    PDF_GENERATION_FAIL: {
        code: 1303,
        message: {
            prompt: 'ApiResponse.PDF_GENERATION_FAIL',
            default: 'PDF generation has failed, please try again later.',
        },
        severity: 8,
        statusCode: 500,
    },
    PASSWORD_RESET: {
        code: 1,
        message: {
            prompt: 'ApiResponse.PASSWORD_RESET',
            default: 'Please check your inbox, we have sent you a mail with instructions how to reset your password.',
        },
        severity: 2,
    },
    NOT_COMPANY_TABLE: {
        code: 1007,
        message: {
            prompt: 'ApiResponse.NOT_COMPANY_TABLE',
            default: 'This service is applicable to company-related tables only.',
        },
        severity: 3,
    },
    INVALID_SERVICE_CALL: {
        code: 1008,
        message: {
            prompt: 'ApiResponse.INVALID_SERVICE_CALL',
            default: 'Invalid service call',
        },
        severity: 2,
    },
    INVALID_HASH: {
        code: 1010,
        message: {
            prompt: 'ApiResponse.INVALID_HASH',
            default: 'Invalid hash',
        },
        severity: 2,
    },
    POSTCODE_NOT_FOUND: {
        code: 1009,
        message: {
            prompt: 'ApiResponse.POSTCODE_NOT_FOUND',
            default: 'Postcode not found',
        },
        severity: 1,
    },
    POSTCODE_COUNTRY_NOT_SUPPORTED: {
        code: 1011,
        message: {
            prompt: 'ApiResponse.POSTCODE_COUNTRY_NOT_SUPPORTED',
            default: 'Postcode lookup is not supported in the selected country',
        },
        severity: 1,
    },
    CAN_NOT_CREATE_RECORD_FOR_DELETED_PARENT: {
        code: 1010,
        message: {
            prompt: 'ApiResponse.CAN_NOT_CREATE_RECORD_FOR_DELETED_PARENT',
            default: 'Can not create record. Parent is deleted.',
        },
        severity: 1,
    },
    MISSING_RECORD: {
        code: 1401,
        message: {
            prompt: 'ApiResponse.MISSING_RECORD',
            default: 'No record found to update',
        },
        severity: 2,
    },
    CUSTOM_DEFINED_RECORD_DUPLICATION_ERROR: {
        code: 1402,
        message: {
            prompt: 'ApiResponse.CUSTOM_DEFINED_RECORD_DUPLICATION_ERROR',
            default: 'Record with (unique) data already exists',
        },
        severity: 2,
    },
    EMPTY_RESULTSET: {
        code: 1403,
        message: {
            prompt: 'ApiResponse.EMPTY_RESULTSET',
            default: 'No results to show',
        },
        severity: 1,
    },
    CANCELED_INVITATION: {
        code: 1404,
        message: {
            prompt: 'ApiResponse.CANCELED_INVITATION',
            default: 'We can not set up the connection. The other company might canceled the invitation.',
        },
        severity: 1,
    },
    INVALID_INVITATION: {
        code: 1405,
        message: {
            prompt: 'ApiResponse.INVALID_INVITATION',
            default: 'Invalid invitation. The most likely reason is because the invitation belongs to another company.',
        },
        severity: 2,
    },
    SUCCESS: {
        code: 1,
    },
    UNKNOWN_USERDEVICE_ID: {
        code: 1406,
        message: {
            prompt: 'Sync.DatabaseReloadWarning',
            default: 'Please wait, Powered Now is reloading your data.',
        },
        severity: 2,
    },
    NO_OPEN_SLOT: {
        code: 1407,
        message: {
            prompt: 'ApiResponse.NO_OPEN_SLOT',
            default: 'No open slot for user.',
        },
        severity: 2,
    },
    CONSUMPTION_ALREADY_EXISTS: {
        code: 1408,
        message: {
            prompt: 'ApiResponse.CONSUMPTION_ALREADY_EXISTS',
            default: 'consumptionExtra already exists in DB for this subscription',
        },
        severity: 2,
    },
    SUBSCRIPTION_ALREADY_EXISTS: {
        code: 1409,
        message: {
            prompt: 'ApiResponse.SUBSCRIPTION_ALREADY_EXISTS',
            default: 'Purchase already exists in DB for this subscription',
        },
        severity: 2,
    },
    FILE_UPLOAD_FAILED: {
        code: 1410,
        message: 'File upload failed. Please try another file or contact support.',
        severity: 6,
    },
    IMPORT_VALIDATION_ERROR: {
        code: 1500,
        message: {
            prompt: 'ApiValidationResponse.IMPORT_VALIDATION_ERROR',
            default: 'Some error occurred during importing data: %s',
        },
        // statusCode: 401,
        severity: 2,
    },
    IMPORT_SUCCESSFUL: {
        code: 1501,
        message: {
            prompt: 'ApiResponse.IMPORT_SUCCESSFUL',
            default: 'Imported successfully finished',
        },
        severity: 0,
    },
    MISSING_DOCUMENT: {
        code: 1600,
        message: {
            prompt: 'ApiResponse.MISSING_DOCUMENT',
            default: 'Document not found!',
        },
        severity: 2,
    },
    OPERATION_NOT_ALLOWED: {
        code: 1601,
        message: {
            prompt: 'ApiResponse.OPERATION_NOT_ALLOWED',
            default: 'You are not authorized for this operation on this field!',
        },
        severity: 2,
    },
    MISSING_PAYMENT_CHECKOUT: {
        code: 1602,
        message: {
            prompt: 'ApiResponse.MISSING_PAYMENT_CHECKOUT',
            default: 'Payment checkout not found!',
        },
        severity: 2,
    },
    PAYMENT_FAILED: {
        code: 1603,
        message: {
            prompt: 'ApiResponse.PAYMENT_FAILED',
            default: 'Payment has failed!',
        },
        severity: 2,
    },
    PAYMENT_NOT_COMPLETED: {
        code: 1604,
        message: {
            prompt: 'ApiResponse.PAYMENT_NOT_COMPLETED',
            default: 'Payment has not been completed!',
        },
        severity: 2,
    },
    SUMUP_REFRESH_TOKEN_INVALID: {
        code: 1605,
        message: {
            prompt: 'ApiResponse.SUMUP_TOKEN_INVALID',
            default: 'SumUp refresh token is no longer valid!',
        },
        severity: 4,
    },
    NOT_ARRAY: {
        message: 'Data is not Array',
        code: 1750,
        severity: 2,
    },
    EMPTY_PARAMETERS: {
        message: 'Empty parameters',
        code: 1760,
        severity: 4,
    },
    BAD_REQUEST: {
        code: 1007,
        message: {
            prompt: 'ApiResponse.BAD_REQUEST',
            default: 'Bad request',
        },
        severity: 6,
        statusCode: 400,
    },

    MISSING_FOREIGN_RECORD: {
        message: 'Missing foreign record',
        code: 1800,
        severity: 6,
    },

    PATCH_ERROR: {
        message: 'Patch not applicable',
        code: 1801,
        severity: 6,
    },
    MISSING_CALCULATION_TYPE: {
        message: 'Calculation types missing for documents',
        code: 1900,
        severity: 6,
    },
    CHARGEBEE_ERROR: {
        code: 2000,
        severity: 8,
    },
    BOOKING_TIME_UNAVAILABLE: {
        message: 'Booking time is not available',
        code: 1901,
        severity: 2,
    },
    DOCUMENT_IS_DELETED: {
        message: 'The document has been deleted',
        code: 1902,
        severity: 2,
    },
};
