import constants from '@powerednow/shared/constants';

const modelProperties = {
    modelName: 'Company',
    tableName: 'Company',
    associations: [{
        type: 'hasOne',
        model: 'User',
        parameters: { as: 'MainUser', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'User',
        parameters: { as: 'Users', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'UserDevice',
        parameters: { as: 'UserDevices', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'ConsumptionStats',
        parameters: {
            as: 'ConsumptionStats',
            complexAssociationName: 'consumptionStats',
            foreignKey: { name: 'company_id', fieldName: 'company_id' }, 
        },
    }, {
        type: 'hasMany',
        model: 'ConsumptionExtra',
        parameters: {
            as: 'ConsumptionExtras',
            complexAssociationName: 'consumptionExtra',
            foreignKey: { name: 'company_id', fieldName: 'company_id' },
        },
    }, {
        type: 'hasMany',
        model: 'Message',
        parameters: { as: 'Messages', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'Document',
        parameters: { as: 'Documents', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'DocumentLogo',
        parameters: { as: 'DocumentLogos', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'FormDocument',
        parameters: { as: 'FormDocuments', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'Action',
        parameters: { as: 'Actions', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'Customer',
        parameters: { as: 'Customers', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'ApiTransaction',
        parameters: { as: 'ApiTransactions', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'Job',
        parameters: { as: 'Jobs', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasOne',
        model: 'SubscriptionStats',
        parameters: { as: 'SubscriptionStats', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'belongsTo',
        model: 'WhiteLabel',
        parameters: { as: 'WhiteLabel', foreignKey: { name: 'whitelabel_id', fieldName: 'whitelabel_id' } },
    }, {
        type: 'belongsTo',
        model: 'BusinessTypes',
        parameters: { as: 'BusinessTypes', foreignKey: { name: 'businesstypes_id', fieldName: 'businesstypes_id' } },
    }, {
        type: 'belongsTo',
        model: 'Language',
        parameters: { as: 'Language', foreignKey: { name: 'language_id', fieldName: 'language_id' } },
    }, {
        type: 'belongsTo',
        model: 'Currency',
        parameters: { as: 'Currency', foreignKey: { name: 'currency_id', fieldName: 'currency_id' } },
    }, {
        type: 'hasMany',
        model: 'CompanyAddress',
        parameters: { as: 'CompanyAddress', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasOne',
        model: 'Feature',
        parameters: { as: 'Feature', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'Purchases',
        parameters: { as: 'Purchases', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
        followFor: ['complexData-company-purchases'],
    }, {
        type: 'hasMany',
        model: 'PaymentSetting',
        parameters: { as: 'PaymentSetting', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasOne',
        model: 'AuthConfig',
        parameters: {
            as: 'HMRCConnection',
            foreignKey: { name: 'company_id', fieldName: 'company_id' },
            scope: { type: constants.OAUTH2.TYPES.HMRC, active: true },
        },
    }, {
        type: 'hasMany',
        model: 'UserProfileRoleCustom',
        parameters: { as: 'UserProfileRoleCustom', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'AppointmentType',
        parameters: { as: 'AppointmentType', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'BookingDateException',
        parameters: { as: 'BookingDateException', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'hasMany',
        model: 'CompanySettings',
        parameters: { as: 'CompanySettings', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }],
    hooks: [{
        localFunction: 'callWebHooks',
        key: 'updateCompany',
        delay: 10000,
        remoteFunction: 'saveAndSync',
        checkValueChange: true,
        structure: 3,
    }, {
        localFunction: 'updateInChargebee',
        key: 'chargebee',
        delay: 5000,
        remoteFunction: 'save',
        checkValueChange: true,
        structure: 4,
    }],
    validations: {
        stationaryRadius: { type: 'range', min: 10, max: 500 },
        name: { type: 'length', min: 1, max: 60 },
        vat_id: { type: 'length', max: 60 },
        default_communication: { type: 'length', max: 10 },
        logo_url: { type: 'length', max: 255 },
        logo_size: { type: 'length', max: 45 },
        email: { type: 'length', max: 60 },
        bccEmail: { type: 'length', max: 60 },
        phone: { type: 'length', max: 128 },
        workday_start: { type: 'length', max: 6 },
        workday_end: { type: 'length', max: 6 },
        utr: { type: 'length', max: 20 },
        insurance_number: { type: 'length', max: 60 },
        company_number: { type: 'length', max: 60 },
        tax_reference: { type: 'length', max: 60 },
        verification_number: { type: 'length', max: 60 },
        tax_year_end: { type: 'length', max: 11 },
        cis_reporting_day: { type: 'length', max: 11 },
        unique_tax_name: { type: 'length', max: 60 },
        defaultPaymentTerm: { type: 'range', min: 0, max: 180 },
    },
};

export default modelProperties;
