const moment = require('moment');

moment.prototype.toJSON = function toJSON() {
    if (this.isValid()) {
        return this.format('YYYY-MM-DD HH:mm:ss');
    }
    if (this.parsingFlags().nullInput) {
        return null;
    }
    return '0000-00-00 00:00:00';
};

export default moment;
