import Entity, { EntityWithId, field } from '../entity';
    
export default class CountryFeatureEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'int' })
    public country_id: number;
    
    @field({ type: 'int' })
    public type: number;
    
    @field({ type: 'string' })
    public params: string;
}
