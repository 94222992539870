const modelProperties = {
    modelName: 'Roles',
    tableName: 'roles',
    associations: [{
        type: 'hasMany',
        model: 'UserProfileRole',
        parameters: { foreignKey: { name: 'role_id', fieldName: 'role_id' } },
    }],
    global: true,
};

export default modelProperties;
