export default {
    STATUS: {
        0: 'Obsolete',
        1: 'Active',
        2: 'Test',
    },
    STATUS_KEYS: {
        OBSOLETE: 0,
        ACTIVE: 1,
        TEST: 2,
    },
};
