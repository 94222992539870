import Customer from 'modules/complexData/customer';
import WhiteLabel from 'modules/complexData/whiteLabel';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import WhiteLabelToCustomerEntity from './entity';
import modelProperties from './modelProperties';
import CustomerEntity from '../customer/entity';
import WhiteLabelEntity from '../whiteLabel/entity';

interface WhiteLabelToCustomerAssociations extends AssociationConfig<any, any> {
    customer: AssociationDefinitionSingle<CustomerEntity, Customer>
    whiteLabel: AssociationDefinitionSingle<WhiteLabelEntity, WhiteLabel>
}

interface WhiteLabelToCustomer extends AutoGeneratedFunctions<WhiteLabelToCustomerAssociations, WhiteLabelToCustomerEntity, ComplexData<WhiteLabelToCustomerEntity>> {}

// eslint-disable-next-line no-redeclare
class WhiteLabelToCustomer extends ComplexData<WhiteLabelToCustomerEntity> {
    static Entity = WhiteLabelToCustomerEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): WhiteLabelToCustomerAssociations {
        return {
            customer: {
                key: 'customer',
                instance: Customer,
                entity: CustomerEntity,
                single: true,
                cascadeDelete: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().customer_id,
                },
            },
            whiteLabel: {
                key: 'whiteLabel',
                instance: WhiteLabel,
                entity: WhiteLabelEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().whitelabel_id,
                },
            },
        };
    }
}

export default WhiteLabelToCustomer;
