import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class MessageTemplateEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'int' })
    public messageType: number;

    @field({ type: 'int' })
    public channelTypeId: number;

    @field({ type: 'string' })
    public subject: string;

    @field({ type: 'string' })
    public message: string;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;
}
