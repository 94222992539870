const modelProperties = {
    modelName: 'ProductTierToSubscription',
    tableName: 'ProductTierToSubscription',
    minAppVersion: '9.0.0',
    global: true,
    associations: [{
        type: 'belongsTo',
        model: 'ProductTier',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'product_tier_id', fieldName: 'product_tier_id' } },
    }],
};

export default modelProperties;
