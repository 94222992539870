import { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class ActionEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created?: DateOrString;

    @field({ type: 'int' })
    public job_id?: number;

    @field({ type: 'int' })
    public site_id?: number;

    @field({ type: 'int', defaultValue: null, allowNull: true })
    public user_id?: number;

    @field({ type: 'string' })
    public plot?: string;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'string' })
    public action: string;

    @field({ type: 'string' })
    public description?: string;

    @field({ type: 'int' })
    public type: number;

    @field({ type: 'int' })
    public plan_type?: number;

    @field({ type: 'string', dbType: 'date' })
    public dt_alert: DateOrString;

    @field({ type: 'string', dbType: 'date' })
    public dt_start?: DateOrString;

    @field({ type: 'string', dbType: 'date' })
    public dt_end?: DateOrString;

    @field({ type: 'boolean' })
    public is_open?: boolean;

    @field({ type: 'int' })
    public linked_id?: number;

    @field({ type: 'int' })
    public linked_type?: number;

    @field({
        type: 'boolean', codeName: 'isDeleted', serverOnly: true, defaultValue: false, 
    })
    public isdeleted?: boolean;

    @field({ type: 'boolean', codeName: 'isSent' })
    public issent?: boolean;

    @field({ type: 'boolean', codeName: 'isNotToSend' })
    public is_not_to_send?: boolean;

    @field({ type: 'boolean' })
    public invite_customer?: boolean;

    @field({
        type: 'boolean', codeName: 'isArchived', serverOnly: true, defaultValue: false, 
    })
    public is_archived?: boolean;

    @field({ minAppVersion: '7.5.0', type: 'int' })
    public form_document_id?: number;

    @field({ type: 'boolean' })
    public has_pending_checkin?: boolean;

    @field({ type: 'string', minAppVersion: '8.0.4' })
    public recurring_group_id?: string;

    @field({ type: 'int', minAppVersion: '9.0.0' })
    public duration_id?: number;

    @field({ type: 'int', minAppVersion: '9.0.0' })
    public appointmenttype_id?: number;

    @field({ type: 'int', minAppVersion: '8.5.0' })
    public status?: number;

    @field({ type: 'int', minAppVersion: '8.5.0' })
    public value?: number;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_pinned?: boolean;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_marked?: boolean;

    @field({
        type: 'int', minAppVersion: '8.5.7', serverOnly: true, defaultValue: null, allowNull: true, 
    })
    public expert_trades_id?: number;

    @field({ type: 'boolean', minAppVersion: '9.0.0' })
    public is_customer_booked?: boolean;

    @field({ type: 'string', dbType: 'date', minAppVersion: '9.0.0' })
    public dt_requested?: DateOrString;

    @field({ type: 'int', minAppVersion: '9.0.0' })
    public requested_duration_id?: number;

    @field({ type: 'boolean', minAppVersion: '9.0.0', defaultValue: false })
    public confirmed?: boolean;
}
