import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class CustomerEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;

    @field({ type: 'string', defaultValue: '' })
    public company?: string;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created?: DateOrString;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'boolean', defaultValue: true })
    public is_customer?: boolean;

    @field({ type: 'boolean', defaultValue: false })
    public is_supplier?: boolean;

    @field({ minAppVersion: '4.2.0', type: 'boolean' })
    public is_nosupplier?: boolean;

    @field({ type: 'boolean', defaultValue: false })
    public is_staff?: boolean;

    @field({ type: 'string' })
    public notes?: string;

    @field({ minAppVersion: '4.2.0', type: 'boolean', defaultValue: false })
    public cis_enabled?: boolean;

    @field({ type: 'string' })
    public utr?: string;

    @field({ type: 'string' })
    public insurance_number?: string;

    @field({ type: 'string' })
    public company_number?: string;

    @field({ type: 'string' })
    public tax_reference?: string;

    @field({ type: 'string' })
    public vat_number?: string;

    @field({ type: 'string' })
    public verification_number?: string;

    @field({ type: 'string' })
    public reference?: string;

    @field({ minAppVersion: '4.2.0', type: 'int' })
    public cis_rate_id?: number;

    @field({ minAppVersion: '4.2.0', type: 'int' })
    public external_company_id?: number;

    @field({ minAppVersion: '4.2.0', type: 'int' })
    public connection_status?: number;

    @field({ minAppVersion: '4.2.0', type: 'string' })
    public invitation_hash?: string;

    @field({ defaultValue: false, minAppVersion: '6.8.0', type: 'boolean' })
    public optout?: boolean;

    @field({
        type: 'boolean', codeName: 'isDeleted', serverOnly: true, defaultValue: false, 
    })
    public isdeleted?: boolean;

    @field({ type: 'string', minAppVersion: '8.0.0', defaultValue: null })
    public accounting_reference?: string;

    @field({ type: 'string', minAppVersion: '8.0.0', defaultValue: null })
    public sync_uuid?: string;

    @field({ type: 'string', minAppVersion: '8.0.0', defaultValue: null })
    public synced_uuid?: string;

    @field({ type: 'string', defaultValue: '', minAppVersion: '8.1.0' })
    public account_name?: string;

    @field({ type: 'boolean', defaultValue: true, minAppVersion: '8.4.0' })
    public is_simple?: boolean;

    @field({
        type: 'int', minAppVersion: '8.5.7', serverOnly: true, defaultValue: null, allowNull: true, 
    })
    public expert_trades_id?: number;

    @field({ type: 'boolean', defaultValue: false, minAppVersion: '9.3.0' })
    public isImported?: boolean;
}
