import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class ConsumptionExtraEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'string' })
    public type: string;

    @field({ type: 'int' })
    public count: number;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created?: DateOrString;
    
    @field({ type: 'string' })
    public product: string;
    
    @field({ type: 'string' })
    public receipt?: string;
    
    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted?: boolean;
    
    @field({ type: 'int' })
    public quantity: number;
    
    @field({ type: 'string' })
    public order_id?: string;
    
    @field({ codeName: 'transactionId', type: 'string' })
    public transaction_id?: string;
    
    @field({ type: 'string', dbType: 'date' })
    public purchase_date?: DateOrString;
    
    @field({ type: 'string', dbType: 'date' })
    public expires_date?: DateOrString;
    
    @field({ type: 'int' })
    public isverified?: number;
    
    @field({ type: 'string' })
    public purchase_origin?: string;
    
    @field({ type: 'string' })
    public purchase_currency?: string;
    
    @field({ type: 'decimal' })
    public purchase_amount?: number;
    
    @field({ type: 'string' })
    public original_price?: string;
}
