import { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class MessageEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'int' })
    public parent_id: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'string' })
    public recipient: string;

    @field({ type: 'int' })
    public customer_id: number;

    @field({ type: 'int' })
    public user_id: number;

    @field({ type: 'int' })
    public document_id: number;

    @field({ type: 'int' })
    public action_id: number;

    @field({ type: 'int' })
    public job_id: number;

    @field({ type: 'int', codeName: 'contactMethodTypeId' })
    public contactmethodtype_id: number;

    @field({ type: 'string' })
    public subject: string;

    @field({ type: 'string', excludeFromClientDownload: true })
    public message: string;

    @field({ defaultValue: 1, type: 'int' })
    public direction: number;

    @field({ defaultValue: 'doc', type: 'string' })
    public type: string;

    @field({ defaultValue: 0, type: 'int' })
    public status: number;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;

    @field({ isJSON: true, type: 'string' })
    public options: { addSelfAsCC?: boolean; recipientName?: string; email?: string, contactId?: number };

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;

    @field({ type: 'string', dbType: 'date' })
    public dt_sent: DateOrString;

    @field({ type: 'int' })
    public mandrill_id: number;

    @field({ type: 'int' })
    public opens: number;

    @field({ type: 'int' })
    public clicks: number;

    @field({ defaultValue: 0, type: 'int' })
    public attachment_count: number;

    @field({ type: 'int', allowNull: true, defaultValue: 0 })
    public msg_error_code: number;

    @field({ type: 'string', allowNull: true, defaultValue: '' })
    public msg_error_message: string;

    @field({ defaultValue: 0, type: 'int' })
    public provider_id: number;

    @field({ type: 'boolean', codeName: 'isArchived', serverOnly: true })
    public is_archived: boolean;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_pinned?: boolean;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_marked?: boolean;
}
