const constants = require('@powerednow/shared/constants').default;
const moment = require('moment');
const _ = require('lodash');

const objectUtils = require('modules/utilities/object');

export default class SyncAction {
    public data: any = null;

    constructor(params: any = null) {
        if (params) {
            const {
                action, userId, extraData, fromServer, 
            } = params;
            this.data = {
                request: {
                    action,
                    userId,
                    extraData,
                    fromServer,
                    timeStamp: moment().utc(),
                },
            };
        }
    }

    public get fromServer() {
        return this.data.request.fromServer;
    }

    public toData(): any {
        return this.data;
    }

    public attachHeadersFromExpressRequest(requestObject): void {
        this.data.request.extraData = {
            headers: {
                ...this.data.request.extraData.headers,
                ...requestObject.headers,
            },
            client: {
                remotePort: requestObject.client.remotePort,
            },
            connection: {
                remoteAddress: requestObject.connection.remoteAddress,
            },
        };
    }

    public responseSuccess(extraData = null): SyncAction {
        this.data.response = {
            extraData,
            result: constants.TAX.VAT_ACTION_RESULT.OK,
            timeStamp: moment().utc(),
        };

        return this;
    }

    public responseFailure(errorObject): SyncAction {
        const error = objectUtils.errorToJSON(errorObject);
        this.data.response = {
            error,
            result: constants.TAX.VAT_ACTION_RESULT.ERROR,
            timeStamp: moment().utc(),
        };
        return this;
    }

    public isRequest(): boolean {
        return this.data.request && !this.data.response;
    }

    public isResponseTo(syncAction): boolean {
        return this.data.response
            && syncAction
            && this.data.request.action === syncAction.data.request.action;
    }

    public static fromData(data: any): SyncAction {
        const syncAction = new SyncAction();
        syncAction.data = data;
        return syncAction;
    }

    public request(): any {
        return this.data.request;
    }

    public response(): any {
        return this.data.response;
    }

    public result(): any {
        return this.data.response.result;
    }

    public error(): any {
        return this.data.response.error;
    }
}
