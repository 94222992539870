import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import ConsumptionStatsEntity from './entity';
import modelProperties from './modelProperties';

interface ConsumptionStatsAssociations extends AssociationConfig<any, any> {}

interface ConsumptionStats extends AutoGeneratedFunctions<ConsumptionStatsAssociations, ConsumptionStatsEntity, ComplexData<ConsumptionStatsEntity>> {}

// eslint-disable-next-line no-redeclare
class ConsumptionStats extends ComplexData<ConsumptionStatsEntity> {
    static Entity = ConsumptionStatsEntity;

    static modelProperties = modelProperties;
}

export default ConsumptionStats;
