import constants from '@powerednow/shared/constants';
import Entity, { DateOrString, EntityWithId, field } from '../entity';
import DOCUMENT from '../../../constants/document';
import ARCHIVE from '../../../constants/archive';

const dateUtils = require('modules/utilities/date');

export const NOTIFICATION = {
    NOTIFY_ON_QUOTE: 'notify_on_quote',
    NOTIFY_ON_PAYMENT: 'notify_on_payment',
    NOTIFY_ON_TASK: 'notify_on_task',
    NOTIFY_ON_READS: 'notify_on_reads',
    NOTIFY_ON_EMAIL: 'notify_on_email',
    NOTIFY_ON_CHAT: 'notify_on_chat',
    NOTIFY_MAIN_EMAILS: 'notify_main_emails',
    INVITE_CUSTOMER_ON_APPOINTMENT_SYNC: 'invite_customer_on_appointment_sync',
    INVITE_ALLOCATED_USER_ON_APPOINTMENT_SYNC: 'invite_allocated_user_on_appointment_sync',
    NOTIFY_ON_CUSTOMER_APPOINTMENT: 'notify_on_customerAppointment',
    NOTIFY_ON_USER_LOGIN: 'notify_on_user_login',
    NOTIFY_CUSTOMER_ON_FORM_DOCUMENT_REMINDER: 'notify_customer_on_form_document_reminder',
    NOTIFY_USERS: 'notify_users',
};
export default class CompanyEntity extends EntityWithId {
    @field({
        type: 'int',
        primaryKey: true,
        autoIncrement: true,
    })
    public id: number;

    @field({
        type: 'string',
        hooks: {
            afterUpdate: [{
                runAfterCommit: true,
                handler: 'checkTestCompanyConditions',
                args: [],
            }],
        },
    })
    public name: string;

    @field({ type: 'int', codeName: 'businessTypesId' })
    public businesstypes_id: number;

    @field({ type: 'boolean', defaultValue: false, allowNull: false })
    public vat_registered: boolean;

    @field({
        type: 'string',
    })
    public vat_id: string;

    @field({
        type: 'boolean',
        defaultValue: false,
        allowNull: false,
        hooks: {
            afterUpdate: [{
                runAfterCommit: true,
                handler: 'tagHubspot',
                args: ['added_vat'],
            }],
        },
    })
    public tax_inclusive: boolean;

    @field({ type: 'int', defaultValue: 1, allowNull: false })
    public default_taxrate_id: number;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;

    @field({ type: 'string' })
    public default_communication: string;

    @field({ type: 'string' })
    public logo_url: string;

    @field({ type: 'string' })
    public logo_size: string;

    @field({
        type: 'string',
        defaultValue: '',
    })
    public defaultQuoteHeader: string;

    @field({
        defaultValue: 'Quote valid for 30 days.',
        type: 'string',
    })
    public defaultQuoteFooter: string;

    @field({
        type: 'string',
        defaultValue: '',
    })
    public defaultInvoiceHeader: string;

    @field({
        defaultValue: 'Payment terms 7 days from date of invoice.',
        type: 'string',
    })
    public defaultInvoiceFooter: string;

    @field({
        type: 'string',
        defaultValue: '',
    })
    public defaultWorksheetHeader: string;

    @field({
        type: 'string',
        defaultValue: '',
    })
    public defaultWorksheetFooter: string;

    @field({
        type: 'string',
        defaultValue: '',
    })
    public defaultExpense_creditnoteHeader: string;

    @field({
        type: 'string',
        defaultValue: '',
    })
    public defaultExpense_creditnoteFooter: string;

    @field({
        type: 'string',
        defaultValue: '',
    })
    public defaultExpenseHeader: string;

    @field({
        type: 'string',
        defaultValue: '',
    })
    public defaultExpenseFooter: string;

    @field({
        type: 'string',
        defaultValue: '',
        codeName: 'defaultCreditNoteHeader',
    })
    public defaultCreditnoteHeader: string;

    @field({
        type: 'string',
        defaultValue: '',
        codeName: 'defaultCreditNoteFooter',
    })
    public defaultCreditnoteFooter: string;

    @field({ type: 'string', defaultValue: 'I accept the work carried out is to a satisfactory standard' })
    public defaultSignatureText: string;

    @field({ defaultValue: 14, type: 'int' })
    public default_document_template: number;

    @field({
        isJSON: true,
        defaultValue: {
            expense_creditnote_show_title: 1,
            expense_creditnote_show_description: 1,
            expense_creditnote_show_headers: 1,
            expense_creditnote_show_line: 1,
            expense_creditnote_show_sku: 0,
            expense_creditnote_show_line_description: 1,
            expense_creditnote_show_quantity: 1,
            expense_creditnote_show_unit_cost: 1,
            expense_creditnote_show_amount: 1,
            expense_creditnote_show_vat_indicator: 0,
            expense_creditnote_show_vat_rate: 1,
            expense_creditnote_show_vat_amount: 1,
            expense_creditnote_show_line_total: 1,
            expense_creditnote_show_subtotal: 1,
            expense_creditnote_show_discount: 1,
            expense_creditnote_show_vat: 1,
            expense_creditnote_show_total: 1,
        },
        type: 'string',
    })
    public priced_item_expense_creditnote_layout: { [name: string]: any };

    @field({
        isJSON: true,
        defaultValue: {
            expense_show_title: 1,
            expense_show_description: 1,
            expense_show_headers: 1,
            expense_show_line: 1,
            expense_show_sku: 0,
            expense_show_line_description: 1,
            expense_show_quantity: 1,
            expense_show_unit_cost: 1,
            expense_show_amount: 1,
            expense_show_vat_indicator: 0,
            expense_show_vat_rate: 1,
            expense_show_vat_amount: 1,
            expense_show_line_total: 1,
            expense_show_subtotal: 1,
            expense_show_discount: 1,
            expense_show_vat: 1,
            expense_show_total: 1,
        },
        type: 'string',
    })
    public priced_item_expense_layout: { [name: string]: any };

    @field({
        isJSON: true,
        defaultValue: {
            invoice_show_title: 1,
            invoice_show_description: 1,
            invoice_show_headers: 1,
            invoice_show_line: 1,
            invoice_show_sku: 0,
            invoice_show_line_description: 1,
            invoice_show_quantity: 1,
            invoice_show_unit_cost: 1,
            invoice_show_amount: 1,
            invoice_show_vat_indicator: 0,
            invoice_show_vat_rate: 1,
            invoice_show_vat_amount: 1,
            invoice_show_line_total: 1,
            invoice_show_subtotal: 1,
            invoice_show_discount: 1,
            invoice_show_vat: 1,
            invoice_show_total: 1,
        },
        type: 'string',
    })
    public priced_item_invoice_layout: { [name: string]: any };

    @field({
        isJSON: true,
        defaultValue: {
            quote_show_title: 1,
            quote_show_description: 1,
            quote_show_headers: 1,
            quote_show_line: 1,
            quote_show_sku: 0,
            quote_show_line_description: 1,
            quote_show_quantity: 0,
            quote_show_unit_cost: 0,
            quote_show_amount: 0,
            quote_show_vat_indicator: 0,
            quote_show_vat_rate: 0,
            quote_show_vat_amount: 0,
            quote_show_line_total: 1,
            quote_show_subtotal: 1,
            quote_show_discount: 1,
            quote_show_vat: 1,
            quote_show_total: 1,
        },
        type: 'string',
    })
    public priced_item_quote_layout: { [name: string]: any };

    @field({
        isJSON: true,
        defaultValue: {
            worksheet_show_title: 1,
            worksheet_show_description: 1,
            worksheet_show_headers: 1,
            worksheet_show_line: 1,
            worksheet_show_sku: 0,
            worksheet_show_line_description: 1,
            worksheet_show_quantity: 1,
            worksheet_show_unit_cost: 0,
            worksheet_show_amount: 0,
            worksheet_show_vat_indicator: 0,
            worksheet_show_vat_rate: 0,
            worksheet_show_vat_amount: 0,
            worksheet_show_line_total: 0,
            worksheet_show_subtotal: 0,
            worksheet_show_discount: 0,
            worksheet_show_vat: 0,
            worksheet_show_total: 0,
        },
        type: 'string',
    })
    public priced_item_worksheet_layout: { [name: string]: any };

    @field({
        isJSON: true,
        defaultValue: {
            creditnote_show_title: 1,
            creditnote_show_description: 1,
            creditnote_show_headers: 1,
            creditnote_show_line: 1,
            creditnote_show_sku: 0,
            creditnote_show_line_description: 1,
            creditnote_show_quantity: 1,
            creditnote_show_unit_cost: 1,
            creditnote_show_amount: 1,
            creditnote_show_vat_indicator: 0,
            creditnote_show_vat_rate: 1,
            creditnote_show_vat_amount: 1,
            creditnote_show_line_total: 1,
            creditnote_show_subtotal: 1,
            creditnote_show_discount: 1,
            creditnote_show_vat: 1,
            creditnote_show_total: 1,
        },
        type: 'string',
        codeName: 'pricedItemCreditNoteLayout',
    })
    public priced_item_creditnote_layout: { [name: string]: any };

    @field({
        isJSON: true,
        defaultValue: {
            purchase_order_show_title: 1,
            purchase_order_show_description: 1,
            purchase_order_show_headers: 1,
            purchase_order_show_line: 1,
            purchase_order_show_sku: 1,
            purchase_order_show_line_description: 1,
            purchase_order_show_quantity: 1,
            purchase_order_show_unit_cost: 1,
            purchase_order_show_amount: 1,
            purchase_order_show_vat_indicator: 0,
            purchase_order_show_vat_rate: 0,
            purchase_order_show_vat_amount: 0,
            purchase_order_show_line_total: 0,
            purchase_order_show_subtotal: 1,
            purchase_order_show_discount: 1,
            purchase_order_show_vat: 1,
            purchase_order_show_total: 1,
        },
        type: 'string',
        codeName: 'pricedItemPurchaseOrderLayout',
    })
    public priced_item_purchase_order_layout: { [name: string]: any };

    @field({
        isJSON: true,
        defaultValue: {
            supplier_invoice_show_title: 1,
            supplier_invoice_show_description: 1,
            supplier_invoice_show_headers: 1,
            supplier_invoice_show_line: 1,
            supplier_invoice_show_sku: 1,
            supplier_invoice_show_line_description: 1,
            supplier_invoice_show_quantity: 1,
            supplier_invoice_show_unit_cost: 1,
            supplier_invoice_show_amount: 1,
            supplier_invoice_show_vat_indicator: 0,
            supplier_invoice_show_vat_rate: 0,
            supplier_invoice_show_vat_amount: 0,
            supplier_invoice_show_line_total: 0,
            supplier_invoice_show_subtotal: 1,
            supplier_invoice_show_discount: 1,
            supplier_invoice_show_vat: 1,
            supplier_invoice_show_total: 1,
        },
        type: 'string',
        codeName: 'pricedItemSupplierInvoiceLayout',
    })
    public priced_item_supplier_invoice_layout: { [name: string]: any };

    @field({
        isJSON: true,
        defaultValue: {
            supplier_invoice_credit_show_title: 1,
            supplier_invoice_credit_show_description: 1,
            supplier_invoice_credit_show_headers: 1,
            supplier_invoice_credit_show_line: 1,
            supplier_invoice_credit_show_sku: 1,
            supplier_invoice_credit_show_line_description: 1,
            supplier_invoice_credit_show_quantity: 1,
            supplier_invoice_credit_show_unit_cost: 1,
            supplier_invoice_credit_show_amount: 1,
            supplier_invoice_credit_show_vat_indicator: 0,
            supplier_invoice_credit_show_vat_rate: 0,
            supplier_invoice_credit_show_vat_amount: 0,
            supplier_invoice_credit_show_line_total: 0,
            supplier_invoice_credit_show_subtotal: 1,
            supplier_invoice_credit_show_discount: 1,
            supplier_invoice_credit_show_vat: 1,
            supplier_invoice_credit_show_total: 1,
        },
        type: 'string',
        codeName: 'pricedItemSupplierInvoiceCreditLayout',
    })
    public priced_item_supplier_invoice_credit_layout: { [name: string]: any };

    @field({ type: 'int', serverOnly: true })
    public cohort_id: number;

    @field({ type: 'int' })
    public currency_id: number;

    @field({ type: 'string' })
    public email: string;

    @field({ type: 'string', codeName: 'bccEmail' })
    public bccEmail: string;

    @field({ type: 'string' })
    public phone: string;

    @field({ isJSON: true, type: 'string', defaultValue: { windowLeftOffset: 25, windowTopOffset: 56, typeId: 1 } })
    public envelope_settings: { [name: string]: any };

    @field({
        isJSON: true,
        defaultValue: {
            [NOTIFICATION.NOTIFY_ON_QUOTE]: 1,
            [NOTIFICATION.NOTIFY_ON_PAYMENT]: 1,
            [NOTIFICATION.NOTIFY_ON_TASK]: 1,
            [NOTIFICATION.NOTIFY_ON_READS]: 1,
            [NOTIFICATION.NOTIFY_ON_EMAIL]: 1,
            [NOTIFICATION.NOTIFY_ON_CHAT]: 1,
            [NOTIFICATION.NOTIFY_MAIN_EMAILS]: 0,
            [NOTIFICATION.INVITE_CUSTOMER_ON_APPOINTMENT_SYNC]: 0,
            [NOTIFICATION.INVITE_ALLOCATED_USER_ON_APPOINTMENT_SYNC]: 1,
            [NOTIFICATION.NOTIFY_ON_USER_LOGIN]: 1,
            [NOTIFICATION.NOTIFY_ON_CUSTOMER_APPOINTMENT]: 0,
        },
        type: 'string',
    })
    public notify_settings: {
        notify_on_quote: number,
        notify_on_payment: number,
        notify_on_task: number,
        notify_on_reads: number,
        notify_on_email: number,
        notify_main_emails: number,
    };

    @field({ defaultValue: 1, type: 'int' })
    public quote_name_id: number;

    @field({ defaultValue: 0, type: 'int' })
    public data_delete_count: number;

    @field({ defaultValue: 0, type: 'int' })
    public benefit_screen_disabled: number;

    @field({ isJSON: true, type: 'string' })
    public layout_settings: { [name: string]: any };

    @field({ type: 'boolean' })
    public already_rated: boolean;

    @field({ type: 'string' })
    public bt_id: string;

    @field({ type: 'int' })
    public language_id: number;

    @field({ type: 'int', codeName: 'autoCompleteOption', defaultValue: 0 })
    public autocomplete_option: number;

    @field({ type: 'int', allowNull: true })
    public autocomplete_product_option: number;

    @field({ type: 'string' })
    public workday_start: string;

    @field({ type: 'string' })
    public workday_end: string;

    @field({ defaultValue: 1, type: 'int', codeName: 'displayPhoneNumber' })
    public displayPhonenumber: number;

    @field({ defaultValue: 1, type: 'int' })
    public displayCompanyAddress: number;

    @field({ type: 'boolean', codeName: 'autoSuppressQuantity' })
    public autoSupressQuantity: boolean;

    @field({ defaultValue: 1, type: 'int' })
    public showTooltips: number;

    @field({ codeName: 'attributionType', type: 'string' })
    public attributionType: string;

    @field({ codeName: 'mediaSource', type: 'string' })
    public mediaSource: string;

    @field({ codeName: 'campaignName', type: 'string' })
    public campaignName: string;

    @field({ codeName: 'adSetName', type: 'string' })
    public adSetName: string;

    @field({ type: 'string' })
    public subParam1: string;

    @field({ type: 'string' })
    public subParam2: string;

    @field({ type: 'string' })
    public subParam3: string;

    @field({ type: 'string' })
    public subParam4: string;

    @field({ type: 'string' })
    public subParam5: string;

    @field({ type: 'int' })
    public ab_random: number;

    @field({ type: 'int' })
    public ab_welcome_carousel: number;

    @field({ type: 'int' })
    public ab_registration: number;

    @field({
        codeName: 'documentNumberConfig',
        type: 'string',
        isJSON: true,
        defaultValue: {
            types: {
                invoice: { prefix: 'INV', suffix: '' },
                quote: { prefix: 'QTE', suffix: '' },
                est: { prefix: 'EST', suffix: '' },
                creditnote: { prefix: 'CR-', suffix: '' },
                signature: { prefix: 'SG-', suffix: '' },
                worksheet: { prefix: 'WS', suffix: '' },
                expense: { prefix: 'EXP', suffix: '' },
                expense_creditnote: { prefix: 'CR-', suffix: '' },
                supplier_invoice: { prefix: 'SUP', suffix: '' },
                supplier_invoice_credit: { prefix: 'SUP-CR', suffix: '' },
                purchase_order: { prefix: 'POR', suffix: '' },
            },
            incrementalLength: 6,
            allowOverride: false,
        },
    })
    public documentNumberConfig: {
        types: {
            invoice: { prefix: string, suffix: string },
            quote: { prefix: string, suffix: string },
            est: { prefix: string, suffix: string },
            creditnote: { prefix: string, suffix: string },
            signature: { prefix: string, suffix: string },
            worksheet: { prefix: string, suffix: string },
            expense: { prefix: string, suffix: string },
            expense_creditnote: { prefix: string, suffix: string },
            supplier_invoice: { prefix: string, suffix: string },
            supplier_invoice_credit: { prefix: string, suffix: string },
            purchase_order: { prefix: string, suffix: string },
        },
        incrementalLength: number,
        allowOverride: boolean,
    };

    @field({ type: 'int', codeName: 'teamSize' })
    public teamSize: number;

    @field({
        codeName: 'companySettings',
        type: 'string',
        isJSON: true,
        defaultValue: {
            extend_trial_disabled: false,
            extend_trial_survey: '',
            extend_trial_survey_a2: '',
            extend_trial_survey_a3: '',
            extend_trial_survey_a4: '',
            show_country: false,
            showQuoteSales: true,
            showInvoiceSales: true,
            showJobSheetSales: true,
            showProjectSales: true,
            show_docs_in_diary: true,
            show_reminders_in_diary: true,
            projectAutoCreationEnabled: true,
            projectNextActionDate: false,
            enable_paid_stamp: true,
        },
    })
    public companySettings: { [name: string]: any };

    @field({ codeName: 'useOwnEmailAddress', type: 'boolean' })
    public useOwnEmailAddress: boolean;

    @field({ type: 'string', serverOnly: true })
    public device_os_name: string;

    @field({
        type: 'string',
        serverOnly: true,
        hooks: { afterUpdate: [{ handler: 'handleChargeBeeIdChange', condition: { oldValue: null }, args: [] }] },
    })
    public chargebee_id: string;

    @field({ type: 'boolean' })
    public debugLocations: boolean;

    @field({ type: 'boolean' })
    public trackLocation: boolean;

    @field({ type: 'boolean' })
    public appointmentCheckin: boolean;

    @field({ codeName: 'appointmentCheckinNotifications', type: 'boolean' })
    public appointmentCheckinNotifications: boolean;

    @field({ codeName: 'lastISUpdate', type: 'string', dbType: 'date' })
    public lastISUpdate: DateOrString;

    @field({
        type: 'boolean',
    })
    public cis_enabled: boolean;

    @field({ minAppVersion: '4.2.0', type: 'int' })
    public cis_rate_id: number;

    @field({ type: 'string' })
    public utr: string;

    @field({ type: 'string' })
    public insurance_number: string;

    @field({ type: 'string' })
    public company_number: string;

    @field({ type: 'string' })
    public tax_reference: string;

    @field({ type: 'string' })
    public verification_number: string;

    @field({ type: 'string' })
    public tax_year_end: string;

    @field({ type: 'int' })
    public cis_reporting_day: number;

    @field({
        codeName: 'expenseInitialStatus',
        type: 'string',
        isJSON: true,
        defaultValue: {
            engineers: { status: 3, paymentType: 0 },
            managers: { status: 2, paymentType: 0 },
            main_users: { status: 2, paymentType: 0 },
            electronically_received: { status: 3, paymentType: 0 },
        },
    })
    public expenseInitialStatus: { [name: string]: any };

    @field({ codeName: 'allowZeroSupplierInvoice', type: 'boolean' })
    public allowZeroSupplierInvoice: boolean;

    @field({ codeName: 'allowDisbursements', type: 'boolean' })
    public allowDisbursements: boolean;

    @field({ codeName: 'isCategoryRequired', type: 'int' })
    public isCategoryRequired: number;

    @field({ type: 'int' })
    public expired_trial_id: number;

    @field({
        type: 'string',
        isJSON: true,
        defaultValue: { sales_payment_id: constants.PAYMENT.TYPES.BANKTRANSFER, expense_payment_id: 0, supplier_payment_id: 0 },
    })
    public default_payment_type_ids: { [name: string]: any };

    @field({ type: 'string' })
    public unique_tax_name: string;

    @field({ codeName: 'taxInclusiveExpense', type: 'boolean', defaultValue: true })
    public tax_inclusive_expense: boolean;

    @field({ codeName: 'defaultExpenseTaxRate', type: 'int' })
    public default_expense_tax_rate: number;

    @field({
        type: 'string',
        isJSON: true,
        defaultValue: {
            INVOICE: { subject: null, body: null },
            QUOTE: { subject: null, body: null },
            WORKSHEET: { subject: null, body: null },
            CREDITNOTE: { subject: null, body: null },
            SIGNATURE: { subject: null, body: null },
            SUPPLIER_INVOICE: { subject: null, body: null },
            SUPPLIER_INVOICE_CREDIT: { subject: null, body: null },
            PURCHASE_ORDER: { subject: null, body: null },
            EXPENSE: { subject: null, body: null },
            EXPENSE_CREDITNOTE: { subject: null, body: null },
            APPOINTMENT: { subject: null, body: null },
            PAYMENT: { subject: null, body: null },
            CREDITNOTE_ELECTRONIC: { subject: null, body: null },
            INVOICE_ELECTRONIC: { subject: null, body: null },
        },
    })
    public email_terminology: Record<keyof typeof DOCUMENT.TYPES_VALUES, { subject: string, body: string }>;

    @field({
        codeName: 'autoArchiveEnabled', minAppVersion: '6.5.0', type: 'boolean', defaultValue: true, 
    })
    public autoArchiveEnabled: boolean;

    @field({ codeName: 'archiveOnClose', minAppVersion: '6.5.0', type: 'boolean' })
    public archiveOnClose: boolean;

    @field({
        codeName: 'archiveAfterDays', minAppVersion: '6.5.0', type: 'float', defaultValue: 90, 
    })
    public archiveAfterDays: number;

    @field({ minAppVersion: '7.7.0', type: 'boolean', defaultValue: true })
    public autoCloseEnabled: boolean;

    @field({ minAppVersion: '7.7.0', type: 'float', defaultValue: 90 })
    public closeAfterDays: number;

    @field({
        minAppVersion: '7.7.0', type: 'boolean', defaultValue: true, codeName: 'autoCloseIgnorePayments',
    })
    public autoCloseIgnorePayments: boolean;

    @field({
        minAppVersion: '7.8.3', type: 'boolean', defaultValue: false, codeName: 'autoCloseIgnoreFutureAppointments',
    })
    public autoCloseIgnoreFutureAppointments: boolean;

    @field({
        minAppVersion: '7.8.3', type: 'boolean', defaultValue: false, codeName: 'autoCloseIgnoreFutureReminders',
    })
    public autoCloseIgnoreFutureReminders: boolean;

    @field({ minAppVersion: '7.5.0', type: 'string', isJSON: true })
    public form_data: { [name: string]: any };

    @field({ minAppVersion: '6.7.0', type: 'int', allowNull: true })
    public optin: number;

    @field({ minAppVersion: '6.7.0', type: 'int', allowNull: true })
    public optout: number;

    @field({
        minAppVersion: '7.2.0', type: 'string', isJSON: true, defaultValue: { dataLayerValue: 0, settings: {} },
    })
    public ab_settings: { [name: string]: any };

    @field({
        codeName: 'defaultPaymentTerm', minAppVersion: '7.7.0', type: 'int', defaultValue: 7, allowNull: false,
    })
    public defaultPaymentTerm: number;

    @field({ type: 'boolean', defaultValue: false })
    public mtd_enabled: boolean;

    @field({ type: 'string', defaultValue: 'standard' })
    public vat_scheme: string;

    @field({
        type: 'string',
        preserveRequest: true,
        isJSON: true,
        hooks: { afterUpdate: [{ handler: 'performVatAction', args: [] }] },
    })
    public sync_action: { [name: string]: any };

    @field({ type: 'int' })
    public vat_period: number;

    @field({ type: 'int' })
    public vat_period_ends: number;

    @field({ type: 'string', dbType: 'date' })
    public vat_start_date: string;

    @field({ type: 'boolean', defaultValue: true })
    public prior_entries_in_vat_return: boolean;

    @field({ type: 'string', dbType: 'date', serverOnly: true })
    public last_tax_history_update: string;

    @field({
        isJSON: true,
        defaultValue: {
            Monday: { enabled: true, from: '6:00', to: '18:00' },
            Tuesday: { enabled: true, from: '6:00', to: '18:00' },
            Wednesday: { enabled: true, from: '6:00', to: '18:00' },
            Thursday: { enabled: true, from: '6:00', to: '18:00' },
            Friday: { enabled: true, from: '6:00', to: '18:00' },
            Saturday: { enabled: false },
            Sunday: { enabled: false },
        },
        codeName: 'trackSchedule',
        type: 'string',
        minAppVersion: '7.9.2',
    })
    public trackSchedule:
        Record<'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday', {
            enabled: boolean;
            from?: string;
            to?: string;
        }>;

    @field({
        defaultValue: 75, codeName: 'stationaryRadius', type: 'int', minAppVersion: '7.9.2',
    })
    public stationaryRadius: number;

    @field({
        defaultValue: 1,
        codeName: 'whitelabelId',
        type: 'int',
        minAppVersion: '8.2.0',
        hooks: { afterUpdate: [{ handler: 'checkTestWhiteLabel', args: [] }] },
    })
    public whitelabel_id: number;

    @field({
        defaultValue: ARCHIVE.METHODS.PROJECT, codeName: 'archiveMethod', minAppVersion: '8.5.0', type: 'int', 
    })
    public archiveMethod: number;

    @field({
        defaultValue: 45, codeName: 'archiveProjectAfterDays', minAppVersion: '8.5.0', type: 'int', 
    })
    public archiveProjectAfterDays: number;

    @field({
        defaultValue: ARCHIVE.ARCHIVE_BATCH_PERIOD_IDS.MONTH, codeName: 'archiveBatchSize', minAppVersion: '8.5.0', type: 'int', 
    })
    public archiveBatchSize: number;

    @field({ type: 'string', minAppVersion: '8.6.3' })
    public xero_id: string;

    @field({ type: 'boolean', defaultValue: false })
    public is_test: boolean;
}
