import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import CompanyForm from '../companyForm';
import FormType from '../formType';
import FormManager from '../../form/FormManager';
import FormEntity from './entity';
import modelProperties from './modelProperties';
import CompanyFormEntity from '../companyForm/entity';
import FormTypeEntity from '../formType/entity';

interface FormAssociations extends AssociationConfig<any, any> {
    companyForm: AssociationDefinitionSingle<CompanyFormEntity, CompanyForm>
    formType: AssociationDefinitionSingle<FormTypeEntity, FormType>
}

interface Form extends AutoGeneratedFunctions<FormAssociations, FormEntity, ComplexData<FormEntity>> {}

// eslint-disable-next-line no-redeclare
class Form extends ComplexData<FormEntity> {
    static Entity = FormEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): FormAssociations {
        return {
            companyForm: {
                key: 'companyForm',
                instance: CompanyForm,
                entity: CompanyFormEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    form_type_id: this.Entity.getForeignFieldSymbols().form_type_id,
                },
            },
            formType: {
                key: 'formType',
                instance: FormType,
                entity: FormTypeEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().form_type_id,
                },
            },
        };
    }

    async getRelated() {
        const formType = await this.getFormType();
        return { name: formType.data.name, ...this.data.getPureDataValues() };
    }

    async getFormManager() {
        return new FormManager(await this.getRelated());
    }
}

export default Form;
