import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class DocumentActionEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ primaryKey: true, serverOnly: true, type: 'int' })
    public company_id: number;
    
    @field({ type: 'int' })
    public job_id: number;
    
    @field({ type: 'int' })
    public action_id: number;
    
    @field({ type: 'int' })
    public user_id: number;
    
    @field({ type: 'int' })
    public document_id: number;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;
    
    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;
    
    @field({ type: 'boolean', codeName: 'isArchived', serverOnly: true })
    public is_archived: boolean;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_pinned?: boolean;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_marked?: boolean;
}
