import CountryFeature from 'modules/complexData/countryFeature';
import CountryToCountryGroup from 'modules/complexData/countryToCountryGroup';
import PaymentTypeToCountry from 'modules/complexData/paymentTypeToCountry';
import PaymentType from 'modules/complexData/paymentType';
import COUNTRY_GROUPS from '@powerednow/shared/constants/countryGroup';
import * as Bluebird from 'bluebird';
import ComplexData, { AssociationConfig, AssociationDefinition, AutoGeneratedFunctions } from '../complexData';
import CountryEntity from './entity';
import modelProperties from './modelProperties';
import PaymentTypeToCountryEntity from '../paymentTypeToCountry/entity';
import CountryToCountryGroupEntity from '../countryToCountryGroup/entity';
import CountryFeatureEntity from '../countryFeature/entity';

interface CountryAssociations extends AssociationConfig<any, any> {
    countryFeature: AssociationDefinition<CountryFeatureEntity, CountryFeature>
    countryToCountryGroup: AssociationDefinition<CountryToCountryGroupEntity, CountryToCountryGroup>
    paymentTypeToCountry: AssociationDefinition<PaymentTypeToCountryEntity, PaymentTypeToCountry>
}

interface Country extends AutoGeneratedFunctions<CountryAssociations, CountryEntity, ComplexData<CountryEntity>> {}

// eslint-disable-next-line no-redeclare
class Country extends ComplexData<CountryEntity> {
    static Entity = CountryEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): CountryAssociations {
        return {
            countryFeature: {
                key: 'countryFeature',
                instance: CountryFeature,
                entity: CountryFeatureEntity,
                cascadeDelete: true,
                condition: {
                    country_id: this.Entity.getFieldSymbols().id,
                },
            },
            countryToCountryGroup: {
                key: 'countryToCountryGroup',
                instance: CountryToCountryGroup,
                entity: CountryToCountryGroupEntity,
                cascadeDelete: false,
                condition: {
                    country_id: this.Entity.getFieldSymbols().id,
                },
            },
            paymentTypeToCountry: {
                key: 'paymentTypeToCountry',
                instance: PaymentTypeToCountry,
                entity: PaymentTypeToCountryEntity,
                cascadeDelete: false,
                condition: {
                    country_id: this.Entity.getFieldSymbols().id,
                },
            },
        };
    }

    async getFeaturesForType(type: number): Promise<CountryFeature[]> {
        return (await this.getAllCountryFeature())
            .filter(countryFeature => countryFeature.validateForType(type));
    }

    public async isUkOrIOM(): Promise<boolean> {
        return this.isInCountryGroup(COUNTRY_GROUPS.UK_AND_IOM.ID);
    }

    private async isInCountryGroup(countryGroupId): Promise<boolean> {
        return (await this.getAllCountryToCountryGroup()).some(countryToCountryGroup => countryToCountryGroup.data.country_group_id === countryGroupId);
    }

    public async getAvailablePayments(): Promise<PaymentType[]> {
        const paymentToCountries: PaymentTypeToCountry[] = await this.getAllPaymentTypeToCountry();
        const paymentTypes = await Bluebird.map(paymentToCountries, async (typeToCountry): Promise<PaymentType> => (typeToCountry as any).getPaymentType());
        return paymentTypes.sort((a, b) => (a.data.sequence <= b.data.sequence ? -1 : 1));
    }
}

export default Country;
