import { MESSAGES } from '@powerednow/shared/constants';

const modelProperties = {
    modelName: 'Document',
    tableName: 'Document',
    associations: [{
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'hasMany',
        model: 'DocumentItem',
        parameters: { foreignKey: { name: 'document_id', fieldName: 'document_id' } },
        followFor: ['Archive', 'DocumentClone'],
        includeRestores: ['Document'],
    }, {
        type: 'hasMany',
        model: 'DocumentAction',
        parameters: { foreignKey: { name: 'document_id', fieldName: 'document_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'Message',
        parameters: { foreignKey: { name: 'document_id', fieldName: 'document_id' }, scope: { type: 'doc' } },
        followFor: ['Archive'],
    }, {
        type: 'belongsToMany',
        model: 'Payment',
        modelKeys: { primary: 'id', foreign: 'payment_id' },
        parameters: {
            as: 'Payments',
            through: { model: 'PaymentToInvoice', unique: false },
            foreignKey: { name: 'document_id', fieldName: 'document_id' },
            otherKey: { name: 'payment_id', fieldName: 'payment_id' },
        },
        followFor: ['Archive'],
        includeRestores: ['Document'],
    }, {
        type: 'belongsToMany',
        model: 'Payment',
        modelKeys: { primary: 'id', foreign: 'payment_id' },
        parameters: {
            as: 'CreditNotePayments',
            through: { model: 'PaymentToInvoice', unique: false },
            foreignKey: { name: 'credit_note_id', fieldName: 'credit_note_id' },
            otherKey: { name: 'payment_id', fieldName: 'payment_id' },
        },
        followFor: ['Archive'],
        includeRestores: ['Document'],
    }, {
        type: 'hasMany',
        model: 'PaymentToInvoice',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'document_id', fieldName: 'document_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'CustomerMessage',
        cascadeDelete: true,
        parameters: { foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
        followFor: ['Archive'],
        where: { linked_type: MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.DOCUMENT },
    }, {
        type: 'belongsTo',
        serverOnly: true,
        model: 'Customer',
        parameters: { as: 'Customer', foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        serverOnly: true,
        model: 'Job',
        parameters: { as: 'Job', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'hasMany',
        model: 'Document',
        parameters: {
            as: 'CreditNotes',
            complexAssociationName: 'document',
            foreignKey: { fieldName: 'parent_document_id' },
        },
    }, {
        type: 'belongsTo',
        serverOnly: true,
        model: 'Document',
        parameters: { as: 'ParentDocument', foreignKey: { name: 'parent_document_id', fieldName: 'parent_document_id' } },
    }, {
        type: 'belongsTo',
        serverOnly: true,
        model: 'User',
        parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, {
        type: 'belongsTo',
        model: 'Customer',
        parameters: { foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        cascadeDelete: false,
        model: 'ContactToAddress',
        parameters: { foreignKey: { name: 'site_id', fieldName: 'site_id' }, targetKeyName: 'id', targetKey: 'id' },
    }],
    validations: {
        documentnumber: { type: 'length', max: 10 },
        displaynumber: { type: 'length', max: 20 },
        prefix: { type: 'length', max: 10 },
        suffix: { type: 'length', max: 10 },
        plot: { type: 'length', max: 120 },
    },
};

export default modelProperties;
