export default {
    MEDIA_TYPES: {
        PROFILE_PICTURE: 1,
        DRIVING_LICENCE: 2,
        PASSPORT: 3,
        QUALIFICATIONS: 4,
        OTHER_IMAGES: 5,
        OTHER_IMAGES_MANAGEMENT: 6,
    },
    LEGACY_COLORS: {
        0: 'ffbc0e',
        1: '0a771b',
        2: 'a25852',
        3: '660000',
        4: '42d4f4',
        5: 'f032e6',
        6: '52a258',
        7: 'a2529c',
        8: 'ff6200',
        9: '5852a2',
        10: 'a1ef0a',
        11: '529ca2',
        12: 'e4cecc',
        13: 'b9d7d9',
        14: 'c797c3',
        15: 'c3c797',
    },
};
