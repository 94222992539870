import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import ProductTierEntity from '../productTier/entity';
import ProductTierToSubscriptionEntity from './entity';
import modelProperties from './modelProperties';
import ProductTier from '../productTier';

interface ProductTierToSubscriptionAssociations extends AssociationConfig<any, any> {
    productTier: AssociationDefinitionSingle<ProductTierEntity, ProductTier>
}

interface ProductTierToSubscription extends AutoGeneratedFunctions<ProductTierToSubscriptionAssociations, ProductTierToSubscriptionEntity, ComplexData<ProductTierToSubscriptionEntity>> {}

// eslint-disable-next-line no-redeclare
class ProductTierToSubscription extends ComplexData<ProductTierToSubscriptionEntity> {
    static Entity = ProductTierToSubscriptionEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): ProductTierToSubscriptionAssociations {
        return {
            productTier: {
                key: 'productTier',
                instance: ProductTier,
                entity: ProductTierEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().product_tier_id,
                },
            },
        };
    }
}

export default ProductTierToSubscription;
