export default {
    SEVERITY: {
        MIN: 0,
        MAX: 10,
        DEFAULT: {
            SYSTEM: 10,
            SERVER: 4,
        },
    },
    TYPES: {
        UNKNOWN: 0,
        HTTP: 10,
        SOCKET: 20,
        MESSAGE_QUEUE: 30,
    },
    DEVICE_ERRORS: {
        ANDROID: {
            20: {
                prompt: 'nativeDeviceErrors.cameraPermissionDenied',
                message: 'Allow Powered Now to access camera, photos and media on your device please!',
            },
        },
    },
    SEND_WARNING: {
        EMAIL: 'email',
    },
};
