export default {
    TYPE: {
        MAIN: 1,
        INVOICE: 2,
        REGISTERED: 3,
        SITE: 4,
    },

    SITE_LIST_MODE: {
        VIEW: 'view',
        SELECT: 'select',
        EDIT: 'edit',
    },

    LABELS: {
        1: 'Main Contact',
        2: 'Invoicing Contact',
        3: 'Registered Company Address',
        4: 'Site Contact',
    },
};
