import { EntityWithId, field } from '../entity';
    
export default class ProductTierEntity extends EntityWithId {
    @field({
        type: 'int', primaryKey: true, indexed: true,
    })
    public id: number;

    @field({ type: 'string' })
    public name: string;

    @field({ type: 'string' })
    public recommendation_text: string;

    @field({ type: 'string' })
    public including_text: string;

    @field({
        isJSON: true,
        defaultValue: {},
        type: 'string',
    })
    public feature_prompt_ids: { [name: string]: any };
}
