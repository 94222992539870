/**
 * Created by zoltanmagyar on 2017. 04. 14..
 */

/**
 * ValidationError
 * @param data
 * @constructor
 */
// eslint-disable-next-line max-classes-per-file
export class ValidationError extends Error {
    constructor(data) {
        super();
        if (data) {
            this.message = data.message;
            this.name = 'ValidationError';
            this.severity = data.severity || 10;
            this.code = data.code;
        }
    }
}

export class TemplateCodeInsertionError extends Error {
    constructor(template, error) {
        super();
        this.cause = error;
        this.message = 'Your email template is invalid and could not be sent. Please check your template and try resending your email. Please contact support for further assistance.';
        this.name = 'TemplateCodeInsertionInvalid';
        this.severity = 8;
        this.template = template;
    }
}
