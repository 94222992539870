import Entity, { DateOrString, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class PaymentToInvoiceEntity extends Entity {
    @field({ type: 'int', primaryKey: true })
    public id?: number;

    @field({ type: 'int' })
    public payment_id: number;

    @field({ type: 'int' })
    public document_id: number;

    @field({ type: 'int' })
    public credit_note_id?: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'int' })
    public amount: number;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted?: boolean;

    @field({ type: 'boolean', codeName: 'isArchived', serverOnly: true })
    public is_archived?: boolean;

    @field({
        codeName: 'lastActivity',
        minAppVersion: '7.7.0',
        type: 'string',
        dbType: 'date',
        defaultValue: dateUtils.getNow.bind(dateUtils),
        serverOnly: true,
    })
    public lastActivity?: DateOrString;
}
