import Entity, { DateOrString, field } from '../entity';
    
export default class FeatureEntity extends Entity {
    @field({ type: 'int', primaryKey: true, autoIncrement: true })
    public id: number;
    
    @field({ type: 'int', serverOnly: true })
    public company_id: number;
    
    @field({
        type: 'boolean', codeName: 'isDeleted', allowNull: false, defaultValue: false, serverOnly: true, 
    })
    public isdeleted: boolean;
    
    @field({ type: 'int', defaultValue: 10 })
    public emails: number;
    
    @field({ type: 'int', defaultValue: 5 })
    public hybrid: number;
    
    @field({ type: 'int', defaultValue: 5 })
    public sms: number;
    
    @field({ type: 'int', defaultValue: 10 })
    public postcode: number;
    
    @field({ type: 'int', defaultValue: 10 })
    public customer: number;
    
    @field({ type: 'int', defaultValue: 5 })
    public job: number;
    
    @field({ type: 'string', dbType: 'date' })
    public dt_trial_expires: DateOrString;
    
    @field({ type: 'string', dbType: 'date' })
    public dt_trial_reminder: DateOrString;
    
    @field({ type: 'int', defaultValue: 2, codeName: 'switchPhone' })
    public switchPhone: number;
    
    @field({ type: 'int', defaultValue: 2, codeName: 'switchTablet' })
    public switchTablet: number;
    
    @field({ type: 'int', defaultValue: -1, codeName: 'switchDesktop' })
    public switchDesktop: number;
    
    @field({ type: 'int', defaultValue: 30, codeName: 'switchPeriodPhone' })
    public switchPeriodPhone: number;
    
    @field({ type: 'int', defaultValue: 30, codeName: 'switchPeriodTablet' })
    public switchPeriodTablet: number;
    
    @field({ type: 'int', defaultValue: 30, codeName: 'switchPeriodDesktop' })
    public switchPeriodDesktop: number;
    
    @field({ type: 'string', dbType: 'date' })
    public dt_team_activated: DateOrString;
    
    @field({ type: 'string', dbType: 'date' })
    public dt_team_trial_expires: DateOrString;
    
    @field({ type: 'boolean', defaultValue: false, minAppVersion: '7.8.1' })
    public tax_history: boolean;
    
    @field({
        codeName: 'forceExpire', type: 'boolean', defaultValue: false, minAppVersion: '8.2.0', 
    })
    public forceExpire: boolean;
    
    @field({
        codeName: 'neverEndingTrailExpiration', type: 'string', dbType: 'date', minAppVersion: '8.2.0', 
    })
    public neverEndingTrailExpiration: DateOrString;
    
    @field({
        codeName: 'useConcurrentLoginLimit', type: 'boolean', defaultValue: true, minAppVersion: '8.4.0',
    })
    public useConcurrentLoginLimit: boolean;
    
    @field({
        codeName: 'concurrentLoginLimit', type: 'int', allowNull: true, minAppVersion: '8.4.2', 
    })
    public concurrentLoginLimit: number;
}
