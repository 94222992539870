const modelProperties = {
    modelName: 'ContactMethod',
    tableName: 'ContactMethod',
    associations: [{
        type: 'belongsTo',
        model: 'ContactMethodType',
        cascadeDelete: false,
        parameters: {
            as: 'ContactMethodType',
            foreignKey: { name: 'contactmethodtype_id', fieldName: 'contactmethodtype_id' }, 
        },
    }],
    validations: { value: [{ type: 'length', max: 70 }] },
    customValidations: {
        value: [{ type: 'email', condition: { contactmethodtype_id: [2, 6] } }, {
            type: 'phone',
            condition: { contactmethodtype_id: [1, 5, 4] },
        }],
    },
};

export default modelProperties;
