const modelProperties = {
    modelName: 'PaymentType',
    tableName: 'PaymentType',
    associations: [{
        type: 'belongsToMany',
        model: 'Country',
        modelKeys: { primary: 'id', foreign: 'country_id' },
        parameters: {
            as: 'Country',
            through: { model: 'PaymentTypeToCountry', unique: false },
            foreignKey: { name: 'paymenttype_id', fieldName: 'paymenttype_id' },
            otherKey: { name: 'country_id', fieldName: 'country_id' },
        },
    }, {
        type: 'hasMany',
        model: 'PaymentTypeToCountry',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'paymenttype_id', fieldName: 'paymenttype_id' } },
    }, {
        type: 'hasOne',
        model: 'PaymentSetting',
        parameters: { as: 'PaymentSetting', foreignKey: { name: 'payment_id', fieldName: 'payment_id' } },
    }],
    validations: {
        name: { type: 'length', max: 45 },
        description: { type: 'length', max: 256 },
        t1: { type: 'length', max: 256 },
        t2: { type: 'length', max: 256 },
        t3: { type: 'length', max: 256 },
        e1: { type: 'length', max: 256 },
        e2: { type: 'length', max: 256 },
        e3: { type: 'length', max: 256 },
    },
    global: true,
};

export default modelProperties;
