import ComplexData, {
    AssociationConfig,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from 'modules/complexData/complexData';
import CountryGroup from 'modules/complexData/countryGroup';
import Country from 'modules/complexData/country';
import CountryToCountryGroupEntity from './entity';
import modelProperties from './modelProperties';
import CountryEntity from '../country/entity';
import CountryGroupEntity from '../countryGroup/entity';

interface CountryToCountryGroupAssociations extends AssociationConfig<any, any> {
    country: AssociationDefinitionSingle<CountryEntity, Country>
    countryGroup: AssociationDefinitionSingle<CountryGroupEntity, CountryGroup>
}

interface CountryToCountryGroup extends AutoGeneratedFunctions<CountryToCountryGroupAssociations, CountryToCountryGroupEntity, ComplexData<CountryToCountryGroupEntity>> {}

// eslint-disable-next-line no-redeclare
class CountryToCountryGroup extends ComplexData<CountryToCountryGroupEntity> {
    static Entity = CountryToCountryGroupEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): CountryToCountryGroupAssociations {
        return {
            countryGroup: {
                key: 'countryGroup',
                instance: CountryGroup,
                entity: CountryGroupEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().country_group_id,
                },
            },
            country: {
                key: 'country',
                instance: Country,
                entity: CountryEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().country_id,
                },
            },
        };
    }
}

export default CountryToCountryGroup;
