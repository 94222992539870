const Bluebird = require('bluebird');

class BaseValidator {
    constructor(config = {}) {
        if (!this.config) {
            this.config = {};
        }

        this.detailedValidationMessage = '';
        Object.assign(this.config, config);
    }

    getDetailedValidationMessage() {
        return this.detailedValidationMessage;
    }

    async validate(value, options = {}) {
        const defaultRuleResult = await this.runDefaultRule(value, options);
        return this.runExtraRules(value, defaultRuleResult);
    }

    /* eslint class-methods-use-this: "off" */
    async runDefaultRule() {
        throw new Error('Undefined abstract method: runDefaultRule');
    }

    async runExtraRules(value, defaultRuleResult) {
        const preparedValue = value || '';

        if (defaultRuleResult !== true) {
            return defaultRuleResult;
        }
        const extraValidationResults = await this._callExtraValidation(preparedValue, defaultRuleResult);
        const extraValidationResultsToArray = extraValidationResults.length === 0 ? [true] : extraValidationResults;

        return this._mergeResults([defaultRuleResult, ...extraValidationResultsToArray]);
    }

    async _callExtraValidation(value, defaultRuleResult = true) {
        const errors = [];

        await Bluebird.each(this.config.extra || [], async extraRule => {
            const extraResult = await extraRule(value, this.config.complexData, defaultRuleResult);
            if (extraResult !== true) {
                errors.push(extraResult);
            }
        });
        return errors;
    }

    /* eslint class-methods-use-this: "off" */
    _mergeResults(results) {
        const notValidResult = results.filter(value => value !== true);
        return notValidResult.length === 0 ? true : notValidResult;
    }
}

module.exports = BaseValidator;
