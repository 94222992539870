const modelProperties = {
    modelName: 'UserTaxRateFilter',
    tableName: 'UserTaxRateFilter',
    minAppVersion: '7.8.0',
    associations: [{
        type: 'belongsTo',
        model: 'Country',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'country_id', fieldName: 'country_id' } },
    }, {
        type: 'belongsTo',
        model: 'UserTaxRate',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'taxrate_id', fieldName: 'taxrate_id' } },
    }, {
        type: 'belongsTo',
        model: 'CountryGroup',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'country_group_id', fieldName: 'country_group_id' } },
    }],
    global: true,
};

export default modelProperties;
