import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import modelProperties from './modelProperties';
import PaymentsForCustomerEntity from './entity';

interface PaymentsForCustomerAssociations extends AssociationConfig<any, any> {}

interface PaymentsForCustomer extends AutoGeneratedFunctions<PaymentsForCustomerAssociations, PaymentsForCustomerEntity, ComplexData<PaymentsForCustomerEntity>> {}

// eslint-disable-next-line no-redeclare
class PaymentsForCustomer extends ComplexData<PaymentsForCustomerEntity> {
    static Entity = PaymentsForCustomerEntity;

    _entity: PaymentsForCustomerEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): PaymentsForCustomerAssociations {
        return {

        };
    }
}

export default PaymentsForCustomer;
