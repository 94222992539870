import Entity, { EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');
const constants = require('@powerednow/shared/constants').default;

export default class AddressEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;

    @field({ type: 'boolean', codeName: 'isDefault' })
    public isdefault?: boolean;

    @field({ type: 'boolean' })
    public invoice?: boolean;

    @field({
        type: 'string',
        convert: function convert(value) {
            if (constants.COUNTRY.CONVERT[value]) {
                return constants.COUNTRY.CONVERT[value];
            }
            return value;
        },
    })
    public country: string;

    @field({ type: 'string' })
    public state?: string;

    @field({ type: 'string' })
    public city: string;

    @field({ type: 'string' })
    public county: string;

    @field({ type: 'string' })
    public address1: string;

    @field({ type: 'string' })
    public address2: string;

    @field({ type: 'string', codeName: 'postCode' })
    public postcode: string;

    @field({ type: 'int' })
    public customer_id: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created?: string;

    @field({
        type: 'boolean', serverOnly: true, codeName: 'isDeleted', defaultValue: false, 
    })
    public isdeleted?: boolean;

    @field({ type: 'string' })
    public name?: string;

    @field({ type: 'boolean', defaultValue: true })
    public is_registered?: boolean;

    @field({ type: 'boolean', defaultValue: false })
    public is_invoice?: boolean;
    
    @field({
        type: 'int', minAppVersion: '8.5.7', serverOnly: true, defaultValue: null, allowNull: true, 
    })
    public expert_trades_id?: number;
}
