export const MONTHLY = 'monthly';

export default {
    ACTIVITY_TYPES: {
        ACTIVE: 1,
        PREVIOUSLY_ACTIVE: 2,
        INACTIVE: 3,
    },
    MONTH_SETTING_NAMES: {
        MONTHLY,
    },
    MONTH_KEY_TO_MONTH_JUMP: {
        monthly: 1,
    },
};
