import UserTaxRate from 'modules/complexData/userTaxRate';
import CountryGroup from 'modules/complexData/countryGroup';
import PRODUCT_CATEGORY from '@powerednow/shared/constants/productCategory';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import UserTaxRateFilterEntity from './entity';
import modelProperties from './modelProperties';
import UserTaxRateEntity from '../userTaxRate/entity';
import CountryGroupEntity from '../countryGroup/entity';

interface UserTaxRateFilterAssociations extends AssociationConfig<any, any> {
    userTaxRate: AssociationDefinitionSingle<UserTaxRateEntity, UserTaxRate>
    countryGroup: AssociationDefinitionSingle<CountryGroupEntity, CountryGroup>
}

interface UserTaxRateFilter extends AutoGeneratedFunctions<UserTaxRateFilterAssociations, UserTaxRateFilterEntity, ComplexData<UserTaxRateFilterEntity>> {}

// eslint-disable-next-line no-redeclare
class UserTaxRateFilter extends ComplexData<UserTaxRateFilterEntity> {
    static Entity = UserTaxRateFilterEntity;

    static modelProperties = modelProperties;

    static get notRequiredFieldValue() {
        return PRODUCT_CATEGORY.TAX_CATEGORY.NONE.ID;
    }

    public static get allowedAssociations(): UserTaxRateFilterAssociations {
        return {
            userTaxRate: {
                key: 'userTaxRate',
                instance: UserTaxRate,
                entity: UserTaxRateEntity,
                single: true,
                condition: {
                    id: this.Entity.getFieldSymbols().taxrate_id,
                },
            },
            countryGroup: {
                key: 'countryGroup',
                instance: CountryGroup,
                entity: CountryGroupEntity,
                single: true,
                condition: {
                    id: this.Entity.getFieldSymbols().country_group_id,
                },
            },
        };
    }

    public async allowedForTargetCountry(sourceCountryId: number, targetCountryId: number): Promise<boolean> {
        if (this.isInlandDefinition()) {
            return sourceCountryId === targetCountryId;
        }
        //
        // use self_included if defined, and target country would the sames as source
        //
        if (this.isSelfIncludedFieldDefined() && UserTaxRateFilter.isTargetCountryIsInland(sourceCountryId, targetCountryId)) {
            return this.data.self_included;
        }

        const countryGroup = await this.getCountryGroup();
        if (countryGroup) {
            const country = await countryGroup.findCountry(targetCountryId);
            return this.isCountryAvailable(country);
        }
        return false;
    }

    public isFieldRequired(fieldName) {
        return this.data[fieldName] !== UserTaxRateFilter.notRequiredFieldValue;
    }

    public isFieldAvailable(fieldName) {
        return this.data[fieldName] !== undefined;
    }

    private isCountryAvailable(country): boolean {
        return this.data.negate_group ? !country : !!country;
    }

    private isInlandDefinition(): boolean {
        return !this.data.country_group_id && this.data.self_included && !this.data.negate_group;
    }

    private isSelfIncludedFieldDefined(): boolean {
        return this.data.self_included !== null && this.data.self_included !== undefined;
    }

    private static isTargetCountryIsInland(sourceCountryId: number, targetCountryId: number): boolean {
        return sourceCountryId === targetCountryId;
    }
}

export default UserTaxRateFilter;
