const modelProperties = {
    modelName: 'WhiteLabelToCustomer',
    tableName: 'WhiteLabelToCustomer',
    associations: [{
        type: 'belongsTo',
        model: 'Customer',
        parameters: { foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
    }, {
        type: 'belongsTo',
        model: 'WhiteLabel',
        cascadeDelete: false,
        parameters: { as: 'whiteLabel', foreignKey: { name: 'whitelabel_id', fieldName: 'whitelabel_id' } },
    }],
};

export default modelProperties;
