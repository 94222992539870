const modelProperties = {
    modelName: 'UserProfileRole',
    tableName: 'userprofile_role',
    associations: [{
        type: 'belongsTo',
        model: 'UserProfile',
        parameters: { foreignKey: { name: 'userprofile_id', fieldName: 'userprofile_id' } },
    }, {
        type: 'hasOne',
        model: 'roles',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'id', fieldName: 'id', sourceKeyName: 'role_id' } },
    }],
    global: true,
};

export default modelProperties;
