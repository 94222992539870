import { MESSAGES } from '@powerednow/shared/constants';

import { ValueOf } from '@powerednow/type-definitions';
import { DateOrString, EntityWithCompanyId, field } from '../entity';

export { MESSAGES } from '@powerednow/shared/constants';

export enum DELIVERY_STATUS_CATEGORY {
    process_failed = 'process_failed',
    processed = 'processed',
    sent = 'sent',
    rejected = 'rejected',
    open = 'open',
    click = 'click',
    reported = 'reported',
    deferred = 'deferred',
}

export type MessageStatusTypeString = keyof typeof MESSAGES.DELIVERY_STATUS
export type MessageStatusTypeId = ValueOf<typeof MESSAGES.DELIVERY_STATUS>;
export type MessageStatusCategory = keyof typeof DELIVERY_STATUS_CATEGORY

const dateUtils = require('modules/utilities/date');

export type MessageDeliveryTemplateData<T = {}> = T & {
    onlinePaymentEnabled?: boolean,
    receiptLink?: string,
    companyname?: string,
    documenturl?: string,
    currencyOptions?: object,
    docType?: string,
    showWhatsAppOptInButton?: boolean,
    whatsAppOptInUrl?: string,
    customername?: string,
    buttonText?: string,
    suppressThereMightBeOtherAttachmentsText?: boolean,
    canUsePortal?: boolean,
    previousValues?: {
        [key: string]: string
    },
}
const sanitizeExtInjection = (newValue: string): string => {
    const matcher = /{%|%}|{\[|\]}/g;
    let cleanValue = newValue;
    while (cleanValue && cleanValue.match(matcher)) {
        cleanValue = cleanValue.replace(matcher, '');
    }
    return cleanValue;
};

export default class MessageRecipientEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'int' })
    public message_id: number;

    @field({ type: 'int' })
    public contact_id: number;

    @field({ type: 'int' })
    public user_id: number;

    @field({ type: 'int', codeName: 'contactMethodTypeId' })
    public contactmethodtype_id: number;

    @field({ type: 'int', codeName: 'messageTemplateId' })
    public message_template_id?: number;

    @field({ type: 'string' })
    public recipient: string;

    @field({ type: 'string' })
    public recipientName: string;

    @field({ type: 'string', processor: sanitizeExtInjection })
    public subject: string;

    @field({ type: 'string', processor: sanitizeExtInjection })
    public message: string;

    @field({ defaultValue: 0, type: 'int' })
    public status?: MessageStatusTypeId;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;

    @field({ type: 'string', dbType: 'date' })
    public dt_sent?: DateOrString;

    @field({ type: 'int', defaultValue: 0 })
    public opens?: number;

    @field({ type: 'int', defaultValue: 0 })
    public clicks?: number;

    @field({ type: 'int', allowNull: true, defaultValue: 0 })
    public msg_error_code?: number;

    @field({ type: 'string', allowNull: true, defaultValue: '' })
    public msg_error_message?: string;

    @field({ defaultValue: 0, type: 'string' })
    public provider_id?: string;

    @field({ type: 'string', isJSON: true, defaultValue: {} })
    public templateData?: MessageDeliveryTemplateData;

    @field({
        type: 'boolean', codeName: 'isArchived', serverOnly: true, defaultValue: false, 
    })
    public is_archived?: boolean;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted?: boolean;

    @field({ type: 'boolean', codeName: 'dontTrack', defaultValue: false })
    public dontTrack?: boolean;
}
