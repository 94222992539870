import Entity, { field } from '../entity';
    
export default class KnownErrorEntity extends Entity {
    @field({ type: 'int', primaryKey: true, autoIncrement: true })
    public id: number;
    
    @field({ type: 'string', serverOnly: true })
    public stack: string;
    
    @field({ type: 'string' })
    public hashed_stack: string;
    
    @field({ type: 'boolean' })
    public is_harmful: boolean;
    
    @field({ type: 'string', serverOnly: true })
    public min_app_version: string;
    
    @field({ type: 'string', serverOnly: true })
    public max_app_version: string;
    
    @field({ type: 'string', serverOnly: true })
    public card_number: string;
}
