export default {
    CHANNEL: {
        EVENT_NAME: {
            CONNECT: 'channelConnect',
            DISCONNECT: 'channelDisconnect',
            SERVER_JOINED: 'serverJoinedToChannel',
            CONNECTION_LIST_PUBLISHED: 'serverConnectionListPublished',
        },
    },
};
