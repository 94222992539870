import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import CustomerMessageAttachmentEntity from './entity';
import modelProperties from './modelProperties';
import AttachmentEntity from '../attachment/entity';
import Attachment from '../attachment';

interface CustomerMessageAttachmentAssociations extends AssociationConfig<any, any> {
    attachment: AssociationDefinitionSingle<AttachmentEntity, Attachment>
}

interface CustomerMessageAttachment extends AutoGeneratedFunctions<CustomerMessageAttachmentAssociations, CustomerMessageAttachmentEntity, ComplexData<CustomerMessageAttachmentEntity>> {}

// eslint-disable-next-line no-redeclare
class CustomerMessageAttachment extends ComplexData<CustomerMessageAttachmentEntity> {
    static Entity = CustomerMessageAttachmentEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations():CustomerMessageAttachmentAssociations {
        return {
            attachment: {
                key: 'attachment',
                entity: AttachmentEntity,
                instance: Attachment,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().attachment_id,
                },
            },
        };
    }
}

export default CustomerMessageAttachment;
