import * as Bluebird from 'bluebird';
import ValidationResult from './validationResult';
import type { ComplexDataType } from '../complexData/complexData';

const constants = require('@powerednow/shared/constants').default;

export default class ModelValidator {
    static async validate(complexData: ComplexDataType<any>, modelDefinition: any, extraConfig: any): Promise<ValidationResult> {
        const validationResult = new ValidationResult();

        if (this.getIsValidFromData(complexData) === true) {
            return validationResult;
        }

        const validations = { ...(modelDefinition.validations || {}), ...(modelDefinition.customValidations || {}) };

        await Bluebird.each(Object.entries(validations), async ([key, rule]) => {
            const shouldValidateField = complexData.isNewItem() || complexData.data.changedFields[key];
            if (shouldValidateField) {
                validationResult.mergeWith(await ModelValidator.validateRules(complexData, modelDefinition.modelName, key, rule, extraConfig));
            }
        });

        return validationResult;
    }

    private static async validateRules(complexData: ComplexDataType<any>, modelName: string, key: string, rule: any, extraConfig = {}): Promise<ValidationResult> {
        const validationResult = new ValidationResult();

        if (Array.isArray(rule)) {
            await Bluebird.each(rule, async subRule => {
                validationResult.mergeWith(await ModelValidator.validateRules(complexData, modelName, key, subRule, extraConfig));
            });
            return validationResult;
        }

        const validateRule = ModelValidator.shouldValidateRule(complexData, rule);

        if (validateRule) {
            const extra = (extraConfig && extraConfig[rule.type]) || [];
            const validator = ModelValidator.validatorLookup(rule.type, { extra, complexData });
            const result = await validator.validate(complexData.data[key], rule);
            if (result !== true) {
                const error = ModelValidator.composeValidationError(complexData, rule.type, modelName, key, result);
                validationResult.errors.push(error);
            }
        }

        return validationResult;
    }

    private static getIsValidFromData(complexData) {
        return complexData && complexData.getData && complexData.getData().is_valid;
    }

    private static validatorLookup(validatorType, config = {}): any {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        const ValidatorClass = require(`./${validatorType}Validator`);
        return new ValidatorClass(config);
    }

    private static composeValidationError(complexData: ComplexDataType<any>, validatorType: string, modelName: string, field: string, result): object {
        const modelNameCamelCase = modelName.charAt(0).toLowerCase() + modelName.substr(1);
        const error = { ...constants.VALIDATION.CODES.FIELD[validatorType.toUpperCase()] };
        error.model = modelName;
        error.field = field;
        error.boundPath = `${modelNameCamelCase}Wrapper.${modelNameCamelCase}`;
        error.complexObject = complexData;
        error.validatorResult = result;
        return error;
    }

    private static shouldValidateRule(complexData: ComplexDataType<any>, rule: any) {
        if (rule.condition) {
            let result = false;
            Object.entries(rule.condition).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach(innerValue => {
                        result = result || (complexData.data[key] === innerValue);
                    });
                } else {
                    result = result || (complexData.data[key] === value);
                }
            });
            return result;
        }

        return true;
    }
}
