import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import AttachmentEntity from './entity';
import modelProperties from './modelProperties';

interface AttachmentAssociations extends AssociationConfig<any, any> {}

interface Attachment extends AutoGeneratedFunctions<AttachmentAssociations, AttachmentEntity, ComplexData<AttachmentEntity>> {}

// eslint-disable-next-line no-redeclare
class Attachment extends ComplexData<AttachmentEntity> {
    static Entity = AttachmentEntity;

    static modelProperties = modelProperties;

    public getFileName(): string {
        let fileName = this.data.name;
        if (fileName.indexOf('.') < 0) {
            const extension = (this.data.url.match(/.*\.([^.]+)$/)[1]).toUpperCase();
            fileName = `${this.data.name}.${extension}`;
        }
        return fileName;
    }
}

export default Attachment;
