import { TYPES_Type as PAYMENT_TYPES_Type } from '@powerednow/shared/constants/payment';
import { EntityWithId, field } from '../entity';
    
export default class PaymentTypeEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: PAYMENT_TYPES_Type;
    
    @field({ type: 'string' })
    public name: string;
    
    @field({ type: 'boolean' })
    public configurable: boolean;
    
    @field({ type: 'boolean' })
    public selectable: boolean;
    
    @field({ type: 'string' })
    public t1: string;
    
    @field({ type: 'string' })
    public t2: string;
    
    @field({ type: 'string' })
    public t3: string;
    
    @field({ type: 'string' })
    public e1: string;
    
    @field({ type: 'string' })
    public e2: string;
    
    @field({ type: 'string' })
    public e3: string;
    
    @field({ type: 'string' })
    public description: string;
    
    @field({ type: 'boolean' })
    public is_sales: boolean;
    
    @field({ type: 'boolean' })
    public is_supplier: boolean;
    
    @field({ type: 'boolean' })
    public is_expense: boolean;
    
    @field({ type: 'boolean', defaultValue: true })
    public editable: boolean;
    
    @field({ minAppVersion: '5.6.0', type: 'int' })
    public sequence: number;

    @field({ type: 'decimal', minAppVersion: '8.5.0' })
    public fee?: number;

    @field({ type: 'boolean', defaultValue: false, minAppVersion: '9.0.4' })
    public availability_limit_enabled: boolean;

    @field({ type: 'decimal', defaultValue: 0, minAppVersion: '9.0.4' })
    public availability_limit: number;

    @field({ type: 'boolean', defaultValue: true })
    public defaultable: boolean;

    @field({ type: 'string', serverOnly: true, minAppVersion: '8.5.0' })
    public min_app_version: string;

    @field({ type: 'string', serverOnly: true, minAppVersion: '8.5.0' })
    public max_app_version: string;
}
