export default {
    SERVICES: {
        MONGODB: 'mongoDb',
        SEQUELIZE: 'sequelize',
        HTTP_SERVER: 'httpServer',
        RABBIT_MQ: 'rabbit',
        SOCKET: 'socket',
        REDIS: 'redis',
        REDISCACHE: 'rediscache',
    },
};
