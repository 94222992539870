const {
    Mixin, settings,
} = require('ts-mixer');

export const skipAddToCacheOnConstruct = Symbol('skipAddToCacheOnConstruct');

export function complexDataMix(...MixerClasses) {
    settings.initFunction = 'complexDataMixConstructor';
    return function (target) {
        Object.defineProperty(target, skipAddToCacheOnConstruct, {
            value: true,
            writable: false,
        });
        const mixedClass = Mixin(target, ...MixerClasses);
        Object.defineProperty(mixedClass, 'name', {
            value: target.name,
            writable: false,
        });
        return mixedClass;
    };
}
