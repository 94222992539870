const modelProperties = {
    modelName: 'UserAction',
    tableName: 'UserAction',
    associations: [
        {
            type: 'belongsTo',
            model: 'User',
            parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
        }, {
            type: 'belongsTo',
            model: 'Action',
            parameters: { foreignKey: { name: 'action_id', fieldName: 'action_id' } },
        }],
};

export default modelProperties;
