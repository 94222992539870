import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import Roles from '../roles';
import UserProfile from '../userProfile';
import UserProfileEntity from '../userProfile/entity';
import UserProfileRoleCustomEntity from './entity';
import modelProperties from './modelProperties';
import RolesEntity from '../roles/entity';

interface UserProfileRoleCustomAssociations extends AssociationConfig<any, any> {
    role: AssociationDefinitionSingle<RolesEntity, Roles>
    userProfile: AssociationDefinitionSingle<UserProfileEntity, UserProfile>
}

interface UserProfileRoleCustom extends AutoGeneratedFunctions<UserProfileRoleCustomAssociations, UserProfileRoleCustomEntity, ComplexData<UserProfileRoleCustomEntity>> {}

// eslint-disable-next-line no-redeclare
class UserProfileRoleCustom extends ComplexData<UserProfileRoleCustomEntity> {
    static Entity = UserProfileRoleCustomEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): UserProfileRoleCustomAssociations {
        return {
            role: {
                key: 'roles',
                instance: Roles,
                entity: RolesEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().role_id,
                },
            },

            userProfile: {
                key: 'userprofile',
                instance: UserProfile,
                entity: UserProfileEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().userprofile_id,
                },
            },
        };
    }
}

export default UserProfileRoleCustom;
