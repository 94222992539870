const modelProperties = {
    modelName: 'FileGroup',
    tableName: 'FileGroup',
    minAppVersion: '8.0.0',
    associations: [{
        type: 'hasMany',
        model: 'File',
        parameters: { foreignKey: { name: 'filegroup_id', fieldName: 'filegroup_id' } },
        followFor: ['Archive'],
        includeRestores: ['FileGroup'],
    }],
    validations: { title: { type: 'length', max: 60 } },
};

export default modelProperties;
