export default {
    ID: {
        JOB_OPEN: 0,
        JOB_CLOSED: 1,
        APPOINTMENT_OPEN: 2,
    },
    TYPE: {
        JOB: 1,
        APPOINTMENT: 2,
    },
    DISPLAY: {
        NONE: 0,
        BORDER: 1,
        DOT: 2,
        BACKGROUND: 3,
    },
};
