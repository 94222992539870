import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import CompanySettingsEntity from './entity';
import modelProperties from './modelProperties';

interface CompanySettingsAssociations extends AssociationConfig<any, any> {}

interface CompanySettings extends AutoGeneratedFunctions<CompanySettingsAssociations, CompanySettingsEntity, ComplexData<CompanySettingsEntity>> {}

// eslint-disable-next-line no-redeclare
class CompanySettings extends ComplexData<CompanySettingsEntity> {
    static Entity = CompanySettingsEntity;

    static modelProperties = modelProperties;
}

export default CompanySettings;
