import Entity, { EntityWithId, field } from '../entity';
    
export default class SettingsKeysEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ codeName: 'keyName', type: 'string' })
    public key_name: string;
    
    @field({ codeName: 'defaultValue', type: 'string' })
    public default_value: string | number | object;
}
