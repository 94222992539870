import ComplexData, {
    AssociationConfig, AssociationDefinition, AutoGeneratedFunctions,
} from '../complexData';
import ProductTierEntity from './entity';
import modelProperties from './modelProperties';
import ProductTierToFeatureEntity from '../productTierToFeature/entity';
import ProductTierToFeature from '../productTierToFeature';

interface ProductTierAssociations extends AssociationConfig<any, any> {
    productTierToFeature: AssociationDefinition<ProductTierToFeatureEntity, ProductTierToFeature>
}

interface ProductTier extends AutoGeneratedFunctions<ProductTierAssociations, ProductTierEntity, ComplexData<ProductTierEntity>> {}

// eslint-disable-next-line no-redeclare
class ProductTier extends ComplexData<ProductTierEntity> {
    static Entity = ProductTierEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): ProductTierAssociations {
        return {
            productTierToFeature: {
                key: 'productTierToFeature',
                instance: ProductTierToFeature,
                entity: ProductTierToFeatureEntity,
                condition: {
                    product_tier_id: this.Entity.getFieldSymbols().id,
                },
            },
        };
    }
}

export default ProductTier;
