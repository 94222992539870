import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import FileGroupEntity from './entity';
import modelProperties from './modelProperties';

interface FileGroupAssociations extends AssociationConfig<any, any> {}

interface FileGroup extends AutoGeneratedFunctions<FileGroupAssociations, FileGroupEntity, ComplexData<FileGroupEntity>> {}

// eslint-disable-next-line no-redeclare
class FileGroup extends ComplexData<FileGroupEntity> {
    static Entity = FileGroupEntity;

    static modelProperties = modelProperties;
}

export default FileGroup;
