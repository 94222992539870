const modelProperties = {
    modelName: 'Action',
    tableName: 'action',
    associations: [{
        type: 'belongsTo',
        serverOnly: true,
        model: 'Job',
        parameters: { as: 'Job', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        serverOnly: true,
        model: 'User',
        parameters: { as: 'Creator', foreignKey: { name: 'user_id', fieldName: 'user_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        cascadeDelete: false,
        model: 'ContactToAddress',
        parameters: { foreignKey: { name: 'site_id', fieldName: 'site_id' }, targetKeyName: 'id', targetKey: 'id' },
        followFor: ['ParentCheck'],
    }, {
        type: 'hasMany',
        model: 'UserAction',
        parameters: { as: 'UserActions', foreignKey: { name: 'action_id', fieldName: 'action_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'DocumentAction',
        parameters: { foreignKey: { name: 'action_id', fieldName: 'action_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'Checkin',
        parameters: { foreignKey: { name: 'action_id', fieldName: 'action_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'Note',
        parameters: { foreignKey: { name: 'action_id', fieldName: 'action_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'TimeLog',
        parameters: { foreignKey: { name: 'action_id', fieldName: 'action_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'MediaGroup',
        parameters: { foreignKey: { name: 'action_id', fieldName: 'action_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'Message',
        parameters: { foreignKey: { name: 'action_id', fieldName: 'action_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'UserAction',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'action_id', fieldName: 'action_id' } },
    }, {
        type: 'belongsTo',
        cascadeDelete: false,
        model: 'JobStatus',
        parameters: {
            as: 'JobStatus',
            foreignKey: {
                name: 'status',
                fieldName: 'status',
            },
        },
    }, {
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
        followFor: ['ParentCheck'],
    }],
    hooks: [{
        localFunction: 'syncCalendar',
        checkFunction: 'isGoogleCalendarSyncNeeded',
        bulkProcessingAllowed: true,
        key: 'update',
        delay: 10000,
        remoteFunction: 'saveAndSync',
        checkValueChange: true,
        structure: 2,
    }],
    validations: {
        action: { type: 'length', max: 250 },
        description: { type: 'length', max: 2048 },
        plot: { type: 'length', max: 120 },
    },
};

export default modelProperties;
