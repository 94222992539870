const modelProperties = {
    modelName: 'JobStats',
    tableName: 'JobStats',
    minAppVersion: '8.5.2',
    associations: [{
        type: 'belongsTo',
        model: 'Job',
        parameters: { foreignKey: { name: 'job_id', fieldName: 'job_id' } },
    }, {
        type: 'belongsTo',
        model: 'Job',
        parameters: {
            foreignKey: {
                name: 'job_id',
                fieldName: 'job_id',
            },
        },
    }],
};

export default modelProperties;
