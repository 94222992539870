import { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class ContactEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;
    
    @field({ type: 'boolean', codeName: 'isDefault' })
    public isdefault?: boolean;
    
    @field({ type: 'boolean' })
    public invoice?: boolean;
    
    @field({ type: 'string', codeName: 'firstName', defaultValue: '' })
    public firstname: string;
    
    @field({ type: 'string', codeName: 'lastName', defaultValue: '' })
    public lastname?: string;
    
    @field({ type: 'int' })
    public customer_id: number;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created?: DateOrString;
    
    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted?: boolean;
    
    @field({
        allowNull: true,
        type: 'int',
        convert: (value = null) => (value === '' ? null : value), 
    })
    public alternate_contact_id?: number;
    
    @field({ type: 'string' })
    public description?: string;
    
    @field({ type: 'string' })
    public title?: string;

    @field({
        type: 'int', minAppVersion: '8.5.7', serverOnly: true, defaultValue: null, allowNull: true, 
    })
    public expert_trades_id?: number;
}
