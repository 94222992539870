const modelProperties = {
    modelName: 'JobItem',
    tableName: 'JobItem',
    associations: [{
        cascadeDelete: false,
        type: 'hasMany',
        model: 'MediaGroup',
        parameters: { foreignKey: { name: 'jobitem_id', fieldName: 'jobitem_id' } },
        includeRestores: ['JobItem'],
    }, {
        type: 'belongsTo',
        model: 'ProductCategory',
        parameters: { foreignKey: { name: 'category_id', fieldName: 'category_id' } },
    }, {
        type: 'belongsTo',
        model: 'JobItemGroup',
        parameters: { foreignKey: { name: 'jobitemgroup_id', fieldName: 'jobitemgroup_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'User',
        parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, { type: 'belongsTo', model: 'UserTaxRate', parameters: { foreignKey: { name: 'vat_id', fieldName: 'vat_id' } } }],
    validations: {
        tax_name: { type: 'length', max: 45 },
        cis_name: { type: 'length', max: 64 },
        sku: { type: 'length', max: 120 },
    },
};

export default modelProperties;
