import type { ComplexDataType } from './complexData';

export default class UniqueIdStorage {
    private storage: { [propName: string]: Set<number | symbol> } = {};

    public add(item: ComplexDataType<any>): void {
        this.getClassSet(item).add(item.data.id);
    }

    public has(item: ComplexDataType<any>): boolean {
        return this.getClassSet(item).has(item.data.id);
    }

    private getClassSet(item: ComplexDataType<any>) {
        if (!this.storage[item.constructor.getModelName()]) {
            this.storage[item.constructor.getModelName()] = new Set();
        }
        return this.storage[item.constructor.getModelName()];
    }
}
