import Company from 'modules/complexData/company';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';

import UserToContactEntity from './entity';
import modelProperties from './modelProperties';
import Contact from '../contact';
import User from '../user';
import CompanyEntity from '../company/entity';
import ContactEntity from '../contact/entity';
import UserEntity from '../user/entity';

interface UserToContactAssociations extends AssociationConfig<any, any> {
    company: AssociationDefinitionSingle<CompanyEntity, Company>
    contact: AssociationDefinition<ContactEntity, Contact>
    // user: AssociationDefinitionSingle<UserEntity, User>
}

interface UserToContact extends AutoGeneratedFunctions<UserToContactAssociations, UserToContactEntity, ComplexData<UserToContactEntity>> {}

// eslint-disable-next-line no-redeclare
class UserToContact extends ComplexData<UserToContactEntity> {
    static Entity = UserToContactEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): UserToContactAssociations {
        return {
            company: {
                instance: Company,
                entity: CompanyEntity,
                key: 'company',
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().company_id,
                },
            },
            contact: {
                key: 'contact',
                instance: Contact,
                entity: ContactEntity,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().contact_id,
                    company_id: this.Entity.getForeignFieldSymbols().company_id,
                },
            },
            //
            // This is intentionally commented out because the user is in other company
            // so the current company should not have right to access it.
            // We may want to change it later.
            //
            // user: {
            //     key: 'user',
            //     instance: User,
            //     entity: UserEntity,
            //     single: true,
            //     cascadeDelete: false,
            //     condition: {
            //         uuid: this.Entity.getForeignFieldSymbols().user_uuid,
            //     },
            // },
        };
    }
}

export default UserToContact;
