export default class Path {
    readonly _path: string;

    constructor(path: Path | string = '') {
        if (path instanceof Path) {
            this._path = path.toString();
        } else {
            this._path = path || '';
        }
    }

    public static getRootPath(): Path {
        return new Path();
    }

    public isRoot(): boolean {
        return this._path === '';
    }

    public endsWith(step: string): boolean {
        return new RegExp(`${step}(\\.[0-9]+)?$`).test(this._path);
    }

    public getLastSelector(): string {
        return this._path.split('.').pop();
    }

    public getExtendedPath(...steps: Array<string | number>): Path {
        const path = [
            ...this._path.split('.'),
            ...steps,
        ].filter(x => (x === 0 ? true : Boolean(x)))
            .join('.');
        return new Path(path);
    }

    public getParentPath(): Path | null {
        const lastDotIndex = this._path.lastIndexOf('.');
        if (lastDotIndex === -1) {
            return null;
        }
        return new Path(this._path.substring(0, lastDotIndex));
    }

    public getIndex(): number | null {
        const lastDotIndex = this._path.lastIndexOf('.');
        if (lastDotIndex === -1) {
            return null;
        }
        const indexStr = this._path.slice(lastDotIndex + 1, this._path.length);
        const index = parseInt(indexStr, 10);
        return Number.isInteger(index) ? index : null;
    }

    public toString(): string {
        return this._path;
    }
}
