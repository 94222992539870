export default {
    STATUS: {
        UNCONNECTED: 1,
        REQUESTED: 2,
        REFUSED: 3,
        CONNECTED: 4,
        DISCONNECTED: 5,
        ERRORED: 6,
        INVITATION_SENT: 7,
    },
};
