import { FEATURES } from '@powerednow/shared/constants/subscription';
import ROLES from '@powerednow/shared/constants/roles';

export default {
    HOME: {
        URL: 'home/',
        NAME: 'Home',
        ID: 1,
        REQUIRED_FEATURE: null,
        REQUIRED_PERMISSION: null,
        APPEARS_IN_SETTINGS: true,
    },
    DIARY_OWN: {
        URL: 'showTasks/',
        PARAMS: {
            view: 'day',
            selectedUserIds: '$userId',
        },
        NAME: 'Daily diary (own)',
        ID: 2,
        REQUIRED_FEATURE: null,
        REQUIRED_PERMISSION: null,
        APPEARS_IN_SETTINGS: true,
    },
    DIARY_ALL: {
        URL: 'showTasks/',
        NAME: 'Daily diary',
        ID: 3,
        REQUIRED_FEATURE: null,
        REQUIRED_PERMISSION: null,
        APPEARS_IN_SETTINGS: true,
    },
    SCHEDULE: {
        URL: 'showTasks/',
        PARAMS: {
            view: 'schedule',
        },
        NAME: 'Schedule diary',
        ID: 4,
        REQUIRED_FEATURE: FEATURES.CAN_USE_DIARY_SCHEDULE,
        REQUIRED_PERMISSION: ROLES.PERMISSIONS.CAN_USE_DIARY_SCHEDULE,
        APPEARS_IN_SETTINGS: true,
    },
    MONTHLY: {
        URL: 'showTasks/',
        PARAMS: {
            view: 'month',
            selectedUserIds: '$userId',
        },
        NAME: 'Monthly',
        ID: 5,
        REQUIRED_FEATURE: null,
        REQUIRED_PERMISSION: null,
        APPEARS_IN_SETTINGS: false,
    },
};
