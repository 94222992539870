const modelProperties = {
    modelName: 'Contact',
    tableName: 'Contact',
    associations: [{
        type: 'hasMany',
        model: 'ContactMethod',
        cascadeDelete: false,
        parameters: {
            as: 'Phone',
            foreignKey: { name: 'contact_id', fieldName: 'contact_id' },
            scope: { contactmethodtype_id: 1 },
        },
    }, {
        type: 'hasMany',
        model: 'ContactMethod',
        cascadeDelete: false,
        parameters: {
            as: 'Email',
            foreignKey: { name: 'contact_id', fieldName: 'contact_id' },
            scope: { contactmethodtype_id: 2 },
        },
    }, {
        type: 'hasMany',
        model: 'ContactMethod',
        cascadeDelete: false,
        parameters: {
            as: 'PhoneWork',
            foreignKey: { name: 'contact_id', fieldName: 'contact_id' },
            scope: { contactmethodtype_id: 4 },
        },
    }, {
        type: 'hasMany',
        model: 'ContactMethod',
        cascadeDelete: false,
        parameters: {
            as: 'PhoneHome',
            foreignKey: { name: 'contact_id', fieldName: 'contact_id' },
            scope: { contactmethodtype_id: 5 },
        },
    }, {
        type: 'hasMany',
        model: 'ContactMethod',
        cascadeDelete: false,
        parameters: {
            as: 'ContactMethods',
            complexAssociationName: 'contactMethod',
            foreignKey: { name: 'contact_id', fieldName: 'contact_id' },
        },
    }, {
        type: 'hasMany',
        model: 'ContactToAddress',
        cascadeDelete: false,
        parameters: { as: 'ContactToAddress', foreignKey: { name: 'contact_id', fieldName: 'contact_id' } },
    },
    {
        type: 'hasOne',
        model: 'UserToContact',
        cascadeDelete: false,
        parameters: { as: 'UserToContact', foreignKey: { name: 'contact_id', fieldName: 'contact_id', sourceKeyName: 'id' } },
    },
    {
        type: 'hasOne',
        model: 'Contact',
        cascadeDelete: false,
        parameters: {
            as: 'contact',
            foreignKey: { name: 'id', fieldName: 'id', sourceKeyName: 'alternate_contact_id' },
        },
    }, {
        type: 'belongsTo',
        model: 'Contact',
        cascadeDelete: false,
        parameters: {
            as: 'AlternativeContact',
            foreignKey: { name: 'alternate_contact_id', fieldName: 'alternate_contact_id' },
        },
    }, {
        type: 'hasOne',
        model: 'Contact',
        cascadeDelete: false,
        parameters: { as: 'Parent', foreignKey: { name: 'alternate_contact_id', fieldName: 'alternateContactId' } },
    }, {
        type: 'belongsTo',
        model: 'Customer',
        cascadeDelete: false,
        parameters: { as: 'Customer', foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
        followFor: ['ParentCheck'],
    }],
    validations: {
        firstname: { type: 'length', max: 60 },
        lastname: { type: 'length', max: 60 },
        title: { type: 'length', max: 60 },
    },
};

export default modelProperties;
