export default {
    SALES: 0,
    EXPENSE: 1,
    SUPPLIER_INVOICE: 2,
    APPOINTMENT: 3,
    JOB: 4,
    QUOTE: 5,
    PURCHASE_ORDER: 6,
    TIME_LOG: 7,
    SALES_PAYMENT: 8,
    SUPPLIER_PAYMENT: 9,
    EXPENSE_PAYMENT: 10,
    PEOPLE_REPORT: 11,
};
