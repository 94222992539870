export default {
    MAIN_USER: {
        ID: 1,
        NAME: 'Main user',
        PROMPT: 'Userprofile.MainUserName',
        DEFAULT_STARTING_PAGE: 'HOME',
    },
    MANAGER: {
        ID: 2,
        NAME: 'Manager',
        PROMPT: 'Userprofile.ManagerName',
        DEFAULT_STARTING_PAGE: 'HOME',
    },
    ENGINEER: {
        ID: 3,
        NAME: 'Engineer',
        PROMPT: 'Userprofile.EnginerName',
        DEFAULT_STARTING_PAGE: 'DIARY_OWN',
    },
    ADMIN: {
        ID: 4,
        Name: 'Main user',
        PROMPT: 'Userprofile.MainUserName',
        DEFAULT_STARTING_PAGE: 'HOME',
    },
};
