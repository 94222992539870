const modelProperties = {
    modelName: 'Country',
    tableName: 'Country',
    associations: [{
        type: 'hasMany',
        model: 'CompanyAddress',
        parameters: { foreignKey: { name: 'country_id', fieldName: 'country_id' } },
    }, {
        type: 'hasMany',
        model: 'Prompt',
        parameters: { foreignKey: { name: 'country_id', fieldName: 'country_id' } },
    }, {
        type: 'hasMany',
        model: 'CountryFeature',
        parameters: { as: 'CountryFeature', foreignKey: { name: 'country_id', fieldName: 'country_id' } },
    }, {
        type: 'hasMany',
        model: 'CountryToCountryGroup',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'country_id', fieldName: 'country_id' } },
    }, {
        type: 'hasMany',
        model: 'PaymentTypeToCountry',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'country_id', fieldName: 'country_id' } },
    }, {
        type: 'belongsToMany',
        model: 'CountryGroup',
        ignoreModelOnCascadeDelete: true,
        modelKeys: { primary: 'id', foreign: 'country_group_id' },
        parameters: {
            through: { model: 'CountryToCountryGroup', unique: false },
            foreignKey: { name: 'country_id', fieldName: 'country_id' },
        },
    }],
    global: true,
};

export default modelProperties;
