import ACTION from '@powerednow/shared/constants/action';
import { EntityWithCompanyId, field } from '../entity';

export default class AppointmentTypeEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'string' })
    public name?: string;

    @field({ type: 'string' })
    public description? : string;

    @field({ type: 'int', defaultValue: 15 })
    public defaultDuration: number;

    @field({ type: 'int', defaultValue: 0 })
    public defaultDurationId: number;

    @field({ type: 'decimal', defaultValue: 0 })
    public price: number;

    @field({ type: 'boolean' })
    public editable: boolean;

    @field({ type: 'boolean', defaultValue: true })
    public isExternal: boolean;

    @field({ type: 'boolean', defaultValue: true })
    public isInternal: boolean;

    @field({
        type: 'string',
        defaultValue: 'You can request an appointment in the morning or afternoon and we '
            + 'will endeavour to meet your requirements. We will get straight back to you to confirm the appointment '
            + 'time or discuss an alternative if this does not work for either party',
    })
    public messageBefore: string;

    @field({
        type: 'string',
        defaultValue: 'Thanks for your booking!',
    })
    public messageAfter: string;

    @field({ type: 'boolean', defaultValue: true })
    public canBeMorning: boolean;

    @field({ type: 'boolean', defaultValue: true })
    public canBeAfternoon: boolean;

    @field({ type: 'boolean', defaultValue: false })
    public canBeEvening: boolean;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;

    @field({ type: 'int', defaultValue: ACTION.ACTION_TYPES.ACTION_TYPE_APPOINTMENT })
    public type: number;

    @field({
        type: 'string',
        isJSON: true,
        defaultValue: {
            color: '#000',
            fill: '#fff',
            backgroundColor: '#fff',
        },
    })
    public style: {
        color: string;
        fill: string;
        backgroundColor: string;
    };
}
