export default {
    IDS: {
        IM: 107,
        AFGHANISTAN: 0,
        ÅLAND_ISLANDS: 1,
        ALBANIA: 2,
        ALGERIA: 3,
        AMERICAN_SAMOA: 4,
        ANDORRA: 5,
        ANGOLA: 6,
        ANGUILLA: 7,
        ANTARCTICA: 8,
        ANTIGUA_AND_BARBUDA: 9,
        ARGENTINA: 10,
        ARMENIA: 11,
        ARUBA: 12,
        AUSTRALIA: 13,
        AUSTRIA: 14,
        AZERBAIJAN: 15,
        BAHAMAS: 16,
        BAHRAIN: 17,
        BANGLADESH: 18,
        BARBADOS: 19,
        BELARUS: 20,
        BELGIUM: 21,
        BELIZE: 22,
        BENIN: 23,
        BERMUDA: 24,
        BHUTAN: 25,
        BOLIVIA: 26,
        BONAIRE_SINT_EUSTATIUS_AND_SABA: 27,
        BOSNIA_AND_HERZEGOVINA: 28,
        BOTSWANA: 29,
        BOUVET_ISLAND: 30,
        BRAZIL: 31,
        BRITISH_INDIAN_OCEAN_TERRITORY: 32,
        BRUNEI_DARUSSALAM: 33,
        BULGARIA: 34,
        BURKINA_FASO: 35,
        BURUNDI: 36,
        CAMBODIA: 37,
        CAMEROON: 38,
        CANADA: 39,
        CAPE_VERDE: 40,
        CAYMAN_ISLANDS: 41,
        CENTRAL_AFRICAN_REPUBLIC: 42,
        CHAD: 43,
        CHILE: 44,
        CHINA: 45,
        CHRISTMAS_ISLAND: 46,
        COCOS_KEELING_ISLANDS: 47,
        COLOMBIA: 48,
        COMOROS: 49,
        CONGO: 50,
        CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE_: 51,
        COOK_ISLANDS: 52,
        COSTA_RICA: 53,
        CÔTE_DIVOIRE: 54,
        CROATIA: 55,
        CUBA: 56,
        CURAÇAO: 57,
        CYPRUS: 58,
        CZECH_REPUBLIC: 59,
        DENMARK: 60,
        DJIBOUTI: 61,
        DOMINICA: 62,
        DOMINICAN_REPUBLIC: 63,
        ECUADOR: 64,
        EGYPT: 65,
        EL_SALVADOR: 66,
        EQUATORIAL_GUINEA: 67,
        ERITREA: 68,
        ESTONIA: 69,
        ETHIOPIA: 70,
        FALKLAND_ISLANDS_MALVINAS: 71,
        FAROE_ISLANDS: 72,
        FIJI: 73,
        FINLAND: 74,
        FRANCE: 75,
        FRENCH_GUIANA: 76,
        FRENCH_POLYNESIA: 77,
        FRENCH_SOUTHERN_TERRITORIES: 78,
        GABON: 79,
        GAMBIA: 80,
        GEORGIA: 81,
        GERMANY: 82,
        GHANA: 83,
        GIBRALTAR: 84,
        GREECE: 85,
        GREENLAND: 86,
        GRENADA: 87,
        GUADELOUPE: 88,
        GUAM: 89,
        GUATEMALA: 90,
        GUERNSEY: 91,
        GUINEA: 92,
        GUINEA_BISSAU: 93,
        GUYANA: 94,
        HAITI: 95,
        HEARD_ISLAND_AND_MCDONALD_ISLANDS: 96,
        HOLY_SEE_VATICAN_CITY_STATE: 97,
        HONDURAS: 98,
        HONG_KONG: 99,
        HUNGARY: 100,
        ICELAND: 101,
        INDIA: 102,
        INDONESIA: 103,
        IRAN_ISLAMIC_REPUBLIC_OF: 104,
        IRAQ: 105,
        IRELAND: 106,
        ISRAEL: 108,
        ITALY: 109,
        JAMAICA: 110,
        JAPAN: 111,
        JERSEY: 112,
        JORDAN: 113,
        KAZAKHSTAN: 114,
        KENYA: 115,
        KIRIBATI: 116,
        KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF: 117,
        KOREA: 118,
        KUWAIT: 119,
        KYRGYZSTAN: 120,
        LAO_PEOPLE_DEMOCRATIC_REPUBLIC: 121,
        LATVIA: 122,
        LEBANON: 123,
        LESOTHO: 124,
        LIBERIA: 125,
        LIBYA: 126,
        LIECHTENSTEIN: 127,
        LITHUANIA: 128,
        LUXEMBOURG: 129,
        MACAO: 130,
        MACEDONIA: 131,
        MADAGASCAR: 132,
        MALAWI: 133,
        MALAYSIA: 134,
        MALDIVES: 135,
        MALI: 136,
        MALTA: 137,
        MARSHALL_ISLANDS: 138,
        MARTINIQUE: 139,
        MAURITANIA: 140,
        MAURITIUS: 141,
        MAYOTTE: 142,
        MEXICO: 143,
        MICRONESIA: 144,
        MOLDOVA: 145,
        MONACO: 146,
        MONGOLIA: 147,
        MONTENEGRO: 148,
        MONTSERRAT: 149,
        MOROCCO: 150,
        MOZAMBIQUE: 151,
        MYANMAR: 152,
        NAMIBIA: 153,
        NAURU: 154,
        NEPAL: 155,
        NETHERLANDS: 156,
        NEW_CALEDONIA: 157,
        NEW_ZEALAND: 158,
        NICARAGUA: 159,
        NIGER: 160,
        NIGERIA: 161,
        NIUE: 162,
        NORFOLK_ISLAND: 163,
        NORTHERN_MARIANA_ISLANDS: 164,
        NORWAY: 165,
        OMAN: 166,
        PAKISTAN: 167,
        PALAU: 168,
        PALESTINIAN_TERRITORY_OCCUPIED: 169,
        PANAMA: 170,
        PAPUA_NEW_GUINEA: 171,
        PARAGUAY: 172,
        PERU: 173,
        PHILIPPINES: 174,
        PITCAIRN: 175,
        POLAND: 176,
        PORTUGAL: 177,
        PUERTO_RICO: 178,
        QATAR: 179,
        RÉUNION: 180,
        ROMANIA: 181,
        RUSSIAN_FEDERATION: 182,
        RWANDA: 183,
        SAINT_BARTHÉLEMY: 184,
        SAINT_HELENA_ASCENSION_AND_TRISTAN_DA_CUNHA: 185,
        SAINT_KITTS_AND_NEVIS: 186,
        SAINT_LUCIA: 187,
        SAINT_MARTIN_FRENCH_PART: 188,
        SAINT_PIERRE_AND_MIQUELON: 189,
        SAINT_VINCENT_AND_THE_GRENADINES: 190,
        SAMOA: 191,
        SAN_MARINO: 192,
        SAO_TOME_AND_PRINCIPE: 193,
        SAUDI_ARABIA: 194,
        SENEGAL: 195,
        SERBIA: 196,
        SEYCHELLES: 197,
        SIERRA_LEONE: 198,
        SINGAPORE: 199,
        SINT_MAARTEN_DUTCH_PART: 200,
        SLOVAKIA: 201,
        SLOVENIA: 202,
        SOLOMON_ISLANDS: 203,
        SOMALIA: 204,
        SOUTH_AFRICA: 205,
        SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: 206,
        SOUTH_SUDAN: 207,
        SPAIN: 208,
        SRI_LANKA: 209,
        SUDAN: 210,
        SURINAME: 211,
        SVALBARD_AND_JAN_MAYEN: 212,
        SWAZILAND: 213,
        SWEDEN: 214,
        SWITZERLAND: 215,
        SYRIAN_ARAB_REPUBLIC: 216,
        TAIWAN: 217,
        TAJIKISTAN: 218,
        TANZANIA: 219,
        THAILAND: 220,
        TIMOR_LESTE: 221,
        TOGO: 222,
        TOKELAU: 223,
        TONGA: 224,
        TRINIDAD_AND_TOBAGO: 225,
        TUNISIA: 226,
        TURKEY: 227,
        TURKMENISTAN: 228,
        TURKS_AND_CAICOS_ISLANDS: 229,
        TUVALU: 230,
        UGANDA: 231,
        UKRAINE: 232,
        UNITED_ARAB_EMIRATES: 233,
        UNITED_KINGDOM: 234,
        UNITED_STATES: 235,
        UNITED_STATES_MINOR_OUTLYING_ISLANDS: 236,
        URUGUAY: 237,
        UZBEKISTAN: 238,
        VANUATU: 239,
        VENEZUELA: 240,
        VIETNAM: 241,
        VIRGIN_ISLANDS_BRITISH: 242,
        VIRGIN_ISLANDS: 243,
        WALLIS_AND_FUTUNA: 244,
        WESTERN_SAHARA: 245,
        YEMEN: 246,
        ZAMBIA: 247,
        ZIMBABWE: 248,
    },
    CONVERT: {
        UK: 'GB',
    },
};
