const modelProperties = {
    modelName: 'BookingDateException',
    tableName: 'BookingDateException',
    minAppVersion: '9.0.0',
    associations: [{
        type: 'hasOne',
        model: 'AppointmentType',
        parameters: { as: 'AppointmentType', foreignKey: { name: 'id', fieldName: 'id', sourceKeyName: 'appointmentTypeId' } },
    }],
    hooks: [],
    validations: {},
};

export default modelProperties;
