import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import ContactMethodTypeEntity from './entity';
import modelProperties from './modelProperties';

interface ContactMethodTypeAssociations extends AssociationConfig<any, any> {}

interface ContactMethodType extends AutoGeneratedFunctions<ContactMethodTypeAssociations, ContactMethodTypeEntity, ComplexData<ContactMethodTypeEntity>> {}

// eslint-disable-next-line no-redeclare
class ContactMethodType extends ComplexData<ContactMethodTypeEntity> {
    static Entity = ContactMethodTypeEntity;

    static modelProperties = modelProperties;
}

export default ContactMethodType;
