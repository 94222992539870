import { Nullable } from '@powerednow/type-definitions';
import Entity, { DateOrString, field } from '../entity';

const dateUtils = require('modules/utilities/date');

interface ContactGroupChoice {
    name: string;
    contactGroupId: string;
}

interface Settings {
    accountCodes: { [key: string]: any[] | null };
    accountCodeChoices: { [key: string]: any[] | null };
    taxRateMapping: { [key: string]: string };
    taxRateChoices: any[];
    contactGroupId: string;
    contactGroupChoices: ContactGroupChoice[];
    contactsCopied: boolean;
    sendContactsToXero: boolean;
    sendContactsToPN: boolean;
    sendSalesInvoicesToXero: boolean;
    sendSalesInvoicesApproved: boolean;
    sendSupplierInvoicesToXero: boolean;
    sendExpensesToXero: boolean;
    sendPaymentsToXero: boolean,
    sendPaymentsToPN: boolean,
    sendSupplierInvoicesApproved: boolean;
    allowSalesPaymentsToBeEnteredInPN: boolean;
    allowSupplierPaymentsToBeEnteredInPN: boolean;
    automaticInterfacing: boolean;
}

interface Token {
    oauth_token?: string;
    oauth_token_secret?: string;
    oauth_session_handle?: string;
    oauth_expires_at?: Date;
}

export interface IntegrationData {
    organisation: Organisation;
}

export interface Organisation {
    name: string;
    id: string;
    contactDeepLinkUrl: string;
    invoiceDeepLinkUrl: string;
    creditNoteDeepLinkUrl: string;
    supplierInvoiceDeepLinkUrl: string;
    supplierCreditNoteDeepLinkUrl: string;
}

export default class CompanyIntegrationEntity extends Entity {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;

    @field({ type: 'string', dbType: 'date' })
    public dt_updated: DateOrString;

    @field({ type: 'string', dbType: 'date' })
    public interfacing_start_date: DateOrString;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'int' })
    public integration_type_id: number;

    @field({ type: 'boolean' })
    public is_enabled: boolean;

    @field({ type: 'boolean' })
    public is_started: boolean;

    @field({ type: 'string' })
    public url: string;

    @field({ type: 'string', isJSON: true, defaultValue: {} })
    public settings: Settings;

    @field({ type: 'string', isJSON: true, defaultValue: {} })
    public token: Nullable<Token>;

    @field({
        type: 'string',
        preserveRequest: true,
        isJSON: true,
        defaultValue: {},
        hooks: { afterUpdate: [{ handler: 'performIntegrationSyncAction', args: [] }] },
    })
    public sync_action: { [name: string]: any };

    @field({ type: 'string', isJSON: true, defaultValue: {} })
    public integration_data: IntegrationData;

    @field({ type: 'string', dbType: 'date' })
    public last_sync_date: DateOrString;

    @field({ type: 'boolean', defaultValue: false })
    public is_usage_enabled: boolean;
}
