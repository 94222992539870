const modelProperties = {
    modelName: 'UserTaxRate',
    tableName: 'UserTaxRate',
    associations: [{
        type: 'hasMany',
        model: 'UserTaxRateFilter',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'taxrate_id', fieldName: 'taxrate_id' } },
    }, {
        type: 'hasMany',
        model: 'CompanyIntegration',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }],
    validations: {
        name: {
            type: 'length',
            min: 1,
            max: 60,
            bothOnlyMessagePromptId: 'ValidatorMessages.userTaxRateName',
            bothOnlyMessagePromptDefault: 'Name length must be between {min} and {max}',
        },
    },
};

export default modelProperties;
