import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import SignatureEntity from './entity';
import modelProperties from './modelProperties';

interface SignatureAssociations extends AssociationConfig<any, any> {}

interface Signature extends AutoGeneratedFunctions<SignatureAssociations, SignatureEntity, ComplexData<SignatureEntity>> {}

// eslint-disable-next-line no-redeclare
class Signature extends ComplexData<SignatureEntity> {
    static Entity = SignatureEntity;

    static modelProperties = modelProperties;
}

export default Signature;
