import { WHITE_LABEL_IDS } from '@powerednow/shared/constants/subscription';
import WhiteLabelToCustomer from '../whiteLabelToCustomer';
import ComplexData, { AssociationConfig, AssociationDefinition, AutoGeneratedFunctions } from '../complexData';
import WhiteLabelEntity from './entity';
import modelProperties from './modelProperties';
import WhiteLabelToCustomerEntity from '../whiteLabelToCustomer/entity';

interface WhiteLabelAssociations extends AssociationConfig<any, any> {
    whiteLabelToCustomer: AssociationDefinition<WhiteLabelToCustomerEntity, WhiteLabelToCustomer>
}

interface WhiteLabel extends AutoGeneratedFunctions<WhiteLabelAssociations, WhiteLabelEntity, ComplexData<WhiteLabelEntity>> {}

// eslint-disable-next-line no-redeclare
class WhiteLabel extends ComplexData<WhiteLabelEntity> {
    static Entity = WhiteLabelEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): WhiteLabelAssociations {
        return {
            whiteLabelToCustomer: {
                key: 'whiteLabelToCustomer',
                instance: WhiteLabelToCustomer,
                entity: WhiteLabelToCustomerEntity,
                cascadeDelete: true,
                condition: {
                    whitelabel_id: this.Entity.getFieldSymbols().id,
                },
            },
        };
    }

    public requiresAccountValidation(): boolean {
        return this.data.account_number_validation_rule !== '';
    }

    public static isValidWhiteLabelId(whiteLabelId) {
        return Object.values(WHITE_LABEL_IDS).includes(whiteLabelId);
    }

    public static validateWhiteLabelId(whitelabelId) {
        if (!this.isValidWhiteLabelId(whitelabelId)) {
            return WHITE_LABEL_IDS.POWEREDNOW;
        }

        return whitelabelId;
    }
}

export default WhiteLabel;
