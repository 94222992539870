import ComplexData, { AssociationConfig, AssociationDefinition, AutoGeneratedFunctions } from '../complexData';
import Media from '../media';
import MediaEntity from '../media/entity';
import MediaGroupEntity from './entity';
import modelProperties from './modelProperties';

interface MediaGroupAssociations extends AssociationConfig<any, any> {
    media: AssociationDefinition<MediaEntity, Media>
}

interface MediaGroup extends AutoGeneratedFunctions<MediaGroupAssociations, MediaGroupEntity, ComplexData<MediaGroupEntity>> {}

// eslint-disable-next-line no-redeclare
class MediaGroup extends ComplexData<MediaGroupEntity> {
    static Entity = MediaGroupEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): MediaGroupAssociations {
        return {
            media: {
                key: 'media',
                instance: Media,
                entity: MediaEntity,
                cascadeDelete: false,
                condition: {
                    mediagroup_id: this.Entity.getFieldSymbols().id,
                },
            },

        };
    }
}

export default MediaGroup;
