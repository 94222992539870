const modelProperties = {
    modelName: 'PaymentToInvoice',
    tableName: 'PaymentToInvoice',
    associations: [{
        type: 'belongsTo',
        model: 'Document',
        cascadeDelete: false,
        parameters: { as: 'Document', foreignKey: { name: 'document_id', fieldName: 'document_id' } },
    }, {
        type: 'belongsTo',
        model: 'Document',
        cascadeDelete: false,
        parameters: { as: 'CreditNote', foreignKey: { name: 'credit_note_id', fieldName: 'credit_note_id' } },
    }, {
        type: 'belongsTo',
        model: 'Payment',
        cascadeDelete: false,
        parameters: { as: 'Payment', foreignKey: { name: 'payment_id', fieldName: 'payment_id' } },
    }],
};

export default modelProperties;
