import { EntityWithCompanyId, field } from '../entity';
    
export default class UsageHistoryEntity extends EntityWithCompanyId {
    @field({ type: 'int' })
    public id: number;
    
    @field({ type: 'int', primaryKey: true, unique: 'compositeIndex' })
    public company_id: number;
    
    @field({ type: 'string', primaryKey: true, unique: 'compositeIndex' })
    public year_month: string;

    @field({ type: 'int' })
    public active_purchases: number;

    @field({ type: 'int' })
    public expired_purchases: number;

    @field({ type: 'boolean' })
    public track_location: boolean;

    @field({ type: 'boolean' })
    public auto_close_projects: boolean;

    @field({ type: 'boolean' })
    public auto_archive_by_project: boolean;

    @field({ type: 'boolean' })
    public auto_archive_by_item: boolean;
    
    @field({ type: 'string' })
    public company_name: string;
    
    @field({ type: 'string' })
    public phone: string;

    @field({ type: 'boolean' })
    public is_vat_registered: boolean;

    @field({ type: 'boolean' })
    public is_cis_enabled: boolean;

    @field({ type: 'boolean' })
    public is_disbursements_enabled: boolean;

    @field({ type: 'boolean' })
    public mtd_enabled: boolean;

    @field({ type: 'int' })
    public team_size: number;

    @field({ type: 'string' })
    public business_type: string;

    @field({ type: 'string' })
    public currency: string;
    
    @field({ type: 'string' })
    public postcode: string;

    @field({ type: 'int' })
    public sites_count: number;

    @field({ type: 'int' })
    public invoicing_addresses_count: number;

    @field({ type: 'int' })
    public users: number;

    @field({ type: 'string' })
    public email: string;
    
    @field({ type: 'int' })
    public customers: number;
    
    @field({ type: 'int' })
    public customers_linked_to_pn: number;

    @field({ type: 'int' })
    public form_documents_count: number;

    @field({ type: 'int' })
    public signatures: number;

    @field({ type: 'int' })
    public jobs: number;

    @field({ type: 'int' })
    public media: number;

    @field({ type: 'int' })
    public time_logs: number;

    @field({ type: 'int' })
    public emails_sent: number;

    @field({ type: 'int' })
    public emails_opened: number;

    @field({ type: 'int' })
    public sms_messages_sent: number;

    @field({ type: 'int' })
    public checkins: number;

    @field({ type: 'int' })
    public notes: number;

    @field({ type: 'boolean' })
    public sumup_enabled: boolean;

    @field({ type: 'boolean' })
    public sumup_online_enabled: boolean;

    @field({ type: 'boolean' })
    public xero_interface_enabled: boolean;

    @field({ type: 'boolean' })
    public xero_usage_enabled: boolean;

    @field({ type: 'string' })
    public active_subscription_tiers: string;
    
    @field({ type: 'int' })
    public appointments: number;

    @field({ type: 'int' })
    public reminders: number;

    @field({ type: 'int' })
    public priced_items: number;
    
    @field({ type: 'int' })
    public documents: number;

    @field({ type: 'int' })
    public sales_invoices: number;

    @field({ type: 'float' })
    public sales_invoices_amount: number;
    
    @field({ type: 'int' })
    public sales_credit_notes: number;
    
    @field({ type: 'int' })
    public quotes: number;
    
    @field({ type: 'int' })
    public job_sheets: number;
    
    @field({ type: 'int' })
    public supplier_invoices_and_expenses: number;
    
    @field({ type: 'float' })
    public supplier_invoices_and_expenses_total: number;

    @field({ type: 'int' })
    public expenses: number;

    @field({ type: 'float' })
    public expenses_total: number;

    @field({ type: 'int' })
    public payments: number;

    @field({ type: 'float' })
    public payments_amount: number;

    @field({ type: 'float' })
    public expense_payments_amount: number;

    @field({ type: 'int' })
    public sumup_payments_count: number;

    @field({ type: 'float' })
    public sumup_payments_amount: number;

    @field({ type: 'int' })
    public sumup_online_payments_count: number;

    @field({ type: 'float' })
    public sumup_online_payments_amount: number;

    @field({ type: 'int' })
    public cash_payments_count: number;

    @field({ type: 'float' })
    public cash_payments_amount: number;

    @field({ type: 'int' })
    public cheque_payments_count: number;

    @field({ type: 'float' })
    public cheque_payments_amount: number;

    @field({ type: 'int' })
    public bank_transfer_payments_count: number;

    @field({ type: 'float' })
    public bank_transfer_payments_amount: number;

    @field({ type: 'int' })
    public paypal_standard_payments_count: number;

    @field({ type: 'float' })
    public paypal_standard_payments_amount: number;

    @field({ type: 'int' })
    public paypal_here_payments_count: number;

    @field({ type: 'float' })
    public paypal_here_payments_amount: number;

    @field({ type: 'int' })
    public paypal_checkin_payments_count: number;

    @field({ type: 'float' })
    public paypal_checkin_payments_amount: number;

    @field({ type: 'int' })
    public direct_debit_payments_count: number;

    @field({ type: 'float' })
    public direct_debit_payments_amount: number;

    @field({ type: 'int' })
    public standing_order_payments_count: number;

    @field({ type: 'float' })
    public standing_order_payments_amount: number;

    @field({ type: 'int' })
    public chip_and_pin_payments_count: number;

    @field({ type: 'float' })
    public chip_and_pin_payments_amount: number;

    @field({ type: 'int' })
    public credit_card_manual_payments_count: number;

    @field({ type: 'float' })
    public credit_card_manual_payments_amount: number;
}
