import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import SettingsKeysEntity from './entity';
import modelProperties from './modelProperties';
import { EntityWithId } from '../entity';

interface SettingsKeysAssociations extends AssociationConfig<any, any> {}

interface SettingsKeys extends AutoGeneratedFunctions<SettingsKeysAssociations, SettingsKeysEntity, ComplexData<SettingsKeysEntity>> {}

// eslint-disable-next-line no-redeclare
class SettingsKeys extends ComplexData<SettingsKeysEntity> {
    static Entity = SettingsKeysEntity;

    static modelProperties = modelProperties;

    public static async getDefaultSettingValue(settingKeyId: number, complexInstance: ComplexData<any>): Promise<number|string|object> {
        const settingsKeys = await this.getAll(complexInstance);
        const settingKey = settingsKeys.find((key: SettingsKeys): boolean => key.data.id === settingKeyId);
        return settingKey ? settingKey.data.default_value : undefined;
    }
}

export default SettingsKeys;
