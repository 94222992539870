const modelProperties = {
    modelName: 'Address',
    tableName: 'Address',
    associations: [{
        type: 'belongsTo',
        model: 'Country',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'country', fieldName: 'country' }, targetKey: 'code', targetKeyName: 'code' },
    }],
    validations: {
        country: { type: 'length', max: 45 },
        state: { type: 'length', max: 45 },
        city: { type: 'length', max: 45 },
        county: { type: 'length', max: 45 },
        address1: { type: 'length', max: 100 },
        address2: { type: 'length', max: 100 },
        postcode: { type: 'length', max: 45 },
        name: { type: 'length', max: 64 },
    },
};

export default modelProperties;
