export default {
    PORTAL_COMPANY_ID: 0,
    FEATURE_NAMES: {
        POSTCODE: 'postcode',
    },
    QUOTE_NAME_IDS: {
        QUOTE: 1,
        ESTIMATE: 2,
    },
    TEST_COMPANY_NAME_PREFIX: 'PN-Test',
    TEST_COMPANY_NAME_MATCHER: '.*Auron.*|.*Revi .*|.*PN-Test.*|.*PN Test.*|^test$|^test company$',
    TEST_MAIN_USER_EMAIL_MATCHER: '.*adammt.*|.*addammt.*|.*cdbarling.*|.*@rony.me|.*@powerednow.com|.*bendyer.*|.*zmagyar.*|^bdyer.*|.*emmatest.*|.*emmajanedyer.*|.*emmathorne.*|.*dyer.*test.*|.*b.endyer.*|.*@test.*',
};
