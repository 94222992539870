import Entity, { EntityWithId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class PromptEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({
        type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils), serverOnly: true, 
    })
    public dt_created: string;

    @field({
        type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils), serverOnly: true, 
    })
    public dt_updated: string;
    
    @field({ type: 'string' })
    public code: string;

    @field({ type: 'string', serverOnly: true })
    public subcode: string;

    @field({ type: 'string', defaultValue: 'string', serverOnly: true })
    public type: string;

    @field({ type: 'string' })
    public value: string;

    @field({ type: 'string' })
    public description: string;

    @field({ type: 'int', serverOnly: true })
    public language_id: number;

    @field({ type: 'string', serverOnly: true })
    public country_id: string;

    @field({ type: 'string' })
    public value_phone: string;
}
