export default {
    ACTIONS: {
        REGISTER: 'registration',
        LOGIN: 'login',
    },
    PROVIDERS: {
        FACEBOOK: {
            NAME: 'facebook',
            APP_ID: '644649102247694',
            TOKEN_VALIDATOR: 'https://graph.facebook.com/me?access_token={accessToken}',
        },
        GOOGLE: {
            API_KEY: 'AIzaSyCpkSJuqwHDgb8qkbrLHp8D4sYncYJmqGo',
            NAME: 'google',
            WEB_CLIENT_ID: '449892265491-uohcs4eacokrj0mmrf21l2g1492g7ogq.apps.googleusercontent.com',
            TOKEN_VALIDATOR: 'https://www.googleapis.com/oauth2/v1/tokeninfo?access_token={accessToken}',
        },
    },
    MAX_TIMEOUT: 10000,
};
