import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class AttachmentEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;
    
    @field({ type: 'string' })
    public name: string;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'string' })
    public url: string;
    
    @field({ type: 'string' })
    public original_url: string;
    
    @field({ type: 'string', clientOnly: true })
    public thumb: string;
    
    @field({ type: 'boolean' })
    public quote: boolean;
    
    @field({ type: 'boolean' })
    public invoice: boolean;
    
    @field({ type: 'boolean' })
    public worksheet: boolean;
    
    @field({ type: 'boolean', codeName: 'creditNote' })
    public creditnote: boolean;
    
    @field({ type: 'float' })
    public size: number;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;
    
    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;
    
    @field({ type: 'string', defaultValue: 'not_uploaded' })
    public status: string;
}
