import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';
    
export default class PurchaseActionEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true, autoIncrement: true })
    public id: number;
    
    @field({ type: 'int', hooks: { afterCreate: [{ handler: 'updateCompanyMtdSettings', args: [] }] } })
    public action: number;
    
    @field({ type: 'string', dbType: 'date' })
    public action_created: DateOrString;
    
    @field({ type: 'int' })
    public company_id: number;
    
    @field({ type: 'string' })
    public product: string;
    
    @field({ type: 'string', dbType: 'date' })
    public expires_date: DateOrString;
    
    @field({ type: 'string', dbType: 'date' })
    public purchase_date: DateOrString;
    
    @field({ type: 'string' })
    public purchase_origin: string;
    
    @field({ type: 'string' })
    public purchase_currency: string;
    
    @field({ type: 'decimal' })
    public purchase_amount: number;
    
    @field({ type: 'boolean' })
    public is_test: boolean;
    
    @field({ type: 'boolean' })
    public is_reactivation: boolean;
    
    @field({ type: 'int' })
    public purchase_id: number;
    
    @field({ type: 'int' })
    public previous_purchase_id: number;
}
