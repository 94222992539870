/// <reference path="../document/index.d.ts" />

import arrayUtils from 'modules/utilities/array';
import * as Bluebird from 'bluebird';
import ACTION_CONST from '@powerednow/shared/constants/action';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import Customer from '../customer';
import ContactToAddress from '../contactToAddress';
import FormDocument from '../formDocument';
import Document, { TotalObject } from '../document';
import Payment from '../payment';
import JobEntity from './entity';
import modelProperties from './modelProperties';
import Action from '../action';
import TimeLog from '../timeLog';
import JobStats from '../jobStats';
import Message from '../message';
import Note from '../note';
import MediaGroup from '../mediaGroup';
import JobItemGroup from '../jobItemGroup';
import DocumentAction from '../documentAction';
import Signature from '../signature';
import FileGroup from '../fileGroup';
import ArchiveBatch from '../archiveBatch';
import Media from '../media';
import ActionEntity from '../action/entity';
import JobStatsEntity from '../jobStats/entity';
import CustomerEntity from '../customer/entity';
import ContactToAddressEntity from '../contactToAddress/entity';
import MessageEntity from '../message/entity';
import FormDocumentEntity from '../formDocument/entity';
import NoteEntity from '../note/entity';
import MediaGroupEntity from '../mediaGroup/entity';
import JobItemGroupEntity from '../jobItemGroup/entity';
import DocumentEntity from '../document/entity';
import DocumentActionEntity from '../documentAction/entity';
import SignatureEntity from '../signature/entity';
import TimeLogEntity from '../timeLog/entity';
import CustomerMessageEntity from '../customerMessage/entity';
import FileGroupEntity from '../fileGroup/entity';
import ArchiveBatchEntity from '../archiveBatch/entity';
import MediaEntity from '../media/entity';

import CustomerMessage from '../customerMessage';
import DemoJob from '../../../constants/demoJob';

const projectItemNames: {
    [product: string]: string;
} = {
    note: 'note',
    action: 'action',
    mediaGroup: 'mediaGroup',
    media: 'media',
    jobItemGroup: 'jobItemGroup',
    document: 'document',
    documentAction: 'documentAction',
    signature: 'signature',
    timeLog: 'timeLog',
    message: 'message',
    archiveBatch: 'archiveBatch',
    formDocument: 'formDocument',
    customerMessage: 'customerMessage',
    fileGroup: 'fileGroup',
};
interface JobAssociations extends AssociationConfig<any, any> {
    jobStats: AssociationDefinitionSingle<JobStatsEntity, JobStats>
    customer: AssociationDefinitionSingle<CustomerEntity, Customer>
    appointment: AssociationDefinition<ActionEntity, Action>
    site: AssociationDefinitionSingle<ContactToAddressEntity, ContactToAddress>
    message: AssociationDefinition<MessageEntity, Message>
    formDocument: AssociationDefinition<FormDocumentEntity, FormDocument>
    note: AssociationDefinition<NoteEntity, Note>
    action: AssociationDefinition<ActionEntity, Action>
    media: AssociationDefinition<MediaEntity, Media>
    mediaGroup: AssociationDefinition<MediaGroupEntity, MediaGroup>
    jobItemGroup: AssociationDefinition<JobItemGroupEntity, JobItemGroup>
    document: AssociationDefinition<DocumentEntity, Document>
    documentAction: AssociationDefinition<DocumentActionEntity, DocumentAction>
    signature: AssociationDefinition<SignatureEntity, Signature>
    timeLog: AssociationDefinition<TimeLogEntity, TimeLog>
    customerMessage: AssociationDefinition<CustomerMessageEntity, CustomerMessage>
    fileGroup: AssociationDefinition<FileGroupEntity, FileGroup>
}

interface Job extends AutoGeneratedFunctions<JobAssociations, JobEntity, ComplexData<JobEntity>> {}

// eslint-disable-next-line no-redeclare
class Job extends ComplexData<JobEntity> {
    static Entity = JobEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): JobAssociations {
        return {
            jobStats: {
                key: 'jobStats',
                instance: JobStats,
                entity: JobStatsEntity,
                single: true,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            customer: {
                key: 'customer',
                instance: Customer,
                entity: CustomerEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().customer_id,
                },
            },
            appointment: {
                key: 'action',
                instance: Action,
                entity: ActionEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                    type: ACTION_CONST.ACTION_TYPES.ACTION_TYPE_APPOINTMENT,
                },
            },
            site: {
                key: 'contactToAddress',
                instance: ContactToAddress,
                entity: ContactToAddressEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().site_id,
                },
            },
            note: {
                key: 'note',
                instance: Note,
                entity: NoteEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            action: {
                key: 'action',
                instance: Action,
                entity: ActionEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            mediaGroup: {
                key: 'mediaGroup',
                instance: MediaGroup,
                entity: MediaGroupEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            jobItemGroup: {
                key: 'jobItemGroup',
                instance: JobItemGroup,
                entity: JobItemGroupEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            document: {
                key: 'document',
                instance: Document,
                entity: DocumentEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            documentAction: {
                key: 'documentAction',
                instance: DocumentAction,
                entity: DocumentActionEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            signature: {
                key: 'signature',
                instance: Signature,
                entity: SignatureEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            timeLog: {
                key: 'timeLog',
                instance: TimeLog,
                entity: TimeLogEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            message: {
                key: 'message',
                instance: Message,
                entity: MessageEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            formDocument: {
                key: 'formDocument',
                instance: FormDocument,
                entity: FormDocumentEntity,
                cascadeDelete: false,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            customerMessage: {
                key: 'customerMessage',
                instance: CustomerMessage,
                entity: CustomerMessageEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            fileGroup: {
                key: 'fileGroup',
                instance: FileGroup,
                entity: FileGroupEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            archiveBatch: {
                key: 'archiveBatch',
                instance: ArchiveBatch,
                entity: ArchiveBatchEntity,
                cascadeDelete: false,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
            media: {
                key: 'media',
                instance: Media,
                entity: MediaEntity,
                cascadeDelete: true,
                condition: {
                    job_id: this.Entity.getFieldSymbols().id,
                },
            },
        };
    }

    async findSite() {
        const site = await this.getSite();
        if (!site) {
            return (await this.getCustomer()).getMainContactToAddress();
        }
        return site;
    }

    hasAnyItem(): Promise<boolean> {
        return arrayUtils.someAsync(this.getProjectItemAssociationNames(), async assocName => (await this.getAssociatedValueCount(assocName, [])) > 0);
    }

    async getItems(): Promise<Array<Note|CustomerMessage|Action|MediaGroup|JobItemGroup|Document|DocumentAction|Signature|TimeLog|Message>> {
        return arrayUtils.mergeAsync(this.getProjectItemAssociationNames().filter(assocName => Boolean(assocName)), assocName => (this.getAssociatedValue(assocName)));
    }

    getProjectItemAssociationNames(): string[] {
        return [
            projectItemNames.note,
            projectItemNames.customerMessage,
            projectItemNames.action,
            projectItemNames.mediaGroup,
            projectItemNames.jobItemGroup,
            projectItemNames.document,
            projectItemNames.documentAction,
            projectItemNames.signature,
            projectItemNames.timeLog,
            projectItemNames.message,
        ];
    }

    public async addPayment(payment: Payment) {
        const stats = await this.getJobStats();
        let total = payment.data.amount;

        if (stats && stats.data.invoice_payments) {
            total += stats.data.invoice_payments;
        }
        await this.setStats({ invoice_payments: total });
    }

    public async getConfirmedDocuments(): Promise<Document[]> {
        return (await this.getAllDocument()).filter(document => document.data.confirmed);
    }

    public async getTotals(): Promise<TotalObject> {
        const totals = {
            quoted: 0,
            accepted: 0,
            invoiced: 0,
            invoiced_exVat: 0,
            supplier_invoiced: 0,
            supplier_invoiced_exVat: 0,
            invoice_payments: 0,
            supplier_invoice_payments: 0,
            projected_profit: 0,
            projected_profit_exVat: 0,
            outstanding_invoice_payments: 0,
            outstanding_supplier_invoice_payments: 0,
        };
        await Bluebird.each(this.getConfirmedDocuments(), async document => {
            const totalAdjustments: TotalObject = await document.getDocumentTotals();
            Object.entries(totalAdjustments).forEach(([key, value]) => {
                totals[key] += value;
            });
        });

        Object.entries(totals).forEach(([key, value]) => {
            totals[key] = Math.abs(value);
        });

        return {
            ...totals,
            outstanding_invoice_payments: totals.invoiced - totals.invoice_payments,
            outstanding_supplier_invoice_payments: totals.supplier_invoiced - totals.supplier_invoice_payments,
            projected_profit: totals.invoiced - totals.supplier_invoiced,
            projected_profit_exVat: totals.invoiced_exVat - totals.supplier_invoiced_exVat,
        };
    }

    private async getTimeLogStats() {
        const logRecords: TimeLog[] = await this.getAllTimeLog();
        const totalTime = logRecords.reduce((subTotal, logRecord) => subTotal + logRecord.data.time, 0);

        return { totalTime };
    }

    async getVisibleTimelineItems() {
        const unFilteredTimelineItems = await this.getItems();
        return unFilteredTimelineItems.filter(item => (!('is_hidden' in item.data) || !item.data?.is_hidden));
    }

    isDemoJob() {
        return this.data.dt_created === DemoJob.dt_created && this.data.customer_id === DemoJob.customer_id;
    }

    public async setStats(newValue): Promise<JobStats> {
        const stats = await this.getJobStats();
        if (!stats) {
            return this.setJobStats({
                company_id: this.data.company_id,
                job_id: this.data.id,
                ...newValue,
            });
        }
        Object.assign(stats.data, newValue);
        return stats;
    }

    public async updateTotals(): Promise<JobStats> {
        const totals = await this.getTotals();
        const timeTotals = await this.getTimeLogStats();
        return this.setStats({
            ...totals,
            ...timeTotals,
        });
    }
}

export default Job;
