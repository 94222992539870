import CONSTANTS from '@powerednow/shared/constants';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import ConsumptionExtraEntity from './entity';
import modelProperties from './modelProperties';
import Purchases from '../purchases';
import PurchaseSetupEntity from '../purchaseSetup/entity';
import PurchaseSetup from '../purchaseSetup';

interface ConsumptionExtraAssociations extends AssociationConfig<any, any> {
    purchaseSetupIOS: AssociationDefinitionSingle<PurchaseSetupEntity, PurchaseSetup>
    purchaseSetupAndroid: AssociationDefinitionSingle<PurchaseSetupEntity, PurchaseSetup>

}

interface ConsumptionExtra extends AutoGeneratedFunctions<ConsumptionExtraAssociations, ConsumptionExtraEntity, ComplexData<ConsumptionExtraEntity>> {}

// eslint-disable-next-line no-redeclare
class ConsumptionExtra extends ComplexData<ConsumptionExtraEntity> {
    static Entity = ConsumptionExtraEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): ConsumptionExtraAssociations {
        return {
            purchaseSetupIOS: {
                key: 'purchaseSetup',
                instance: PurchaseSetup,
                entity: PurchaseSetupEntity,
                single: true,
                condition: {
                    ios_product_id: this.Entity.getFieldSymbols().product,
                },
            },
            purchaseSetupAndroid: {
                key: 'purchaseSetup',
                instance: PurchaseSetup,
                entity: PurchaseSetupEntity,
                single: true,
                condition: {
                    android_product_id: this.Entity.getFieldSymbols().product,
                },
            },
        };
    }

    isExpired() {
        return this.data.expires_date <= Purchases.getOverTimedDate();
    }

    async getPurchaseSetup() {
        return (await this.getPurchaseSetupAndroid() || this.getPurchaseSetupIOS());
    }
}

export default ConsumptionExtra;
export type ConsumptionExtraType = ConsumptionExtra;
