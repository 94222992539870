import { TYPES_Type as PAYMENT_TYPES_Type } from '@powerednow/shared/constants/payment';
import Entity, { DateOrString, field } from '../entity';

const dateUtils = require('modules/utilities/date');
const { v4: uuidv4 } = require('uuid');

export default class PaymentEntity extends Entity {
    @field({ type: 'int', primaryKey: true })
    public id?: number;
    
    @field({ type: 'decimal' })
    public amount: number;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;
    
    @field({ type: 'int' })
    public type: PAYMENT_TYPES_Type;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'string' })
    public reference: string;
    
    @field({ type: 'boolean', defaultValue: false })
    public is_refund: boolean;
    
    @field({ type: 'boolean', defaultValue: false })
    public is_expense: boolean;
    
    @field({ type: 'boolean', defaultValue: false })
    public is_cis?: boolean;
    
    @field({ type: 'int' })
    public cis_payment_id?: number;
    
    @field({ type: 'boolean', defaultValue: true })
    public is_approved: boolean;
    
    @field({ type: 'int', clientOnly: true })
    public user_id?: number;
    
    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;
    
    @field({ type: 'boolean', defaultValue: false, codeName: 'isLocked' })
    public islocked?: boolean;
    
    @field({ type: 'string', dbType: 'date' })
    public dt_locked?: DateOrString;
    
    @field({ type: 'boolean', codeName: 'isArchived', serverOnly: true })
    public is_archived?: boolean;
    
    @field({ type: 'int', allowNull: true })
    public vat_return_id?: number;
    
    @field({ type: 'string', defaultValue: null })
    public accounting_reference?: string;
    
    @field({ type: 'string', defaultValue: uuidv4.bind(uuidv4) })
    public sync_uuid?: string;
    
    @field({ type: 'string', defaultValue: null })
    public synced_uuid?: string;
    
    @field({ type: 'string', isJSON: true, defaultValue: {} })
    public details?: {
        ok: boolean;
        status: number;
        headers: any;
        response: any;
        statusText: string;
    };
    
    @field({ type: 'int', allowNull: true })
    public integration_source_type_id?: number;

    @field({ type: 'decimal', minAppVersion: '8.5.0' })
    public fee?: number;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_pinned?: boolean;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_marked?: boolean;
}
