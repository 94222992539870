const modelProperties = {
    modelName: 'UserToContact',
    tableName: 'UserToContact',
    minAppVersion: '9.0.0',
    associations: [{
        cascadeDelete: false,
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        cascadeDelete: false,
        type: 'belongsTo',
        model: 'User',
        parameters: {
            as: 'User',
            foreignKey: {
                name: 'user_uuid', fieldName: 'user_uuid',
            },
            targetKeyName: 'uuid',
            targetKey: 'uuid',
        },
    }, {
        cascadeDelete: false,
        type: 'belongsTo',
        model: 'Contact',
        parameters: { as: 'Contact', foreignKey: { name: 'contact_id', fieldName: 'contact_id' } },
    }],
    validations: {

    },
};

export default modelProperties;
