import Country from '../country';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import CompanyAddressEntity from './entity';
import modelProperties from './modelProperties';
import CountryEntity from '../country/entity';

interface CompanyAddressAssociations extends AssociationConfig<any, any> {
    country: AssociationDefinitionSingle<CountryEntity, Country>
}

interface CompanyAddress extends AutoGeneratedFunctions<CompanyAddressAssociations, CompanyAddressEntity, ComplexData<CompanyAddressEntity>> {}

// eslint-disable-next-line no-redeclare
class CompanyAddress extends ComplexData<CompanyAddressEntity> {
    static Entity = CompanyAddressEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): CompanyAddressAssociations {
        return {
            country: {
                key: 'country',
                instance: Country,
                entity: CountryEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().country_id,
                },
            },
        };
    }

    public async findCountryId(): Promise<number> {
        const country = await this.getCountry();
        return country.data.id;
    }
}

export default CompanyAddress;
