export default {
    INVALID_CSV_FILE: {
        prompt: 'ApiValidationResponse.INVALID_CSV_FILE',
        default: 'The uploaded file is not a valid CSV file!',
    },
    MISSING_COLUMN: {
        prompt: 'ApiValidationResponse.MISSING_COLUMN',
        default: 'Column %s not found!',
    },
    MISSING_FIELD: {
        prompt: 'ApiValidationResponse.MISSING_FIELD',
        default: '%s field cannot be blank!',
    },
    FOREIGN_VALUE_NOT_EXISTS: {
        prompt: 'ApiValidationResponse.FOREIGN_VALUE_NOT_EXISTS',
        default: '"%s" must be an existing %s!',
    },
    INVALID_LENGTH: {
        prompt: 'ApiValidationResponse.INVALID_LENGTH',
        default: '%s should be at least %s character!',
    },
    MAX_LENGTH: {
        prompt: 'ApiValidationResponse.MAX_LENGTH',
        default: '%s cannot be longer than %s character! Using special characters can causing more length than expected!',
    },
    FIELD_MUST_BE_NUMERIC: {
        prompt: 'ApiValidationResponse.FIELD_MUST_BE_NUMERIC',
        default: '"%s" is not a valid number on field "%s"!',
    },
    FIELD_MUST_MATCH_WITH_PARAMS: {
        prompt: 'ApiValidationResponse.FIELD_MUST_MATCH_WITH_PARAMS',
        default: 'Field "%s" should be one of the following values: %s!',
    },
};
