const modelProperties = {
    modelName: 'JobItemGroup',
    tableName: 'JobItemGroup',
    associations: [{
        type: 'hasMany',
        model: 'JobItem',
        parameters: { foreignKey: { name: 'jobitemgroup_id', fieldName: 'jobitemgroup_id' } },
        followFor: ['Archive', 'DocumentClone'],
        includeRestores: ['JobItemGroup'],
    }, {
        serverOnly: true,
        type: 'hasOne',
        model: 'DocumentItem',
        parameters: { foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
    }, { type: 'belongsTo', model: 'Job', parameters: { foreignKey: { name: 'job_id', fieldName: 'job_id' } } }, {
        type: 'belongsTo',
        model: 'Job',
        parameters: {
            foreignKey: {
                name: 'job_id',
                fieldName: 'job_id',
            },
        },
    }],
};

export default modelProperties;
