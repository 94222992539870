const modelProperties = {
    modelName: 'ProductTierToFeature',
    tableName: 'ProductTierToFeature',
    minAppVersion: '9.0.0',
    global: true,
    associations: [],
    validations: { name: { type: 'length', max: 64 } },
};

export default modelProperties;
