export default {
    TYPES: {
        EMAIL: 'email',
        SMS: 'sms',
        PUSH: 'push',
        WHATSAPP: 'whatsApp',
        ELECTRONIC: 'electronic',
        CALL: 'Call',
        PORTAL: 'Portal',
    },
};
