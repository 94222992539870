import Company from 'modules/complexData/company';
import CustomerMessage from '../complexData/customerMessage';

type TemplateDataType = {
    [key: string]: any;
};

export default class TemplateDataExtender {
    private templateData: TemplateDataType;

    constructor(templateData: TemplateDataType) {
        this.templateData = templateData;
    }

    getData(): TemplateDataType {
        return this.templateData;
    }

    public async addLocaleInfoForCompany(company: Company): Promise<void> {
        const localeInfo = await company.getLocaleInfo();
        this.templateData = {
            ...this.templateData,
            ...localeInfo,
        };
    }

    public async addLinkedRecordTemplateData(message: CustomerMessage): Promise<void> {
        this.templateData = {
            ...this.templateData,
            ...await message.getLinkedRecordTemplateData(),
        };
    }
}
