export default {
    DOCUMENT_TYPE: {
        DOCUMENT: 'document',
        FORM_DOCUMENT: 'form_document',
        CUSTOMER_PORTAL_LOGO: 'customer_portal',
    },
    SOURCE: {
        UNKNOWN: 0,
        DEVICE_GALERY: 1,
        DEVICE_CAMERA: 2,
        LOGOCOLLECTION: 3,
        GOOGLEDRIVE: 4,
        DROPBOX: 5,
    },
};
