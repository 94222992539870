/**
 * Created by krisztiankormoczi on 2017. 07. 27..
 */
const BaseValidator = require('modules/validation/baseValidator');
const { commonPasswords } = require('./commonPasswords.json');

class PasswordValidator extends BaseValidator {
    constructor(config = {}) {
        super(config);
        Object.assign(this.config, {
            message: 'notValidPassword',
            messageCommonPass: 'commonPassword',
            matcher: /\S+/,
        });
    }

    validate(value) {
        const { matcher } = this.config;
        const result = matcher && matcher.test(value);

        if (!result) {
            return this.config.message;
        }

        if (commonPasswords.includes(value)) {
            return this.config.messageCommonPass;
        }

        return result;
    }
}

module.exports = PasswordValidator;
