import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class CustomerMessageEntity extends EntityWithCompanyId {
    @field({
        type: 'int',
        primaryKey: true,
    })
    public id?: number;

    @field({
        type: 'int',
        primaryKey: true,
        serverOnly: true,
    })
    public company_id: number;

    @field({ type: 'int' })
    public parent_id: number;

    @field({ type: 'int' })
    public customer_id: number;

    @field({ type: 'int' })
    public user_id: number;

    @field({ type: 'int' })
    public job_id: number;

    @field({ type: 'int' })
    public linked_id: number;

    @field({ type: 'int' })
    public linked_type: number;

    @field({ type: 'int' })
    public type: number;

    @field({
        defaultValue: 1,
        type: 'int',
    })
    public direction: number;

    @field({
        type: 'string',
        dbType: 'date',
        defaultValue: dateUtils.getNow.bind(dateUtils),
    })
    public dt_created: DateOrString;

    @field({
        type: 'boolean',
        codeName: 'isDeleted',
        serverOnly: true,
    })
    public isdeleted?: boolean;

    @field({
        defaultValue: 0,
        type: 'int',
    })
    public attachment_count?: number;

    @field({
        type: 'boolean',
        codeName: 'isArchived',
        serverOnly: true,
        defaultValue: false,
    })
    public is_archived?: boolean;

    @field({ type: 'boolean', defaultValue: false })
    public is_pinned?: boolean;

    @field({ type: 'boolean', defaultValue: false })
    public is_marked?: boolean;
}
