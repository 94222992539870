import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class ContactMethodEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;
    
    @field({ type: 'string' })
    public value: string;
    
    @field({ type: 'boolean', codeName: 'isDefault' })
    public isdefault?: boolean;
    
    @field({ type: 'int', codeName: 'contactMethodTypeId' })
    public contactmethodtype_id: number;
    
    @field({ type: 'int' })
    public contact_id: number;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created?: DateOrString;
    
    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted?: boolean;
    
    @field({ type: 'boolean' })
    public is_regular?: boolean;
    
    @field({ type: 'boolean' })
    public is_valid?: boolean;
}
