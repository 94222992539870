export default {
    TYPES: {
        PAYMENT: 'payment',
        DOCUMENT: 'doc',
        REPORT: 'report',
        FORM_DOCUMENT: 'formDocument',
    },
    ALTERNATE_ACCOUNT_NEEDED: {},
    PROBLEMATIC_EMAIL_HOSTS: {
        AOL: 'aol.(com|co.uk)',
        greber: 'greber.co.uk', // added after customer's emails started to get rejected: info@greber.co.uk
        Yahoo: '(ca.|qc.)?yahoo.(com|com.ar|com.tw|com.ph|com.sg|com.vn|com.hk|com.my|com.mx|com.au|com.br|com.tr|com.co|co.uk|co.id|co.th|co.in|co.il|co.jp|co.za|co.nz|es|se|ch|pl|pt|ro|ru|no|ae|nl|at|be|cz|dk|fi|fr|de|gr|hu|ie|it)',
        ymail: 'ymail.com',
        rocketmail: 'rocketmail.com',
    },
    NOT_PROBLEMATIC_EMAIL_HOSTS: {
        google: 'g(oogle)?mail.com',
        icloud: 'icloud.com',
    },
    IS_WARNING_NEEDED: /spam/i,
    BOUNCE: [{
        pattern: /unable to resolve mx/i,
        message: 'No mailserver found for domain $domain, maybe the e-mail address is incorrect. (In case the e-mail address ends with @btinternet.co.uk, please change it to @btinternet.com)',
        promptId: 'EmailBounce.domainNotFound',
    }, {
        pattern: /mailbox.*?full/i,
        message: 'The recipient\'s mailbox does exist, but it is full.',
        promptId: 'EmailBounce.mailboxFull',
    }, {
        pattern: /bounced address/i,
        message: 'The email address does not exists',
        promptId: 'EmailBounce.bouncedAddress',
    }, {
        // eslint-disable-next-line max-len
        pattern: /(error dialing remote address|Error getting LDAP|allowed to send mail from|AOL will not accept|not a valid RFC|Mail rejected|Hop count exceeded|Relay|Administrative prohibition|permanent failure|delivery error|Too many hops|authentication required|handle mail for|Unroute?able|Sender verify failed|Denied by policy|SPF policy|message refused|address rejected|has been rejected|allowed rcpthosts|cert for gateway|not hosted here)/i,
        message: 'Some error happened while sending your mail, which may be a transient error. If this error persists, please contact support.',
        promptId: 'EmailBounce.transientError',
    }, {
        pattern: /(due to user complaints|spam|content rejected|due to local policy|Message filtered)/i,
        message: 'Unfortunately your mail was identified as SPAM by the recipient\'s mail service.',
        promptId: 'EmailBounce.spam',
    }, {
        pattern: /isn't a group member/i,
        message: 'The recipient\'s mailbox is not configured to receive mails. Please contact the recipient and ask them to resolve or use an other address.',
        promptId: 'EmailBounce.groupMailBox',
    }, {
        pattern: /no.*MX/,
        message: 'There seems to be an issue at our end. Please contact support.',
        promptId: 'EmailBounce.ourEnd',
    }, {
        // eslint-disable-next-line max-len
        pattern: /(delivery error|Recipient address rejected|No such (user|adddress)|Unknown (recipient|(user|email) address)|email account.*not exist|unknown user|no mailbox.*available|user unknown|invalid (mailbox|recipient)|mailbox invalid|unknown local-part|address could not be found|addressee unknown)/i,
        message: 'The recipient\'s mailbox does not exist or it is disabled. Please check the e-mail address and contact the recipient.',
        promptId: 'EmailBounce.mailboxDisabled',
    }],
};
