import * as Bluebird from 'bluebird';

import JobItem from 'modules/complexData/jobItem';
import Job from 'modules/complexData/job';
import Customer from 'modules/complexData/customer';
import BUSINESS_CALCULATOR_CONSTANTS from 'constants/businessCalculator';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import JobItemGroupEntity from './entity';
import modelProperties from './modelProperties';
import JobItemEntity from '../jobItem/entity';
import JobEntity from '../job/entity';

const math = require('modules//utilities/math');

interface JobItemGroupAssociations extends AssociationConfig<any, any> {
    job: AssociationDefinitionSingle<JobEntity, Job>
    jobItem: AssociationDefinition<JobItemEntity, JobItem>
}

interface JobItemGroup extends AutoGeneratedFunctions<JobItemGroupAssociations, JobItemGroupEntity, ComplexData<JobItemGroupEntity>> {}

// eslint-disable-next-line no-redeclare
class JobItemGroup extends ComplexData<JobItemGroupEntity> {
    static Entity = JobItemGroupEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): JobItemGroupAssociations {
        return {
            job: {
                key: 'job',
                instance: Job,
                entity: JobEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().job_id,
                },
            },
            jobItem: {
                key: 'jobItem',
                instance: JobItem,
                entity: JobItemEntity,
                cascadeDelete: true,
                condition: {
                    jobitemgroup_id: this.Entity.getFieldSymbols().id,
                },
            },
        };
    }

    public async isAllowed(documentGroupType: number, targetCountryId: number): Promise<boolean> {
        const jobItems = await this.getAllJobItem();
        const sourceCountryId = await this.findCompanyCountryId();

        const jobItemResults = await Bluebird.map(jobItems, async jobItem => jobItem.isAllowed({
            sourceCountryId,
            targetCountryId,
            documentGroupType,
        }));
        return jobItemResults.every(jobItemIsAllowed => jobItemIsAllowed === true);
    }

    public async findCustomerCountryId(): Promise<number> {
        const customer = await this.findCustomer();
        return customer.findCountryId();
    }

    public async findCompanyCountryId(): Promise<number> {
        const customer = await this.findCustomer();
        const company = await (customer as any).getCompany();
        return company.findCountryId();
    }

    private async findCustomer(): Promise<Customer> {
        const job = await this.getJob();
        return job.getCustomer();
    }

    /**
     * Execute all calculations and update data
     */
    async calculate(options: Record<string, any> = {}): Promise<JobItemGroupEntity> {
        //
        // Recalculate all lines
        //
        await this.recalculateAllLines(options);
        //
        // Update discount
        //
        this.calculateDiscount();
        //
        // Update tax by discount
        //
        this.calculateDiscountTax();
        //
        // Calculate CIS
        //
        this.calculateCIS();
        //
        // Update the final total
        //
        this.updateTotals();

        this.emit(BUSINESS_CALCULATOR_CONSTANTS.EVENTS.GROUP_RECALCULATED, this.getData());

        return this.getData();
    }

    /**
     * Iterate on all lines, recalculate them and update the group totals.
     * NOTE: this function empties the calculated fields before calculation is started
     */
    async recalculateAllLines(options: Record<string, any> = {}): Promise<void> {
        this.resetCalculatedFields();
        (await this.getAllJobItem()).forEach(line => {
            this.recalculateLine(line, options);
        });
    }

    recalculateLine(jobItem: JobItem, options: Record<string, any> = {}) {
        const newData = jobItem.calculate({
            cloneData: false,
            cisEnabled: jobItem.data.is_cis,
            use_tax: this.data.vat_registered,
            is_inclusive: this.data.tax_inclusive,
        });
        this.updateGroupTotals(newData, options);
        return newData;
    }

    updateGroupTotals(lineData: JobItemEntity, options: Record<string, any> = {}) {
        //
        // Subtotal
        //
        if (!this.data.vat_registered || this.data.tax_inclusive) {
            this.data.subtotal += lineData.cost;
        } else {
            this.data.subtotal += lineData.exclusive_cost;
        }
        //
        // VAT
        //
        this.data.vat += lineData.vat;
        //
        // CIS - only if enabled and only if not in tax inclusive mode
        //
        if (options.cisEnabled
            && !(this.data.vat_registered
                && this.data.tax_inclusive)
            && lineData.category_is_cis) {
            this.data.cis_estimate += lineData.cis_value;
            if (!math.nearlyEqual(lineData.cis_value, 0, 0.00001)) {
                this.data.cisable_amount += lineData.exclusive_cost;
            }
        }
    }

    /**
     * Recalculate CIS if there is any discount. In that case CIS amount and
     * the CISable amount is pro-rated by the discount value
     */
    calculateCIS(): void {
        //
        // Pro-rate CIS value
        //
        const originalCIS = this.data.cis_estimate;
        if (this.data.cis_estimate > 0 && this.data.discount !== 0) {
            this.data.cis_estimate = this.data.subtotal === 0
                ? 0 : this.data.cis_estimate + ((this.data.discount / this.data.subtotal) * this.data.cis_estimate);
        }
        //
        // Pro-rate CISable amount
        //
        if (this.data.cis_estimate > 0 && this.data.cisable_amount > 0) {
            this.data.cisable_amount = (this.data.cisable_amount / originalCIS) * this.data.cis_estimate;
        }
    }

    /**
     * Calculate the discount amount
     *
     * @returns {number}
     */
    calculateDiscount(): number {
        let discount = 0;
        if (this.data.discount_type === 0) {
            discount = (this.data.discount_amount * this.data.subtotal) / 100;
        } else {
            discount = this.data.discount_amount || 0;
        }

        if (this.data.is_surcharge === 0) {
            discount *= -1;
        }
        this.data.discount = discount;
        return discount;
    }

    /**
     * Calculate VAT on the discount
     *
     * @returns {number}
     * @private
     */
    calculateDiscountTax(): number {
        let discountTax = 0;
        if (this.data.vat_registered) {
            if (this.data.tax_inclusive) {
                const groupTaxRate = math.calculateProRate(this.data.subtotal - this.data.vat, this.data.vat);
                discountTax = math.calculateIncludedTax(this.data.discount, groupTaxRate);
            } else {
                discountTax = this.data.subtotal === 0
                    ? 0 : this.data.vat * (this.data.discount / this.data.subtotal);
            }
        }
        // this.data.vatTotal = this.data.vat;
        this.data.vat += discountTax;
        return discountTax;
    }

    updateTotals(): void {
        if (!this.data.vat_registered
            || (this.data.vat_registered && this.data.tax_inclusive)) {
            this.data.total = this.data.subtotal + this.data.discount;
        } else {
            this.data.total = this.data.vat + this.data.subtotal + this.data.discount;
        }
    }

    resetCalculatedFields(): void {
        this.data.total = 0;
        this.data.discount = 0;
        this.data.subtotal = 0;
        this.data.vat = 0;
        this.data.cis_estimate = 0;
        this.data.cisable_amount = 0;
    }
}

export default JobItemGroup;
