import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import WhiteLabelMetricsEntity from './entity';
import modelProperties from './modelProperties';

interface WhiteLabelMetricsAssociations extends AssociationConfig<any, any> {}

interface WhiteLabelMetrics extends AutoGeneratedFunctions<WhiteLabelMetricsAssociations, WhiteLabelMetricsEntity, ComplexData<WhiteLabelMetricsEntity>> {}

// eslint-disable-next-line no-redeclare
class WhiteLabelMetrics extends ComplexData<WhiteLabelMetricsEntity> {
    static Entity = WhiteLabelMetricsEntity;

    static modelProperties = modelProperties;
}

export default WhiteLabelMetrics;
