import DOCUMENT from '@powerednow/shared/constants/document';
import PAYMENT from '@powerednow/shared/constants/payment';
import * as Bluebird from 'bluebird';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import PaymentTypeToCountry from '../paymentTypeToCountry';
import PaymentSetting from '../paymentSetting';
import PaymentTypeEntity from './entity';
import modelProperties from './modelProperties';
import PaymentTypeToCountryEntity from '../paymentTypeToCountry/entity';
import PaymentSettingEntity from '../paymentSetting/entity';

interface PaymentTypeAssociations extends AssociationConfig<any, any> {
    paymentTypeToCountry: AssociationDefinition<PaymentTypeToCountryEntity, PaymentTypeToCountry>
    paymentSetting: AssociationDefinitionSingle<PaymentSettingEntity, PaymentSetting>
}

interface PaymentType extends AutoGeneratedFunctions<PaymentTypeAssociations, PaymentTypeEntity, ComplexData<PaymentTypeEntity>> {}

// eslint-disable-next-line no-redeclare
class PaymentType extends ComplexData<PaymentTypeEntity> {
    static Entity = PaymentTypeEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): PaymentTypeAssociations {
        return {
            paymentTypeToCountry: {
                key: 'paymentTypeToCountry',
                instance: PaymentTypeToCountry,
                entity: PaymentTypeToCountryEntity,
                cascadeDelete: false,
                condition: {
                    paymenttype_id: this.Entity.getForeignFieldSymbols().id,
                },
            },
            paymentSetting: {
                key: 'paymentSetting',
                instance: PaymentSetting,
                entity: PaymentSettingEntity,
                single: true,
                condition: {
                    payment_id: this.Entity.getForeignFieldSymbols().id,
                },
            },
        };
    }

    public async getAvailableCountryIds(): Promise<number[]> {
        return Bluebird.map(this.getAllPaymentTypeToCountry(), (typeToCountry: PaymentTypeToCountry) => typeToCountry.data.country_id);
    }

    public async isValidForCountry(countryId: number): Promise<boolean> {
        const validCountries = await this.getAvailableCountryIds();
        return validCountries.includes(countryId);
    }

    public async enabledForDocumentType(documentTypeId: number): Promise<boolean> {
        const settings = await this.getPaymentSetting();
        const fieldMap = {
            [DOCUMENT.TYPES_VALUES.EXPENSE]: 'enabled_expense',
            [DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE]: 'enabled_supplier',
        };
        const settingField = fieldMap[documentTypeId] ? fieldMap[documentTypeId] : 'enabled';
        return (await this.availableForDocumentType(documentTypeId)) && settings.data[settingField];
    }

    public async availableForDocumentType(documentTypeId: number): Promise<boolean> {
        const fieldMap = {
            [DOCUMENT.TYPES_VALUES.EXPENSE]: 'is_expense',
            [DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE]: 'is_supplier',
        };
        const settingField = fieldMap[documentTypeId] ? fieldMap[documentTypeId] : 'is_sales';
        return this.data[settingField];
    }

    public static isSumUpPayment(paymentTypeId): boolean {
        return [PAYMENT.TYPES.SUMUP, PAYMENT.TYPES.SUMUP_ONLINE].includes(paymentTypeId);
    }

    public async isEnabledForDocumentPaymentCategory(documentPaymentCategory: number): Promise<boolean> {
        const paymentSetting = await this.getPaymentSetting();
        const isAllowed = this.data[PAYMENT.DOCUMENT_PAYMENT_CATEGORY_TO_ALLOWED_PROPERTY[documentPaymentCategory]];
        return isAllowed && paymentSetting.isEnabledForDocumentPaymentCategory(documentPaymentCategory);
    }
}

export default PaymentType;
