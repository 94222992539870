export default {
    TYPES: {
        GOOGLE_CALENDAR: 1,
        GOOGLE_LOGIN: 2,
        HMRC: 3,
        SUMUP: 4,
    },

    ENVIRONMENT: {
        TEST: 'test',
        LIVE: 'live',
    },

    ERRORS: {
        3: {
            INVALID_CREDENTIALS_PATH: 'data.payload.error',
            INVALID_CREDENTIALS_VALUE: 'invalid_grant',
        },
        4: {
            INVALID_CREDENTIALS_PATH: 'output.error',
            INVALID_CREDENTIALS_VALUE: 'invalid_grant',
        },
    },

    CONFIGURE_INSTRUCTIONS: {
        3: {
            promptId: 'AuthTokenExpired.hmrcInstructions',
            defaultValue: 'head into Tax & Accounting settings , and click the \'Connect to MTD\' button on the MTD settings tab.',
        },
        4: {
            promptId: 'AuthTokenExpired.sumupInstructions',
            defaultValue: 'head into Currency & Payment settings, and click the \'Configure\' button under the SumUp settings.',

        },
    },

};
