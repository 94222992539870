import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';
    
export default class SubscriptionStatsEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ primaryKey: true, type: 'int' })
    public company_id: number;
    
    @field({ type: 'string' })
    public company_name: string;
    
    @field({ type: 'string' })
    public country_name: string;
    
    @field({ type: 'string' })
    public user_email: string;
    
    @field({ type: 'string', codeName: 'userFirstName' })
    public user_firstname: string;
    
    @field({ type: 'string', codeName: 'userLastName' })
    public user_lastname: string;
    
    @field({ type: 'string' })
    public user_phone: string;
    
    @field({ type: 'string' })
    public registration_date: string;
    
    @field({ type: 'int' })
    public cohort_id: number;
    
    @field({ type: 'string' })
    public cohort_name: string;
    
    @field({ type: 'string' })
    public subscription_status: string;
    
    @field({ type: 'string', dbType: 'date' })
    public first_subscription_date: DateOrString;
    
    @field({ type: 'string', dbType: 'date' })
    public current_subscription_date: DateOrString;
    
    @field({ type: 'string', dbType: 'date' })
    public subscription_expiry_date: DateOrString;
    
    @field({ type: 'int' })
    public subscription_length: number;
    
    @field({ type: 'int' })
    public subscription_amount: number;
    
    @field({ type: 'int' })
    public tax_amount: number;
    
    @field({ type: 'int' })
    public subscription_renewal_count: number;
    
    @field({ type: 'int' })
    public subscription_method: number;
    
    @field({ type: 'string' })
    public device_registration: string;
    
    @field({ type: 'string' })
    public device_subscription: string;
    
    @field({ type: 'string' })
    public devices_lifetime: string;
    
    @field({ type: 'string' })
    public attributionType: string;
    
    @field({ type: 'string' })
    public mediaSource: string;
    
    @field({ type: 'string' })
    public campaignName: string;
    
    @field({ type: 'string' })
    public adSetName: string;
    
    @field({ type: 'string' })
    public subParam1: string;
    
    @field({ type: 'string' })
    public subParam2: string;
    
    @field({ type: 'string' })
    public subParam3: string;
    
    @field({ type: 'string' })
    public subParam4: string;
    
    @field({ type: 'string' })
    public subParam5: string;
    
    @field({ type: 'int' })
    public teamSize: number;
    
    @field({ type: 'string', serverOnly: true })
    public device_os_name: string;
}
