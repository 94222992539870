const modelProperties = {
    modelName: 'PaymentTypeToCountry',
    tableName: 'PaymentTypeToCountry',
    associations: [{
        type: 'belongsTo',
        model: 'Country',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'country_id', fieldName: 'country_id' } },
    }, {
        type: 'belongsTo',
        model: 'PaymentType',
        cascadeDelete: false,
        parameters: { as: 'paymentType', foreignKey: { name: 'paymenttype_id', fieldName: 'paymenttype_id' } },
    }],
    global: true,
};

export default modelProperties;
