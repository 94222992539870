import ContactMethod from 'modules/complexData/contactMethod';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import Contact from '../contact';
import Address from '../address';
import Job from '../job';
import ContactToAddressEntity from './entity';
import modelProperties from './modelProperties';
import ContactEntity from '../contact/entity';
import AddressEntity from '../address/entity';
import JobEntity from '../job/entity';
import Customer from '../customer';
import CustomerEntity from '../customer/entity';
import { ChannelTypes } from '../../../constants/customerEmailTemplateValues';
import Action from '../action';
import ActionEntity from '../action/entity';
import Entity from '../entity';

interface ContactToAddressAssociations extends AssociationConfig<any, any> {
    customer: AssociationDefinitionSingle<CustomerEntity, Customer>
    contact: AssociationDefinitionSingle<ContactEntity, Contact>
    address: AssociationDefinitionSingle<AddressEntity, Address>
    job: AssociationDefinition<JobEntity, Job>
    action: AssociationDefinition<ActionEntity, Action>
}

interface ContactToAddress extends AutoGeneratedFunctions<ContactToAddressAssociations, ContactToAddressEntity, ComplexData<ContactToAddressEntity>> {}

// eslint-disable-next-line no-redeclare
class ContactToAddress extends ComplexData<ContactToAddressEntity> {
    static Entity = ContactToAddressEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): ContactToAddressAssociations {
        return {
            contact: {
                key: 'contact',
                instance: Contact,
                entity: ContactEntity,
                single: true,
                cascadeDelete: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().contact_id,
                    customer_id: this.Entity.getFieldSymbols().customer_id,
                },
            },
            address: {
                key: 'address',
                instance: Address,
                entity: AddressEntity,
                single: true,
                cascadeDelete: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().address_id,
                    customer_id: this.Entity.getFieldSymbols().customer_id,
                },
            },
            job: {
                key: 'job',
                instance: Job,
                entity: JobEntity,
                cascadeDelete: false,
                condition: {
                    site_id: this.Entity.getForeignFieldSymbols().id,
                    customer_id: this.Entity.getFieldSymbols().customer_id,
                },
            },
            action: {
                key: 'action',
                instance: Action,
                entity: ActionEntity,
                cascadeDelete: false,
                condition: {
                    site_id: this.Entity.getForeignFieldSymbols().id,
                },
            },

            customer: {
                key: 'customer',
                instance: Customer,
                entity: CustomerEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getFieldSymbols().customer_id,
                },
            },
        };
    }

    public async getEmailableContactMethods(): Promise<ContactMethod[]> {
        const contact = await this.getContact();
        return contact.findContactMethodsBy(async contactMethod => contactMethod.canEmail());
    }

    protected async initDefaultAssociatedItems(): Promise<void> {
        await this.setContact({ customer_id: this.data.customer_id });
        await this.setAddress({ customer_id: this.data.customer_id });
    }

    public async getSearchableFields(): Promise<string[]> {
        const searchableFields = [];    
        const contact = await this.getContact();
        if (contact) {
            searchableFields.push(...(await contact.getSearchableFields()));
        }
        const address = await this.getAddress();
        if (address) {
            searchableFields.push(...(await address.getSearchableFields()));
        }

        return searchableFields;
    }

    public async copyData(item: ContactToAddress) {
        const {
            id, customer_id, contact_id, address_id, type, ...itemData
        } = item.data.getDataValues();

        const contact = await this.getContact();
        if (contact) {
            await contact.copyData(await item.getContact());
        }

        const address = await this.getAddress();
        if (address) {
            await address.copyData(await item.getAddress());
        }

        Object.assign(this.data, itemData);

        return this;
    }

    async prepareSearchField() {
        this.data.suppressEvents();
        this.data[Entity.searchFieldSymbol as unknown as string] = (await this.getSearchableFields()).join(' ').toLowerCase();
        this.data.unSuppressEvents();
    }

    public async isContactable(channels: ChannelTypes[]): Promise<boolean> {
        const contact = await this.getContact();
        return contact.isContactable(channels);
    }
}

export default ContactToAddress;
