import * as Bluebird from 'bluebird';

const memoizeTimeout = Symbol('memoizeTimeout');
const memoizeByInstance = Symbol('memoizeByInstance');
const boundMapSymbol = Symbol('boundMap');
const memoizeStorage = Symbol('memoizeStorage');
const retryTimeoutSymbol = Symbol('retryTimeout');

export function bindWithArgs(...args) {
    return function (target, propertyKey, descriptor) {
        return {
            get() {
                // We are accessing on prototype, we don't want to bind
                // Interesting case when adding spy with jest on prototype :/
                if (!this || this === target) {
                    return descriptor.value.bind(target, ...args);
                }
                this[boundMapSymbol] = this[boundMapSymbol] || new WeakMap();
                const boundMap = this[boundMapSymbol];
                if (!boundMap.has(descriptor.value)) {
                    boundMap.set(descriptor.value, descriptor.value.bind(this, ...args));
                }
                return boundMap.get(descriptor.value);
            },
        };
    };
}

export function bind(target, propertyKey, descriptor) {
    return bindWithArgs().call(this, target, propertyKey, descriptor);
}

export function bindExtScope() {
    return function (target, propertyKey, descriptor) {
        const original = descriptor.value;
        descriptor.value = function (...args) {
            /**
             * These lines make it possible to decorate even private functions.
             * This is a special decorator only to allow other decorators to be applied to private and special ext methods, but separated from
             * as a common decorator, can be combined with any other decorator function.
             * It must be the last decorator added.
             */
            original.$previous = descriptor.value.$previous;
            original.$owner = descriptor.value.$owner;
            original.$privacy = descriptor.value.$privacy;
            return original.apply(this, args);
        };
    };
}

export function debounceAfterCalls(callCountLimit, timeout, keyGenFn = (...args) => args.join('-')) {
    const timeOutRefMap = new Map();
    return function (target, propertyKey, descriptor) {
        let callCount = 0;
        const original = descriptor.value;
        descriptor.value = function (...args) {
            const originalScope = this;
            const cacheKey = keyGenFn.call(originalScope, ...args);
            let timeoutRef = timeOutRefMap.get(cacheKey);
            if (timeoutRef) {
                clearTimeout(timeoutRef);
            }
            if (callCount >= callCountLimit) {
                timeoutRef = setTimeout(() => {
                    // call original function
                    original.apply(this, args);
                    callCount = 0;
                }, timeout);
                timeOutRefMap.set(cacheKey, timeoutRef);
            } else {
                callCount += 1;
                original.apply(this, args);
            }
        };
        // return descriptor with new value
        return descriptor;
    };
}

export function debounce(...args) {
    return debounceAfterCalls.call(this, [0, ...args]);
}

export function memoize(keyGenFn = (...args) => args.join('-')) {
    return function (target, key, descriptor) {
        const originalFn = descriptor.value;
        const cachedValues = new Map();
        descriptor.value = function (...args) {
            const originalScope = this;
            const cacheKey = keyGenFn.call(originalScope, ...args);
            let returnValue = cachedValues.get(cacheKey);
            if (!returnValue) {
                returnValue = originalFn.call(originalScope, ...args);
                cachedValues.set(cacheKey, returnValue);
                if (descriptor[memoizeTimeout]) {
                    setTimeout(() => cachedValues.delete(cacheKey), descriptor[memoizeTimeout]);
                }
                return returnValue;
            }
            return returnValue;
        };
        return descriptor;
    };
}

export function setMemoizeByInstance() {
    return function (target, key, descriptor) {
        descriptor[memoizeByInstance] = true;
        return descriptor;
    };
}

export function setMemoizePruneTimeout(pruneTimeout: number = null) {
    return function (target, key, descriptor) {
        descriptor[memoizeTimeout] = pruneTimeout;
        return descriptor;
    };
}

export function memoizePromise() {
    return function (target, key, descriptor) {
        const originalFn = descriptor.value;
        descriptor.value = async function (...args) {
            const originalScope = this;
            if (Object.prototype.hasOwnProperty.call(originalScope, memoizeStorage)) {
                return originalScope[memoizeStorage];
            }

            originalScope[memoizeStorage] = await originalFn.call(originalScope, ...args);
            if (descriptor[memoizeTimeout]) {
                setTimeout(() => {
                    delete originalScope[memoizeStorage];
                }, descriptor[memoizeTimeout]);
            }
            return originalScope[memoizeStorage];
        };
        return descriptor;
    };
}

export function memoizeFiltered(filterFn = v => Boolean(v)) {
    return function (target, key, descriptor) {
        const originalFn = descriptor.value;
        const cachedValues = new Map();
        descriptor.value = function (...args) {
            const originalScope = this;
            const cacheKey = args.join('-');
            let returnValue = cachedValues.get(cacheKey);
            if (!returnValue) {
                returnValue = originalFn.call(originalScope, ...args);
                if (filterFn(returnValue)) {
                    cachedValues.set(cacheKey, returnValue);
                }
                return returnValue;
            }
            return returnValue;
        };
        return descriptor;
    };
}

export function memoizeRunningPromise(target, key, descriptor) {
    const originalFn = descriptor.value;
    const runningPromises = new Map();
    descriptor.value = async function (...args) {
        const originalScope = this;
        const promiseKey = args.join('-');
        const runningPromise = runningPromises.get(promiseKey);
        if (!runningPromise) {
            const newPromise = originalFn.call(originalScope, ...args)
                //  .finally polyfill
                .then(value => {
                    runningPromises.delete(promiseKey);
                    return value;
                })
                .catch(error => {
                    runningPromises.delete(promiseKey);
                    throw error;
                });
            runningPromises.set(promiseKey, newPromise);
            return newPromise;
        }
        return runningPromise;
    };
    return descriptor;
}

export function memoizeRunningPromiseByKey(keyGenFn = (...args) => args.join('-')) {
    return function (target, key, descriptor) {
        const originalFn = descriptor.value;
        if (!descriptor[memoizeByInstance]) {
            descriptor.runningPromises = new Map();
        }
        descriptor.value = function (...args) {
            const originalScope = this;
            let { runningPromises } = descriptor;
            if (descriptor[memoizeByInstance]) {
                if (!originalScope.runningPromises) {
                    originalScope.runningPromises = new Map();
                }
                runningPromises = originalScope.runningPromises;
            }
            const promiseKey = keyGenFn(...args);
            const runningPromise = runningPromises.get(promiseKey);
            if (!runningPromise) {
                const returnedValue = originalFn.call(originalScope, ...args);
                if (!returnedValue.then) {
                    return returnedValue;
                }
                // .finally polyfill
                // wrapping promise to fix error catching issue caused by babel
                const newPromise = new Promise((resolve, reject) => {
                    returnedValue.then(value => {
                        runningPromises.delete(promiseKey);
                        resolve(value);
                    }).catch(error => {
                        runningPromises.delete(promiseKey);
                        reject(error);
                    });
                });

                runningPromises.set(promiseKey, newPromise);
                return newPromise;
            }
            return runningPromise;
        };
        return descriptor;
    };
}

export function guardCall(guardFn: (error: any) => (never | Promise<never>) = error => {
    // I want to explicitly handle errors using this decorator, adding a simple guardCall, without handler,
    // is pretty much undefined behaviour, so it simply rethrows the error instead of silently ignore it.
    throw error;
}, finallyFn = () => {
}) {
    return function (target, key, descriptor) {
        const originalFn = descriptor.value;
        descriptor.value = async function (...args) {
            const originalScope = this;
            try {
                return await originalFn.call(this, ...args);
            } catch (error) {
                return guardFn.call(originalScope, error);
            } finally {
                finallyFn.call(this, ...args);
            }
        };
        return descriptor;
    };
}

export function retryTimeout(retryTimeoutValue: number | ((attempt: number) => number)) {
    return function (target, key, descriptor) {
        let retryTimeoutFunction = retryTimeoutValue;
        if (typeof retryTimeoutValue !== 'function') {
            retryTimeoutFunction = attempt => retryTimeoutValue;
        }
        descriptor[retryTimeoutSymbol] = retryTimeoutFunction;
        return descriptor;
    };
}

export function retry(maximumRetry = 5, isRetryable = error => true) {
    return function (target, key, descriptor) {
        const originalFn = descriptor.value;
        descriptor.value = async function (...args) {
            const originalScope = this;
            let retryAttempt = 0;
            while (retryAttempt <= maximumRetry) {
                try {
                    // eslint-disable-next-line no-await-in-loop
                    const resolvedValue = await originalFn.call(this, ...args);
                    retryAttempt = 0;
                    return resolvedValue;
                } catch (error) {
                    retryAttempt += 1;
                    if (retryAttempt >= maximumRetry || !isRetryable.call(originalScope, error)) {
                        retryAttempt = 0;
                        throw error;
                    }
                    const retryTimeoutFn = descriptor[retryTimeoutSymbol];

                    if (retryTimeoutFn) {
                        // eslint-disable-next-line no-await-in-loop
                        await Bluebird.delay(retryTimeoutFn(retryAttempt));
                    }
                }
            }
            throw new Error('Must not reach this');
        };
        return descriptor;
    };
}
