import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import Document from '../document';
import Payment from '../payment';
import PaymentToInvoiceEntity from './entity';
import modelProperties from './modelProperties';
import DocumentEntity from '../document/entity';
import PaymentEntity from '../payment/entity';

interface PaymentToInvoiceAssociations extends AssociationConfig<any, any> {
    document: AssociationDefinitionSingle<DocumentEntity, Document>
    payment: AssociationDefinitionSingle<PaymentEntity, Payment>
}

interface PaymentToInvoice extends AutoGeneratedFunctions<PaymentToInvoiceAssociations, PaymentToInvoiceEntity, ComplexData<PaymentToInvoiceEntity>> {}

// eslint-disable-next-line no-redeclare
class PaymentToInvoice extends ComplexData<PaymentToInvoiceEntity> {
    static Entity = PaymentToInvoiceEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): PaymentToInvoiceAssociations {
        return {
            document: {
                key: 'document',
                instance: Document,
                entity: DocumentEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().document_id,
                },
            },
            payment: {
                key: 'payment',
                instance: Payment,
                entity: PaymentEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().payment_id,
                },
            },
        };
    }
}

export default PaymentToInvoice;
