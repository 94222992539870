const modelProperties = {
    modelName: 'AppointmentType',
    tableName: 'AppointmentType',
    minAppVersion: '9.0.0',
    associations: [
        {
            type: 'hasMany',
            model: 'BookingDateException',
            parameters: { as: 'BookingDateException', foreignKey: { name: 'appointmentTypeId', fieldName: 'appointmentTypeId' } },
        },
        {
            type: 'belongsTo',
            model: 'Company',
            parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
        },
    ],
    hooks: [],
    validations: {},
};

export default modelProperties;
