const structure = {
    baseModel: 'Customer',
    fields: [{
        name: 'company',
        field: 'company',
        label: 'Company name',
        isChecked: true,
    }, {
        name: 'isCustomer',
        field: 'isCustomer',
        defaultValue: '1',
    }, {
        name: 'notes',
        field: 'notes',
        label: 'Notes',
    }, {
        name: 'firstName',
        field: 'firstName',
        modelName: 'Contact',
        modelAsAssociated: 'Contact',
        label: 'First name',
        isChecked: true,
        defaultValue: '',
    }, {
        name: 'lastName',
        field: 'lastName',
        modelName: 'Contact',
        modelAsAssociated: 'Contact',
        label: 'Last name',
        isChecked: true,
    }, {
        name: 'addressline1',
        field: 'address1',
        modelName: 'Address',
        modelAsAssociated: 'Addresses',
        label: 'Address line 1',
    }, {
        name: 'addressline2',
        field: 'address2',
        modelName: 'Address',
        modelAsAssociated: 'Addresses',
        label: 'Address line 2',
    }, {
        name: 'city',
        field: 'city',
        modelName: 'Address',
        modelAsAssociated: 'Addresses',
        label: 'City',
        limitToOneValue: true,
    }, {
        name: 'county',
        field: 'county',
        modelName: 'Address',
        modelAsAssociated: 'Addresses',
        label: 'County',
        limitToOneValue: true,
    }, {
        name: 'country',
        field: 'country',
        modelName: 'Address',
        modelAsAssociated: 'Addresses',
        label: 'Country',
        defaultValue: 'GB',
        limitToOneValue: true,
    }, {
        name: 'zipcode',
        field: 'postcode',
        modelName: 'Address',
        modelAsAssociated: 'Addresses',
        label: 'Zip code',
        limitToOneValue: true,
    }, {
        name: 'phone',
        field: 'value',
        modelName: 'Contact.ContactMethod',
        modelAsAssociated: 'Contact.Phone',
        limitToOneValue: true,
        where: {
            contactmethodtype_id: 1,
        },
        label: 'Phone',
    }, {
        name: 'email',
        field: 'value',
        modelName: 'Contact.ContactMethod',
        modelAsAssociated: 'Contact.Email',
        limitToOneValue: true,
        where: {
            contactmethodtype_id: 2,
        },
        label: 'Email',
    }, {
        name: 'reference',
        field: 'reference',
        label: 'External reference',
    }, {
        name: 'groupId',
        field: 'groupId',
        label: 'Group Id',
    }],
    callAfterUpdate: ['Address'],
};

module.exports = structure;
