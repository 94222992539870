import Entity, { DateOrString, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class SignatureEntity extends Entity {
    @field({ type: 'int', primaryKey: true })
    public id?: number;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'int', allowNull: true })
    public job_id?: number;

    @field({ type: 'int', allowNull: true })
    public action_id?: number;

    @field({ type: 'int', allowNull: true })
    public user_id?: number;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;
    
    @field({ type: 'string', defaultValue: '' })
    public description?: string;
    
    @field({ type: 'string' })
    public url: string;
    
    @field({ defaultValue: 'not_uploaded', type: 'string' })
    public status: string;
    
    @field({
        type: 'boolean', codeName: 'isDeleted', serverOnly: true, defaultValue: false, 
    })
    public isdeleted?: boolean;
    
    @field({
        type: 'boolean', codeName: 'isArchived', serverOnly: true, defaultValue: false,
    })
    public is_archived?: boolean;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_pinned?: boolean;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_marked?: boolean;
}
