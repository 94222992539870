export default {
    CUSTOM_ATTRIBUTES: {
        SET_TEMPLATE: 'set_template',
        EXTRA_USERS_ADDED: 'extra_user_added',
        INVOICE_CREATED: 'invoice_created',
        BUSINESS_TYPE: 'business_type',
        PARTNER: 'partner',
        IS_PN_TEST: 'is_pn_test',
        IS_PN_TEST_COMPANY: 'is_pn_test_company',
    },
};
