import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import ArchiveBatchEntity from './entity';
import modelProperties from './modelProperties';

interface ArchiveBatchAssociations extends AssociationConfig<any, any> {}

interface ArchiveBatch extends AutoGeneratedFunctions<ArchiveBatchAssociations, ArchiveBatchEntity, ComplexData<ArchiveBatchEntity>> {}

// eslint-disable-next-line no-redeclare
class ArchiveBatch extends ComplexData<ArchiveBatchEntity> {
    static Entity = ArchiveBatchEntity;

    static modelProperties = modelProperties;
}

export default ArchiveBatch;
