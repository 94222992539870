import moment from '@powerednow/shared/modules/momentOverride';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import PurchaseSetup from '../purchaseSetup';
import PurchasesEntity from './entity';
import modelProperties from './modelProperties';
import PurchaseSetupEntity from '../purchaseSetup/entity';

const CONSTANTS = require('@powerednow/shared/constants').default;

interface PurchasesAssociations extends AssociationConfig<any, any> {
    purchaseSetupAndroid: AssociationDefinitionSingle<PurchaseSetupEntity, PurchaseSetup>
    purchaseSetupIOS: AssociationDefinitionSingle<PurchaseSetupEntity, PurchaseSetup>
}

interface Purchases extends AutoGeneratedFunctions<PurchasesAssociations, PurchasesEntity, ComplexData<PurchasesEntity>> {}

// eslint-disable-next-line no-redeclare
class Purchases extends ComplexData<PurchasesEntity> {
    static Entity = PurchasesEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): PurchasesAssociations {
        return {
            purchaseSetupAndroid: {
                key: 'purchaseSetup',
                instance: PurchaseSetup,
                entity: PurchaseSetupEntity,
                single: true,
                condition: {
                    android_product_id: this.Entity.getFieldSymbols().product,
                },
            },
            purchaseSetupIOS: {
                key: 'purchaseSetup',
                instance: PurchaseSetup,
                entity: PurchaseSetupEntity,
                single: true,
                condition: {
                    ios_product_id: this.Entity.getFieldSymbols().product,
                },
            },
        };
    }

    async getPurchaseSetup() {
        return (await this.getPurchaseSetupIOS()) || this.getPurchaseSetupAndroid();
    }

    async findPurchaseSetup() {
        const purchaseSetup = await this.getPurchaseSetup();
        if (!purchaseSetup) {
            const error = new Error(`No purchaseSetup for product: ${this.data.product}`);
            throw Object.assign(error, {
                severity: 8,
            });
        }
        return purchaseSetup;
    }

    /**
     * Returns the number of days between billing cycles
     *
     * @returns {number}
     */
    async getRenewCycle() {
        return (await this.findPurchaseSetup()).data.days_before_expires;
    }

    /**
     * tells if purchase is currently active with dunning period
     * @returns {boolean}
     */
    isActiveWithDunningPeriod() {
        const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        return this.data.expires_date >= Purchases.getOverTimedDate() && this.data.purchase_date <= now;
    }

    /**
     * tells if purchase is currently active
     * @returns {boolean}
     */
    isActive() {
        const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        return now <= this.data.expires_date;
    }

    static getOverTimedDate() {
        const now = new Date();
        return moment(now).subtract(CONSTANTS.SUBSCRIPTION.OVERTIME, 'days').format('YYYY-MM-DD HH:mm:ss');
    }
}

export default Purchases;
export type PurchasesType = Purchases;
