import * as objectUtils from 'modules/utilities/object';
import { EventEmitter } from 'events';
import type { ComplexDataType, AssociationConfig } from './complexData';
import { DataObject } from './entity';
import AssociationsStorage from './associationsStorage';

const debugCache = require('modules/debug')('cache');

const _ = require('lodash');

declare type CacheItemKey = number | symbol;
declare type CacheMap = Map<string, Map<CacheItemKey, ComplexDataType<any>>>;

declare type NameToComplexDataArray = {
    [propName: string]: ComplexDataType<any>[],
};
declare type AssociationNameToComplexArray = {
    [associatedItemName: string]: NameToComplexDataArray,
};
declare type TypeToComplexArray = {
    [associatedItemType: string]: NameToComplexDataArray,
};
declare type ConditionValueToTypeToComplexArray = {
    [conditionValue: string]: TypeToComplexArray,
};
declare type ConditionKeyToValue = {
    [conditionKey: string]: ConditionValueToTypeToComplexArray,
};
declare type AssociationMap = {
    [modelName: string]: ConditionKeyToValue,
};

const noConditionKey = Symbol('noConditionKey');
const noConditionValue = Symbol('noConditionValue');

export default class Cache extends EventEmitter {
    declare ['constructor']: typeof Cache;

    private cachedItems: CacheMap = new Map();

    private associationMap: AssociationMap = {};

    public reverseAssociations = new AssociationsStorage();

    static getIgnoredAssociationConditionKeys(association: AssociationConfig<any, any>): string[] {
        return [];
    }

    static get noCondition() {
        return {
            key: noConditionKey,
            value: noConditionValue,
        };
    }

    public static isCacheable(association): boolean {
        return Boolean(association.instance);
    }

    /**
     * When a new association is loaded for any complex data object then we get
     * notification here and store the association in a map. This map will later
     * be used to update already loaded associations when a new item added externally
     * which matches the conditions of the association.
     *
     * @param {ComplexData} item
     * @param association
     * @param {string} associationName
     */
    public updateAssociationMap(item: ComplexDataType<any>, association, associationName: string): void {
        if (!this.constructor.isCacheable(association)) {
            return;
        }
        this.associationMap[association.instance.getModelName()] = this.associationMap[association.instance.getModelName()] || {};
        const map = this.associationMap[association.instance.getModelName()];
        const conditionKeyValues = this.getConditionValuesForAssociation(item, associationName, association);

        if (conditionKeyValues.length === 0) {
            this.updateAssociationMapKeyValue(map, Cache.noCondition.key, Cache.noCondition.value, associationName, item);
        } else {
            conditionKeyValues.forEach(([key, value]) => {
                this.updateAssociationMapKeyValue(map, key, value, associationName, item);
            });
        }
    }

    private updateAssociationMapKeyValue(map, key, value, associationName: string, associatedItem: ComplexDataType<any>) {
        const modelName = associatedItem.constructor.getModelName();
        map[key] = map[key] || {};
        map[key][value] = map[key][value] || {};
        map[key][value][associationName] = map[key][value][associationName] || {};
        map[key][value][associationName][modelName] = map[key][value][associationName][associatedItem.constructor.getModelName()] || [];
        map[key][value][associationName][modelName].push(associatedItem);
    }

    /**
     * Utility function to create intersection of two arrays containing
     * complex data objects.
     *
     * @param {ComplexData[]} array1
     * @param {ComplexData[]} array2
     * @returns {ComplexData[]}
     */
    private intersect(array1: ComplexDataType<any>[], array2: ComplexDataType<any>[]): ComplexDataType<any>[] {
        return _.intersection(array1, array2);
    }

    /**
     * Update the cached associations when a new item added externally.
     *
     * @param {string} modelName
     * @param {DataObject} data
     */
    public updateAssociation(modelName: string, data: DataObject): void {
        const possibleAssociations = this.getPossibleAssociations(modelName, data);
        Object.entries(possibleAssociations).forEach(([associationName, items]) => {
            items.forEach(item => {
                item.addAssociationIfMatchesConditions(associationName, data);
            });
        });
    }

    /**
     * Remove a removed cached item from all cached associations
     *
     * @param {string} modelName
     * @param {ComplexData} item
     */
    private removeAssociation(modelName: string, item: ComplexDataType<any>): ComplexDataType<any>[] {
        return [...this.reverseAssociations.get(modelName, item.data.id), ...item.getAssociatedItemSet(false)]
            .map(associatedItem => {
                item.removeFromReverseAssociations(associatedItem);
                associatedItem.removeFromReverseAssociations(item);
                this.reverseAssociations.delete(item, associatedItem.constructor.getModelName(), associatedItem.data.id);
                return associatedItem;
            });
    }

    private removeFromDeletedAssociations(processedAssociatedItems: ComplexDataType<any>[], item: ComplexDataType<any>): void {
        processedAssociatedItems.forEach(associatedItem => associatedItem.removeDeletedAssociation(item.constructor as any, item.data.id));
    }

    private getConditionValuesForAssociation(item: ComplexDataType<any>, associationName: string, association) {
        const condition = item.generateConditions(associationName, (this.constructor as any).getIgnoredAssociationConditionKeys(association));
        return Object.entries(condition);
    }

    private updateAssociatedValueIdsAsKeys(item: ComplexDataType<any>, oldId: number | symbol) {
        //
        // We can not easily look up association conditions on this ay. E.g. the association map
        // can be something like:
        // FormDocument:
        //  -> customer_id:
        //      -> c1:
        //      -> c2:
        //  -> job_id:
        //      -> j1:
        //      -> j2:
        //
        // In the above structure if both customer_id and job_id has association maps
        // for the same value (e.g. we have both job and customer using id=1) then the
        // code below would update both what is certainly not the expected behaviour.
        // As I did not find an easy way to find out the paths where the id must be updated
        // therefore added the condition below.Although it is very unlikely we are updating
        // non symbol ID here I thought it is better to be protected against it.
        //
        if (typeof oldId !== 'symbol') {
            return;
        }
        this.walkOnItemsAssociations(item, (associationName, association) => {
            const map = this.associationMap[association.instance.getModelName()];
            const conditionKeyValues = this.getConditionValuesForAssociation(item, associationName, association);
            if (conditionKeyValues.length !== 0) {
                conditionKeyValues.forEach(([key, value]) => {
                    // @ts-ignore
                    if (map[key][oldId] && map[key][oldId][associationName]) {
                        objectUtils.renameProperty(map[key], oldId, item.data.id);
                    }
                });
            }
        });
    }

    private updateIdKeysInAssociationValueItems(modelName: string, data: DataObject, oldId): void {
        const possibleAssociations = this.getPossibleAssociations(modelName, data);
        Object.entries(possibleAssociations).forEach(([associationName, items]) => {
            items.forEach(item => {
                item.updateIdKeyInAssociatedValueItems(oldId, associationName, data);
            });
        });
    }

    private getPossibleAssociations(modelName: string, data: DataObject): NameToComplexDataArray {
        return Object.entries(this.getPossibleAssociationsByType(modelName, data))
            .reduce((map, [currentAssociation, itemsByType]) => {
                Object.values(itemsByType).forEach(itemValue => {
                    map[currentAssociation] = _.union(map[currentAssociation] || [], itemValue);
                });
                return map;
            }, {});
    }

    private getPossibleAssociationsByType(modelName: string, data: DataObject): TypeToComplexArray {
        if (!this.associationMap[modelName]) {
            return {};
        }
        return [
            ...Object.keys(this.associationMap[modelName]),
            ...Object.getOwnPropertySymbols(this.associationMap[modelName]),
        ]
            .reduce((map, conditionKey) => {
                const { noCondition } = this.constructor as any;
                // @ts-ignore
                const associationMap = this.associationMap[modelName][conditionKey];
                // @ts-ignore
                const conditionKeyList = conditionKey === noCondition.key ? associationMap[noCondition.value] : associationMap[data[conditionKey]];
                return !conditionKeyList ? map : this.getItemsForConditionValues(conditionKeyList, map);
            }, {});
    }

    private getItemsForConditionValues(conditionValues: TypeToComplexArray, map: AssociationNameToComplexArray): AssociationNameToComplexArray {
        Object.entries(conditionValues).forEach(([associationName, itemTypes]) => {
            Object.entries(itemTypes).forEach(([itemtype, itemList]) => {
                if (!map[associationName]) {
                    map[associationName] = {
                        [itemtype]: itemList,
                    };
                } else if (!map[associationName][itemtype]) {
                    map[associationName][itemtype] = itemList;
                } else {
                    map[associationName][itemtype] = this.intersect(map[associationName][itemtype], itemList);
                }
            });
        });
        return map;
    }

    /**
     * Update the cache when data is externally added
     * @param {string} modelName
     * @param {DataObject} data
     */
    public updateOnAdd(modelName: string, data: DataObject): void {
        const cachedItem = this.cachedItems.get(modelName).get(data.id);
        if (cachedItem) {
            /**
             * There were updateId call in previous version, but I can not imagine any scenario when we want to update
             * the id of a cached item when we add a record to store. As this handler is called after we added a record
             * to store, the id of that record will be always good.
             */
            this.updateData(modelName, data);
            if (cachedItem && cachedItem.data.isMuted()) {
                return;
            }
        }
        this.updateAssociation(modelName, data);
    }

    /**
     * Update cache on when data is externally removed
     *
     * @param {string} modelName
     * @param {DataObject} data
     */
    public async updateOnRemove(modelName: string, data: DataObject): Promise<void> {
        const cachedItem = this.cachedItems.get(modelName).get(data.id);
        if (!cachedItem) {
            return;
        }
        const processedAssociatedItems = this.removeAssociation(modelName, cachedItem);
        await cachedItem.delete();
        this.removeFromDeletedAssociations(processedAssociatedItems, cachedItem);
    }

    /**
     * Update the id of a cached item
     *
     * @param item
     */
    public updateId(item: ComplexDataType<any>, newDataValues?: DataObject): void {
        const modelName = item.constructor.getModelName();
        const oldId = item.getInitialId();

        if (oldId === null) {
            // @TODO _remove_ this when PN-8634 finished.
            if (modelName === 'Action') {
                /**
                 * Our app has a valid use case where action got into the cache before we add it to a store.
                 * In that case we just want to update data, as id is correct everywhere.
                 */
                this.updateData(modelName, newDataValues);
                return;
            }
            const dataValues = item.data.getPureDataValues();
            if (_.isEqual(newDataValues, dataValues)) {
                return;
            }

            throw Object.assign(new Error('Cache error: no initial id'), {
                details: {
                    modelName,
                    dataValues,
                    newDataValues,
                },
            });
        }
        if (this.cachedItems.get(modelName).get(oldId)) {
            if (this.cachedItems.get(modelName).get(oldId) !== item) {
                throw Object.assign(new Error('Cache error: item mismatch'), {
                    details: {
                        dataValues: item.data.getDataValues(),
                        cachedItemDataValues: this.cachedItems.get(modelName).get(oldId).data.getDataValues(),
                    },
                });
            }
            debugCache('cache update data:', modelName, oldId, item.data.id);
            this.cachedItems.get(modelName).set(item.data.id, item);
            this.cachedItems.get(modelName).set(oldId, null);
        }

        this.reverseAssociations.updateId(modelName, oldId, item.data.id);

        this.updateAssociatedValueIdsAsKeys(item, oldId);
        this.updateIdKeysInAssociationValueItems(modelName, item.data.getDataValues(), oldId);
    }

    /**
     * Update data values of a cached item
     *
     * @param {string} modelName
     * @param {DataObject} data
     */
    public updateData(modelName: string, data: DataObject): void {
        if (!this.cachedItems.get(modelName) || !this.cachedItems.get(modelName).get(data.id)) {
            //
            // Nothing to update
            //
            return;
        }
        const cachedData = this.cachedItems.get(modelName).get(data.id).data;
        //
        // The data is muted during the save operation. We do not want to
        // update the data in that case
        //
        if (!cachedData.isMuted()) {
            Object.assign(cachedData, _.cloneDeep(data));
        }
    }

    /**
     * Update (replace) a cached item by the passed in item. If there is
     * no such item in the cache then it will be added.
     *
     * @param {ComplexData} item
     */
    public update(item: ComplexDataType<any>): void {
        const cachedItem = this.get(item.constructor.getModelName(), item.data.id);
        if (cachedItem) {
            debugCache('cache update:', item.constructor.getModelName(), item.data.id);
            cachedItem.update(item);
        } else {
            debugCache('cache update-add:', item.constructor.getModelName(), item.data.id);
            this.add(item);
        }
    }

    /**
     * Remove item from cache
     *
     * @param {ComplexData} item
     */
    public remove(item: ComplexDataType<any>): void {
        const modelName: string = item.constructor.getModelName();
        debugCache('cache remove:', modelName, item.data.id);
        this.removeAssociation(modelName, item);
        this.cachedItems.get(modelName).set(item.data.id, null);
        item.setCache(null);
    }

    /**
     * Checks whether the given model has any item in the cache
     *
     * @param modelName
     * @returns {boolean}
     */
    protected isModelCached(modelName): boolean {
        return !!this.cachedItems.get(modelName);
    }

    /**
     * Called when a model instance is added to the cache
     * at the first time. Overwriting this allows to add
     * listeners on stores.
     * @param {string} modelName
     */
    protected notifyOnNewModel(modelName: string): void {
    }

    /**
     * Add a ne item to the cache
     *
     * @param {ComplexData} item
     */
    public add(item: ComplexDataType<any>): void {
        item.setCache(this);

        const modelName = item.constructor.getModelName();
        this.notifyOnNewModel(modelName);
        this.notifyOnNewAssociationModels(item);

        const { id } = item.data;
        this.cachedItems.set(modelName, this.cachedItems.get(modelName) || new Map());
        const cachedInstance = this.cachedItems.get(modelName).get(id);

        if (!cachedInstance) {
            debugCache('cache set:', modelName, id);
            this.cachedItems.get(modelName).set(id, item);
        } else if (cachedInstance !== item) {
            throw new Error('Cache error: update non identical items');
        }
    }

    private notifyOnNewAssociationModels(item: ComplexDataType<any>): void {
        this.walkOnItemsAssociations(item, (associationName, association) => {
            const modelName = association.instance.getModelName();
            this.notifyOnNewModel(modelName);
            this.cachedItems.set(modelName, this.cachedItems.get(modelName) || new Map());
            this.updateAssociationMap(item, association, associationName);
        });
    }

    walkOnItemsAssociations(item: ComplexDataType<any>, callBack: Function = (associationName, association) => {
    }) {
        Object.entries(item.constructor.allowedAssociations || {}).forEach(([associationName, association]) => {
            if (Cache.isCacheable(association)) {
                callBack.call(this, associationName, association);
            }
        });
    }

    public isItemCached(item: ComplexDataType<any>): boolean {
        const modelName = item.constructor.getModelName();
        return this.cachedItems.get(modelName) && typeof this.cachedItems.get(modelName).get(item.data.id) !== 'undefined';
    }

    public getCachedItems<T extends ComplexDataType<any>>(modelName: string): T[] {
        return this.getCachedItemsByFilter(modelName, () => true);
    }

    public getCachedItemsByFilter<T extends ComplexDataType<any>>(modelName: string, filterFn): T[] {
        const acc: T[] = [];
        this.cachedItems.get(modelName).forEach(item => {
            if (filterFn(item)) {
                // @ts-ignore
                acc.push(item);
            }
        });
        return acc;
    }

    /**
     * Get cached item
     *
     * @param {string} storeName
     * @param {CacheItemKey} id
     * @returns {ComplexData}
     */
    public get<T extends ComplexDataType<any>>(storeName: string, id: CacheItemKey): T {
        // @ts-ignore
        const item: T = this.cachedItems.get(storeName) && this.cachedItems.get(storeName).get(id);
        if (item) {
            debugCache('cache get:', storeName, id);
        }
        // @ts-ignore
        return item;
    }
}
