import ComplexData, { AssociationConfig, AssociationDefinition, AutoGeneratedFunctions } from '../complexData';
import UserProfileEntity from './entity';
import modelProperties from './modelProperties';
import UserProfileRole from '../userProfileRole';
import UserProfileRoleEntity from '../userProfileRole/entity';

interface UserProfileAssociations extends AssociationConfig<any, any> {
    userProfileRoles: AssociationDefinition<UserProfileRoleEntity, UserProfileRole>
}

interface UserProfile extends AutoGeneratedFunctions<UserProfileAssociations, UserProfileEntity, ComplexData<UserProfileEntity>> {}

// eslint-disable-next-line no-redeclare
class UserProfile extends ComplexData<UserProfileEntity> {
    static Entity = UserProfileEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): UserProfileAssociations {
        return {
            userProfileRoles: {
                key: 'userprofile_role',
                instance: UserProfileRole,
                entity: UserProfileRoleEntity,
                condition: {
                    userprofile_id: this.Entity.getFieldSymbols().id,
                },
            },
        };
    }
}

export default UserProfile;
