import Entity, { DateOrString, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class CompanyAddressEntity extends Entity {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'boolean', codeName: 'isDefault', defaultValue: true })
    public isdefault: boolean;
    
    @field({ type: 'string' })
    public country: string;
    
    @field({ type: 'string' })
    public state: string;
    
    @field({ type: 'string' })
    public city: string;
    
    @field({ type: 'string' })
    public address1: string;
    
    @field({ type: 'string' })
    public address2: string;
    
    @field({ type: 'string' })
    public postcode: string;
    
    @field({ type: 'int', primaryKey: true })
    public company_id: number;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;
    
    @field({ type: 'int' })
    public country_id: number;
    
    @field({
        type: 'boolean', codeName: 'isDeleted', allowNull: false, defaultValue: false, serverOnly: true, 
    })
    public isdeleted: boolean;
    
    @field({ type: 'string' })
    public name: string;
    
    @field({ type: 'boolean', defaultValue: true })
    public is_registered: boolean;
    
    @field({ type: 'boolean', defaultValue: false })
    public is_invoice: boolean;
}
