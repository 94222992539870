class RecursiveReplace {
    constructor(replacer, config) {
        this._replacer = replacer;
        this._config = config;
    }

    _replaceVariableNamesInItems(items) {
        return items.map(item => this.replace(item));
    }

    _replaceVariableNamesInObject(item) {
        return Object.keys(item)
        // Replace all properties of `item` object
            .reduce((result, key) => Object.assign(result, { [key]: this.replace(item[key]) }), {});
    }

    _replaceVariable(itemValue) {
        if (!itemValue) {
            return itemValue;
        }
        return this._replacer(itemValue, this._config);
    }

    replace(item) {
        if (Array.isArray(item)) {
            return this._replaceVariableNamesInItems(item);
        } if (item && typeof item === 'object' && Object.keys(item).length > 0) {
            return this._replaceVariableNamesInObject(item);
        }
        return this._replaceVariable(item);
    }
}

module.exports = RecursiveReplace;
