export default {
    LINK_TYPE: {
        Document: {
            code: 1,
            event_type: {
                1: 'docEdit',
                2: 'docSent',
                3: 'itemAdded',
                4: 'itemDeleted',
                5: 'docPrinted',
                6: 'receiptSent',
            },
        },
        Message: {
            code: 2,
            event_type: {
                1: 'messageSent',
            },
        },
        Payment: {
            code: 3,
            event_type: {
                1: 'paymentRecorded',
                2: 'paymentRefunded',
                3: 'paymentReceived',
            },
        },
        FormDocument: {
            code: 4,
            event_type: {
                1: 'docPrinted',
            },
        },
    },
};
