const modelProperties = {
    modelName: 'CustomerMessageAttachment',
    tableName: 'CustomerMessageAttachment',
    minAppVersion: '9.0.0',

    associations: [{
        type: 'belongsTo',
        model: 'CustomerMessage',
        parameters: { as: 'CustomerMessage', foreignKey: { name: 'customermessage_id', fieldName: 'customermessage_id', sourceKeyName: 'id' } },
    }, {
        type: 'belongsTo',
        model: 'Attachment',
        parameters: {
            foreignKey: {
                complexAssociationName: 'attachment',
                name: 'attachment_id',
                fieldName: 'attachment_id',
            },
        },
    }],
};

export default modelProperties;
