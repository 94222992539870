import { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');
const constants = require('@powerednow/shared/constants').default;

export default class UserEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'string', primaryKey: true, requiresRole: { UPDATE: [constants.ROLES.PERMISSIONS.CAN_ADMIN_PN] } })
    public email: string;

    @field({ 
        type: 'string',
        hooks: { afterUpdate: [{ handler: 'onPasswordUpdated', args: [] }] },    
    })
    public password: string;

    @field({ type: 'int', codeName: 'rememberMe' })
    public rememberme: number;

    @field({ type: 'int', primaryKey: true })
    public company_id: number;

    @field({ type: 'string', codeName: 'firstName' })
    public firstname: string;

    @field({ type: 'string', codeName: 'lastName' })
    public lastname: string;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;

    @field({ type: 'string', dbType: 'date', serverOnly: true })
    public dt_expires: DateOrString;

    @field({ type: 'int', serverOnly: true })
    public logins: number;

    @field({ type: 'string', defaultValue: 0 })
    public last_login: string;

    @field({ type: 'string', serverOnly: true })
    public pwd_reminder_hash: string;

    @field({
        type: 'boolean', codeName: 'isDeleted', allowNull: false, defaultValue: false, serverOnly: true,
    })
    public isdeleted: boolean;

    @field({ type: 'boolean' })
    public is_main: boolean;

    @field({ type: 'boolean', defaultValue: 1, allowNull: false })
    public is_active: boolean;

    @field({ type: 'int', allowNull: true })
    public consumption_id: number | null;

    @field({ type: 'string', codeName: 'socialId' })
    public socialId: string;

    @field({ type: 'string', codeName: 'socialProvider' })
    public socialProvider: string;

    @field({ type: 'string', clientOnly: true })
    public token: string;

    @field({ minAppVersion: '7.5.0', type: 'int' })
    public signature_id: number;

    @field({ minAppVersion: '7.5.0', type: 'string', isJSON: true })
    public form_data: {
        electricalPosition?: string;
        competentPersonsScheme?: string;
        cpsRegistrationNumber?: string;
        gasSafeRegistrationNumber?: string;
        gasSafeLicenseNumber?: string;
    };

    @field({ type: 'boolean', minAppVersion: '8.1.2' })
    public is_valid: boolean;

    @field({ type: 'string' })
    public uuid?: string;
}
