const modelProperties = {
    modelName: 'Form',
    tableName: 'Form',
    minAppVersion: '7.5.0',
    associations: [{
        type: 'hasOne',
        model: 'CompanyForm',
        cascadeDelete: false,
        parameters: { foreignKey: { fieldName: 'form_type_id', sourceKeyName: 'form_type_id' }, targetKeyName: 'form_type_id' },
    }, {
        type: 'belongsTo',
        model: 'FormType',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'form_type_id', fieldName: 'form_type_id' } },
    }, {
        type: 'belongsTo',
        model: 'Form',
        cascadeDelete: false,
        parameters: { as: 'NewerForm', foreignKey: { name: 'form_type_id', fieldName: 'form_type_id', sourceKeyName: 'form_type_id' }, targetKey: 'form_type_id' },
    }],
    validations: {},
    global: true,
};

export default modelProperties;
