import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import ProductTierToFeatureEntity from './entity';
import modelProperties from './modelProperties';

interface ProductTierToFeatureAssociations extends AssociationConfig<any, any> {}

interface ProductTierToFeature extends AutoGeneratedFunctions<ProductTierToFeatureAssociations, ProductTierToFeatureEntity, ComplexData<ProductTierToFeatureEntity>> {}

// eslint-disable-next-line no-redeclare
class ProductTierToFeature extends ComplexData<ProductTierToFeatureEntity> {
    static Entity = ProductTierToFeatureEntity;

    static modelProperties = modelProperties;
}

export default ProductTierToFeature;
export type ProductTierToFeatureType = ProductTierToFeature;
