export default {
    EU: {
        ID: 1,
        NAME: 'European Union',
    },
    UK_AND_IOM: {
        ID: 2,
        NAME: 'UK and Isle Of Man',
    },
};
