const BaseValidator = require('modules/validation/baseValidator');

class RangeValidator extends BaseValidator {
    constructor(config = {}) {
        Object.assign(config, {
            message: 'notCorrectLength',
        });
        super(config);
    }

    async runDefaultRule(value, options = {}) {
        const intValue = parseInt(value, 10);

        if (isNaN(intValue)) {
            this.detailedValidationMessage = 'Must be a number';
            return this.config.message;
        }

        if (options.min && options.max && (intValue < options.min || intValue > options.max)) {
            if (options.min === options.max) {
                this.detailedValidationMessage = `(Should be exactly ${options.min})`;
            } else {
                this.detailedValidationMessage = `(At least ${options.min}, and max ${options.max} )`;
            }

            return this.config.message;
        } if (options.min && intValue < options.min) {
            this.detailedValidationMessage = `(At least ${options.min} )`;
            return this.config.message;
        } if (options.max && intValue > options.max) {
            this.detailedValidationMessage = `(Max ${options.max} )`;
            return this.config.message;
        }

        return true;
    }

    getDetailedValidationMessage() {
        return this.detailedValidationMessage;
    }
}

module.exports = RangeValidator;
