const modelProperties = {
    modelName: 'ConsumptionExtra',
    tableName: 'ConsumptionExtra',
    minAppVersion: '3.6.0',

    associations: [{
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'belongsTo',
        model: 'PurchaseSetup',
        parameters: {
            as: 'PurchaseSetupIOS',
            complexAssociationName: 'PurchaseSetup',
            foreignKey: { name: 'product', fieldName: 'product' },
            targetKey: 'ios_product_id',
            targetKeyName: 'ios_product_id',
        },
    }, {
        type: 'belongsTo',
        model: 'PurchaseSetup',
        parameters: {
            as: 'PurchaseSetupAndroid',
            complexAssociationName: 'PurchaseSetup',
            foreignKey: { name: 'product', fieldName: 'product' },
            targetKey: 'android_product_id',
            targetKeyName: 'android_product_id',
        },
    }, {
        type: 'hasOne',
        model: 'User',
        parameters: {
            foreignKey: {
                name: 'consumption_id',
                fieldName: 'consumption_id',
            },
        },
    }],
};

export default modelProperties;
