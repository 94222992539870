import Entity, { EntityWithCompanyId, field } from '../entity';
    
export default class UserProfileRoleCustomEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'boolean' })
    public mode: boolean;
    
    @field({ type: 'int' })
    public role_id: number;
    
    @field({ codeName: 'userProfileId', type: 'int' })
    public userprofile_id: number;
    
    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;
}
