import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import UserProfile from '../userProfile';
import UserProfileEntity from '../userProfile/entity';
import UserUserProfileEntity from './entity';
import modelProperties from './modelProperties';

interface UserUserProfileAssociations extends AssociationConfig<any, any> {
    userProfile: AssociationDefinitionSingle<UserProfileEntity, UserProfile>
}

interface UserUserProfile extends AutoGeneratedFunctions<UserUserProfileAssociations, UserUserProfileEntity, ComplexData<UserUserProfileEntity>> {}

// eslint-disable-next-line no-redeclare
class UserUserProfile extends ComplexData<UserUserProfileEntity> {
    static Entity = UserUserProfileEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): UserUserProfileAssociations {
        return {
            userProfile: {
                key: 'userprofile',
                instance: UserProfile,
                entity: UserProfileEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().userprofile_id,
                },
            },
        };
    }
}

export default UserUserProfile;
