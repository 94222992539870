const modelProperties = {
    modelName: 'Payment',
    tableName: 'Payment',
    associations: [{
        type: 'hasMany',
        model: 'PaymentToInvoice',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'payment_id', fieldName: 'payment_id' } },
    }, {
        type: 'belongsToMany',
        model: 'Document',
        ignoreModelOnCascadeDelete: true,
        modelKeys: { primary: 'id', foreign: 'document_id' },
        parameters: {
            as: 'Documents',
            through: { model: 'PaymentToInvoice', unique: false },
            foreignKey: { name: 'payment_id', fieldName: 'payment_id' },
            otherKey: { name: 'document_id', fieldName: 'document_id' },
        },
    }, {
        type: 'belongsToMany',
        model: 'Document',
        ignoreModelOnCascadeDelete: true,
        modelKeys: { primary: 'id', foreign: 'document_id' },
        parameters: {
            as: 'CreditNotes',
            through: { model: 'PaymentToInvoice', unique: false },
            foreignKey: { name: 'payment_id', fieldName: 'payment_id' },
            otherKey: { name: 'credit_note_id', fieldName: 'credit_note_id' },
        },
    }, { type: 'belongsTo', model: 'PaymentType', parameters: { foreignKey: 'type' } }, {
        type: 'belongsTo',
        model: 'Payment',
        cascadeDelete: false,
        parameters: {
            foreignKey: 'cis_payment_id',
            as: 'CisPayment',
        },
    }],
    validations: { reference: { type: 'length', max: 60 } },
};

export default modelProperties;
