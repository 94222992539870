import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import KnownErrorEntity from './entity';
import modelProperties from './modelProperties';

interface KnownErrorAssociations extends AssociationConfig<any, any> {}

interface KnownError extends AutoGeneratedFunctions<KnownErrorAssociations, KnownErrorEntity, ComplexData<KnownErrorEntity>> {}

// eslint-disable-next-line no-redeclare
class KnownError extends ComplexData<KnownErrorEntity> {
    static Entity = KnownErrorEntity;

    static modelProperties = modelProperties;
}

export default KnownError;
