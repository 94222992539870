export default {
    CODES: {
        FIELD: {
            EMAIL: {
                message: {
                    promptId: 'Validation.notValidEmail',
                    text: 'Invalid email',
                },
            },
            PHONE: {
                message: {
                    promptId: 'Validation.notValidPhone',
                    text: 'Invalid phone number',
                },
            },
            LENGTH: {
                message: {
                    promptId: 'Validation.notCorrectLength',
                    text: 'Invalid field length',
                },
            },
        },
        CUSTOMER: {
            INVALID_TYPE: {
                message: {
                    alertTitlePromptId: 'MessageBox.Warning',
                    alertTitleText: 'Warning',
                    promptId: 'EditCustomer.MissingType',
                    text: 'Please choose if the contact is a customer or supplier, you can also choose both.',
                },
            },
            INVALID_TYPE_AND_CIS: {
                message: {
                    alertTitlePromptId: 'MessageBox.Warning',
                    alertTitleText: 'Warning',
                    promptId: 'EditCustomer.CisWithSupplierAndCustomerWarning',
                    text: 'Sorry! Looks like you\'ve selected both supplier and customer and have CIS enabled. Either create separate records or disable CIS.',
                },
            },
            INVALID_UTR: {
                message: {
                    alertTitlePromptId: 'MessageBox.Warning',
                    alertTitleText: 'Warning',
                    promptId: 'SettingsScreen.invalidUTR',
                    text: 'Unique Taxpayer Reference (UTR) number is invalid!',
                },
            },
            INVALID_CONTACT_NAMES: {
                message: {
                    alertTitlePromptId: 'MessageBox.Warning',
                    alertTitleText: 'Warning',
                    promptId: 'MessageBox.CompanyOrNameInvalid',
                    text: 'Company name or first or last name is required!',
                },
            },
            NO_CREATE_PERMISSION: {
                message: {
                    alertTitlePromptId: 'Permission.warningTitle',
                    alertTitleText: 'Warning',
                    promptId: 'Permission.createCustomerDenied',
                    text: 'You don\'t have permission to create Customer!',
                },
            },
        },
        ADDRESS: {
            NO_CHANGE_PERMISSION: {
                message: {
                    alertTitlePromptId: 'Permission.warningTitle',
                    alertTitleText: 'Warning',
                    promptId: 'Permission.changeCompanyDenied',
                    text: 'You don\'t have permission to change company details!',
                },
            },
        },
    },
    TIMEOUT: {
        ONLINE_CHECK: 3000, // milliseconds
    },
};
