export default {
    ID: {
        POWEREDNOW: 1,
        GRAHAM: 2,
        ONETRADE: 3,
        TOOLSTATION: 4,
        EXPERT_TRADES: 15,
        SCREWFIX: 18,
    },
    CATALOG: {
        TYPE: {
            DATA: 1,
            DOCUMENT: 2,
        },
    },
};
