import { EventEmitter } from 'events';
import { PropType } from '@powerednow/type-definitions';
import Entity, {
    DataObject, EntityWithId, FieldDefinition, ModelFields,
} from './entity';
import type Cache from './cache';

export type HookDefinition = {
    localFunction: string,
    key: string,
    delay: number,
    remoteFunction: string,
    checkValueChange: boolean,
    structure: number,
};

export type ModelProperties = {
    global?: boolean;
    modelName: string,
    tableName: string,
    syncTableName?: string,
    syncable?: boolean,
    associations?: Record<string, any>[],
    hooks?: HookDefinition[],
}

export type ModelDefinition = ModelProperties & { fields: FieldDefinition[] }

export default abstract class BaseData<ExtendedEntity extends EntityWithId> extends EventEmitter {
    declare ['constructor']: typeof BaseData;

    _entity: ExtendedEntity;

    public static EVENTS = {
        DATA_LOADED: 'DATA_LOADED',
        MODEL_DATA_REQUEST: 'model_data_request',
        NEW_ITEM_ADDED: 'newItemAdded',
        ITEM_DELETED: 'itemDeleted',
        FIELD_CHANGED: 'fieldChanged',
        NEW_ITEM_ASSOCIATED: 'item_associated',
        ID_UPDATED: 'idUpdated',
        ASSOCIATION_CHANGED: 'associationChanged',
        MODEL_DATA_INITIALISED: 'model_data_initialised',
        OWN_ASSOCIATION_SAVED: 'own_association_id_updated',
        OWN_ASSOCIATION_ADDED: 'own_association_added',
    };

    public static NON_FORWARDING_EVENTS = [
        BaseData.EVENTS.OWN_ASSOCIATION_SAVED,
        BaseData.EVENTS.OWN_ASSOCIATION_ADDED,
    ];

    public static EXTERNAL_EVENTS = [
        BaseData.EVENTS.DATA_LOADED,
        BaseData.EVENTS.MODEL_DATA_REQUEST,
        BaseData.EVENTS.MODEL_DATA_INITIALISED,
    ];

    /**
     * Member holding the actual values of this data object.
     * It is an Entity instance tracking the changes on its properties.
     * Use the related getter to obtain its value
     *
     * @type {Entity}
     */
    private privateData: ExtendedEntity;

    /**
     *
     * @type {boolean}
     */
    protected privateDeleted: boolean = false;

    protected disposable = false;

    static get modelDefinition(): ModelDefinition {
        return {
            ...this.modelProperties,
            fields: this.Entity.fields,
        };
    }

    static Entity: typeof Entity;

    static modelProperties: ModelProperties;

    static get serverOnlyFieldNames(): string[] {
        return this.getFieldNamesByFilter(field => field.serverOnly);
    }

    static getFieldNamesByFilter(filter: (arg: FieldDefinition) => boolean): string[] {
        return this.modelDefinition.fields
            .filter(filter)
            .map(field => field.name);
    }

    /**
     * Creates a new instance of the constructor
     *
     * @param {DataObject} data - The data object to initialize the instance with
     * @param {DataObject} _extraData - Additional data object
     * @param {Object} _options - Additional options for the constructor
     * @param {BaseData<any>} _options.parent - The parent object
     * @param {boolean} _options.fromBuilder - Indicates if the instance is created from a builder
     * @param {Object.<string, Function>} _options.listeners - Event listeners for the instance
     * @param {Cache} _options.cache - Cache object for the instance
     * @param {boolean} _options.disposable - Indicates if the instance is disposable
     */
    public constructor(
        data: DataObject,
        _extraData: DataObject,
        _options: {
                           parent?: BaseData<any>,
                           fromBuilder?: boolean,
                           listeners?: { string?: Function },
                           cache?: Cache,
                           disposable?: boolean,},
        disposable = false,
    ) {
        super();
        this.disposable = disposable;
        this.setMonitoredEntity(data);
        this.setId();
        this.postCreate();
    }

    protected postCreate(): void {

    }

    /**
     * Abstract function to return private data.
     * Could  be overwritten by inherited classes
     *
     * @returns {{}}
     */
    get data(): ExtendedEntity {
        return this.privateData;
    }

    protected get dataValues(): ModelFields<ExtendedEntity> {
        return this.data.getDataValues();
    }

    public getData(): ExtendedEntity {
        return this.data;
    }

    /**
     * Get flag indicating whether the object is deleted
     *
     * @returns {boolean}
     */
    public get isDeleted() {
        return this.privateDeleted;
    }

    public get isUpdated() {
        return (!this.isDeleted && this.data.isUpdated());
    }

    public get isNew() {
        return (!this.isDeleted && this.data.isNew());
    }

    /**
     * Returns true if the current item is is a new data instance (does not have id field)
     *
     * @returns {boolean}
     */
    public isNewItem(): boolean {
        return (this.data && typeof this.data.id === 'symbol');
    }

    /**
     * If there is no ID field on the data object then create an id symbol
     */
    private setId() {
        this.data.suppressEvents();
        BaseData.createIdSymbol(this.data);
        this.data.unSuppressEvents();
    }

    public static getModelName(): string {
        return this.modelDefinition.modelName;
    }

    public static getTableName(): string {
        return this.modelDefinition.tableName;
    }

    /**
     * If there is no ID field on the passed in data object
     * then create an id symbol
     *
     * @param itemData
     */
    public static createIdSymbol(itemData: DataObject): void {
        const allowedIdTypes = ['symbol', 'number'];
        if (!allowedIdTypes.includes(typeof itemData.id)) {
            itemData.id = Symbol(Math.random().toString(36).slice(-8));
        }
    }

    static get associationKey(): string {
        //
        // Unit tests may not have modelDefinition so use class name
        //
        const name = this.modelDefinition.tableName || this.name;
        return name.charAt(0).toLowerCase() + name.slice(1);
    }

    protected entityChanged<LE extends PropType<this, '_entity'>, RT extends keyof ModelFields<LE>>(key: RT, newValue: PropType<LE, RT>, oldValue: PropType<LE, RT>) {

    }

    /**
     * Create a private data entity holding the data of this object
     * and monitor changes on it
     *
     * @param {DataObject} data
     */
    private setMonitoredEntity(data: DataObject): void {
        //
        // Create new entity to monitor change statuses
        //
        const EntityConstructor = (this.constructor as any).Entity;
        this.privateData = new EntityConstructor(data);
        if (this.disposable) {
            return;
        }
        //
        // Set up event forwarding
        //
        this.privateData.onFieldChanged = (key, newValue, oldValue) => {
            this.entityChanged(key, newValue, oldValue);
            this.emit(BaseData.EVENTS.FIELD_CHANGED, {
                ...{
                    key,
                    oldValue,
                    newValue,
                },
                ...{ item: this },
            });
        };
    }
}
