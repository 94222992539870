import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import PromptEntity from './entity';
import modelProperties from './modelProperties';

interface PromptAssociations extends AssociationConfig<any, any> {}

interface Prompt extends AutoGeneratedFunctions<PromptAssociations, PromptEntity, ComplexData<PromptEntity>> {}

// eslint-disable-next-line no-redeclare
class Prompt extends ComplexData<PromptEntity> {
    static Entity = PromptEntity;

    static modelProperties = modelProperties;
}

export default Prompt;
