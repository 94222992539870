import { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class BookingDataExceptionEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'boolean', defaultValue: false })
    public allowBooking: boolean;

    @field({ type: 'string' })
    public title: string;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_start: DateOrString;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_end: DateOrString;

    @field({ type: 'int', defaultValue: null })
    public appointmentTypeId: number;

    @field({ type: 'boolean', defaultValue: true })
    public canBeMorning: boolean;

    @field({ type: 'boolean', defaultValue: true })
    public canBeAfternoon: boolean;

    @field({ type: 'boolean', defaultValue: false })
    public canBeEvening: boolean;

    @field({ type: 'int', defaultValue: 1 })
    public morningMax: number;

    @field({ type: 'int', defaultValue: 1 })
    public afternoonMax: number;

    @field({ type: 'int', defaultValue: 1 })
    public eveningMax: number;
    
    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;
}
