import Entity, { EntityWithCompanyId, field } from '../entity';
    
export default class ConsumptionStatsEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: false })
    public id?: number;
    
    @field({
        type: 'int', primaryKey: true, serverOnly: true, unique: 'compositeIndex', 
    })
    public company_id: number;
    
    @field({ type: 'int', primaryKey: true, unique: 'compositeIndex' })
    public year: number;
    
    @field({ type: 'int', primaryKey: true, unique: 'compositeIndex' })
    public month: number;
    
    @field({
        type: 'int', primaryKey: true, unique: 'compositeIndex', minAppVersion: '8.4.1', 
    })
    public day: number;
    
    @field({ codeName: 'documentCreation', defaultValue: 0, type: 'int' })
    public documentCreation?: number;

    @field({ codeName: 'documentCreation', defaultValue: 0, type: 'int' })
    public users?: number;
    
    @field({ codeName: 'deviceSwitch', defaultValue: 0, type: 'int' })
    public deviceSwitch?: number;
}
