/**
 * @TODO Just look at it.
 */
declare global {
    interface Window {
        captureStackTrace: Function
    }
}
/**
 * ValidationError
 * @param data
 * @constructor
 */
export function ValidationError(data) {
    this.message = data.message;
    this.name = 'ValidationError';
    this.severity = data.severity || 10;
    this.code = data.code;

    window.captureStackTrace(this, ValidationError);
}

/**
 * NetworkError
 * @param data
 * @constructor
 */
export function NetworkError(data) {
    this.message = data.message;
    this.name = 'NetworkError';
    this.severity = data.severity || 10;
    this.code = data.code;

    window.captureStackTrace(this, NetworkError);
}

/**
 * ServerError
 * @param data
 * @constructor
 */
export function ServiceError(data) {
    this.message = data.message;
    this.name = 'ServiceError';
    this.severity = data.severity || 10;
    this.code = data.code;

    window.captureStackTrace(this, ServiceError);
}

/**
 * ServerError
 * @param data
 * @constructor
 */
export function ServerError(data) {
    this.message = data.message;
    this.name = 'ServerError';
    this.severity = data.severity || 10;
    this.code = data.code;

    window.captureStackTrace(this, ServerError);
}

/**
 * DatabaseError
 * @param data
 * @constructor
 */
export function DatabaseVersionChangeError(data) {
    this.message = data.message;
    this.name = 'DatabaseVersionChangeError';
    this.severity = data.severity || 10;
    this.code = data.code;

    window.captureStackTrace(this, DatabaseVersionChangeError);
}

/**
 * DatabaseError
 * @param data
 * @constructor
 */
export function DatabaseVersionNotSetError(data) {
    this.message = data.message;
    this.name = 'DatabaseError';
    this.severity = data.severity || 10;
    this.code = data.code;

    window.captureStackTrace(this, DatabaseVersionNotSetError);
}

/**
 * DatabaseError
 * @param data
 * @constructor
 */
export function DatabaseVersionTableNotExistsError(data) {
    this.message = data.message;
    this.name = 'DatabaseError';
    this.severity = data.severity || 10;
    this.code = data.code;

    window.captureStackTrace(this, DatabaseVersionTableNotExistsError);
}

/**
 * DatabaseError
 * @param data
 * @constructor
 */
export function DatabaseError(data) {
    this.message = data.message;
    this.name = 'DatabaseError';
    this.severity = data.severity || 10;
    this.code = data.code;

    window.captureStackTrace(this, DatabaseError);
}

/**
 * AppUsabilityError
 * @param data
 * @constructor
 */
export function AppUsabilityError(data) {
    this.message = data.message;
    this.name = 'AppUsabilityError';
    this.severity = data.severity || 10;
    this.code = data.code;

    window.captureStackTrace(this, AppUsabilityError);
}

ValidationError.prototype = Object.create(Error.prototype);
ValidationError.prototype.constructor = ValidationError;

ServerError.prototype = Object.create(Error.prototype);
ServerError.prototype.constructor = ServerError;

ServiceError.prototype = Object.create(Error.prototype);
ServiceError.prototype.constructor = ServiceError;

NetworkError.prototype = Object.create(Error.prototype);
NetworkError.prototype.constructor = NetworkError;

DatabaseVersionChangeError.prototype = Object.create(DatabaseError.prototype);
DatabaseVersionChangeError.prototype.constructor = DatabaseVersionChangeError;

DatabaseVersionNotSetError.prototype = Object.create(DatabaseError.prototype);
DatabaseVersionNotSetError.prototype.constructor = DatabaseVersionNotSetError;

DatabaseVersionTableNotExistsError.prototype = Object.create(DatabaseError.prototype);
DatabaseVersionTableNotExistsError.prototype.constructor = DatabaseVersionTableNotExistsError;

DatabaseError.prototype = Object.create(Error.prototype);
DatabaseError.prototype.constructor = DatabaseError;

AppUsabilityError.prototype = Object.create(Error.prototype);
AppUsabilityError.prototype.constructor = AppUsabilityError;

window.captureStackTrace = function captureStackTrace(self, error) {
    if (Error.captureStackTrace) {
        Error.captureStackTrace(self, error);
    }
};
