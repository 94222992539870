import DOCUMENT, { TYPES_VALUES_Type } from '@powerednow/shared/constants/document';
import { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class DocumentEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'int' })
    public type: TYPES_VALUES_Type;

    @field({
        type: 'int',
        hooks: {
            afterUpdate: [{
                handler: 'tagHubspot',
                condition: {
                    newValue: DOCUMENT.STATUS.PAID,
                },
                args: ['marked_invoice_as_paid'],
            }],
        },
    })
    public status: number;

    @field({ type: 'int', allowNull: true, codeName: 'documentNumber' })
    public documentnumber: number;

    @field({ type: 'string', codeName: 'displayNumber' })
    public displaynumber: string;

    @field({ type: 'string', codeName: 'taxPoint', dbType: 'date' })
    public taxpoint: DateOrString;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;

    @field({ defaultValue: 0, type: 'decimal' })
    public subtotal: number;

    @field({ defaultValue: 0, type: 'decimal' })
    public vat: number;

    @field({ defaultValue: 0, type: 'decimal' })
    public total: number;

    @field({ defaultValue: 0, type: 'decimal' })
    public accepted: number;

    @field({ type: 'string', hooks: { beforeUpdate: [{ handler: 'clearStorageUrl' }] } })
    public html: string;

    @field({ type: 'string', codeName: 'refuseReason' })
    public refusereason: string;

    @field({ type: 'int' })
    public job_id: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'string' })
    public uuid: string;

    @field({ type: 'int' })
    public customer_id: number;

    @field({ type: 'int' })
    public action_id: number;

    @field({ type: 'int' })
    public user_id: number;

    @field({ type: 'string' })
    public header: string;

    @field({ type: 'string' })
    public footer: string;

    @field({ type: 'string' })
    public template_path: string;

    @field({ defaultValue: 1, type: 'int' })
    public layout_id: number;

    @field({ type: 'boolean' })
    public display_total: boolean;

    @field({ type: 'int' })
    public parent_document_id: number;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;

    @field({ type: 'boolean' })
    public confirmed: boolean;

    @field({ type: 'boolean' })
    public printed: boolean;

    @field({ type: 'string', defaultValue: null, allowNull: true })
    public prefix: string;

    @field({ type: 'string', defaultValue: null, allowNull: true })
    public suffix: string;

    @field({ type: 'boolean', codeName: 'isLocked', defaultValue: false })
    public islocked: boolean;

    @field({ type: 'int' })
    public consumption_id: number | null;

    @field({ type: 'boolean', defaultValue: false })
    public is_disbursement: boolean;

    @field({ type: 'boolean', defaultValue: false })
    public is_cis: boolean;

    @field({ type: 'decimal', defaultValue: 0 })
    public cis_total: number;

    @field({ type: 'decimal', defaultValue: 0 })
    public cisable_amount: number;

    @field({ type: 'decimal', defaultValue: 0 })
    public cis_estimate: number;

    @field({ type: 'string', dbType: 'date' })
    public dt_locked: DateOrString;

    @field({ type: 'int' })
    public external_company_id: number;

    @field({ type: 'int' })
    public external_document_id: number;

    @field({ type: 'boolean', defaultValue: false })
    public external_clone: boolean;

    @field({ type: 'boolean', codeName: 'isArchived', serverOnly: true })
    public is_archived: boolean;

    @field({ codeName: 'storageUrl', type: 'string' })
    public storage_url: string;

    @field({ type: 'string' })
    public plot: string;

    @field({
        codeName: 'lastActivity',
        minAppVersion: '7.7.0',
        type: 'string',
        dbType: 'date',
        defaultValue: dateUtils.getNow.bind(dateUtils),
        serverOnly: true,
    })
    public lastActivity: DateOrString;

    @field({ type: 'string', dbType: 'date', minAppVersion: '7.7.0' })
    public dueDate: DateOrString;

    @field({ type: 'int', allowNull: true })
    public vat_return_id: number;

    @field({ type: 'string', minAppVersion: '7.7.3' })
    public summary: string;

    @field({
        type: 'boolean', defaultValue: false, clientOnly: true, minAppVersion: '7.9.0', 
    })
    public bulkCreated: boolean;

    @field({ type: 'string', defaultValue: null, minAppVersion: '8.0.0' })
    public accounting_reference: string;

    @field({ type: 'string', defaultValue: null, minAppVersion: '8.0.0' })
    public sync_uuid: string;

    @field({ type: 'string', defaultValue: null, minAppVersion: '8.0.0' })
    public synced_uuid: string;

    @field({ codeName: 'deliveryAddress', type: 'string', minAppVersion: '8.1.0' })
    public deliveryAddress: string;

    @field({
        codeName: 'deliveryAddressType', type: 'int', defaultValue: null, minAppVersion: '8.1.0', 
    })
    public deliveryAddressType: number;

    @field({ type: 'int' })
    public integration_failure: number | null;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_pinned?: boolean;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_marked?: boolean;

    @field({ type: 'int' })
    public site_id?: number;

    @field({ type: 'boolean' })
    public hide_site_address?: boolean;
}
