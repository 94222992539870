export default {
    CIS: {
        MONTHLY: 1,
        CONTRACTOR: 2,
        SUBCONTRACTOR: 3,
    },
    TYPE: {
        TOTALS: 0,
        LINES: 1,
        ALL_APPOINTMENTS: 2,
        UNPLANNED_FOR_DAY: 3,
        UNPLANNED: 4,
        REMINDERS: 5,
        FORM_REMINDERS: 6,
        JOB: 7,
        PAYMENT: 8,
    },
};
