const modelProperties = {
    modelName: 'Prompt',
    tableName: 'prompt',
    associations: [{
        type: 'belongsTo',
        model: 'Country',
        parameters: { foreignKey: { name: 'country_id', fieldName: 'country_id' } },
    }, {
        type: 'belongsTo',
        model: 'Language',
        parameters: { foreignKey: { name: 'language_id', fieldName: 'language_id' } },
    }],
    global: true,
};

export default modelProperties;
