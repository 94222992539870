import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import Company from '../company';
import CisRateEntity from './entity';
import modelProperties from './modelProperties';

const constants = require('@powerednow/shared/constants').default;

interface CisRateAssociations extends AssociationConfig<any, any> {}

interface CisRate extends AutoGeneratedFunctions<CisRateAssociations, CisRateEntity, ComplexData<CisRateEntity>> {}

// eslint-disable-next-line no-redeclare
class CisRate extends ComplexData<CisRateEntity> {
    static Entity = CisRateEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): CisRateAssociations {
        return {};
    }

    public static async getDefaultRateId(complexObject: ComplexData<any>) {
        const registeredAddressCountryId = await Company.getCompanyCountryId(complexObject);

        return (registeredAddressCountryId === constants.COUNTRY.IDS.UNITED_KINGDOM) ? constants.CIS.RATES.NET : constants.CIS.RATES.STANDARD;
    }
}

export default CisRate;
