import { EntityWithId, field } from '../entity';
    
export default class ProductTierToSubscriptionEntity extends EntityWithId {
    @field({
        type: 'int', primaryKey: true, indexed: true,
    })
    public id?: number;

    @field({ type: 'int' })
    public product_tier_id: number;

    @field({ type: 'int' })
    public purchase_setup_id: number;

    @field({ type: 'int' })
    public whitelabel_id: number;
}
