import Entity, { EntityWithId, field } from '../entity';
    
export default class UserProfileRoleEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'int' })
    public role_id: number;
    
    @field({ type: 'int', codeName: 'userProfileId' })
    public userprofile_id: number;
}
