import MediaGroup from 'modules/complexData/mediaGroup';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import MediaEntity from './entity';
import modelProperties from './modelProperties';
import MediaGroupEntity from '../mediaGroup/entity';

interface MediaAssociations extends AssociationConfig<any, any> {
    mediaGroup: AssociationDefinitionSingle<MediaGroupEntity, MediaGroup>
}

interface Media extends AutoGeneratedFunctions<MediaAssociations, MediaEntity, ComplexData<MediaEntity>> {}

// eslint-disable-next-line no-redeclare
class Media extends ComplexData<MediaEntity> {
    static Entity = MediaEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): MediaAssociations {
        return {
            mediaGroup: {
                key: 'mediaGroup',
                instance: MediaGroup,
                entity: MediaGroupEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().mediagroup_id,
                },
            },

        };
    }
}

export default Media;
