import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import AdminAuditTrailEntity from './entity';
import modelProperties from './modelProperties';

interface AdminAuditTrailAssociations extends AssociationConfig<any, any> {}

interface AdminAuditTrail extends AutoGeneratedFunctions<AdminAuditTrailAssociations, AdminAuditTrailEntity, ComplexData<AdminAuditTrailEntity>> {}

// eslint-disable-next-line no-redeclare
class AdminAuditTrail extends ComplexData<AdminAuditTrailEntity> {
    static Entity = AdminAuditTrailEntity;

    static modelProperties = modelProperties;
}

export default AdminAuditTrail;
