const VAT_RETURN_BOXES = {
    BOX1: {
        TYPE: 1,
        DEFAULT_LABEL_VALUE: 'VAT Due on sales and other outputs',
        viewConfig: {
            viewModel: {
                data: {
                    showAsDecimal: true,
                },
            },
        },
    },
    BOX2: {
        TYPE: 2,
        DEFAULT_LABEL_VALUE: 'VAT due in the period on acquisitions of goods made in Northern Ireland from EU Member States',
        viewConfig: {
            viewModel: {
                data: {
                    showAsDecimal: true,
                },
            },
        },
    },
    BOX3: {
        TYPE: 3,
        DEFAULT_LABEL_VALUE: 'Total VAT due (the sum of boxes 1 and 2)',
        viewConfig: {
            viewModel: {
                data: {
                    showAsDecimal: true,
                },
            },
        },
    },
    BOX4: {
        TYPE: 4,
        DEFAULT_LABEL_VALUE: 'VAT reclaimed in the period on purchases and other inputs (including acquisitions in Northern Ireland from EU member states)',
        viewConfig: {
            viewModel: {
                data: {
                    showAsDecimal: true,
                },
            },
        },
    },
    BOX5: {
        TYPE: 5,
        DEFAULT_LABEL_VALUE: 'Net VAT to be paid to Customer or reclaimed by you (difference between boxes 3 and 4)',
        viewConfig: {
            viewModel: {
                data: {
                    showAsDecimal: true,
                },
            },
        },
    },
    BOX6: {
        TYPE: 6,
        DEFAULT_LABEL_VALUE: 'Total value of sales and all other outputs excluding any VAT',
    },
    BOX7: {
        TYPE: 7,
        DEFAULT_LABEL_VALUE: 'Total value of purchases and all other inputs excluding any VAT',
    },
    BOX8: {
        TYPE: 8,
        DEFAULT_LABEL_VALUE: 'Total value of dispatches of goods and related costs (excluding VAT) from Northern Ireland to EU Member States',
    },
    BOX9: {
        TYPE: 9,
        DEFAULT_LABEL_VALUE: 'Total value of acquisitions of goods and related costs (excluding VAT) made in Northern Ireland from EU Member States',
    },
};

const RECORD_TYPES = {
    OBLIGATIONS: 'obligations',
    LIABILITIES: 'liabilities',
    PAYMENTS: 'payments',
    RETURNS: 'returns',
};

const AMOUNT_TYPES = {
    NET_AMOUNT: 1,
    VAT_AMOUNT: 2,
    GROSS_AMOUNT: 3,
    NET_AMOUNT_BY_STANDARD_RATE: 4,
    NET_AMOUNT_BY_REDUCED_RATE: 5,
    NET_AMOUNT_BY_REVERSE_STANDARD_RATE: 6,
    NET_AMOUNT_BY_REVERSE_REDUCED_RATE: 7,
};

export default {
    RECORD_TYPES,

    VAT_RETURN_BOXES,

    AMOUNT_TYPES,

    SETTINGS: {
        CUT_OFF_OPTIONS: {
            LAST_MONTH: 1,
            CUSTOM: 2,
        },
    },

    SCHEMES: {
        STANDARD: 'standard',
        CASH_ACCOUNTING: 'cashAccounting',
    },

    HISTORY_ITEM_TYPES: {
        VAT_RETURN_DUE: 1,
        VAT_RETURN_MADE: 2,
        PAYMENT_DUE: 3,
        PAYMENT_MADE: 4,
    },

    SCREEN_BUTTONS: {
        PROCEED: 'Proceed',
        CANCEL: 'Cancel',
    },

    VAT_PERIOD_TYPES: {
        MONTHLY: 1,
        QUARTERLY: 2,
        YEARLY: 3,
    },

    VAT_ACTIONS: {
        CALCULATE: 1,
        SUBMIT: 2,
        VALIDATE_VAT_NUMBER: 3,
        UPDATE_VAT_STATUS: 4,
    },

    VAT_ACTION_RESULT: {
        OK: 1,
        ERROR: 2,
    },

    VAT_ACTION_STATUS: {
        OUTSTANDING: 1,
        IN_PROGRESS: 2,
        DONE: 3,
    },

    HMRC_API: {
        OUTSTANDING: 'O',
        FULFILLED: 'F',
        RESULT: {
            OK: 1,
            CREDENTIALS_EXPIRED: 2,
            SERVICE_FAILURE: 3,
            INVALID_VAT_NUMBER: 4,
        },
        PERIOD_CONSTANTS: {
            MONTH: 31,
            QUARTER: 93,
        },
        CONNECTION_STATUS: {
            CONNECTED: { ID: 1, BUTTON: { PROMPT_ID: 'MTDSettings.DisconnectFromMTDButton', DEFAULT: 'Disconnect from MTD' } },
            DISCONNECTED: { ID: 2, BUTTON: { PROMPT_ID: 'MTDSettings.ConnectToMTDButton', DEFAULT: 'Connect to MTD' } },
            EXPIRED: { ID: 4, BUTTON: { PROMPT_ID: 'MTDSettings.ReConnectToMTDButton', DEFAULT: 'Re-connect to MTD' } },
            CONNECTING: { ID: 4, BUTTON: { PROMPT_ID: 'MTDSettings.ConnectToMTDButton', DEFAULT: 'Connect to MTD' } },
        },
        ERROR_CODES: {
            CLIENT_OR_AGENT_NOT_AUTHORISED_PATH: 'response.data.code',
            CLIENT_OR_AGENT_NOT_AUTHORISED: 'CLIENT_OR_AGENT_NOT_AUTHORISED',
        },
        SILENT_ERROR_CODES: {
            NOT_FOUND: 'NOT_FOUND',
            NOT_FOUND_STATUS_CODE: 404,
        },
    },

    TAX_RATES: {
        STANDARD: 1,
        REDUCED: 2,
        EXEMPT: 3,
        ZERO_RATED: 4,
        REVERSE: 115,
        OUTSIDE_SCOPE: 116,
        NO_C79: 117,
        REVERSE_STANDARD: 208,
        REVERSE_REDUCED: 209,
    },

    CALCULATION_TYPES: {
        ROW1: {
            TYPE: 1,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW2: {
            TYPE: 2,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW3: {
            TYPE: 3,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW4: {
            TYPE: 4,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW5: {
            TYPE: 5,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX4.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW6: {
            TYPE: 6,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX4.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW7: {
            TYPE: 7,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX4.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW8: {
            TYPE: 8,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX4.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW9: {
            TYPE: 9,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW10: {
            TYPE: 10,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX8.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW11: {
            TYPE: 11,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW12: {
            TYPE: 12,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW13: {
            TYPE: 13,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW14: {
            TYPE: 14,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW15: {
            TYPE: 15,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW16: {
            TYPE: 16,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW17: {
            TYPE: 17,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW18: {
            TYPE: 18,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW19: {
            TYPE: 19,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW20: {
            TYPE: 20,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX2.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT_BY_STANDARD_RATE,
            }, {
                boxType: VAT_RETURN_BOXES.BOX4.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT_BY_STANDARD_RATE,
            }, {
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX9.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW21: {
            TYPE: 21,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT_BY_STANDARD_RATE,
            }, {
                boxType: VAT_RETURN_BOXES.BOX4.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT_BY_STANDARD_RATE,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW22: {
            TYPE: 22,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.GROSS_AMOUNT,
            }],
        },
        ROW23: {
            TYPE: 23,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.GROSS_AMOUNT,
            }],
        },
        ROW24: {
            TYPE: 24,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.GROSS_AMOUNT,
            }],
        },
        ROW25: {
            TYPE: 25,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.GROSS_AMOUNT,
            }],
        },
        ROW26: {
            TYPE: 26,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.GROSS_AMOUNT,
            }],
        },
        ROW27: {
            TYPE: 27,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.GROSS_AMOUNT,
            }],
        },
        ROW28: {
            TYPE: 28,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.GROSS_AMOUNT,
            }],
        },
        ROW29: {
            TYPE: 29,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.GROSS_AMOUNT,
            }],
        },
        ROW30: {
            TYPE: 30,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW31: {
            TYPE: 31,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX4.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW32: {
            TYPE: 32,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX4.TYPE,
                amountType: AMOUNT_TYPES.VAT_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW33: {
            TYPE: 33,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW34: {
            TYPE: 34,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.GROSS_AMOUNT,
            }],
        },
        ROW35: {
            TYPE: 35,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT_BY_STANDARD_RATE,
            }, {
                boxType: VAT_RETURN_BOXES.BOX4.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT_BY_STANDARD_RATE,
            }, {
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }, {
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW36: {
            TYPE: 36,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT_BY_REVERSE_STANDARD_RATE,
            }, {
                boxType: VAT_RETURN_BOXES.BOX4.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT_BY_REVERSE_STANDARD_RATE,
            }, {
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW37: {
            TYPE: 37,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX1.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT_BY_REVERSE_REDUCED_RATE,
            }, {
                boxType: VAT_RETURN_BOXES.BOX4.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT_BY_REVERSE_REDUCED_RATE,
            }, {
                boxType: VAT_RETURN_BOXES.BOX7.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW38: {
            TYPE: 38,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
        ROW39: {
            TYPE: 39,
            RULES: [{
                boxType: VAT_RETURN_BOXES.BOX6.TYPE,
                amountType: AMOUNT_TYPES.NET_AMOUNT,
            }],
        },
    },
};
