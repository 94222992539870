export default {
    PRECISION_MULTIPLIER: 10000,
    PRICE_PRECISION_MULTIPLIER: 100,

    EVENTS: {
        LINE_RECALCULATED: 'lineRecalculated',
        GROUP_RECALCULATED: 'groupRecalculated',
        DOCUMENT_RECALCULATED: 'documentRecalculated',
    },
};
