const modelProperties = {
    modelName: 'TimeLog',
    tableName: 'TimeLog',
    associations: [{
        type: 'belongsTo',
        model: 'User',
        parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, {
        type: 'belongsTo',
        model: 'Job',
        parameters: { as: 'Job', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
    }, {
        type: 'belongsTo',
        model: 'Action',
        parameters: { as: 'Action', foreignKey: { name: 'action_id', fieldName: 'action_id' } },
    }],

    fields: [
        {
            name: 'id',
            type: 'int',
            primaryKey: true,
        },
        {
            name: 'dt_created',
            type: 'string',
            dbType: 'date',
        },
        {
            name: 'company_id',
            serverOnly: true,
            primaryKey: true,
            type: 'int',
        },
        {
            name: 'job_id',
            type: 'int',
        },
        {
            name: 'action_id',
            type: 'int',
        },
        {
            name: 'user_id',
            type: 'int',
        },
        {
            name: 'checkin_id',
            type: 'int',
        },
        {
            name: 'time',
            type: 'int',
        },
        {
            name: 'isdeleted',
            type: 'boolean',
            codeName: 'isDeleted',
            allowNull: false,
            defaultValue: false,
            serverOnly: true,
        },
        {
            name: 'is_archived',
            type: 'boolean',
            codeName: 'isArchived',
            serverOnly: true,
        },
    ],
};

export default modelProperties;
