import User from 'modules/complexData/user';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import UserMediaEntity from './entity';
import modelProperties from './modelProperties';
import UserEntity from '../user/entity';

interface UserMediaAssociations extends AssociationConfig<any, any> {
    user: AssociationDefinitionSingle<UserEntity, User>
}

interface UserMedia extends AutoGeneratedFunctions<UserMediaAssociations, UserMediaEntity, ComplexData<UserMediaEntity>> {}

// eslint-disable-next-line no-redeclare
class UserMedia extends ComplexData<UserMediaEntity> {
    static Entity = UserMediaEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): UserMediaAssociations {
        return {
            user: {
                instance: User,
                entity: UserEntity,
                key: 'user',
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().user_id,
                },
            },
        };
    }
}

export default UserMedia;
