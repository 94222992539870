import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import BookingDateExceptionEntity from './entity';
import modelProperties from './modelProperties';
import AppointmentType from '../appointmentType';
import AppointmentTypeEntity from '../appointmentType/entity';

interface BookingDateExceptionAssociations extends AssociationConfig<any, any> {
    appointmentType: AssociationDefinitionSingle<AppointmentTypeEntity, AppointmentType>
}

interface BookingDateException extends AutoGeneratedFunctions<BookingDateExceptionAssociations, BookingDateExceptionEntity, ComplexData<BookingDateExceptionEntity>> {}

// eslint-disable-next-line no-redeclare
class BookingDateException extends ComplexData<BookingDateExceptionEntity> {
    static Entity = BookingDateExceptionEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): BookingDateExceptionAssociations {
        return {
            appointmentType: {
                key: 'appointmentType',
                instance: AppointmentType,
                entity: AppointmentTypeEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().appointmentTypeId,
                },
            },
        };
    }
}

export default BookingDateException;
