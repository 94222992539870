import type { ComplexDataType } from './complexData';

declare type AssociationId = number | symbol;

export default class AssociationsStorage {
    private storage = {};

    public get(name: string, id: AssociationId): Set<ComplexDataType<any>> {
        if (!this.areArgumentsCorrect(name, id) || !this.isContained(name, id)) {
            return new Set();
        }

        return this.storage[name][id];
    }

    public add(item: ComplexDataType<any>, name: string, id: AssociationId) {
        if (!this.areArgumentsCorrect(name, id)) {
            this.throwError(name, id);
        }
        if (!this.storage[name]) {
            this.storage[name] = {};
        }
        if (!this.storage[name][id]) {
            this.storage[name][id] = new Set();
        }

        this.storage[name][id].add(item);
    }

    public delete(associatedItem: ComplexDataType<any>, name: string, id: AssociationId) {
        if (this.storage[name] && this.storage[name][id]) {
            this.storage[name][id].delete(associatedItem);
        }
    }

    public updateId(modelName: string, oldId: AssociationId, newId: AssociationId) {
        if (oldId === null) {
            throw Error('Error: no initial id');
        } else if (this.storage[modelName] && this.storage[modelName][oldId]) {
            const set = this.storage[modelName][oldId];
            this.storage[modelName][oldId] = null;
            this.storage[modelName][newId] = set;
        }
    }

    private areArgumentsCorrect(name, id): boolean {
        return ![name, id].some(element => element === null || typeof element === 'undefined');
    }

    private isContained(name: string, id: AssociationId): boolean {
        return Boolean(this.storage[name] && this.storage[name][id]);
    }

    private throwError(name, id) {
        const error = new Error('Error: no valid data given.');
        throw Object.assign(error, {
            details: {
                name,
                id,
            },
        });
    }
}
