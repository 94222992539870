export default {
    TYPE: {
        MONTH: {
            queryFormat: '%Y-%m',
            displayFormat: 'YYYY-MM',
            dateFormat: 'Y-m',
            localFormat: 'yearmonth',
            name: 'months',
        },
        YEAR: {
            queryFormat: '%Y',
            displayFormat: 'YYYY',
            dateFormat: 'Y',
            localFormat: 'year',
            name: 'years',
        },
    },
    TABS: {
        SUB: 'subscription',
        CANCELLED: 'cancelled',
        NET: 'net',
    },
    CANCELLED: {
        FIRST_CANCELLATIONS: 0,
        CHURNS: 1,
    },
};
