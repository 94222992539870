export default {
    USER_COLOR: 1,
    DEFAULT_APPOINTMENT_LENGTH: 2,
    PROJECT_NUMBERING_CONFIG: 3,
    SERVICE_PERSON: 4,
    PHONE_NUMBER: 5,
    RATES: 6,
    AVAILABILITY_APPOINTMENTS: 7,
    APPOINTMENTS_DAILY_SCHEDULE: 8,
    CUSTOMER_APPOINTMENT_NOTIFICATION_BYEMAIL: 9,
    CUSTOMER_APPOINTMENT_NOTIFICATION_BYTEXT: 10,
    CUSTOMER_APPOINTMENT_NOTIFICATION_BY_WHATSAPP: 35,

    CUSTOMER_APPOINTMENT_NOTIFICATION_ATTENDINGNOW: 11,
    LUNCH_TIME: 12,
    EVENING_HOURS: 13,
    NAVIGATION_APP: 14,
    BOOK_APPOINTMENT_DEFAULT_TEXT: 15,
    BOOK_APPOINTMENT_DISPLAY_PRICE: 16,
    EVENT_TEMPLATE: 17,
    DIARY_SNAP_TIME: 18,
    PROXY_TYPE: 19,
    USE_WHATSAPP: 20,
    BOOK_APPOINTMENT_PLAN_TYPE: 21,
    HMRC_TEST_ENABLED: 22,

    NOTIFY_ON_QUOTE_STATUS: 23,
    NOTIFY_ON_PAYMENT: 24,
    NOTIFY_ON_APPOINTMENT: 25,
    NOTIFY_ON_EMAIL_OPEN: 26,
    NOTIFY_ON_CHAT_MESSAGE: 27,
    NOTIFY_ON_TEAM_LOGIN: 28,
    NOTIFY_ON_NEW_MESSAGE: 29,
    NOTIFY_ON_NEW_BOOKING: 30,
    NOTIFY_ON_APPOINTMENT_ASSIGNMENT: 31,
    NOTIFY_ON_FORM_EXPIRATION: 49,

    NOTIFY_BY_EMAIL: 32,
    NOTIFY_BY_PUSH: 33,
    NOTIFY_COMPANY_EMAIL: 34,
    NOTIFY_BY_PUSH_ONLY_IN_APP: 48,

    CUSTOMER_NOTIFICATION_CONTACT: 36,

    CUSTOMER_APPOINTMENT_NOTIFICATION_CREATED: 37,
    CUSTOMER_APPOINTMENT_NOTIFICATION_UPDATED: 38,
    CUSTOMER_APPOINTMENT_NOTIFICATION_DELETED: 39,

    NOTIFY_ON_APPOINTMENT_ASSIGNMENT_TO_TEAM_MEMBER: 40,
    EVENT_COLOR_VARIANT: 41,
    CUSTOM_EVENT_TEMPLATES: 42,
    AUTO_ALLOCATE_USER_TO_APPOINTMENT: 43,

    NOTIFY_ON_APPOINTMENT_DEASSIGNMENT: 44,
    DEFAULT_APPOINTMENT_DURATION_ID: 45,

    APPOINTMENT_CHECKIN_NOTIFICATIONS: 46,

    DISPLAY_PROJECTS_ON_PORTAL: 47,
    CAN_SITE_CONTACTS_ACCESS_FULL_PORTAL: 53,

    STARTING_PAGE: 50,
    RENEWABLE_ENERGY_FORMS_ENABLED: 51,
    SHOW_SPAN_EVENT_TIME_ON_MONTHLY_VIEW: 52,

    DISPLAY_ASSIGNEE_PHONE_NUMBER_ON_PORTAL: 54,
    DISPLAY_COMPANY_PHONE_NUMBER_ON_PORTAL: 55,

    MAX_EMAIL_LIMIT_PER_MONTH: 56,
};
