const modelProperties = {
    modelName: 'User',
    tableName: 'User',
    associations: [{
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        cascadeDelete: false,
        type: 'hasMany',
        model: 'Document',
        parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, {
        cascadeDelete: false,
        type: 'hasMany',
        model: 'Signature',
        parameters: { as: 'Signatures', foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, {
        cascadeDelete: false,
        type: 'belongsTo',
        model: 'Signature',
        parameters: { as: 'Signature', foreignKey: { name: 'signature_id', fieldName: 'signature_id' } },
    }, {
        cascadeDelete: false,
        type: 'hasMany',
        model: 'TimeLog',
        parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, {
        cascadeDelete: false,
        type: 'hasMany',
        model: 'DocumentAction',
        parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, {
        serverOnly: true,
        type: 'hasMany',
        model: 'UserDevice',
        parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, {
        type: 'hasMany',
        model: 'AuditTrail',
        parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, {
        type: 'hasMany',
        model: 'UserAction',
        parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, {
        type: 'belongsToMany',
        model: 'UserProfile',
        cascadeDelete: false,
        parameters: {
            through: { model: 'UserUserProfile', unique: false },
            foreignKey: { name: 'user_id', fieldName: 'user_id' },
        },
    }, {
        type: 'hasMany',
        model: 'UserUserProfile',
        parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
        includeRestores: ['User'],
    }, {
        type: 'hasMany',
        model: 'UserToAddress',
        cascadeDelete: true,
        parameters: { as: 'UserToAddress', foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, {
        cascadeDelete: true,
        type: 'hasMany',
        model: 'UserMedia',
        parameters: { as: 'UserMedia', foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, {
        cascadeDelete: true,
        type: 'hasMany',
        model: 'UserSettings',
        parameters: { as: 'UserSettings', foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }],
    validations: {
        email: [{ type: 'email' }, { type: 'length', min: 1, max: 80 }],
        firstname: { type: 'length', min: 1, max: 60 },
        lastname: { type: 'length', min: 1, max: 60 },
    },
};

export default modelProperties;
