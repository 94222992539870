/// <reference path="index.d.ts" />

import * as Bluebird from 'bluebird';
import { TYPES_Type as PAYMENT_TYPES_Type } from '@powerednow/shared/constants/payment';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import Customer from '../customer';
import Payment from '../payment';
import Job from '../job';
import ContactToAddress from '../contactToAddress';
import PaymentToInvoice from '../paymentToInvoice';
import { DataObject } from '../entity';
import DocumentEntity from './entity';
import modelProperties from './modelProperties';
import DocumentItem from '../documentItem';
import JobItemGroup from '../jobItemGroup';
import Company from '../company';
import JobItemGroupEntity from '../jobItemGroup/entity';
import JobItem from '../jobItem';
import JobEntity from '../job/entity';
import ContactToAddressEntity from '../contactToAddress/entity';
import CustomerEntity from '../customer/entity';
import DocumentItemEntity from '../documentItem/entity';
import PaymentToInvoiceEntity from '../paymentToInvoice/entity';
import CompanyEntity from '../company/entity';
import Message from '../message';
import MessageEntity from '../message/entity';
import { CustomerMessageLinkedRecord } from '../interfaces';
import CustomerMessageEntity from '../customerMessage/entity';
import CustomerMessage from '../customerMessage';
import { DOCUMENT } from '../../../constants';
import { StatusStringDefinition } from '../../../constants/document';

const CONSTANTS = require('@powerednow/shared/constants').default;
const mathUtils = require('@powerednow/shared/modules/utilities/math');

export type AlreadyPaidAmountType = {
    alreadyPaid: number,
    alreadyPaidCIS: number,
    alreadyPaidAmount: number,
}

export type TotalObject = {
    quoted?: number,
    accepted?: number,
    invoiced?: number,
    invoiced_exVat?: number,
    supplier_invoiced?: number,
    supplier_invoiced_exVat?: number,
    invoice_payments: number,
    supplier_invoice_payments?: number,
    projected_profit?: number,
    projected_profit_exVat?: number,
    outstanding_invoice_payments?: number,
    outstanding_supplier_invoice_payments?: number,
};

export type DocumentSummaryData = {
    showTotal?: boolean,
    groupTitles?: string[],
    itemDescriptions?: string[][]
};

interface DocumentAssociations extends AssociationConfig<any, any> {
    job: AssociationDefinitionSingle<JobEntity, Job>
    customer: AssociationDefinitionSingle<CustomerEntity, Customer>
    documentItem: AssociationDefinition<DocumentItemEntity, DocumentItem>
    paymentToInvoice: AssociationDefinition<PaymentToInvoiceEntity, PaymentToInvoice>
    childDocument: AssociationDefinition<DocumentEntity, Document>
    company: AssociationDefinitionSingle<CompanyEntity, Company>
    message: AssociationDefinition<MessageEntity, Message>
    customerMessage: AssociationDefinition<CustomerMessageEntity, CustomerMessage>
    site: AssociationDefinitionSingle<ContactToAddressEntity, ContactToAddress>
}

interface Document extends AutoGeneratedFunctions<DocumentAssociations, DocumentEntity, ComplexData<DocumentEntity>> {
}

// eslint-disable-next-line no-redeclare
class Document extends ComplexData<DocumentEntity> implements CustomerMessageLinkedRecord {
    static Entity = DocumentEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): DocumentAssociations {
        return {
            job: {
                key: 'job',
                instance: Job,
                entity: JobEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().job_id,
                },
            },
            site: {
                key: 'contactToAddress',
                instance: ContactToAddress,
                entity: ContactToAddressEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().site_id,
                },
            },
            customer: {
                key: 'customer',
                instance: Customer,
                entity: CustomerEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().customer_id,
                },
            },
            documentItem: {
                key: 'documentItem',
                instance: DocumentItem,
                entity: DocumentItemEntity,
                cascadeDelete: true,
                condition: {
                    document_id: this.Entity.getFieldSymbols().id,
                },
            },
            paymentToInvoice: {
                key: 'paymentToInvoice',
                instance: PaymentToInvoice,
                entity: PaymentToInvoiceEntity,
                cascadeDelete: true,
                condition: {
                    document_id: this.Entity.getFieldSymbols().id,
                },
            },
            childDocument: {
                key: 'document',
                instance: Document,
                entity: DocumentEntity,
                cascadeDelete: false,
                condition: {
                    parent_document_id: this.Entity.getFieldSymbols().id,
                },
            },
            company: {
                instance: Company,
                entity: CompanyEntity,
                key: 'company',
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getFieldSymbols().company_id,
                },
            },
            message: {
                key: 'message',
                instance: Message,
                entity: MessageEntity,
                cascadeDelete: true,
                condition: {
                    document_id: this.Entity.getFieldSymbols().id,
                },
            },
            customerMessage: {
                key: 'customerMessage',
                instance: CustomerMessage,
                entity: CustomerMessageEntity,
                cascadeDelete: true,
                condition: {
                    linked_id: this.Entity.getFieldSymbols().id,
                    linked_type: CONSTANTS.MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.DOCUMENT,
                },
            },
        };
    }

    public static getStatusStringByTypeAndStatus(type: number, status: number): StatusStringDefinition {
        const isExpense = type === DOCUMENT.TYPES_VALUES.EXPENSE || type === CONSTANTS.DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE;
        const isSupplierInvoice = type === DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE;
        switch (status) {
            case DOCUMENT.STATUS.RAISED:
                if (isExpense || isSupplierInvoice) {
                    return DOCUMENT.STATUS_STRING.UNAPPROVED;
                }
                return DOCUMENT.STATUS_STRING.RAISED;
            case DOCUMENT.STATUS.SENT:
                if (type === DOCUMENT.TYPES_VALUES.INVOICE) {
                    //
                    // In case of invoice "pending payment'
                    //
                    return DOCUMENT.STATUS_STRING.PENDING_PAYMENT;
                }
                //
                // All other cases 'sent'
                //
                return DOCUMENT.STATUS_STRING.QUOTE_SENT;
            case DOCUMENT.STATUS.ACCEPTED:
                if (isExpense || isSupplierInvoice) {
                    return DOCUMENT.STATUS_STRING.EXPENSE_ACCEPTED;
                }
                return DOCUMENT.STATUS_STRING.ACCEPTED;
            case DOCUMENT.STATUS.REJECTED:
                if (isExpense || isSupplierInvoice) {
                    return DOCUMENT.STATUS_STRING.EXPENSE_REJECTED;
                }
                return DOCUMENT.STATUS_STRING.REJECTED;
            case DOCUMENT.STATUS.PAID:
                if (type === DOCUMENT.TYPES_VALUES.CREDITNOTE) {
                    return DOCUMENT.STATUS_STRING.RAISED;
                }
                return DOCUMENT.STATUS_STRING.PAID;
            case DOCUMENT.STATUS.PART_PAID:
                return DOCUMENT.STATUS_STRING.PARTIALLY_PAID;
            case DOCUMENT.STATUS.OVER_PAID:
                return DOCUMENT.STATUS_STRING.OVER_PAID;
            default:
                return { promptId: '', defaultValue: '' };
        }
    }

    public getStatusStringPrompt(): StatusStringDefinition {
        return Document.getStatusStringByTypeAndStatus(this.data.type, this.data.status);
    }

    public getDocumentGroupTypeId(): number {
        const documentGroupTypes = CONSTANTS.DOCUMENT.DOCUMENT_GROUP_TYPE;
        return documentGroupTypes[this.data.type];
    }

    public getDocumentPaymentCategory() {
        return CONSTANTS.DOCUMENT.DOCUMENT_TYPE_TO_DOCUMENT_PAYMENT_CATEGORY[this.data.type];
    }

    public async getDefaultSite() {
        const customer = await this.getCustomer();

        switch (this.data.type) {
            case CONSTANTS.DOCUMENT.TYPES_VALUES.INVOICE:
            case CONSTANTS.DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE:
            case CONSTANTS.DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE_CREDIT:
                return (await customer.getInvoiceSite()) || customer.getMainSite();
            case CONSTANTS.DOCUMENT.TYPES_VALUES.QUOTE:
            case CONSTANTS.DOCUMENT.TYPES_VALUES.WORKSHEET:
            case CONSTANTS.DOCUMENT.TYPES_VALUES.SIGNATURE:
            case CONSTANTS.DOCUMENT.TYPES_VALUES.CREDITNOTE:
            case CONSTANTS.DOCUMENT.TYPES_VALUES.EXPENSE:
            case CONSTANTS.DOCUMENT.TYPES_VALUES.EXPENSE_CREDITNOTE:
            case CONSTANTS.DOCUMENT.TYPES_VALUES.PURCHASE_ORDER:
                return customer.getMainSite();
            default:
                return null;
        }
    }

    /**
     * Return the name appearing on the invoice what is the company name if specified otherwise the
     * customer name
     */
    public async getInvoiceName(): Promise<string> {
        const customerObject = await this.getCustomer();
        return customerObject.getFullName(false, true);
    }

    public async recordPayment(paymentValues: DataObject, cisAmount: number = 0, creditNoteId: number = null): Promise<{
        complexPayment: Payment,
        complexCisPayment: Payment,
    }> {
        let complexCisPayment;
        if (cisAmount && cisAmount !== 0) {
            complexCisPayment = await this.createPayment({
                ...paymentValues,
                amount: cisAmount,
                is_cis: true,
                type: CONSTANTS.PAYMENT.TYPES.CIS,
            }, creditNoteId);
        }
        const complexPayment = await this.createPayment({
            ...paymentValues,
            cis_payment_id: complexCisPayment ? complexCisPayment.data.id : null,
        }, creditNoteId);
        const jobInstance = await this.getJob();
        await jobInstance.addPayment(complexPayment);
        await jobInstance.updateTotals();
        await this.setStatus();

        return {
            complexPayment,
            complexCisPayment,
        };
    }

    private async createPayment(paymentValues: DataObject, creditNoteId: number = null): Promise<Payment> {
        const paymentToInvoice = await this.addPaymentToInvoice({
            amount: paymentValues.amount,
            credit_note_id: creditNoteId,
        });
        return paymentToInvoice.setPayment(paymentValues);
    }

    public async hasPaymentWithType(paymentTypeId: number): Promise<boolean> {
        return (await this.getPayments()).some(payment => payment.data.type === paymentTypeId);
    }

    public async getPayments(): Promise<Payment[]> {
        return Bluebird.reduce(this.getAllPaymentToInvoice(), async (paymentItems, paymentToInvoice) => {
            const complexPayment = await (paymentToInvoice as any).getPayment();
            //
            // Some cases we may have paymentToInvoce record but not Payment record
            // We should not crash in that case
            //
            if (complexPayment) {
                paymentItems.push(complexPayment);
            }
            return paymentItems;
        }, []);
    }

    public async getAlreadyPaidAmount(excludeRecords = []): Promise<AlreadyPaidAmountType> {
        let alreadyPaid = 0;
        let alreadyPaidCIS = 0;
        let alreadyPaidAmount = 0;
        (await this.getPayments())
            .filter(filterRec => excludeRecords.every(record => record.data.id !== filterRec.data.id))
            .forEach(paymentRec => {
                if (paymentRec.data.type === CONSTANTS.PAYMENT.TYPES.CIS) {
                    alreadyPaidCIS += paymentRec.data.amount;
                } else {
                    alreadyPaidAmount += paymentRec.data.amount;
                }
                alreadyPaid += paymentRec.data.amount;
            });
        //
        // reverse if expense
        //
        if (this.data.type === CONSTANTS.DOCUMENT.TYPES_VALUES.EXPENSE || this.data.type === CONSTANTS.DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE) {
            alreadyPaid = -alreadyPaid;
            alreadyPaidCIS = -alreadyPaidCIS;
            alreadyPaidAmount = -alreadyPaidAmount;
        }

        return {
            alreadyPaid,
            alreadyPaidCIS,
            alreadyPaidAmount,
        };
    }

    public async getOutstandingAmount(): Promise<any> {
        const documentTotal = mathUtils.roundPrice(this.data.total);
        const creditedTotal = await this.getTotalCredited();
        const cisTotal = mathUtils.roundPrice(this.data.cis_total);
        const paidValues = await this.getAlreadyPaidAmount();
        const outstanding = mathUtils.roundPrice(documentTotal + creditedTotal - paidValues.alreadyPaid);
        const outstandingCIS = mathUtils.roundPrice(cisTotal - paidValues.alreadyPaidCIS);
        const outstandingAmount = mathUtils.roundPrice(outstanding - outstandingCIS);

        return {
            outstanding,
            outstandingCIS,
            outstandingAmount,
        };
    }

    public async getTotalPaid(): Promise<number> {
        return Bluebird.reduce(this.getPayments(), (currentTotal, paymentItem) => (paymentItem ? currentTotal + paymentItem.data.amount : currentTotal), 0);
    }

    public async getCreditNotes(): Promise<Document[]> {
        return (await this.getAllChildDocument()).filter(document => document.isOneOfCreditNotes());
    }

    getTotalCredited(): Promise<number> {
        return Bluebird.reduce(this.getCreditNotes(), (currentTotal, documentItem: Document) => currentTotal + documentItem.data.total, 0);
    }

    private async getTotals(): Promise<{ totalPayable: number, totalPaid: number, totalCredited: number, hasPayment: boolean }> {
        const totalPaid = await this.getTotalPaid();
        const totalCredited = await this.getTotalCredited();
        const totalPayable = (this.data.total + totalCredited) * (this.isCost() ? -1 : 1);
        const allPayments = await this.getPayments();
        return {
            totalPayable: Math.round(totalPayable * 100) / 100,
            totalPaid: Math.round(totalPaid * 100) / 100,
            totalCredited: Math.round(totalCredited * 100) / 100,
            hasPayment: allPayments && allPayments.length > 0,
        };
    }

    public async getPaymentsOfSameType(paymentItem: Payment): Promise<Payment[]> {
        const siblingPayments = await this.getPayments();
        return siblingPayments.filter(payment => payment.data.type === paymentItem.data.type);
    }

    public async isRefundedPayment(paymentItem: Payment): Promise<boolean> {
        const sameTypePayments = await this.getPaymentsOfSameType(paymentItem);
        return sameTypePayments.some(payment => payment.data.is_refund && payment.data.reference === paymentItem.data.reference);
    }

    public isCost(): boolean {
        return (this.data.type === CONSTANTS.DOCUMENT.TYPES_VALUES.EXPENSE
            || this.data.type === CONSTANTS.DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE);
    }

    public async setStatus(previousStatus: number = null, hadPayment: boolean = false, hadCreditNote: boolean = false) {
        const {
            totalPayable, totalPaid, totalCredited, hasPayment, 
        } = await this.getTotals();
        const absTotalPayable = Math.abs(totalPayable);
        const absTotalPaid = Math.abs(totalPaid);
        if (this.data.type === CONSTANTS.DOCUMENT.TYPES_VALUES.CREDITNOTE) { // processed credit notes are always paid.
            this.data.status = CONSTANTS.DOCUMENT.STATUS.PAID;
        }

        if (!hasPayment && !hadPayment && totalCredited === 0 && !hadCreditNote) {
            return;
        }
        if (absTotalPayable === absTotalPaid) {
            this.data.status = CONSTANTS.DOCUMENT.STATUS.PAID;
        } else if (absTotalPayable > absTotalPaid) {
            if (mathUtils.nearlyEqual(absTotalPaid, 0) && totalCredited === 0) {
                if (this.isCost()) {
                    if (previousStatus !== null) {
                        this.data.status = previousStatus;
                    } else {
                        this.data.status = CONSTANTS.DOCUMENT.STATUS.ACCEPTED;
                    }
                } else {
                    this.data.status = CONSTANTS.DOCUMENT.STATUS.RAISED;
                }
            } else {
                this.data.status = CONSTANTS.DOCUMENT.STATUS.PART_PAID;
            }
        } else {
            this.data.status = CONSTANTS.DOCUMENT.STATUS.OVER_PAID;
        }
    }

    /**
     * tells if document is one of the creditnote type
     *
     * @returns {boolean}
     */
    isOneOfCreditNotes() {
        const { data } = this;
        return CONSTANTS.DOCUMENT.CREDIT_NOTE_TYPES.includes(data.type);
    }

    private async getInvoiceTotals(): Promise<DataObject> {
        const prefixAndSeparator = [CONSTANTS.DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE,
            CONSTANTS.DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE_CREDIT].includes(this.data.type) ? 'supplier_' : '';
        return {
            [`${prefixAndSeparator}invoiced`]: mathUtils.roundPrice(this.data.total),
            [`${prefixAndSeparator}invoiced_exVat`]: mathUtils.roundPrice((this.data.total === this.data.subtotal) ? this.data.total - this.data.vat : this.data.subtotal),
            [`${prefixAndSeparator}invoice_payments`]: mathUtils.roundPrice(await this.getTotalPaid()),
        };
    }

    private async getQuoteTotals(): Promise<DataObject> {
        return {
            quoted: mathUtils.roundPrice(this.data.total),
            accepted: mathUtils.roundPrice(this.data.accepted),
        };
    }

    public async getDocumentTotals(): Promise<TotalObject> {
        const getTotalFunction = {
            [CONSTANTS.DOCUMENT.TYPES_VALUES.INVOICE]: this.getInvoiceTotals.bind(this),
            [CONSTANTS.DOCUMENT.TYPES_VALUES.CREDITNOTE]: this.getInvoiceTotals.bind(this),
            [CONSTANTS.DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE]: this.getInvoiceTotals.bind(this),
            [CONSTANTS.DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE_CREDIT]: this.getInvoiceTotals.bind(this),
            [CONSTANTS.DOCUMENT.TYPES_VALUES.QUOTE]: this.getQuoteTotals.bind(this),
        }[this.data.type];

        return getTotalFunction ? getTotalFunction() : {};
    }

    public async paymentCanBeRecorded(paymentTypeId: PAYMENT_TYPES_Type, isRefund: boolean, isInitiatedByUser: boolean = false): Promise<boolean> {
        if (this.data.isdeleted) {
            return false;
        }

        const documentPaymentCategory = this.getDocumentPaymentCategory();
        if (!documentPaymentCategory) {
            return false;
        }

        const company = await this.getCompany();
        const documentType = this.data.type;
        const countryId = await Company.getCompanyCountryId(this);
        const paymentSetting = await company.getPaymentSettingByPaymentTypeId(paymentTypeId);
        const isPaid = await this.isPaid();

        if (!paymentSetting || !(await paymentSetting.isEnabledForDocumentPaymentCategory(documentPaymentCategory))) {
            return false;
        }

        const totalOverAvailabilityLimit = paymentSetting.data.availability_limit_enabled && paymentSetting.data.availability_limit <= this.data.total;
        const paymentType = await paymentSetting.getPaymentType();

        if (!await paymentType.isValidForCountry(countryId)) {
            return false;
        }

        if (!await paymentType.enabledForDocumentType(this.data.type)) {
            return false;
        }

        if (paymentTypeId === CONSTANTS.PAYMENT.TYPES.PAYPAL_STANDARD) {
            if (!isRefund && (isPaid || documentType !== CONSTANTS.DOCUMENT.TYPES_VALUES.INVOICE)) {
                return false;
            }
        }

        if (paymentTypeId === CONSTANTS.PAYMENT.TYPES.SUMUP) {
            if (totalOverAvailabilityLimit) {
                return false;
            }
            if (!isRefund && (isPaid || documentType !== CONSTANTS.DOCUMENT.TYPES_VALUES.INVOICE)) {
                return false;
            }
            if (isRefund && !await this.hasPaymentWithType(CONSTANTS.PAYMENT.TYPES.SUMUP)) {
                return false;
            }
        }

        if (paymentTypeId === CONSTANTS.PAYMENT.TYPES.SUMUP_ONLINE) {
            if (totalOverAvailabilityLimit) {
                return false;
            }
            if (!isRefund && !isInitiatedByUser) {
                return false;
            }
            if (!isRefund && (isPaid || documentType !== CONSTANTS.DOCUMENT.TYPES_VALUES.INVOICE)) {
                return false;
            }
            if (isRefund && !await this.hasPaymentWithType(CONSTANTS.PAYMENT.TYPES.SUMUP_ONLINE)) {
                return false;
            }
        }

        return true;
    }

    public async isPaid() {
        const documentStatus = this.data.status;
        const outstandingAmount = await this.getOutstandingAmount();
        return [CONSTANTS.DOCUMENT.STATUS.PAID, CONSTANTS.DOCUMENT.STATUS.OVER_PAID].includes(documentStatus) || outstandingAmount.outstandingAmount <= 0;
    }

    public async getPaymentTitleText() {
        const customerName = await this.getInvoiceName();
        return this.data.displaynumber === CONSTANTS.DOCUMENT.DEFAULT_DISPLAY_NUMBER ? customerName : `${customerName} - ${this.data.displaynumber}`;
    }

    /**
     * Execute all calculations and update data
     *
     * @returns {*|{}}
     */
    async calculate() {
        this.resetCalculatedFields();
        await Bluebird.each(this.getPricedItems(), item => {
            this.recalculatePricedItem(item);
        });

        this.roundTotals();

        this.emit(CONSTANTS.BUSINESS_CALCULATOR.EVENTS.DOCUMENT_RECALCULATED, this.getData());

        return this.getData();
    }

    async calculateCredit(options: Record<string, any>) {
        await this.calculate();
        const totalsByVatId = await this.getTotalsByVatId(options);
        const isMixed = (Object.keys(totalsByVatId).length > 1);
        const isInclusive = await this.isInclusive();
        const creditAmount = await this.calculateCreditAmount(options);
        const lines = {};
        let taxRate = 0;
        if (await this.isTaxUsed()) {
            taxRate = mathUtils.getTaxRateByCost(
                isInclusive ? this.data.total : this.data.subtotal,
                this.data.vat,
                isInclusive,
            );
            if (!options.prorate && isMixed) {
                taxRate = options.tax_rate;
            }
        }

        let vat;
        let total;
        let amount;
        if (options.is_inclusive) {
            vat = mathUtils.calculateIncludedTax(creditAmount, taxRate);
            total = creditAmount;
            amount = creditAmount - vat;
        } else {
            vat = mathUtils.calculateTax(creditAmount, taxRate);
            total = creditAmount + vat;
            amount = creditAmount;
        }

        // mixed and use pro-rate tax
        // (its mixed but all lines would like to keep own tax rate)
        //
        if (options.prorate && isMixed) {
            Object.keys(totalsByVatId).forEach(key => {
                const value = totalsByVatId[key];
                const rate = creditAmount / this.getData().total;
                lines[key] = {
                    subtotal: value.subtotal * rate,
                    total: value.total * rate,
                    vat: value.vat * rate,
                    rate: value.rate,
                    name: value.name,
                };
            });
            //
            // mixed/not mixed and used pro-rate (one rate for all items)
            //
        } else {
            // use selected rate id if not prorate
            let nonMixedId;
            if (!options.prorate) {
                nonMixedId = options.vat_id;
            } else {
                [nonMixedId] = Object.keys(totalsByVatId);
            }
            lines[nonMixedId] = {
                subtotal: amount,
                vat,
                total,
                rate: options.tax_rate,
                name: options.tax_name,
            };
        }

        return {
            amount,
            vat,
            total,
            refusereason: options.refusereason,
            lines,
            nTotal: total,
        };
    }

    async recalculatePricedItem(item: JobItemGroup): Promise<JobItemGroupEntity> {
        const newData = await item.calculate({
            cloneData: false,
            use_tax: item.data.vat_registered,
            is_inclusive: item.data.tax_inclusive,
            cisEnabled: this.data.is_cis,
        });
        this.updateTotals(newData);
        return newData;
    }

    /**
     * Add the line totals to the document totals
     *
     * @param item
     * @private
     */
    updateTotals(item: JobItemGroupEntity) {
        const subtotal = item.subtotal + (item.discount === null ? 0 : item.discount);
        //
        // If document type is quote and display total flag is off then
        // use the max value in the totals
        //
        if (!this.data.display_total && this.data.type === 1) {
            this.data.total = Math.max(this.data.total, item.total);
            this.data.vat = Math.max(this.data.vat, item.vat);
            this.data.cis_total = item.cis_total;
            this.data.cis_estimate = item.cis_estimate;
            this.data.cisable_amount = item.cisable_amount;
            this.data.subtotal = Math.max(this.data.subtotal, subtotal);
        } else {
            //
            // Otherwise just accumulate values
            //
            this.data.total += item.total;
            this.data.vat += item.vat;
            this.data.cis_total += item.cis_total;
            this.data.cis_estimate += item.cis_estimate;
            this.data.cisable_amount += item.cisable_amount;
            this.data.subtotal += subtotal;
        }
    }

    async getPricedItems(): Promise<JobItemGroup[]> {
        const items = (await this.getAllDocumentItem()).filter(item => item.data.type === CONSTANTS.DOCUMENT.DOCUMENT_ITEMS.TYPES.JOB_ITEM_GROUP);
        return Bluebird.map(items, item => (item as any).getLinkedItem());
    }

    /**
     * Checks whether the priced items in the document are taxed
     *
     * @returns {boolean}
     * @private
     */
    async isTaxUsed() {
        let taxUsed = false;
        (await this.getPricedItems()).forEach(item => {
            taxUsed = taxUsed || item.data.vat_registered;
        });
        return taxUsed;
    }

    /**
     * Checks whether the pricet items included in the document are tax inclusive
     *
     * @returns {boolean}
     * @private
     */
    async isInclusive() {
        let isInclusive = false;
        (await this.getPricedItems()).forEach(item => {
            isInclusive = isInclusive || item.data.tax_inclusive;
        });
        return isInclusive;
    }

    async calculateCreditAmount(values) {
        let originalAmount;
        if (values.amount_type === 0) {
            originalAmount = (this.getData().total / 100) * values.value;
            if (await this.isTaxUsed() && !(await this.isInclusive()) && (values.is_inclusive === 0)) {
                originalAmount = (this.getData().subtotal / 100) * values.value;
            }
        } else {
            originalAmount = values.value;
        }
        return originalAmount;
    }

    /**
     * Collect priced item lines grouped by VAT rate
     *
     * @param options
     * @returns {{}}
     * @private
     */
    async getTotalsByVatId(options: Record<string, any>) {
        const vatIds: number[] = [];
        const totalsByVatId: Record<number, {
            subtotal?: number,
            total?: number,
            vat?: number,
            name?: string,
            rate?: number,
        }> = {};
        await Bluebird.each(this.getPricedItems(), async (item: JobItemGroup) => {
            const discountBase = options.is_inclusive ? item.data.subtotal : item.data.total;
            const discountRate = (discountBase + item.data.discount) / discountBase;
            await Bluebird.each(await (item as any).getAllJobItem(), async (line: JobItem) => {
                const data = line.getData();
                const subtotal = (data.is_inclusive
                    ? data.cost
                    : data.cost - data.vat) * discountRate;
                const total = data.cost * discountRate;

                if (!vatIds.includes(data.vat_id)) {
                    vatIds.push(data.vat_id);
                    totalsByVatId[data.vat_id] = {
                        subtotal,
                        total,
                        vat: data.vat * discountRate,
                        name: data.tax_name,
                        rate: data.vat_rate,
                    };
                } else {
                    totalsByVatId[data.vat_id].subtotal += subtotal;
                    totalsByVatId[data.vat_id].total += total;
                    totalsByVatId[data.vat_id].vat += data.vat * discountRate;
                }
            });
        });

        return totalsByVatId;
    }

    /**
     * Reset all calculated values. Used to start new calculations
     * with clean sheets.
     *
     * @private
     */
    resetCalculatedFields() {
        this.data.total = 0;
        this.data.vat = 0;
        this.data.cis_total = 0;
        this.data.cis_estimate = 0;
        this.data.cisable_amount = 0;
        this.data.subtotal = 0;
    }

    roundTotals() {
        this.data.total = mathUtils.roundPrice(this.data.total);
        this.data.vat = mathUtils.roundPrice(this.data.vat);
        this.data.subtotal = mathUtils.roundPrice(this.data.subtotal);
    }

    async findSite() {
        const job = await this.getJob();
        return job.findSite();
    }

    async getTemplateData(): Promise<object> {
        return this.data.getPureDataValues();
    }

    async getSummaryData(): Promise<DocumentSummaryData> {
        const summaryObject: DocumentSummaryData = {};
        const docTypeName = DOCUMENT.TYPES[this.data.type];
        const pricedItemGroups = await this.getPricedItems();
        if (!pricedItemGroups.length) {
            return summaryObject;
        }
        const {
            showTotal, showGroupTitles, showItems, showItemDescriptions, 
        } = this.parsePricedItemLayout(docTypeName, pricedItemGroups[0].data[`priced_item_${docTypeName}_layout`]);
        summaryObject.showTotal = showTotal;
        if (!showGroupTitles && !showItems) {
            return summaryObject;
        }
        summaryObject.groupTitles = [];
        summaryObject.itemDescriptions = [];

        await Bluebird.each(pricedItemGroups, async pricedItemGroup => {
            summaryObject.groupTitles.push(showGroupTitles ? pricedItemGroup.data.title : '');
            if (showItems) {
                const pricedItems = await pricedItemGroup.getAllJobItem();
                const itemDescriptions = showItemDescriptions ? pricedItems.map(item => item.data.description) : [`${pricedItems.length} items`];
                summaryObject.itemDescriptions.push(itemDescriptions);
            }
        });
        
        return summaryObject;
    }
    
    parsePricedItemLayout(docTypeName: string, layoutSettings: any) {
        return {
            showGroupTitles: layoutSettings[`${docTypeName}_show_title`],
            showItems: layoutSettings[`${docTypeName}_show_line`],
            showItemDescriptions: layoutSettings[`${docTypeName}_show_line_description`],
            showTotal: layoutSettings[`${docTypeName}_show_total`],
        };
    }
}

export default Document;
