import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class JobItemGroupEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;

    @field({ type: 'string' })
    public title: string;

    @field({ type: 'string' })
    public description: string;

    @field({ type: 'int' })
    public job_id: number;

    @field({ type: 'decimal' })
    public total: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'int' })
    public user_id: number;

    @field({ type: 'int' })
    public discount_type: number;

    @field({ type: 'decimal' })
    public discount_amount: number;

    @field({ type: 'decimal' })
    public discount: number;

    @field({ type: 'decimal' })
    public subtotal: number;

    @field({ type: 'decimal' })
    public vat: number;

    @field({ type: 'int' })
    public is_surcharge: number;

    @field({ type: 'boolean' })
    public is_hidden: boolean;

    @field({ type: 'int' })
    public vat_id: number;

    @field({ type: 'decimal' })
    public vat_rate: number;

    @field({ type: 'int' })
    public cis_rate_id: number;

    @field({ type: 'decimal' })
    public cis_rate: number;

    @field({ isJSON: true, type: 'string' })
    public priced_item_invoice_layout: {
        invoice_show_title: boolean;
        invoice_show_description: boolean;
        invoice_show_headers: boolean;
        invoice_show_line: boolean;
        invoice_show_quantity: boolean;
        invoice_show_unit_cost: boolean;
        invoice_show_amount: boolean;
        invoice_show_vat_indicator: boolean;
        invoice_show_vat_rate: boolean;
        invoice_show_vat_amount: boolean;
        invoice_show_line_total: boolean;
        invoice_show_subtotal: boolean;
        invoice_show_discount: boolean;
        invoice_show_vat: boolean;
        invoice_show_total: boolean;
    };

    @field({ isJSON: true, type: 'string' })
    public priced_item_expense_layout: {
        expense_show_title: boolean;
        expense_show_description: boolean;
        expense_show_headers: boolean;
        expense_show_line: boolean;
        expense_show_quantity: boolean;
        expense_show_unit_cost: boolean;
        expense_show_amount: boolean;
        expense_show_vat_indicator: boolean;
        expense_show_vat_rate: boolean;
        expense_show_vat_amount: boolean;
        expense_show_line_total: boolean;
        expense_show_subtotal: boolean;
        expense_show_discount: boolean;
        expense_show_vat: boolean;
        expense_show_total: boolean;
    };

    @field({ isJSON: true, type: 'string' })
    public priced_item_expense_creditnote_layout: {
        expense_creditnote_show_title: boolean;
        expense_creditnote_show_description: boolean;
        expense_creditnote_show_headers: boolean;
        expense_creditnote_show_line: boolean;
        expense_creditnote_show_quantity: boolean;
        expense_creditnote_show_unit_cost: boolean;
        expense_creditnote_show_amount: boolean;
        expense_creditnote_show_vat_indicator: boolean;
        expense_creditnote_show_vat_rate: boolean;
        expense_creditnote_show_vat_amount: boolean;
        expense_creditnote_show_line_total: boolean;
        expense_creditnote_show_subtotal: boolean;
        expense_creditnote_show_discount: boolean;
        expense_creditnote_show_vat: boolean;
        expense_creditnote_show_total: boolean;
    };

    @field({ isJSON: true, type: 'string' })
    public priced_item_quote_layout: {
        quote_show_title: boolean;
        quote_show_description: boolean;
        quote_show_headers: boolean;
        quote_show_line: boolean;
        quote_show_quantity: boolean;
        quote_show_unit_cost: boolean;
        quote_show_amount: boolean;
        quote_show_vat_indicator: boolean;
        quote_show_vat_rate: boolean;
        quote_show_vat_amount: boolean;
        quote_show_line_total: boolean,
        quote_show_subtotal: boolean;
        quote_show_discount: boolean;
        quote_show_vat: boolean;
        quote_show_total: boolean;
    };

    @field({ isJSON: true, type: 'string' })
    public priced_item_worksheet_layout: {
        worksheet_show_title: boolean;
        worksheet_show_description: boolean;
        worksheet_show_headers: boolean;
        worksheet_show_line: boolean;
        worksheet_show_quantity: boolean;
        worksheet_show_unit_cost: boolean;
        worksheet_show_amount: boolean;
        worksheet_show_vat_indicator: boolean;
        worksheet_show_vat_rate: boolean;
        worksheet_show_vat_amount: boolean;
        worksheet_show_line_total: boolean,
        worksheet_show_subtotal: boolean;
        worksheet_show_discount: boolean;
        worksheet_show_vat: boolean;
        worksheet_show_total: boolean;
    };

    @field({ isJSON: true, type: 'string', codeName: 'pricedItemCreditNoteLayout' })
    public priced_item_creditnote_layout: {
        creditnote_show_title: boolean;
        creditnote_show_description: boolean;
        creditnote_show_headers: boolean;
        creditnote_show_line: boolean;
        creditnote_show_quantity: boolean;
        creditnote_show_unit_cost: boolean;
        creditnote_show_amount: boolean;
        creditnote_show_vat_indicator: boolean;
        creditnote_show_vat_rate: boolean;
        creditnote_show_vat_amount: boolean;
        creditnote_show_line_total: boolean,
        creditnote_show_subtotal: boolean;
        creditnote_show_discount: boolean;
        creditnote_show_vat: boolean;
        creditnote_show_total: boolean;
    };

    @field({ isJSON: true, type: 'string' })
    public priced_item_purchase_order_layout: {
        purchase_order_show_title: boolean;
        purchase_order_show_description: boolean;
        purchase_order_show_headers: boolean;
        purchase_order_show_line: boolean;
        purchase_order_show_sku: boolean;
        purchase_order_show_line_description: boolean;
        purchase_order_show_quantity: boolean;
        purchase_order_show_unit_cost: boolean;
        purchase_order_show_amount: boolean;
        purchase_order_show_vat_indicator: boolean;
        purchase_order_show_vat_rate: boolean;
        purchase_order_show_vat_amount: boolean;
        purchase_order_show_line_total: boolean;
        purchase_order_show_subtotal: boolean;
        purchase_order_show_discount: boolean;
        purchase_order_show_vat: boolean;
        purchase_order_show_total: boolean;
    };

    @field({ isJSON: true, type: 'string' })
    public priced_item_supplier_invoice_layout: {
        supplier_invoice_show_title: boolean;
        supplier_invoice_show_description: boolean;
        supplier_invoice_show_headers: boolean;
        supplier_invoice_show_line: boolean;
        supplier_invoice_show_sku: boolean;
        supplier_invoice_show_line_description: boolean;
        supplier_invoice_show_quantity: boolean;
        supplier_invoice_show_unit_cost: boolean;
        supplier_invoice_show_amount: boolean;
        supplier_invoice_show_vat_indicator: boolean;
        supplier_invoice_show_vat_rate: boolean;
        supplier_invoice_show_vat_amount: boolean;
        supplier_invoice_show_line_total: boolean;
        supplier_invoice_show_subtotal: boolean;
        supplier_invoice_show_discount: boolean;
        supplier_invoice_show_vat: boolean;
        supplier_invoice_show_total: boolean;
    };

    @field({ isJSON: true, type: 'string' })
    public priced_item_supplier_invoice_credit_layout: {
        supplier_invoice_credit_show_title: boolean;
        supplier_invoice_credit_show_description: boolean;
        supplier_invoice_credit_show_headers: boolean;
        supplier_invoice_credit_show_line: boolean;
        supplier_invoice_credit_show_sku: boolean;
        supplier_invoice_credit_show_line_description: boolean;
        supplier_invoice_credit_show_quantity: boolean;
        supplier_invoice_credit_show_unit_cost: boolean;
        supplier_invoice_credit_show_amount: boolean;
        supplier_invoice_credit_show_vat_indicator: boolean;
        supplier_invoice_credit_show_vat_rate: boolean;
        supplier_invoice_credit_show_vat_amount: boolean;
        supplier_invoice_credit_show_line_total: boolean;
        supplier_invoice_credit_show_subtotal: boolean;
        supplier_invoice_credit_show_discount: boolean;
        supplier_invoice_credit_show_vat: boolean;
        supplier_invoice_credit_show_total: boolean;
    };

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;

    @field({ type: 'int' })
    public original_id: number;

    @field({ type: 'decimal' })
    public cis_total: number;

    @field({ type: 'decimal' })
    public cis_estimate: number;

    @field({ type: 'string' })
    public cis_explanation: string;

    @field({ type: 'decimal' })
    public cisable_amount: number;

    @field({ type: 'boolean', allowNull: true })
    public tax_inclusive: boolean;

    @field({ type: 'boolean', allowNull: true })
    public vat_registered: boolean;

    @field({ type: 'boolean', codeName: 'isArchived', serverOnly: true })
    public is_archived: boolean;

    @field({
        codeName: 'lastActivity',
        minAppVersion: '7.7.0',
        type: 'string',
        dbType: 'date',
        defaultValue: dateUtils.getNow.bind(dateUtils),
        serverOnly: true,
    })
    public lastActivity: DateOrString;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_pinned?: boolean;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_marked?: boolean;
}
