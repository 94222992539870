const modelProperties = {
    modelName: 'CompanySettings',
    tableName: 'CompanySettings',
    minAppVersion: '7.9.2',
    associations: [{
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'belongsTo',
        model: 'SettingsKeys',
        parameters: { foreignKey: { name: 'setting_key_id', fieldName: 'setting_key_id' } },
    }],
};

export default modelProperties;
