import { field } from '../entity';
import PaymentEntity from '../payment/entity';

export default class PaymentsForCustomerEntity extends PaymentEntity {
    @field({ type: 'int' })
    public customer_id?: number;

    @field({ type: 'int' })
    public document_id?: number;

    @field({ type: 'int' })
    public job_id?: number;

    @field({ type: 'int' })
    public total?: number;

    @field({ type: 'string' })
    public displaynumber?: string;
}
