import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import TableVersionEntity from './entity';
import modelProperties from './modelProperties';

interface TableVersionAssociations extends AssociationConfig<any, any> {}

interface TableVersion extends AutoGeneratedFunctions<TableVersionAssociations, TableVersionEntity, ComplexData<TableVersionEntity>> {}

// eslint-disable-next-line no-redeclare
class TableVersion extends ComplexData<TableVersionEntity> {
    static Entity = TableVersionEntity;

    static modelProperties = modelProperties;
}

export default TableVersion;
