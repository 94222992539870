import Entity, { EntityWithId, field } from '../entity';
    
export default class CountryEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'string' })
    public code: string;
    
    @field({ type: 'string' })
    public name: string;
    
    @field({ type: 'string' })
    public date_format: string;
    
    @field({ type: 'int' })
    public display_order: number;
    
    @field({ type: 'string' })
    public phone_code: string;
    
    @field({ type: 'boolean' })
    public is_cis: boolean;
}
