/** **************************************************************
 *
 * Luhn hash generation and check
 *
 */
/* eslint no-bitwise: "off" */
/* eslint no-extend-native: "off" */
String.prototype.checkLuhn = function checkLuhn() {
    const luhnArr = [[0, 2, 4, 6, 8, 1, 3, 5, 7, 9], [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]];
    let sum = 0;
    this.replace(/\D+/g, '').replace(/[\d]/g, (c, p, o) => {
        sum += luhnArr[(o.length - p) & 1][parseInt(c, 10)];
    });
    return (sum % 10 === 0) && (sum > 0);
};

String.prototype.getLuhn = function getLuhn() {
    const luhnArr = [[0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]];
    let sum = 0;
    this.replace(/\D+/g, '').replace(/[\d]/g, (c, p, o) => {
        sum += luhnArr[(o.length - p) & 1][parseInt(c, 10)];
    });
    return this + ((10 - (sum % 10)) % 10);
};

// escapes special regex characters for searchField matching
String.prototype.regexEscape = function regexEscape() {
    return this.replace(/[.?+*^$|({[\\]/g, '\\$&');
};

// pads left
String.prototype.lpad = function lpad(padString, length) {
    let str = this;
    while (str.length < length) {
        str = padString + str;
    }
    return str;
};

// pads right
String.prototype.rpad = function rpad(padString, length) {
    let str = this;
    while (str.length < length) {
        str += padString;
    }
    return str;
};

String.prototype.removeCharacters = function removeCharacters(regExp) {
    const expression = new RegExp(regExp, 'g');
    return this.replace(expression, '');
};

String.prototype.capitalizeAll = function capitalizeAll() {
    return this.replace(/\b\w/g, char => char.toUpperCase());
};

String.prototype.capitalize = function capitalize() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.camelCaseToDisplay = function camelCaseToDisplay() {
    return this.replace(/([A-Z])/g, ' $1').trim();
};

/** **************************************************************
 *
 * capitalizeSentences
 * Capitalizes every first word of a sentence in a string.
 *
 * @param textToCap the string to capitalize
 * @param capLock boolean whether to lowercase the whole string at the beginning or not
 * @return capitalized string
 *
 */
String.prototype.capitalizeSentences = function capitalizeSentences(textToCap, capLock) {
    let capText = textToCap;
    if (capLock === 1 || capLock === true) {
        capText = capText.toLowerCase();
    }

    capText = capText.replace(/\.\n/g, '.[-<br>-]. ');
    capText = capText.replace(/\.\s\n/g, '. [-<br>-]. ');

    const wordSplit = /[.!?]\s/;
    const wordArray = capText.split(wordSplit);
    const numWords = wordArray.length;

    for (let x = 0; x < numWords; x += 1) {
        wordArray[x] = wordArray[x].replace(wordArray[x].charAt(0), wordArray[x].charAt(0).toUpperCase());

        if (numWords === 1) {
            capText = wordArray[x];
        } else if (x === 0 && numWords > 1) {
            capText = `${wordArray[x]}. `;
        } else if (x !== numWords - 1) {
            capText = `${capText + wordArray[x]}. `;
        } else if (x === numWords - 1) {
            capText += wordArray[x];
        }
    }
    capText = capText.replace(/\[-<br>-\]\.\s/g, '\n');
    capText = capText.replace(/\si\s/g, ' I ');

    return capText;
};

String.prototype.stripTags = function stripTags(value) {
    const text = value || this;
    return text.replace(/<(?:.|\n)*?>/gm, '');
};
