import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import FormTypeEntity from './entity';
import modelProperties from './modelProperties';

interface FormTypeAssociations extends AssociationConfig<any, any> {}

interface FormType extends AutoGeneratedFunctions<FormTypeAssociations, FormTypeEntity, ComplexData<FormTypeEntity>> {}

// eslint-disable-next-line no-redeclare
class FormType extends ComplexData<FormTypeEntity> {
    static Entity = FormTypeEntity;

    static modelProperties = modelProperties;
}

export default FormType;
