const BY_POSTCODE = 0;
const BY_CUSTOMER = 1;
const BY_SAME_SITE = 2;

export default {
    FILTERS: {
        BY_CUSTOMER,
        BY_SAME_SITE,
        BY_POSTCODE,
    },
    ORDER: [
        BY_CUSTOMER,
        BY_SAME_SITE,
        BY_POSTCODE,
    ],
};
