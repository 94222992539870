export const APPOINTMENT_DURATION = {
    EXACT_TIME: 0,
    WORKING_DAYS: 1,
    MORNING: 2,
    AFTERNOON: 3,
    EVENING: 4,
    ALL_DAY: 5,
    HALF_DAY: 6,
} as const;

export default {
    ACTION_LINKED_TYPE_DOCUMENT: 1,
    ACTION_LINKED_TYPE_PAYMENT: 2,
    ACTIONS: {
        PAYMENT_RECEIVED: 1,
        PAYMENT_REFUNDED: 2,
        QUOTE_ACCEPTED: 3,
        QUOTE_REJECTED: 4,
        ESTIMATE_ACCEPTED: 5,
        ESTIMATE_REJECTED: 6,
        NOTIFICATION: 7,
    },
    ACTION_TYPES: {
        ACTION_TYPE_NOTIFICATION: 0,
        ACTION_TYPE_REMINDER: 2,
        ACTION_TYPE_APPOINTMENT: 1,
        ACTION_TYPE_FORM_DOCUMENT_REMINDER: 3,
        ACTION_TYPE_INTEGRATION_WARNING: 4,
        ACTION_TYPE_FORM_DOCUMENT_NOTIFICATION: 5,
        ACTION_TYPE_SIMPLE: 6,
        ACTION_TYPE_HOLIDAY_SICKNESS_OR_OTHER: 7,
    },
    APPOINTMENT_TYPE_ID: {
        ALL: 1,
        PUBLIC_HOLIDAY: 2,
        HOLIDAY: 3,
        SICKNESS: 4,
        MEDICAL_DENTAL: 5,
        TRAINING: 6,
        OTHER_ABSENCE: 7,
    },
    ACTION_TYPES_STRINGS: {
        0: 'Notification',
        2: 'Reminder',
        1: 'Appointment',
        3: 'Form Document Reminder',
        4: 'Tax Warning',
        5: 'Form Document Notification',
        6: 'Task, Lead or Action',
        7: 'Holiday, Sickness or other',
    },
    PLAN_TYPES: {
        DATE: { ID: 0, NOTIFY_CUSTOMER: true, FORMAT_OPTIONS: 'Do MMM Y [from] h:mm A' },
        UNPLANNED_FOR_DAY: { ID: 1, NOTIFY_CUSTOMER: true, FORMAT_OPTIONS: 'Do MMM Y' },
        UNPLANNED: { ID: 2, NOTIFY_CUSTOMER: false, FORMAT_OPTIONS: null },
    },
    APPOINTMENT_DURATION,
    APPOINTMENT_DURATION_TEXT: {
        2: 'morning',
        3: 'afternoon',
        4: 'evening',
    },
    DEFAULT_TIME: {
        WORKDAY_START: '09:00',
        WORKDAY_END: '17:00',
    },
    CUSTOM_APPOINTMENT_TYPE: {
        DEFAULT_DURATION: [
            {
                text: '15 minutes',
                value: 15,
                id: 1,
                duration_id: APPOINTMENT_DURATION.EXACT_TIME,
            },
            {
                text: '30 minutes',
                value: 30,
                id: 2,
                duration_id: APPOINTMENT_DURATION.EXACT_TIME,
            },
            {
                text: '60 minutes (1 hour)',
                value: 60,
                id: 3,
                duration_id: APPOINTMENT_DURATION.EXACT_TIME,
            },
            {
                text: '90 minutes',
                value: 90,
                id: 4,
                duration_id: APPOINTMENT_DURATION.EXACT_TIME,
            },
            {
                text: '120 minutes (2 hours)',
                value: 120,
                id: 5,
                duration_id: APPOINTMENT_DURATION.EXACT_TIME,
            },
            {
                text: '180 minutes (3 hours)',
                value: 180,
                id: 6,
                duration_id: APPOINTMENT_DURATION.EXACT_TIME,
            },
            {
                text: '240 minutes (4 hours)',
                value: 240,
                id: 7,
                duration_id: APPOINTMENT_DURATION.EXACT_TIME,
            },
            {
                text: 'All day - Working hours',
                value: 1440,
                id: 8,
                duration_id: APPOINTMENT_DURATION.WORKING_DAYS,
            },
        ],
    },
    EVENING_HOURS_MAX_LENGTH: 6,
    LUNCHTIME_GAP: 2,
    APPOINTMENT_DESCRIPTION_LENGTH: 2048,
};
