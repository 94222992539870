import { ValueOf } from '@powerednow/type-definitions';
import DOCUMENT from './document';

const PAYMENT_CATEGORIES_SALES = DOCUMENT.DOCUMENT_PAYMENT_CATEGORIES.SALES;
const PAYMENT_CATEGORIES_SUPPLIER = DOCUMENT.DOCUMENT_PAYMENT_CATEGORIES.SUPPLIER;
const PAYMENT_CATEGORIES_EXPENSE = DOCUMENT.DOCUMENT_PAYMENT_CATEGORIES.EXPENSE;
const DOCUMENT_PAYMENT_CATEGORY_TO_ENABLED_PROPERTY = {
    [PAYMENT_CATEGORIES_SALES]: 'enabled',
    [PAYMENT_CATEGORIES_SUPPLIER]: 'enabled_supplier',
    [PAYMENT_CATEGORIES_EXPENSE]: 'enabled_expense',
} as const;
const TYPES = {
    CASH: 1,
    CHEQUE: 2,
    BANKTRANSFER: 3,
    PAYPAL_STANDARD: 4,
    PAYPAL_HERE: 5,
    DIRECT_DEBIT: 6,
    STANDING_ORDER: 7,
    CHIP_AND_PIN: 9,
    CREDIT_CARD: 10,
    COMPANY_CARD: 11,
    PETTY_CASH: 12,
    PAYROLL: 13,
    CIS: 14,
    PAYPAL_CHECKIN: 15,
    CONTRA: 16,
    BANK_PAYMENT: 17,
    SUMUP: 18,
    SUMUP_ONLINE: 19,
} as const;
export default {
    TYPES,
    FILTERS: {
        ALL: 0,
        REFUND: 1,
        NOTREFUND: 2,
    },
    DOCUMENT_PAYMENT_CATEGORY_TO_ENABLED_PROPERTY,
    DOCUMENT_PAYMENT_CATEGORY_TO_ALLOWED_PROPERTY: {
        [DOCUMENT.DOCUMENT_PAYMENT_CATEGORIES.SALES]: 'is_sales',
        [DOCUMENT.DOCUMENT_PAYMENT_CATEGORIES.SUPPLIER]: 'is_supplier',
        [DOCUMENT.DOCUMENT_PAYMENT_CATEGORIES.EXPENSE]: 'is_expense',
    },
};

export type TYPES_Type = ValueOf<typeof TYPES>
