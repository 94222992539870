const modelProperties = {
    modelName: 'ProductCategory',
    tableName: 'ProductCategory',
    minAppVersion: '5.0.0',
    associations: [{
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }],
    validations: {
        name: {
            type: 'length',
            min: 1,
            max: 64,
            bothOnlyMessagePromptId: 'ValidatorMessages.ProductCategoryName',
            bothOnlyMessagePromptDefault: 'Name length must be between {min} and {max}',
        },
    },
};

export default modelProperties;
