import Entity, { EntityWithId, field } from '../entity';
    
export default class WhiteLabelMetricsEntity extends EntityWithId {
    @field({ codeName: 'whiteLabelId', type: 'int', primaryKey: true })
    public whiteLabelId: number;
    
    @field({ type: 'int', primaryKey: true })
    public year: number;
    
    @field({ type: 'int', primaryKey: true })
    public month: number;
    
    @field({ codeName: 'newCompanies', type: 'int' })
    public newCompanies: number;
    
    @field({ codeName: 'activeCompanies', type: 'int' })
    public activeCompanies: number;
    
    @field({ codeName: 'allPurchaseOrders', type: 'int' })
    public allPurchaseOrders: number;
    
    @field({ codeName: 'ownPurchaseOrders', type: 'int' })
    public ownPurchaseOrders: number;
}
