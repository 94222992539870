const papaparse = require('papaparse');
const _ = require('lodash');

class CSVParser {
    constructor(csvData, skipHeader = false, separator = ' ') {
        this._separator = separator;
        this._skipHeader = skipHeader;
        this._csvData = csvData;
        this._parsedData = this._convertCSVToArray();
    }

    convertByMap(map) {
        const data = this._parsedData;

        if (this._skipHeader) {
            data.shift();
        }

        return data.map(row => this._transformRowByMap(row, map));
    }

    getParsedData() {
        return this._parsedData;
    }

    _convertCSVToArray() {
        const parsedData = papaparse.parse(this._csvData);
        if (parsedData && parsedData.data) {
            return parsedData.data;
        }
        throw Error('Csv parsing error');
    }

    _transformRowByMap(row, map) {
        return Object.keys(map)
            .reduce((obj, key) => {
                const value = this._generateColumnValue(map, key, row);
                return Object.assign(obj, { [key]: value });
            }, {});
    }

    _generateColumnValue(map, key, row) {
        const columnDescriptor = map[key];
        if (this.constructor._shouldReplaceToDefault(columnDescriptor.fields)) {
            return this.constructor._getDefaultValue(columnDescriptor);
        }
        const values = columnDescriptor.fields
            .map(index => row[index])
            .filter(value => value !== '');

        if (values.length === 0) {
            return this.constructor._getDefaultValue(columnDescriptor);
        }
        return values.join(this._separator);
    }

    static _shouldReplaceToDefault(fields) {
        if (!fields) {
            return true;
        }
        return _.isEmpty(fields.filter(f => f !== null));
    }

    static _getDefaultValue(columnDescriptor) {
        return columnDescriptor && Object.prototype.hasOwnProperty.call(columnDescriptor, 'defaultValue')
            ? columnDescriptor.defaultValue
            : '';
    }
}

module.exports = CSVParser;
