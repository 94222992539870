class AbstractPromptValue {
    constructor({
        value, promptId, data, type, 
    }) {
        this.value = value;
        this.promptId = promptId;
        this.data = data;
        this.type = type;
    }

    get translatedValue() {
        return this.translate();
    }

    translate() {
        throw new Error('You should define translate method');
    }
}

module.exports = AbstractPromptValue;
