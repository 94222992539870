import * as Bluebird from 'bluebird';

import ContactMethod from 'modules/complexData/contactMethod';
import ContactToAddress from 'modules/complexData/contactToAddress';
import Customer from 'modules/complexData/customer';
import ContactMethodType from 'modules/complexData/contactMethodType';
import ArrayUtils from 'modules/utilities/array';
import MESSAGES from '@powerednow/shared/constants/messages';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import ContactEntity from './entity';
import modelProperties from './modelProperties';
import UserToContact from '../userToContact';
import ConnectedData from '../connectedData';
import UserToContactEntity from '../userToContact/entity';
import ContactMethodEntity from '../contactMethod/entity';
import ContactToAddressEntity from '../contactToAddress/entity';
import CustomerEntity from '../customer/entity';
import { ModelCreationFields } from '../entity';
import { ChannelTypes } from '../../../constants/customerEmailTemplateValues';
import CompanyEntity from '../company/entity';
import Company from '../company';

const constants = require('@powerednow/shared/constants').default;

interface ContactAssociations extends AssociationConfig<any, any> {
    company: AssociationDefinitionSingle<CompanyEntity, Company>
    userToContact: AssociationDefinitionSingle<UserToContactEntity, UserToContact>
    contactMethod: AssociationDefinition<ContactMethodEntity, ContactMethod>
    alternateContact: AssociationDefinitionSingle<ContactEntity, Contact>
    contactToAddress: AssociationDefinitionSingle<ContactToAddressEntity, ContactToAddress>
    mainContact: AssociationDefinitionSingle<ContactEntity, Contact>
    customer: AssociationDefinitionSingle<CustomerEntity, Customer>
}

export type ContactTemplateData = Required<ModelCreationFields<ContactEntity>> & {
    fullName?: string,
    contactMethods?: any[],
}

interface Contact extends AutoGeneratedFunctions<ContactAssociations, ContactEntity, ComplexData<ContactEntity>> {
}

// eslint-disable-next-line no-redeclare
class Contact extends ComplexData<ContactEntity> {
    static Entity = ContactEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): ContactAssociations {
        return {
            company: {
                instance: Company,
                entity: CompanyEntity,
                key: 'company',
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().company_id,
                },
            },
            userToContact: {
                key: 'userToContact',
                instance: UserToContact,
                entity: UserToContactEntity,
                single: true,
                condition: {
                    contact_id: this.Entity.getFieldSymbols().id,
                    company_id: this.Entity.getFieldSymbols().company_id,
                },
            },
            contactMethod: {
                key: 'contactMethod',
                instance: ContactMethod,
                entity: ContactMethodEntity,
                cascadeDelete: true,
                condition: {
                    contact_id: this.Entity.getFieldSymbols().id,
                },
            },
            alternateContact: {
                key: 'contact',
                instance: Contact,
                entity: ContactEntity,
                single: true,
                cascadeDelete: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().alternate_contact_id,
                    customer_id: this.Entity.getFieldSymbols().customer_id,
                },
            },
            contactToAddress: {
                key: 'contactToAddress',
                instance: ContactToAddress,
                entity: ContactToAddressEntity,
                single: true,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().customer_id,
                    contact_id: this.Entity.getFieldSymbols().id,
                },
            },
            mainContact: {
                key: 'contact',
                instance: Contact,
                entity: ContactEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().customer_id,
                    alternate_contact_id: this.Entity.getFieldSymbols().id,
                },
            },
            customer: {
                key: 'customer',
                instance: Customer,
                entity: CustomerEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getFieldSymbols().customer_id,
                },
            },
        };
    }

    public async hasRegisteredUser(): Promise<boolean> {
        const userToContact: UserToContact = await this.getUserToContact();
        return Boolean(userToContact);
    }

    /**
     * Extra initialisation for newly created Contacts
     */
    protected async initDefaultAssociatedItems(): Promise<void> {
        await this.addContactMethod({
            contactmethodtype_id: constants.MESSAGES.TYPES.SMS,
            is_regular: true,
            contact_id: this.data.id,
        });

        await this.addContactMethod({
            contactmethodtype_id: constants.MESSAGES.TYPES.EMAIL,
            is_regular: true,
            contact_id: this.data.id,
        });
    }

    /**
     * Returns true if the contact is an alternate contact for another contact
     *
     * @returns {boolean}
     */
    async isAlternate(): Promise<boolean> {
        const mainContact = await this.getMainContact();
        return mainContact !== null;
    }

    public async getPhone(): Promise<ContactMethod> {
        return (await this.getAllContactMethod()).find(contactMethod => contactMethod.data.contactmethodtype_id === constants.MESSAGES.TYPES.SMS);
    }

    public async getEmail(): Promise<ContactMethod> {
        return (await this.getAllContactMethod()).find(contactMethod => contactMethod.data.contactmethodtype_id === constants.MESSAGES.TYPES.EMAIL);
    }

    public async hasEmailOrPhone(): Promise<boolean> {
        const phone = await this.getPhone();
        const email = await this.getEmail();
        return Boolean(phone && phone.hasValue()) || Boolean(email && email.hasValue());
    }

    public async findWhatsAppContactMethod(): Promise<ContactMethod> {
        const [whatsAppContactMethod] = await this.findContactMethodsBy(async contactMethod => contactMethod.data.contactmethodtype_id === constants.MESSAGES.TYPES.WHATSAPP);
        return whatsAppContactMethod;
    }

    public async getRegularContactMethods(): Promise<ContactMethod[]> {
        return this.findContactMethodsBy(async contactMethod => contactMethod.isRegular());
    }

    public async getAdditionalContactMethods(): Promise<ContactMethod[]> {
        return this.findContactMethodsBy(async contactMethod => contactMethod.isAdditional());
    }

    public async getTextableContactMethods(): Promise<ContactMethod[]> {
        return this.findContactMethodsBy(async contactMethod => contactMethod.canText());
    }

    public async getSmsContactMethods(): Promise<ContactMethod[]> {
        return this.findContactMethodsBy(async contactMethod => await contactMethod.canText() && contactMethod.data.contactmethodtype_id === MESSAGES.TYPES.SMS && contactMethod.hasValue());
    }

    public async findPhoneForWhatsApp(): Promise<ContactMethod> {
        return (await this.findContactMethods()).find(contactMethod => contactMethod.data.contactmethodtype_id === MESSAGES.TYPES.SMS && contactMethod.hasValue());
    }

    public async getEmailableContactMethods(): Promise<ContactMethod[]> {
        return this.findContactMethodsBy(async contactMethod => await contactMethod.canEmail() && contactMethod.data.contactmethodtype_id === MESSAGES.TYPES.EMAIL && contactMethod.hasValue());
    }

    public async getCallableContactMethods(): Promise<ContactMethod[]> {
        return this.findContactMethodsBy(async contactMethod => contactMethod.canCall());
    }

    public async findContactMethods(): Promise<ContactMethod[]> {
        return this.getAllContactMethod();
    }

    public async findNotUsedAdditionalContactMethodTypeIds() {
        return this.findNotUsedContactMethodTypeIds({
            is_additional: true,
        });
    }

    public async canAddNewAdditionalContactMethod(): Promise<boolean> {
        const notUsedIds = await this.findNotUsedAdditionalContactMethodTypeIds();
        return notUsedIds.length > 0;
    }

    public async hasUnfilledContactMethod(): Promise<boolean> {
        const canAddNew = await this.canAddNewAdditionalContactMethod();
        const hasUnfilled = (await this.findContactMethods())
            .some(contactMethod => (contactMethod.data.value || '').trim() === '');

        return hasUnfilled || canAddNew;
    }

    public async findContactMethodsBy(filterFn): Promise<ContactMethod[]> {
        return Bluebird.reduce(await this.findContactMethods(), async (allMethods, contactMethod) => {
            const isMatched = await filterFn(contactMethod);
            return [
                ...allMethods,
                ...(isMatched ? [contactMethod] : []),
            ];
        }, []);
    }

    private async findNotUsedContactMethodTypeIds(filterProperties): Promise<(number[])> {
        const allContactMethodTypeIds = await this.findGenericTypeIds(filterProperties);
        const usedContactMethodTypeIds = await this.findAlreadyUsedContactMethodTypeIds();
        return ArrayUtils.difference(allContactMethodTypeIds, usedContactMethodTypeIds);
    }

    private async findAlreadyUsedContactMethodTypeIds(filterFn?): Promise<number[]> {
        const contactMethods = await this.findContactMethods();
        return contactMethods
            .map(usedContactMethods => usedContactMethods.data.contactmethodtype_id)
            .filter(methodTypeId => (filterFn ? filterFn(methodTypeId) : true));
    }

    private async findGenericTypeIds(filterProperties): Promise<number[]> {
        const additionalContactMethods = await this.loadGenericData(<typeof ConnectedData>(ContactMethodType as unknown), filterProperties);
        return additionalContactMethods.responseData.map(responseItem => responseItem.id);
    }

    public async getFullName(): Promise<string> {
        const title = (this.data.title || '').trim();
        const titleString = title === '' ? '' : `${title} `;
        const contactName = `${titleString}${this.data.firstname} ${this.data.lastname}`.trim();

        if (!contactName && this.data.isdefault) {
            return (await (this as any).getCustomer()).data.company;
        }
        return contactName;
    }

    public async getContactMethodsWithTypes() {
        const groupedMethods = await this.getGroupedContactMethods();
        return Bluebird.reduce(groupedMethods, async (newMethodGroups, contactMethodGroup) => [
            ...newMethodGroups,
            (await this.getContactMethodTypeDataForMethods(contactMethodGroup)),
        ], []);
    }

    async getContactMethodTypeDataForMethods(contactMethods: ContactMethod[]) {
        return Bluebird.reduce(contactMethods, async (newContactMethods, contactMethod) => [
            ...newContactMethods,
            ({
                ...contactMethod.data.getPureDataValues(),
                contactMethodType: (await (contactMethod as any).getContactMethodType()).data.getPureDataValues(),
            }),
        ], []);
    }

    async getGroupedContactMethods() {
        return [
            (await this.getCallableContactMethods()),
            (await this.getEmailableContactMethods()),
        ];
    }

    public async getSearchableFields(): Promise<string[]> {
        const searchableFields = [
            this.data.firstname,
            this.data.lastname,
            this.data.title,
            this.data.description,
        ];
        const alternateContact = await this.getAlternateContact();
        if (alternateContact) {
            searchableFields.push(...(await alternateContact.getSearchableFields() || []));
        }
        await Bluebird.map(this.getAllContactMethod(), async method => {
            searchableFields.push(...await method.getSearchableFields());
        });

        return searchableFields;
    }

    public async getContactMethodsMap(): Promise<ContactMethod[]> {
        const contactMethods = await (<any> this).getAllContactMethod();
        return contactMethods.reduce((map, contactMethod) => Object.assign(map, {
            [contactMethod.data.contactmethodtype_id]: contactMethod,
        }), {});
    }

    public getPropertiesToCopy() {
        const {
            firstname, lastname, description, title,
        } = this.data.getPureDataValues();
        return {
            firstname, lastname, description, title,
        };
    }

    public async copyData(item: Contact) {
        const contactMethods = await this.getContactMethodsMap();

        await Bluebird.map(Object.entries(await item.getContactMethodsMap()), async ([typeId, contactMethodToCopy]: [any, ContactMethod]) => {
            if (contactMethodToCopy.data.value) {
                if (contactMethods[typeId]) {
                    await contactMethods[typeId].copyData(contactMethodToCopy);
                } else {
                    await (<any> this).addContactMethod({
                        ...contactMethodToCopy.getPropertiesToCopy(),
                        contactmethodtype_id: contactMethodToCopy.data.contactmethodtype_id,
                    });
                }
            }
        });

        Object.assign(this.data, item.getPropertiesToCopy());

        return this;
    }

    public async isContactable(channels: ChannelTypes[]): Promise<boolean> {
        const contactMethods: ContactMethod[] = await this.getAllContactMethod();

        return Bluebird.reduce(contactMethods, async (contactable: boolean, contactMethod) => {
            const methodIsContactable = await contactMethod.isContactable(channels);
            return contactable || methodIsContactable;
        }, false);
    }

    public async getTemplateData(): Promise<ContactTemplateData> {
        const data = {
            ...this.data.getPureDataValues(),
            fullName: await this.getFullName(),
            contactMethods: [],
        };

        const contactPhone = await this.getPhone();
        if (contactPhone && contactPhone.hasValue()) {
            data.contactMethods.push(await contactPhone.getTemplateData());
        }

        const contactEmail = await this.getEmail();
        if (contactEmail && contactEmail.hasValue()) {
            data.contactMethods.push(await contactEmail.getTemplateData());
        }

        return data;
    }

    async getNotificationContactMethods(): Promise<ContactMethod[]> {
        const company = await this.getCompany();
        const emailableMethods = await this.getEmailableContactMethods();
        const smsMethods = await this.getSmsContactMethods();
        const whatsappMethods = [await this.findWhatsAppContactMethod()];
        const channelTypes = await company.getNotificationChannels();
        const returnMethods = [
            ...(channelTypes.includes('SMS') ? smsMethods : []),
            ...(channelTypes.includes('WHATSAPP') ? whatsappMethods : []),
            ...(channelTypes.includes('EMAIL') ? emailableMethods : []),
        ];
        //
        // If no method is added by following the settings then fall back to whatever is available
        //
        if (returnMethods.length === 0 && emailableMethods.length !== 0) {
            returnMethods.push(...emailableMethods);
        }
        if (returnMethods.length === 0 && smsMethods.length !== 0) {
            returnMethods.push(...smsMethods);
        }
        return returnMethods.filter(Boolean);
    }

    public async updateBySite(sourceSite): Promise<void> {
        const sourceContact = await sourceSite.getContact();
        const siteToEdit = await this.getContactToAddress();
        if (siteToEdit) {
            const addressToEdit = await siteToEdit.getAddress();
            const sourceAddress = await sourceSite.getAddress();
            await addressToEdit.copyData(sourceAddress);
        }

        await this.copyData(sourceContact);
    }
}

export default Contact;
