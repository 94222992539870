import Entity, { EntityWithCompanyId, field } from '../entity';

export default class ContactToAddressEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'int' })
    public customer_id: number;

    @field({ type: 'int' })
    public contact_id: number;

    @field({ type: 'int' })
    public address_id: number;

    @field({ type: 'int' })
    public type: number;

    @field({
        type: 'boolean', codeName: 'isDeleted', serverOnly: true, defaultValue: false, 
    })
    public isdeleted?: boolean;

    @field({ type: 'boolean' })
    public plots_allowed?: boolean;

    @field({ type: 'boolean', defaultValue: true })
    public use_on_job_sheets?: boolean;
}
