import CONSTANTS, { DOCUMENT, MESSAGES } from '@powerednow/shared/constants';
import * as Bluebird from 'bluebird';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import CustomerMessageEntity from './entity';
import modelProperties from './modelProperties';
import Document from '../document';
import FormDocument from '../formDocument';
import Payment from '../payment';
import Action from '../action';
import MessageRecipient from '../messageRecipient';
import MessageRecipientEntity from '../messageRecipient/entity';
import ContactMethod from '../contactMethod';
import DefaultMessages, {
    ChannelTypes,
    MessageDefault,
    MessageDefaultContent,
} from '../../../constants/customerEmailTemplateValues';
import CustomerMessageAttachmentEntity from '../customerMessageAttachment/entity';
import CustomerMessageAttachment from '../customerMessageAttachment';
import Attachment from '../attachment';
import UserEntity from '../user/entity';
import User from '../user';
import Company from '../company';
import CompanyEntity from '../company/entity';
import Contact from '../contact';
import DocumentEntity from '../document/entity';
import PaymentEntity from '../payment/entity';
import ActionEntity from '../action/entity';
import FormDocumentEntity from '../formDocument/entity';
import Job from '../job';
import JobEntity from '../job/entity';
import TemplateDataExtender from '../../templateDataExtender/templateDataExtender';
import * as dateUtils from '../../utilities/date';
import type { ComplexModelFields } from '../entity';

interface CustomerMessageAssociations extends AssociationConfig<any, any> {
    messageRecipient: AssociationDefinition<MessageRecipientEntity, MessageRecipient>;
    customerMessageAttachment: AssociationDefinition<CustomerMessageAttachmentEntity, CustomerMessageAttachment>;
    parent: AssociationDefinitionSingle<CustomerMessageEntity, CustomerMessage>;
    user: AssociationDefinitionSingle<UserEntity, User>;
    company: AssociationDefinitionSingle<CompanyEntity, Company>;
    children: AssociationDefinition<CustomerMessageEntity, CustomerMessage>;
    document: AssociationDefinitionSingle<DocumentEntity, Document>;
    formDocument: AssociationDefinitionSingle<FormDocumentEntity, FormDocument>;
    payment: AssociationDefinitionSingle<PaymentEntity, Payment>;
    action: AssociationDefinitionSingle<ActionEntity, Action>;
    job: AssociationDefinitionSingle<JobEntity, Job>;
}

interface CustomerMessage extends AutoGeneratedFunctions<CustomerMessageAssociations, CustomerMessageEntity, ComplexData<CustomerMessageEntity>> {
}

// eslint-disable-next-line no-redeclare
class CustomerMessage extends ComplexData<CustomerMessageEntity> {
    static Entity = CustomerMessageEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): CustomerMessageAssociations {
        return {
            company: {
                key: 'company',
                instance: Company,
                entity: CompanyEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().company_id,
                },
            },
            user: {
                key: 'user',
                instance: User,
                entity: UserEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().user_id,
                },
            },
            messageRecipient: {
                key: 'messageRecipient',
                instance: MessageRecipient,
                entity: MessageRecipientEntity,
                condition: {
                    message_id: this.Entity.getForeignFieldSymbols().id,
                },
            },
            customerMessageAttachment: {
                key: 'customerMessageAttachment',
                instance: CustomerMessageAttachment,
                entity: CustomerMessageAttachmentEntity,
                condition: {
                    customermessage_id: this.Entity.getForeignFieldSymbols().id,
                },
            },
            children: {
                key: 'customerMessage',
                instance: CustomerMessage,
                entity: CustomerMessageEntity,
                condition: {
                    parent_id: this.Entity.getFieldSymbols().id,
                },
            },
            parent: {
                key: 'customerMessage',
                instance: CustomerMessage,
                entity: CustomerMessageEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().parent_id,
                },
            },
            action: {
                key: 'action',
                instance: Action,
                entity: ActionEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().linked_id,
                    [this.Entity.getFieldSymbols().linked_type]: MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.APPOINTMENT,
                },
            },
            document: {
                key: 'document',
                instance: Document,
                entity: DocumentEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().linked_id,
                    [this.Entity.getFieldSymbols().linked_type]: MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.DOCUMENT,
                },
            },
            formDocument: {
                key: 'formDocument',
                instance: FormDocument,
                entity: FormDocumentEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().linked_id,
                    [this.Entity.getFieldSymbols().linked_type]: MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.FORMDOCUMENT,
                },
            },
            payment: {
                key: 'payment',
                instance: Payment,
                entity: PaymentEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().linked_id,
                    [this.Entity.getFieldSymbols().linked_type]: MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.PAYMENT,
                },
            },
            job: {
                key: 'job',
                instance: Job,
                entity: JobEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().job_id,
                },
            },
        };
    }

    public getLinkedTypeFromLinkRecord(recordToSend: Action | Document | Payment | FormDocument): number {
        return {
            Document: CONSTANTS.MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.DOCUMENT,
            Action: CONSTANTS.MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.APPOINTMENT,
            Payment: CONSTANTS.MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.PAYMENT,
            FormDocument: CONSTANTS.MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.FORMDOCUMENT,
        }[recordToSend.constructor.getModelName()];
    }

    public static async getTimelineRecipients(source: Document | FormDocument) {
        return Bluebird.map(await this.getCustomerMessageRecipients(source), async customerMessageRecipient => {
            const displayDate = customerMessageRecipient.data.dt_sent;
            return {
                date: dateUtils.isValid(displayDate) ? dateUtils.format(dateUtils.convertToLocalFromDateString(displayDate), 'DD/MM/YY HH:mm:ss') : 'N/A',
                email: customerMessageRecipient.data.recipient,
                opens: customerMessageRecipient.data.opens || 0,
                msg_error_message: customerMessageRecipient.data.msg_error_message && new TextEncoder().encode(customerMessageRecipient.data.msg_error_message), 
            };
        });
    }

    public static async getCustomerMessageRecipients(source: Document | FormDocument): Promise<MessageRecipient[]> {
        const customerMessages = (await source.getAllCustomerMessage()).filter(customerMessage => !customerMessage.data.parent_id);
        return Bluebird.reduce(customerMessages, async (customerMessageRecipients, customerMessage) => {
            const messageRecipients = await customerMessage.getAllMessageRecipient();
            return [
                ...customerMessageRecipients,
                ...messageRecipients,
            ];
        }, []);
    }

    public static getMessageTypeFromLinkRecord(recordToSend: Action | Document | Payment | FormDocument): number {
        return {
            Document: () => ({
                [DOCUMENT.TYPES_VALUES.INVOICE]: DefaultMessages.INVOICE_TO_CUSTOMER.ID,
                [DOCUMENT.TYPES_VALUES.QUOTE]: DefaultMessages.QUOTE_TO_CUSTOMER.ID,
                [DOCUMENT.TYPES_VALUES.WORKSHEET]: DefaultMessages.WORKSHEET_TO_CUSTOMER.ID,
                [DOCUMENT.TYPES_VALUES.CREDITNOTE]: DefaultMessages.CREDITNOTE_TO_CUSTOMER.ID,
                [DOCUMENT.TYPES_VALUES.SIGNATURE]: DefaultMessages.SIGNATURE_TO_CUSTOMER.ID,
                [DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE]: DefaultMessages.SUPPLIER_INVOICE_TO_CUSTOMER.ID,
                [DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE_CREDIT]: DefaultMessages.SUPPLIER_INVOICE_CREDIT_TO_CUSTOMER.ID,
                [DOCUMENT.TYPES_VALUES.EXPENSE]: DefaultMessages.EXPENSE_TO_CUSTOMER.ID,
                [DOCUMENT.TYPES_VALUES.EXPENSE_CREDITNOTE]: DefaultMessages.EXPENSE_CREDITNOTE_TO_CUSTOMER.ID,
                [DOCUMENT.TYPES_VALUES.PURCHASE_ORDER]: DefaultMessages.PURCHASE_ORDER_TO_CUSTOMER.ID,
            }[(recordToSend as Document).data.type]),
            Action: () => DefaultMessages.APPOINTMENT_CREATED.ID,
            Payment: () => DefaultMessages.PAYMENT.ID,
            FormDocument: () => DefaultMessages.FORMDOCUMENT_TO_CUSTOMER.ID,
        }[recordToSend.constructor.getModelName()]();
    }

    public async initWithLinkedRecord(linkedRecord: Action | Document | Payment | FormDocument, type: number = undefined) {
        const jobId = linkedRecord instanceof Payment ? (await linkedRecord.getDocument())?.data?.job_id : linkedRecord.data.job_id;
        const customerId = (await linkedRecord.getCustomer()).data.id;
        this.data.linked_id = linkedRecord.data.id;
        this.data.job_id = jobId;
        this.data.customer_id = customerId;
        this.data.type = type || CustomerMessage.getMessageTypeFromLinkRecord(linkedRecord);
        this.data.linked_type = this.getLinkedTypeFromLinkRecord(linkedRecord);
        this.data.direction = CONSTANTS.MESSAGES.MESSAGE_DIRECTION.OUTGOING;
        this.data.parent_id = 0;
    }

    public async addAttachments(attachments: Attachment[]) {
        await Bluebird.map(attachments, attachment => this.addCustomerMessageAttachment({
            attachment_id: attachment.data.id,
        }));
        const allAttachements = await this.getAllCustomerMessageAttachment();
        this.data.attachment_count = attachments.length;
        return allAttachements;
    }

    public async getAttachments(): Promise<Attachment[]> {
        return Bluebird.map(this.getAllCustomerMessageAttachment(), customerAttachment => customerAttachment.getAttachment());
    }

    public async addRecipientFromContactMethod(contactMethod: ContactMethod, contactOrUser: Contact | User): Promise<MessageRecipient> {
        return this.addMessageRecipient({
            contact_id: contactOrUser instanceof Contact ? contactOrUser.data.id : null,
            user_id: contactOrUser instanceof User ? contactOrUser.data.id : null,
            contactmethodtype_id: contactMethod.data.contactmethodtype_id,
            recipient: contactMethod.data.value,
            recipientName: await contactOrUser.getFullName(),
        });
    }

    public async getMessageTypeString(): Promise<string> {
        const messageDefinition = this.findDefaultValueById();
        return messageDefinition.NAME;
    }

    public async getReplyToUser(): Promise<User> {
        const user = await this.getUser();
        if (user) {
            return user;
        }
        const company: Company = await this.getCompany();
        return company.getMainUser();
    }

    public async addReply(
        recipientDetails: Partial<ComplexModelFields<MessageRecipientEntity>> | MessageRecipient,
        direction: number = undefined,
        customerMessage = new CustomerMessage({}),
        messageRecipient = new MessageRecipient({}),
    ): Promise<{
        customerMessage: CustomerMessage,
        messageRecipient: MessageRecipient,
    }> {
        let replyDirection = direction;

        if (typeof direction === 'undefined') {
            replyDirection = (this.data.direction === MESSAGES.MESSAGE_DIRECTION.INCOMING ? MESSAGES.MESSAGE_DIRECTION.OUTGOING : MESSAGES.MESSAGE_DIRECTION.INCOMING);
        }

        const {
            user_id,
            customer_id,
            company_id,
            linked_id,
            linked_type,
            job_id,
            type,
        } = this.data;
        Object.assign(customerMessage.data, {
            user_id,
            customer_id,
            company_id,
            linked_id,
            linked_type,
            job_id,
            type,
            parent_id: this.data.id,
            direction: replyDirection,
            dt_created: dateUtils.getNow(),
        });
        const newMessage = await this.addChildren(customerMessage);

        Object.assign(messageRecipient.data, recipientDetails);
        await newMessage.addMessageRecipient(messageRecipient);
        return {
            customerMessage: newMessage,
            messageRecipient,
        };
    }

    public findDefaultValueById(): MessageDefault {
        return Object.values(DefaultMessages)
            .find(messageValue => messageValue.ID === this.data.type);
    }

    public findDefaultContentById(): MessageDefaultContent {
        const messageDefault = this.findDefaultValueById();
        if (!messageDefault?.CONTENT) {
            throw Object.assign(new Error('No default values for message type'), {
                details: {
                    type: this.data.type,
                    id: this.data.id,
                },
            });
        }
        return messageDefault.CONTENT;
    }

    public async getLinkedItem(): Promise<Action | Document | Payment | FormDocument> {
        const linkedItemGetter = {
            [MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.DOCUMENT]: () => this.getDocument(),
            [MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.APPOINTMENT]: () => this.getAction(),
            [MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.PAYMENT]: () => this.getPayment(),
            [MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.FORMDOCUMENT]: () => this.getFormDocument(),
        }[this.data.linked_type];
        return linkedItemGetter && linkedItemGetter();
    }

    public async getLinkedRecordTemplateData(): Promise<object> {
        const linkedItem = await this.getLinkedItem();
        if (!linkedItem) {
            return {};
        }
        return linkedItem.getTemplateData();
    }

    public async getDisplayRecipient(): Promise<MessageRecipient> {
        return this.getRecipientForChannel('EMAIL');
    }

    public async getRecipientForChannel(channel: ChannelTypes): Promise<MessageRecipient> {
        const recipients = await this.getAllMessageRecipient();
        const emailRecipient = recipients.find(recipient => recipient.getChannelType() === channel);
        return emailRecipient || recipients[0];
    }

    public async getHistory() {
        const firstMessage = !this.data.parent_id ? this : await this.getParent();
        const childrenMessages = firstMessage ? await firstMessage.getAllChildren() : [];

        childrenMessages.sort((messageA, messageB) => (new Date(messageA.data.dt_created).getTime() - new Date(messageB.data.dt_created).getTime()));

        return [firstMessage, ...childrenMessages];
    }

    public async getContacts(): Promise<Contact[]> {
        const recipients = await this.getAllMessageRecipient();
        return Bluebird.map(recipients, recipient => recipient.getContact());
    }

    public async getNotificationTemplateData() {
        const job = await this.getJob();
        const customer = await job?.getCustomer();
        const company = await this.getCompany();

        const extender = new TemplateDataExtender({
            projectName: job?.data.description,
            customerName: await customer?.getFullName(),
            currencyOptions: (await company.getCurrency()).getData().getDataValues(),
        });
        await extender.addLinkedRecordTemplateData(this);
        await extender.addLocaleInfoForCompany(company);
        return extender.getData();
    }
}

export default CustomerMessage;
