import Entity, { DateOrString, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class CompanySettingsEntity extends Entity {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'int' })
    public setting_key_id: number;
    
    @field({ type: 'string' })
    public setting_value: string | object | number;
    
    @field({
        type: 'string', dbType: 'date', serverOnly: true, defaultValue: dateUtils.getNow.bind(dateUtils), 
    })
    public dt_created: DateOrString;
    
    @field({ type: 'string', dbType: 'date', serverOnly: true })
    public dt_updated: DateOrString;
    
    @field({
        type: 'boolean', codeName: 'isDeleted', allowNull: false, defaultValue: false, serverOnly: true, 
    })
    public isdeleted: boolean;
}
