import textUtils from 'modules/utilities/textUtils';
import SETTINGS_KEYS from '@powerednow/shared/constants/settingsKeys';
import { ACTION } from '../../../constants';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import AppointmentTypeEntity from './entity';
import modelProperties from './modelProperties';
import BookingDateException from '../bookingDateException';
import BookingDateExceptionEntity from '../bookingDateException/entity';
import Company from '../company';
import CompanyEntity from '../company/entity';

interface AppointmentTypeAssociations extends AssociationConfig<any, any> {
    bookingDateException: AssociationDefinition<BookingDateExceptionEntity, BookingDateException>
    company: AssociationDefinitionSingle<CompanyEntity, Company>
}

interface AppointmentType extends AutoGeneratedFunctions<AppointmentTypeAssociations, AppointmentTypeEntity, ComplexData<AppointmentTypeEntity>> {}

// eslint-disable-next-line no-redeclare
class AppointmentType extends ComplexData<AppointmentTypeEntity> {
    static Entity = AppointmentTypeEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): AppointmentTypeAssociations {
        return {
            bookingDateException: {
                key: 'bookingDateException',
                instance: BookingDateException,
                entity: BookingDateExceptionEntity,
                condition: {
                    appointmentTypeId: this.Entity.getFieldSymbols().id,
                },
            },
            company: {
                key: 'company',
                instance: Company,
                entity: CompanyEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().company_id,
                },
            },
        };
    }

    public async isDeleteAllowed(): Promise<Boolean> {
        const count = (await this.getAllBookingDateException()).length;
        return count === 0;
    }

    async getSymbolizedPriceText() {
        const company = await this.getCompany();
        const currency = await company.getCurrency();

        return textUtils.formatMoney(this.data.price, currency.data);
    }

    getDurationMinutes() {
        const durationData = ACTION.CUSTOM_APPOINTMENT_TYPE.DEFAULT_DURATION.find(defaultDuration => defaultDuration.id === this.dataValues.id);
        return durationData?.value || 0;
    }

    async getCompanyDefaultDuration() {
        const company = await this.getCompany();
        return company.getSettingValue(SETTINGS_KEYS.DEFAULT_APPOINTMENT_LENGTH);
    }

    async getDefaultDuration() {
        return this.dataValues.defaultDuration || this.getCompanyDefaultDuration();
    }
}

export default AppointmentType;
