import Entity, { field } from '../entity';
    
export default class BusinessTypesEntity extends Entity {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'string' })
    public type: string;
    
    @field({ type: 'int' })
    public sequence: number;
}
