const modelProperties = {
    modelName: 'DocumentItem',
    tableName: 'DocumentItem',
    associations: [{
        type: 'belongsTo',
        model: 'Document',
        parameters: { foreignKey: { name: 'document_id', fieldName: 'document_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'MediaGroup',
        parameters: { as: 'Media', foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
        where: { type: 'Media' },
        followFor: ['Archive', 'DocumentClone'],
    }, {
        type: 'belongsTo',
        model: 'JobItemGroup',
        parameters: { as: 'JobItemGroup', foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
    }, {
        type: 'belongsTo',
        model: 'JobItemGroup',
        parameters: { as: 'JobItem', foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
        where: { type: 'JobItem' },
        followFor: ['Archive', 'DocumentClone'],
        includeRestores: ['DocumentItem'],
    }, {
        type: 'belongsTo',
        model: 'Note',
        parameters: { as: 'Note', foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
        where: { type: 'Note' },
        followFor: ['Archive', 'DocumentClone'],
    }, {
        type: 'belongsTo',
        model: 'Action',
        parameters: { as: 'Action', foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
        where: { type: 'Action' },
        followFor: ['Archive', 'ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'Message',
        parameters: { as: 'Message', foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
        where: { type: 'Message' },
        followFor: ['Archive', 'ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'Signature',
        parameters: { as: 'Signature', foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
        where: { type: 'Signature' },
        followFor: ['Archive', 'DocumentClone'],
    }],
};

export default modelProperties;
