import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

export default class AdminAuditTrailEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true, autoIncrement: true })
    public id?: number;

    @field({ type: 'int', serverOnly: true, allowNull: false })
    public company_id: number;

    @field({ type: 'int', allowNull: false })
    public user_id: number;

    @field({ type: 'int', allowNull: false })
    public event_type: number;

    @field({ type: 'int' })
    public linked_company_id: number;

    @field({ type: 'int' })
    public linked_id: number;

    @field({ type: 'string' })
    public linked_table_name: string;

    @field({ type: 'string' })
    public note: string;

    @field({ type: 'string', isJSON: true })
    public details: { data: any, comment: string };

    @field({ type: 'string', dbType: 'date', defaultValue: 'NOW' })
    public dt_created: DateOrString;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;
}
