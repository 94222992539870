import ContactMethodType from 'modules/complexData/contactMethodType';
import constants from '@powerednow/shared/constants';
import * as Bluebird from 'bluebird';
import {
    ChannelTypeDetails, ChannelTypes, ContactMethodTypeMap,
} from '@powerednow/shared/constants/customerEmailTemplateValues';
import ComplexData, {
    AssociationConfig,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
    FieldChangeEventHandlers,
} from '../complexData';
import ContactMethodEntity from './entity';
import modelProperties from './modelProperties';
import { ModelCreationFields } from '../entity';
import ContactMethodTypeEntity from '../contactMethodType/entity';
import ContactEntity from '../contact/entity';
import Contact from '../contact';

interface ContactMethodAssociations extends AssociationConfig<any, any> {
    contactMethodType: AssociationDefinitionSingle<ContactMethodTypeEntity, ContactMethodType>
    contact: AssociationDefinitionSingle<ContactEntity, Contact>
}

interface ContactMethod extends AutoGeneratedFunctions<ContactMethodAssociations, ContactMethodEntity, ComplexData<ContactMethodEntity>> {
}

// eslint-disable-next-line no-redeclare
class ContactMethod extends ComplexData<ContactMethodEntity> {
    static Entity = ContactMethodEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): ContactMethodAssociations {
        return {
            contactMethodType: {
                key: 'contactMethodType',
                instance: ContactMethodType,
                entity: ContactMethodTypeEntity,
                single: true,
                condition: {
                    id: this.Entity.getFieldSymbols().contactmethodtype_id,
                },
            },
            contact: {
                key: 'contact',
                instance: Contact,
                entity: ContactEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().contact_id,
                },
            },
        };
    }

    protected get fieldChangeEventHandlers(): FieldChangeEventHandlers {
        return {
            value: () => {
                this.data.is_valid = false;
            },
        };
    }

    static get singularName(): string {
        return 'ContactMethod';
    }

    /* eslint class-methods-use-this: "off" */
    canReceiveSMS() {
        return false;
    }

    isWhatsApp() {
        return this.data.contactmethodtype_id === constants.MESSAGES.TYPES.WHATSAPP;
    }

    public async canReceiveDoc(): Promise<boolean> {
        return Boolean(await this.getContactMethodTypeProperty('canreceivedoc'));
    }

    public async isRegular(): Promise<boolean> {
        return Boolean(await this.getContactMethodTypeProperty('is_regular'));
    }

    public async isAdditional(): Promise<boolean> {
        return Boolean(await this.getContactMethodTypeProperty('is_additional'));
    }

    public async canText(): Promise<boolean> {
        return Boolean(await this.getContactMethodTypeProperty('can_text'));
    }

    public async canEmail(): Promise<boolean> {
        return Boolean(await this.getContactMethodTypeProperty('can_email'));
    }

    public async canCall(): Promise<boolean> {
        return Boolean(await this.getContactMethodTypeProperty('can_call'));
    }

    public hasValue() {
        return (this.data.value || '').trim() !== '';
    }

    public async hasChannel(channel: ChannelTypes) {
        const canText = await this.canText();
        const canCall = await this.canCall();
        const canEmail = await this.canEmail();
        return (channel === 'SMS' && canText)
            || (channel === 'CALL' && canCall)
            || (channel === 'EMAIL' && canEmail);
    }

    public async isContactable(channels: ChannelTypes[]) {
        const isContactable = await Bluebird.reduce(channels, async (contactable, channel) => {
            const hasChannel = await this.hasChannel(channel);
            return contactable || hasChannel;
        }, false);
        return this.hasValue() && isContactable;
    }

    public async findContactMethodType(): Promise<any> {
        return this.getContactMethodType();
    }

    public async getContactMethodTypeProperty(property): Promise<any> {
        const methodType = await this.findContactMethodType();
        if (methodType && methodType.data) {
            return methodType.data[property];
        }
        const error = new Error('cannot find contactMethod');
        throw Object.assign(error, {
            details: {
                contactMethod: this.dataValues,
                contactMethodTypeProperty: property,
            },
        });
    }

    public async getSearchableFields(): Promise<string[]> {
        return [this.data.value];
    }

    public getPropertiesToCopy() {
        const { value } = this.data.getPureDataValues();
        return { value };
    }

    public async copyData(item: ContactMethod) {
        Object.assign(this.data, item.getPropertiesToCopy());
        return this;
    }

    public async getTemplateData(): Promise<ModelCreationFields<ContactMethodEntity> & { name?: string }> {
        const data: ModelCreationFields<ContactMethodEntity> & { name?: string } = { ...this.data.getPureDataValues() };

        const contactMethodType = await this.getContactMethodType();
        data.name = contactMethodType.data.name;

        return data;
    }

    public getChannelType(): ChannelTypes {
        return ContactMethodTypeMap[this.data.contactmethodtype_id];
    }

    public getChannelTypeId(): number {
        return ChannelTypeDetails[this.getChannelType()].ID;
    }
}

export default ContactMethod;
