const modelProperties = {
    modelName: 'LastMessageInThread',
    tableName: 'LastMessageInThread',
    syncTableName: 'CustomerMessage',
    associations: [{
        type: 'hasOne',
        model: 'CustomerMessage',
        parameters: { foreignKey: { name: 'id', fieldName: 'id' } },
    }],
}; 

export default modelProperties;
