import { ValueOf } from '@powerednow/type-definitions';
import { SETTINGS_KEYS, USER_PROFILE } from './index';

export type NotificationUserGroup = {
    id: number,
    UserProfileIds: number[],
    IncludedUserIds?: number[],
    ExcludedUserIds?: number[],
}

const createNotificationUserGroups = <T extends Record<string, NotificationUserGroup>>(notificationUserGroup: T) => notificationUserGroup;

export type CustomerNotificationGroup = {
    id: number,
    title: string,
}

const createNotificationGroup = <T extends Record<string, CustomerNotificationGroup>>(notificationGroup: T) => notificationGroup;

export const CustomerNotificationGroups = createNotificationGroup({
    MainContact: {
        id: 1,
        title: 'Only main contact',
    },
    SiteContact: {
        id: 2,
        title: 'Only address contact',
    },
    Both: {
        id: 3,
        title: 'Both',
    },
});

export const CustomerNotificationOptions = {
    On: { id: 1, title: 'On' },
    Off: { id: 2, title: 'Off' },
    Ask: { id: 3, title: 'Ask' },
};
export const CustomerNotifications = {
    AppointmentCreated: { settingId: SETTINGS_KEYS.CUSTOMER_APPOINTMENT_NOTIFICATION_CREATED, title: 'Notify on appointment creation' },
    AppointmentUpdated: { settingId: SETTINGS_KEYS.CUSTOMER_APPOINTMENT_NOTIFICATION_UPDATED, title: 'Notify on appointment date/time or assignment change' },
    AppointmentDeleted: { settingId: SETTINGS_KEYS.CUSTOMER_APPOINTMENT_NOTIFICATION_DELETED, title: 'Notify on cancellation of appointment' },
    AttendingAppointment: { settingId: SETTINGS_KEYS.CUSTOMER_APPOINTMENT_NOTIFICATION_ATTENDINGNOW, title: 'Notify customer on going to appointment' },
};

export const NotificationUserGroups = createNotificationUserGroups({
    None: { id: 1, UserProfileIds: [] },
    All: { id: 2, UserProfileIds: [USER_PROFILE.MAIN_USER, USER_PROFILE.ENGINEER, USER_PROFILE.MANAGER] },
    Main: { id: 3, UserProfileIds: [USER_PROFILE.MAIN_USER] },
    Managers: { id: 4, UserProfileIds: [USER_PROFILE.MANAGER] },
    Selected: { id: 5, UserProfileIds: [] },
});

export const NotificationUserGroupNames: Record<keyof typeof NotificationUserGroups, string> = {
    None: 'Don\'t notify',
    All: 'All users',
    Main: 'Main user only',
    Managers: 'Managers only',
    Selected: 'Selected users',
};

export type NotificationDefinition = {
    id: number,
    default: ValueOf<typeof NotificationUserGroups>,
    title: {
        value: string,
        promptId: string,
    },
    description: {
        value: string,
        promptId: string,
    },
    ignoreChannelSettings?:boolean,
}

const createDefaultNotifications = <T extends Record<string, NotificationDefinition>>(notificationDefinition: T) => notificationDefinition;

const DefaultNotifications = createDefaultNotifications({
    notifyOnFormExpiration: {
        id: SETTINGS_KEYS.NOTIFY_ON_FORM_EXPIRATION,
        default: NotificationUserGroups.Main,
        title: {
            value: 'Notify when a certificate is about to expire',
            promptId: 'NotificationSettings.FormExpirationText',
        },
        description: {
            value: 'You will receive a notification when a certificate is going to be expired',
            promptId: 'NotificationSettings.FormExpirationDescription',
        },
        ignoreChannelSettings: true, // It is controlled per-form basis
    },
    notifyOnQuoteStatus: {
        id: SETTINGS_KEYS.NOTIFY_ON_QUOTE_STATUS,
        default: NotificationUserGroups.Main,
        title: {
            value: 'Notify when a quote has been accepted or rejected',
            promptId: 'NotificationSettings.QuotesText',
        },
        description: {
            value: 'You will receive a notification any time a customer changes the status of a Quote to either Accepted or Rejected.',
            promptId: 'NotificationSettings.QuotesDescription',
        },
        ignoreChannelSettings: false,
    },
    notifyOnPayment: {
        id: SETTINGS_KEYS.NOTIFY_ON_PAYMENT,
        default: NotificationUserGroups.Main,
        title: {
            value: 'Notify when a payment has been made',
            promptId: 'NotificationSettings.PaymentText',
        },
        description: {
            value: 'You will receive an automated notification any time a payment is received or refunded.',
            promptId: 'NotificationSettings.PaymentDescription',
        },
        ignoreChannelSettings: false,
    },
    notifyOnAppointment: {
        id: SETTINGS_KEYS.NOTIFY_ON_APPOINTMENT,
        default: NotificationUserGroups.All,
        title: {
            value: 'Notify at the start of Appointments and Reminders',
            promptId: 'NotificationSettings.JobsText',
        },
        description: {
            value: 'You will receive an automated notification any time you have an Appointment or Reminder set in the Diary.',
            promptId: 'NotificationSettings.JobsDescription',
        },
        ignoreChannelSettings: false,
        customTitles: {
            All: 'Assignee only',
        },
    },
    notifyOnEmailOpen: {
        id: SETTINGS_KEYS.NOTIFY_ON_EMAIL_OPEN,
        default: NotificationUserGroups.Main,
        title: {
            value: 'Notify when an email has been opened',
            promptId: 'NotificationSettings.EmailOpenText',
        },
        description: {
            value: 'You will receive an automated notification any time a document sent to one of your Contacts is opened.',
            promptId: 'NotificationSettings.EmailOpenDescription',
        },
        ignoreChannelSettings: false,
    },
    notifyOnChatMessage: {
        id: SETTINGS_KEYS.NOTIFY_ON_CHAT_MESSAGE,
        default: NotificationUserGroups.All,
        title: {
            value: 'Notify when chat message arrived',
            promptId: 'NotificationSettings.ChatMessage',
        },
        description: {
            value: 'You will receive an automated notification any time a User within your system sends you a message.',
            promptId: 'NotificationSettings.ChatMessageDescription',
        },
        ignoreChannelSettings: true, // in-app
        customTitles: {
            All: 'Recipient only',
        },
    },
    notifyOnTeamLogin: {
        id: SETTINGS_KEYS.NOTIFY_ON_TEAM_LOGIN,
        default: NotificationUserGroups.None,
        title: {
            value: 'Notify when team member is currently active',
            promptId: 'NotificationSettings.userLogin',
        },
        description: {
            value: 'Enable or disable the “User Active” pop-up at the top of your Powered Now screen.',
            promptId: 'NotificationSettings.userLoginDescription',
        },
        ignoreChannelSettings: true, // in-app
    },
    notifyOnNewMessage: {
        id: SETTINGS_KEYS.NOTIFY_ON_NEW_MESSAGE,
        default: NotificationUserGroups.Main,
        title: {
            value: 'Notify when a customer has sent a message',
            promptId: 'NotificationSettings.NewMessage',
        },
        description: {
            value: 'You will receive an automated notification any time a customer replies to your message.',
            promptId: 'NotificationSettings.NewMessageDescription',
        },
        ignoreChannelSettings: false,
    },
    notifyOnNewBooking: {
        id: SETTINGS_KEYS.NOTIFY_ON_NEW_BOOKING,
        default: NotificationUserGroups.Main,
        title: {
            value: 'Notify on new appointment booking.',
            promptId: 'NotificationSettings.NewBooking',
        },
        description: {
            value: 'You will receive a notification any time a customer places an appointment booking at your customer portal',
            promptId: 'NotificationSettings.NewBookingDescription',
        },
        ignoreChannelSettings: false,
    },
    notifyOnAppointmentAssignment: {
        id: SETTINGS_KEYS.NOTIFY_ON_APPOINTMENT_ASSIGNMENT,
        default: NotificationUserGroups.All,
        title: {
            value: 'Tell assignees about appointments assigned to them',
            promptId: 'NotificationSettings.NewAssignment',
        },
        description: {
            value: 'Users will get an automated notification any time an appointment is allocated to them.',
            promptId: 'NotificationSettings.NewAssignmentDescription',
        },
        ignoreChannelSettings: false,
        customTitles: {
            All: 'To assignee',
        },
    },
    notifyOnAppointmentDeAssignment: {
        id: SETTINGS_KEYS.NOTIFY_ON_APPOINTMENT_DEASSIGNMENT,
        default: NotificationUserGroups.All,
        title: {
            value: 'Tell assignees about appointments unassigned',
            promptId: 'NotificationSettings.DeAssignment',
        },
        description: {
            value: 'Users will get an automated notification any time an appointment is no longer allocated to them.',
            promptId: 'NotificationSettings.DeAssignmentDescription',
        },
        ignoreChannelSettings: false,
        customTitles: {
            All: 'To assignee',
        },
    },
    notifyOnAppointmentAssignmentToTeamMember: {
        id: SETTINGS_KEYS.NOTIFY_ON_APPOINTMENT_ASSIGNMENT_TO_TEAM_MEMBER,
        default: NotificationUserGroups.None,
        title: {
            value: 'Notify about appointment assigned to a team member',
            promptId: 'NotificationSettings.NewAssignmentToTeamMember',
        },
        description: {
            value: 'Users will get an automated notification any time an appointment is allocated to another team member.',
            promptId: 'NotificationSettings.NewAssignmentToTeamMemberDescription',
        },
        ignoreChannelSettings: false,
    },
    appointmentCheckinNotifications: {
        id: SETTINGS_KEYS.APPOINTMENT_CHECKIN_NOTIFICATIONS,
        default: NotificationUserGroups.None,
        title: {
            value: 'Notify on appointment Check-in/out.',
            promptId: 'NotificationSettings.AppointmentCheckin',
        },
        description: {
            value: 'Users will get an automated notification any time a checkin has been started or finished on an appointment by another team member.',
            promptId: 'NotificationSettings.AppointmentCheckinDescription',
        },
        ignoreChannelSettings: false,
    },
});

export default DefaultNotifications;
