const modelProperties = {
    modelName: 'WhiteLabel',
    tableName: 'WhiteLabel',
    associations: [{
        type: 'belongsToMany',
        model: 'AppVersion',
        parameters: {
            through: { model: 'WhiteLabelToAppVersion', unique: false },
            foreignKey: { name: 'white_label_id', fieldName: 'white_label_id' },
        },
    }, {
        type: 'belongsToMany',
        model: 'Customer',
        modelKeys: { primary: 'id', foreign: 'customer_id' },
        parameters: {
            as: 'Customers',
            through: { model: 'WhiteLabelToCustomer', unique: false },
            foreignKey: { name: 'whitelabel_id', fieldName: 'whitelabel_id' },
            otherKey: { name: 'customer_id', fieldName: 'customer_id' },
        },
    }, {
        type: 'belongsToMany',
        model: 'Country',
        modelKeys: { primary: 'id', foreign: 'country_id' },
        parameters: {
            as: 'Country',
            through: { model: 'WhiteLabelToCountry', unique: false },
            foreignKey: { name: 'whitelabel_id', fieldName: 'whitelabel_id' },
            otherKey: { name: 'country_id', fieldName: 'country_id' },
        },
    }],
    global: true,
};

export default modelProperties;
