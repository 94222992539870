export default {
    RATES: {
        GROSS_PAYMENT: 1,
        NET: 2,
        HIGHER_RATE_30: 3,
        C2_EXEMPTION_CARD: 4,
        STANDARD: 5,
        HIGHER_RATE_35: 6,
    },
};
