import MESSAGES from './messages';

export type MessageContentDefinition = {
    HIDE_ACTION?: boolean,
    SUBJECT?: {
        PROMPT?: string,
        TEXT: string,
    },
    BODY: {
        PROMPT?: string,
        TEXT: string,
        BUTTON_PROMPT?: string,
        BUTTON_TEXT?: string,
    },
    OPEN_NOTIFICATION?: {
        EMAIL: {
          PROMPT_ID?: string,
          PROMPT_TEXT: string,
        },
        PUSH: {
            PROMPT_ID?: string,
            PROMPT_TEXT: string,
        },
    },
}

export type ChannelTypes = 'PUSH' | 'SMS' | 'EMAIL' | 'WHATSAPP' | 'ELECTRONIC' | 'PORTAL'| 'CALL'
export const ChannelTypeDetails: Record<ChannelTypes, { ID: number; HTML?: boolean }> = {
    PUSH: { ID: 0, HTML: false },
    SMS: { ID: 1, HTML: false },
    EMAIL: { ID: 2, HTML: true },
    WHATSAPP: { ID: 3, HTML: false },
    ELECTRONIC: { ID: 4, HTML: true },
    PORTAL: { ID: 5, HTML: true },
    CALL: { ID: 6, HTML: false },
};

export const channelTypeIdToType: Record<number, ChannelTypes> = Object.entries(ChannelTypeDetails).reduce((types, [key, value]) => ({
    ...types,
    [value.ID]: key,
}), {});

export const channelTypeFallbacks: Partial<Record<ChannelTypes, ChannelTypes>> = {
    WHATSAPP: 'SMS',
    SMS: 'EMAIL',
};

export type MessageDefaultContent = {
    [K in ChannelTypes]?: MessageContentDefinition
}

export type MessageDefault = {
    ID: number,
    NAME: string,
    EDITABLE?: boolean,
    CONTENT: MessageDefaultContent,
}

export const ContactMethodTypeMap: Record<number, ChannelTypes> = {
    [MESSAGES.TYPES.PUSH]: 'PUSH',
    [MESSAGES.TYPES.SMS]: 'SMS',
    [MESSAGES.TYPES.EMAIL]: 'EMAIL',
    [MESSAGES.TYPES.ELECTRONIC]: 'ELECTRONIC',
    [MESSAGES.TYPES.WORK]: 'SMS',
    [MESSAGES.TYPES.HOME]: 'SMS',
    [MESSAGES.TYPES.EMAIL_CC]: 'EMAIL',
    [MESSAGES.TYPES.WHATSAPP]: 'WHATSAPP',
    [MESSAGES.TYPES.PORTAL]: 'PORTAL',
};

const appointmentOpenEmailNotificationMessage = 'Good News!<br/><br/>Looks like the {messageType} message you sent to '
    + '<b>{customerName}</b> from the project named <i>- {projectName} -</i> about an appointment scheduled with you from <b>{dt_start}</b> with the title '
    + '<i>- {action} -</i> has just been opened. You don\'t need to do anything, but '
    + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>Need help with '
    + 'Powered Now? Simply reply to this mail!';

const appointmentDeletedOpenEmailNotificationMessage = 'Good News!<br/><br/>Looks like the {messageType} message you sent to '
    + '<b>{customerName}</b> from the project named <i>- {projectName} -</i> has just been opened. You don\'t need to do anything, but '
    + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>Need help with '
    + 'Powered Now? Simply reply to this mail!';

const appointmentOpenPushNotificationMessage = 'Good News!<br/><br/>Looks like the {messageType} message you sent to '
    + '<b>{customerName}</b> from the project named <i>- {projectName} -</i> about an appointment scheduled with you from <b>{dt_start}</b> with the title '
    + '<i>- {action} -</i> has just been opened. You don\'t need to do anything, but '
    + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>';

const documentOpenEmailNotificationMessage = 'Good News!<br/><br/>Looks like the {messageType} message you sent with display number <b>{displaynumber}</b> to '
    + '<b>{customerName}</b> from the project named <i>- {projectName} -</i> has just been opened. You don\'t need to do anything, but '
    + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>Need help with '
    + 'Powered Now? Simply reply to this mail!';

const documentOpenPushNotificationMessage = 'Good News!<br/><br/>Looks like the {messageType} message you sent with display number <b>{displaynumber}</b> to '
    + '<b>{customerName}</b> from the project named <i>- {projectName} -</i> has just been opened. You don\'t need to do anything, but '
    + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>';

const paymentOpenEmailNotificationMessage = 'Good News!<br/><br/>Looks like the {messageType} message you sent about a payment of <b>{amount:formatMoney(values.currencyOptions)}</b> on ' 
    + '<i>{dt_created:formatLocaleDateTime(values, "Do MMM Y")}</i> to '
    + '<b>{customerName}</b> from the project named <i>- {projectName} -</i> has just been opened. You don\'t need to do anything, but '
    + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>Need help with '
    + 'Powered Now? Simply reply to this mail!';

const paymentOpenPushNotificationMessage = 'Good News!<br/><br/>Looks like the {messageType} message you sent about a payment of <b>{amount:formatMoney(values.currencyOptions)}</b> on '
    + '<i>{dt_created:formatLocaleDateTime(values, "Do MMM Y")}</i> to '
    + '<b>{customerName}</b> from the project named <i>- {projectName} -</i> has just been opened. You don\'t need to do anything, but '
    + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>';

const formDocumentOpenEmailNotificationMessage = 'Good News!<br/><br/>Looks like the {certificateName} message you sent with display number <b>{formDocument.displayNumber}</b> to '
    + '<b>{customerName}</b> from the project named <i>- {projectName} -</i> has just been opened. You don\'t need to do anything, but '
    + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>Need help with '
    + 'Powered Now? Simply reply to this mail!';

const formDocumentOpenPushNotificationMessage = 'Good News!<br/><br/>Looks like the {certificateName} message you sent with display number <b>{formDocument.displayNumber}</b> to '
    + '<b>{customerName}</b> from the project named <i>- {projectName} -</i> has just been opened. You don\'t need to do anything, but '
    + 'we thought it would be nice to let you know.<br/><br/>Happy Invoicing!<br/><br/>–<br/>';

const directMessageOpenNotificationMessage = 'Good News!<br/><br/>Looks like the message you sent to '
    + '<b>{customerName}</b> from the project named <i>- {projectName} -</i> has just been opened. You don\'t need to do anything, but '
    + 'we thought it would be nice to let you know.';

const DefaultMessages: Record<string, MessageDefault> = {
    DIRECT_MESSAGE: {
        ID: -1,
        EDITABLE: false,
        NAME: 'Direct message',
        CONTENT: {
            EMAIL: {
                HIDE_ACTION: true,
                SUBJECT: {
                    TEXT: '',
                },
                BODY: {
                    TEXT: '',
                    BUTTON_TEXT: 'Reply to this message',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: directMessageOpenNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: directMessageOpenNotificationMessage,
                    },
                },
            },
        },
    },
    FORM_EXPIRATION_REMINDER: {
        ID: -2,
        EDITABLE: false,
        NAME: 'Form expiration reminder',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    TEXT: '', // This is required by type but we actually never use it
                },
                BODY: {
                    TEXT: '', // This is required by type but we actually never use it
                },
            },
        },
    },
    INVOICE_TO_CUSTOMER: {
        ID: 0,
        NAME: 'New invoice',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Message.NewInvoiceEmailSubject_v9',
                    TEXT: 'New Invoice from {companyname}',
                },
                BODY: {
                    PROMPT: 'DocumentMail.TextLine2InvoiceWithoutPay_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Invoice ({displaynumber}) from {companyname}. '
                        + 'You can view <tpl if="documenturl!==\'\'"> <tpl if="canUsePortal"> or reply to </tpl> your invoice online by clicking the link below or you can see</tpl> it in the attachment of this email.',
                    BUTTON_TEXT: 'View Invoice <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Message.NewInvoiceSMSMessage_v9',
                    TEXT: 'Dear {customername},\n{companyname} has sent you an Invoice. To view click here: {documenturl} ',
                },
            },
            WHATSAPP: {
                BODY: {
                    PROMPT: 'Message.NewInvoiceWhatsAppMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Invoice from {companyname}. You can view <tpl if="canUsePortal"> or reply to </tpl> your Invoice online: {documenturl} ',
                },
            },
            ELECTRONIC: {
                SUBJECT: {
                    PROMPT: 'Message.NewInvoiceElectronicSubject_v9',
                    TEXT: 'New Invoice from {companyname}',
                },
                BODY: {
                    PROMPT: 'DocumentMail.TextLine2ElectronicInvoice_v9',
                    TEXT: 'Your invoice is currently rejected. You can approve it <tpl if="canUsePortal"> or reply </tpl> in your Powered Now account by clicking the button below.',
                    BUTTON_TEXT: 'View Document <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
            },
        },
    },
    QUOTE_TO_CUSTOMER: {
        ID: 1,
        NAME: 'New Quote/Estimate',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Message.NewQuoteEmailSubject_v9',
                    TEXT: 'New Quote from {companyname}',
                },
                BODY: {
                    PROMPT: 'DocumentMail.TextLine2Quote_v9',
                    TEXT: 'Dear {customername},<br/> You have received a new Quote ({displaynumber}) from {companyname}. '
                        + 'You can view<tpl if="documenturl!==\'\'">, accept, reject <tpl if="canUsePortal"> or reply to </tpl> '
                        + 'your quote online by clicking the link below or you can see</tpl> it in the attachment of this email.',
                    BUTTON_TEXT: 'View {quoteTerminology} <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Message.NewQuoteSMSMessage_v9',
                    TEXT: 'Dear {customername},\n{companyname} has sent you a Quote. To view, accept or reject, click here: {documenturl} ',
                },
            },
            WHATSAPP: {
                BODY: {
                    PROMPT: 'Message.NewQuoteWhatsAppMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/> {companyname} has sent you a Quote. You can view, accept, reject <tpl if="canUsePortal"> or reply to </tpl>your Quote online: {documenturl} ',
                },
            },
        },
    },
    WORKSHEET_TO_CUSTOMER: {
        ID: 3,
        NAME: 'New jobsheet',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Message.NewWorksheetEmailSubject_v9',
                    TEXT: 'New job sheet from {companyname}',
                },
                BODY: {
                    PROMPT: 'DocumentMail.TextLine2WorkSheet_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new job sheet ({displaynumber}) from {companyname}. '
                        + 'You can view your worksheet <tpl if="documenturl!==\'\'">online <tpl if="canUsePortal"> or reply </tpl> by clicking the link below or you can see it</tpl> in the attachment of this email.'
                        + '<br/>Location: {location}'
                        + '<tpl if="siteAddress"><br/>Site Address: {siteAddress}</tpl>',
                    BUTTON_TEXT: 'View worksheet <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Message.NewWorksheetSMSMessage_v9',
                    TEXT: 'Dear {customername},\n{companyname} has sent you a Job Sheet. To view click here: {documenturl}.'
                        + '\nLocation: {location}'
                        + '<tpl if="siteAddress">\nSite Address: {siteAddress}</tpl>',
                },
            },
            WHATSAPP: {
                BODY: {
                    PROMPT: 'Message.NewWorksheetWhatsAppMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new job sheet from {companyname}. You can view <tpl if="canUsePortal"> or reply to </tpl> your Worksheet online: {documenturl} ',
                },
            },
        },
    },
    CREDITNOTE_TO_CUSTOMER: {
        ID: 4,
        NAME: 'New credit note',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Message.NewCreditNoteEmailSubject_v9',
                    TEXT: 'New Credit Note from {companyname}',
                },
                BODY: {
                    PROMPT: 'DocumentMail.TextLine2CreditNote_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Credit Note ({displaynumber}) from {companyname}. '
                        + 'You can view <tpl if="documenturl!==\'\'"> <tpl if="canUsePortal"> or reply to </tpl> your credit note online by clicking the link below or you can see</tpl> it in the attachment of this email.',
                    BUTTON_TEXT: 'View Credit Note <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Message.NewCreditNoteSMSMessage_v9',
                    TEXT: 'Dear {customername},\n{companyname} has sent you a Credit Note. To view click here: {documenturl} ',
                },
            },
            WHATSAPP: {
                BODY: {
                    PROMPT: 'Message.NewCreditNoteWhatsAppMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Credit Note from {companyname}. You can view <tpl if="canUsePortal"> or reply to </tpl> your Credit Note online: {documenturl} ',
                },
            },
            ELECTRONIC: {
                SUBJECT: {
                    PROMPT: 'Message.NewCreditNoteElectronicSubject_v9',
                    TEXT: 'New Credit Note from {companyname}',
                },
                BODY: {
                    PROMPT: 'DocumentMail.TextLine2ElectronicCreditNote_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Credit Note from {companyname}. '
                        + 'You can approve your credit note or reply in your Powered Now account by clicking the button below. You can also see it in the attachment of this email.',
                },
            },
        },
    },
    SIGNATURE_TO_CUSTOMER: {
        ID: 5,
        NAME: 'New signature',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Message.NewSignatureEmailSubject_v9',
                    TEXT: 'New Signature Document from {companyname}',
                },
                BODY: {
                    PROMPT: 'DocumentMail.TextLine2DocSig_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Signature Document ({displaynumber}) from {companyname}. '
                        + 'You can view <tpl if="documenturl!==\'\'"> <tpl if="canUsePortal"> or reply to </tpl> the document online by clicking the link below or you can see</tpl> it in the attachment of this email.',
                    BUTTON_TEXT: 'View Signature <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Message.NewSignatureSMSMessage_v9',
                    TEXT: 'Dear {customername},\n{companyname} has sent you a Signature Document. To view click here: {documenturl} ',
                },
            },
            WHATSAPP: {
                BODY: {
                    PROMPT: 'Message.NewSignatureWhatsAppMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Signature Document from {companyname}. You can view <tpl if="canUsePortal"> or reply to </tpl> the document online: {documenturl} ',
                },
            },
        },
    },
    PURCHASE_ORDER_TO_CUSTOMER: {
        ID: 6,
        NAME: 'New purchase order',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Message.NewPurchaseOrderEmailSubject_v9',
                    TEXT: 'New Purchase Order from {companyname}',
                },
                BODY: {
                    PROMPT: 'DocumentMail.TextLine2PurchaseOrder_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Purchase Order ({displaynumber}) from {companyname}. '
                        + 'You can view <tpl if="documenturl!==\'\'"> <tpl if="canUsePortal"> or reply to </tpl> your Purchase Order online by clicking the link below or you can see</tpl> it in the attachment of this email.',
                    BUTTON_TEXT: 'View Purchase Order <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Message.NewPurchaseOrderSMSMessage_v9',
                    TEXT: 'Dear {customername},\n{companyname} has sent you a Purchase Order. To view click here: {documenturl} ',
                },
            },
            WHATSAPP: {
                BODY: {
                    PROMPT: 'Message.NewPurchaseOrderWhatsAppMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Purchase Order from {companyname}. You can view <tpl if="canUsePortal"> or reply to </tpl> your Purchase Order online: {documenturl} ',
                },
            },
        },
    },
    SUPPLIER_INVOICE_TO_CUSTOMER: {
        ID: 7,
        NAME: 'New supplier invoice',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Message.NewSupplierInvoiceEmailSubject_v9',
                    TEXT: 'New Supplier Invoice from {companyname}',
                },
                BODY: {
                    PROMPT: 'DocumentMail.TextLine2SupInvoice_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Supplier Invoice ({displaynumber}) from {companyname}. '
                        + 'You can view <tpl if="documenturl!==\'\'"> <tpl if="canUsePortal"> or reply to </tpl> your supplier invoice online by clicking the link below or you can see</tpl> it in the attachment of this email.',
                    BUTTON_TEXT: 'View Supplier Invoice <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Message.NewSupplierInvoiceSMSMessage_v9',
                    TEXT: 'Dear {customername},\n{companyname} has sent you a Supplier Invoice. To view click here: {documenturl} ',
                },
            },
            WHATSAPP: {
                BODY: {
                    PROMPT: 'Message.NewSupplierInvoiceWhatsAppMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Supplier Invoice from {companyname}. You can view <tpl if="canUsePortal"> or reply to </tpl>to your Invoice online: {documenturl} ',
                },
            },
        },
    },
    SUPPLIER_INVOICE_CREDIT_TO_CUSTOMER: {
        ID: 8,
        NAME: 'New supplier invoice credit note',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Message.NewSupplierInvoiceCreditNoteEmailSubject_v9',
                    TEXT: 'New Supplier credit note from {companyname}',
                },
                BODY: {
                    PROMPT: 'DocumentMail.TextLine2SupCreditNote_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Supplier credit note ({displaynumber}) from {companyname}. '
                        + 'You can view <tpl if="documenturl!==\'\'"> <tpl if="canUsePortal"> or reply to </tpl> your supplier credit note online by clicking the link below or you can see </tpl>'
                        + 'it in the attachment of this email.',
                    BUTTON_TEXT: 'View Supplier Invoice Credit <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Message.NewSupplierInvoiceCreditNoteSMSMessage_v9',
                    TEXT: 'Dear {customername},\n{companyname} has sent you a Supplier credit note. To view click here: {documenturl} ',
                },
            },
            WHATSAPP: {
                BODY: {
                    PROMPT: 'Message.NewSupplierInvoiceCreditNoteWhatsAppMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Supplier credit note from {companyname}. You can view <tpl if="canUsePortal"> or reply to </tpl> your Supplier credit note online: {documenturl} ',
                },
            },
        },
    },
    EXPENSE_TO_CUSTOMER: {
        ID: 9,
        NAME: 'New expense',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Message.NewExpenseEmailSubject_v9',
                    TEXT: 'New Expense from {companyname}',
                },
                BODY: {
                    PROMPT: 'DocumentMail.TextLine2Expense_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Expense ({displaynumber}) from {companyname}. '
                        + 'You can view <tpl if="documenturl!==\'\'"> <tpl if="canUsePortal"> or reply to </tpl> your document online by clicking the link below or you can see</tpl> it in the attachment of this email.',
                    BUTTON_TEXT: 'View Expense <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Message.NewExpenseSMSMessage_v9',
                    TEXT: 'Dear {customername},\n{companyname} has sent you an Expense. To view click here: {documenturl} ',
                },
            },
            WHATSAPP: {
                BODY: {
                    PROMPT: 'Message.NewExpenseWhatsAppMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Expense from {companyname}. You can view <tpl if="canUsePortal"> or reply to </tpl> your Invoice online: {documenturl} ',
                },
            },
        },
    },
    EXPENSE_CREDITNOTE_TO_CUSTOMER: {
        ID: 10,
        NAME: 'New expense credit',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Message.NewExpenseCreditNoteEmailSubject_v9',
                    TEXT: 'New Expense credit note from {companyname}',
                },
                BODY: {
                    PROMPT: 'DocumentMail.TextLine2ExpCreditNote_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Expense credit note ({displaynumber}) from {companyname}. '
                        + 'You can view <tpl if="documenturl!==\'\'"> <tpl if="canUsePortal"> or reply to </tpl> your expense credit note online by clicking the link below or you can see</tpl> it in the attachment of this email.',
                    BUTTON_TEXT: 'View Expense Credit <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgDoc_v9',
                        PROMPT_TEXT: documentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Message.NewExpenseCreditNoteSMSMessage_v9',
                    TEXT: 'Dear {customername},\n{companyname} has sent you a Expense credit note. To view click here: {documenturl} ',
                },
            },
            WHATSAPP: {
                BODY: {
                    PROMPT: 'Message.NewExpenseCreditNoteWhatsAppMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new Expense credit note from {companyname}. You can view <tpl if="canUsePortal"> or reply to </tpl> your expense credit note online: {documenturl} ',
                },
            },
        },
    },
    APPOINTMENT_CREATED: {
        ID: 11,
        NAME: 'New appointment',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Appointment.NewAppointmentEmailSubject_v9',
                    TEXT: '{companyname} - New Appointment',
                },
                BODY: {
                    PROMPT: 'Appointment.AppointmentNotificationMessage_v9',
                    TEXT: 'This is to confirm {companyname} has scheduled an appointment {action} with you on {dt_start:formatLocaleDateTime(values)}.'
                        + '<br/>Location: {location}'
                        + '<tpl if="siteAddress"><br/>Site Address: {siteAddress}</tpl>',
                    BUTTON_TEXT: 'View Appointment Details <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgAppointment_v9',
                        PROMPT_TEXT: appointmentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgAppointment_v9',
                        PROMPT_TEXT: appointmentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Appointment.NewAppointmentSMSNotification_v9',
                    TEXT: '{companyname} has an appointment with you on {dt_start:formatLocaleDateTime(values)}. To view click here {documenturl}.'
                        + '\nLocation: {location}'
                        + '<tpl if="siteAddress">\nSite Address: {siteAddress}</tpl>',
                },
            },
        },
    },
    APPOINTMENT_UPDATED: {
        ID: 12,
        NAME: 'Appointment updated',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Appointment.DateUpdatedEmailSubject_v9',
                    TEXT: '{companyname} - Appointment Updated',
                },
                BODY: {
                    PROMPT: 'Appointment.DateUpdatedEmailBody_v9',
                    TEXT: 'This is to confirm {companyname} has updated an appointment on {previousValues.dt_start:formatLocaleDateTime(values)} with you '
                        + 'to {dt_start:formatLocaleDateTime(values)}.'
                        + '<br/>Location: {location}'
                        + '<tpl if="siteAddress"><br/>Site Address: {siteAddress}</tpl>',
                    BUTTON_TEXT: 'View Appointment Details <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgAppointment_v9',
                        PROMPT_TEXT: appointmentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgAppointment_v9',
                        PROMPT_TEXT: appointmentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Appointment.DateUpdatedSMSNotification_v9',
                    TEXT: '{companyname} has moved your appointment on {previousValues.dt_start:formatLocaleDateTime(values)} '
                        + 'to {dt_start:formatLocaleDateTime(values)}. To view click here: {documenturl}.'
                        + '\nLocation: {location}'
                        + '<tpl if="siteAddress">\nSite Address: {siteAddress}</tpl>',
                },
            },
        },
    },
    //
    // These are commented out as requested by PN-10764. But left in here because later we may want to reinstate them once
    // we have better understanding how unplanned appointments are used on the field.
    //
    // UNPLANNED_APPOINTMENT_UPDATED: {
    //     ID: 13,
    //     NAME: 'Unplanned appointment updated',
    //     CONTENT: {
    //         EMAIL: {
    //             SUBJECT: {
    //                 PROMPT: 'Appointment.DateUpdatedEmailSubject_v9',
    //                 TEXT: '{companyname} - Appointment Updated',
    //             },
    //             BODY: {
    //                 PROMPT: 'Appointment.UnplannedAppointmentUpdatedEmailBody_v9',
    //                 TEXT: 'This is to confirm {companyname} has updated an appointment on {previousValues.dt_start:formatLocaleDateTime(values)} with you '
    //                     + 'to {dt_start:formatLocaleDateTime(values)}.',
    //                 BUTTON_TEXT: 'View Appointment Details <tpl if="canUsePortal"> or reply to this message</tpl>',
    //             },
    //             OPEN_NOTIFICATION: {
    //                 PROMPT_ID: 'ServerNotification.EmailOpenedMsgAppointment_v9',
    //                 PROMPT_TEXT: appointmentOpenNotificationMessage,
    //             },
    //         },
    //         SMS: {
    //             BODY: {
    //                 PROMPT: 'Appointment.UnplannedAppointmentUpdatedSMSNotification_v9',
    //                 TEXT: '{companyname} has moved your appointment from {previousValues.dt_start:formatLocaleDateTime(values)} '
    //                     + 'to {dt_start:formatLocaleDateTime(values)}. To view click here: {documenturl} ',
    //             },
    //         },
    //     },
    // },
    // UNPLANNED_APPOINTMENT_CREATED: {
    //     ID: 14,
    //     NAME: 'New unplanned appointment',
    //     CONTENT: {
    //         EMAIL: {
    //             SUBJECT: {
    //                 PROMPT: 'Appointment.NewUnplannedAppointmentEmailSubject_v9',
    //                 TEXT: '{companyname} - Unplanned Appointment Scheduled',
    //             },
    //             BODY: {
    //                 PROMPT: 'Appointment.UnplannedAppointmentNotificationMessage_v9',
    //                 TEXT: 'This is to confirm {companyname} has scheduled an appointment with you in the {durationType}{dt_start:formatLocaleDateTime(values)}.',
    //                 BUTTON_TEXT: 'View Appointment Details <tpl if="canUsePortal"> or reply to this message</tpl>',
    //             },
    //             OPEN_NOTIFICATION: {
    //                 PROMPT_ID: 'ServerNotification.EmailOpenedMsgAppointment_v9',
    //                 PROMPT_TEXT: appointmentOpenNotificationMessage,
    //             },
    //         },
    //         SMS: {
    //             BODY: {
    //                 PROMPT: 'Appointment.UnplannedAppointmentSMSNotification_v9',
    //                 TEXT: '{companyname} has an appointment with you in the {durationType} of {dt_start:formatLocaleDateTime(values)}. '
    //                     + 'To view click here: {documenturl} ',
    //             },
    //         },
    //     },
    // },
    APPOINTMENT_DELETION: {
        ID: 15,
        NAME: 'Appointment cancelled',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Appointment.DeletedAppointmentEmailSubject_v9',
                    TEXT: '{companyname} - Appointment Cancelled',
                },
                BODY: {
                    PROMPT: 'Appointment.AppointmentDeleteNotificationMessage_v9',
                    TEXT: 'This is to confirm {companyname}\'s scheduled appointment on '
                        + '<tpl if="previousValues.dt_start">{previousValues.dt_start:formatLocaleDateTime(values)}</tpl>'
                        + '<tpl if="previousValues.dt_requested">, requested at {previousValues.dt_requested:formatLocaleDateTime(values)}</tpl> has been cancelled.'
                        + '<br/>Location: {location}'
                        + '<tpl if="siteAddress"><br/>Site Address: {siteAddress}</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgAppointment_v9',
                        PROMPT_TEXT: appointmentDeletedOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgAppointment_v9',
                        PROMPT_TEXT: appointmentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Appointment.AppointmentDeleteSMSNotification_v9',
                    TEXT: '{companyname} has cancelled your appointment on {previousValues.dt_start:formatLocaleDateTime(values)}.'
                        + '\nLocation: {location}'
                        + '<tpl if="siteAddress">\nSite Address: {siteAddress}</tpl>',
                },
            },
        },
    },
    ATTENDING_APPOINTMENT: {
        ID: 16,
        NAME: 'Attending appointment',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Appointment.AttendingAppointmentEmailSubject_v9',
                    TEXT: '{companyname} - Attending Appointment Now',
                },
                BODY: {
                    PROMPT: 'Appointment.AttendingAppointmentEmailBody_v9',
                    TEXT: '<tpl if="fullname">{fullname}, {jobDescription} from </tpl>{companyname} has just set off for their appointment with you.'
                        + '<br/>Location: {location}'
                        + '<tpl if="siteAddress"><br/>Site Address: {siteAddress}</tpl>',
                    BUTTON_TEXT: 'See their picture and details <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgAppointment_v9',
                        PROMPT_TEXT: appointmentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgAppointment_v9',
                        PROMPT_TEXT: appointmentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Appointment.AttendingAppointmentSMSNotification_v9',
                    TEXT: '<tpl if="fullname">{fullname}, {jobDescription} from </tpl>{companyname} has just set off for their appointment with you. Click here to view {documenturl}.'
                        + '\nLocation: {location}'
                        + '<tpl if="siteAddress">\nSite Address: {siteAddress}</tpl>',
                },
            },
        },
    },
    PAYMENT: {
        ID: 17,
        NAME: 'Payment received',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'Message.NewPaymentEmailSubject_v9',
                    TEXT: 'New Payment from {customername}',
                },
                BODY: {
                    PROMPT: 'Message.NewPaymentEmailMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/>Your payment of <b>{amount:formatMoney(values.currencyOptions)}</b> on <i>{dt_created:formatLocaleDateTime(values, "Do MMM Y")}</i> '
                        + '<tpl if="document">for {document.displaynumber} </tpl>has been acknowledged by {companyname}.',
                    BUTTON_TEXT: 'View Payment Details <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgNewPayment_v9',
                        PROMPT_TEXT: paymentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgNewPayment_v9',
                        PROMPT_TEXT: paymentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Message.NewPaymentSMSMessage_v9',
                    TEXT: 'Dear {customername},\n{companyname} has received {amount:formatMoney(values.currencyOptions)} from you on {dt_created:formatLocaleDateTime(values, "Do MMM Y")}. '
                        + 'View at {documenturl} ',
                },
            },
            WHATSAPP: {
                BODY: {
                    PROMPT: 'Message.NewPaymentWhatsAppMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/>Your payment of {amount:formatMoney(values.currencyOptions)} on {dt_created:formatLocaleDateTime(values, "Do MMM Y")} '
                        + 'has been acknowledged by {companyname}.',
                },
            },
        },
    },
    FORMDOCUMENT_TO_CUSTOMER: {
        ID: 18,
        NAME: 'New certificate',
        CONTENT: {
            EMAIL: {
                SUBJECT: {
                    PROMPT: 'DocumentMail.HasSentFormDocument_v9',
                    TEXT: 'New document from {companyname}',
                },
                BODY: {
                    PROMPT: 'Message.NewFormDocumentEmail_v9',
                    TEXT:
                        'You can view your {certificateName} <tpl if="documenturl!==\'\'">online by clicking the link below or you can see it</tpl> in the attachment of this email.'
                        + '<br/>Location: {location}'
                        + '<tpl if="siteAddress"><br/>Site Address: {siteAddress}</tpl>',

                    BUTTON_TEXT: 'View Document <tpl if="canUsePortal"> or reply to this message</tpl>',
                },
                OPEN_NOTIFICATION: {
                    EMAIL: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgFormDocument_v9',
                        PROMPT_TEXT: formDocumentOpenEmailNotificationMessage,
                    },
                    PUSH: {
                        PROMPT_ID: 'ServerNotification.EmailOpenedMsgFormDocument_v9',
                        PROMPT_TEXT: formDocumentOpenPushNotificationMessage,
                    },
                },
            },
            SMS: {
                BODY: {
                    PROMPT: 'Message.NewFormDocumentSMS_v9',
                    TEXT: 'Dear {customername},\n{companyname} has sent a {certificateName}. View at {documenturl}.'
                        + '\nLocation: {location}'
                        + '<tpl if="siteAddress">\nSite Address: {siteAddress}</tpl>',
                },
            },
            WHATSAPP: {
                BODY: {
                    PROMPT: 'Message.NewFormDocumentWhatsAppMessage_v9',
                    TEXT: 'Dear {customername},<br/><br/> You have received a new {certificateName} from {companyname}. You can view your document online: {documenturl} ',
                },
            },
        },
    },
};

export default DefaultMessages;
