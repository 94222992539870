import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import DocumentItemEntity from './entity';
import modelProperties from './modelProperties';

import MediaGroup from '../mediaGroup';
import Document from '../document';
import JobItemGroup from '../jobItemGroup';
import Note from '../note';
import Action from '../action';
import Message from '../message';
import Signature from '../signature';
import MediaGroupEntity from '../mediaGroup/entity';
import DocumentEntity from '../document/entity';
import JobItemGroupEntity from '../jobItemGroup/entity';
import NoteEntity from '../note/entity';
import ActionEntity from '../action/entity';
import MessageEntity from '../message/entity';
import SignatureEntity from '../signature/entity';

interface DocumentItemAssociations extends AssociationConfig<any, any> {
    media: AssociationDefinitionSingle<MediaGroupEntity, MediaGroup>
    document: AssociationDefinitionSingle<DocumentEntity, Document>
    jobItem: AssociationDefinitionSingle<JobItemGroupEntity, JobItemGroup>
    note: AssociationDefinitionSingle<NoteEntity, Note>
    action: AssociationDefinitionSingle<ActionEntity, Action>
    message: AssociationDefinitionSingle<MessageEntity, Message>
    signature: AssociationDefinitionSingle<SignatureEntity, Signature>
}

interface DocumentItem extends AutoGeneratedFunctions<DocumentItemAssociations, DocumentItemEntity, ComplexData<DocumentItemEntity>> {}

// eslint-disable-next-line no-redeclare
class DocumentItem extends ComplexData<DocumentItemEntity> {
    static Entity = DocumentItemEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): DocumentItemAssociations {
        return {
            media: {
                key: 'mediaGroup',
                instance: MediaGroup,
                entity: MediaGroupEntity,
                single: true,
                condition: {
                    [this.Entity.getFieldSymbols().type]: 'MediaGroup',
                    id: this.Entity.getForeignFieldSymbols().linked_id,
                },
            },
            document: {
                key: 'document',
                instance: Document,
                entity: DocumentEntity,
                single: true,
                condition: {
                    [this.Entity.getFieldSymbols().type]: 'Document',
                    id: this.Entity.getForeignFieldSymbols().linked_id,
                },
            },
            jobItem: {
                key: 'jobItemGroup',
                instance: JobItemGroup,
                entity: JobItemGroupEntity,
                single: true,
                condition: {
                    [this.Entity.getFieldSymbols().type]: 'JobItem',
                    id: this.Entity.getForeignFieldSymbols().linked_id,
                },
            },
            note: {
                key: 'note',
                instance: Note,
                entity: NoteEntity,
                single: true,
                condition: {
                    [this.Entity.getFieldSymbols().type]: 'Note',
                    id: this.Entity.getForeignFieldSymbols().linked_id,
                },
            },
            action: {
                key: 'action',
                instance: Action,
                entity: ActionEntity,
                single: true,
                condition: {
                    [this.Entity.getFieldSymbols().type]: 'Action',
                    id: this.Entity.getForeignFieldSymbols().linked_id,
                },
            },
            message: {
                key: 'message',
                instance: Message,
                entity: MessageEntity,
                single: true,
                condition: {
                    [this.Entity.getFieldSymbols().type]: 'Message',
                    id: this.Entity.getForeignFieldSymbols().linked_id,
                },
            },
            signature: {
                key: 'signature',
                instance: Signature,
                entity: SignatureEntity,
                single: true,
                condition: {
                    [this.Entity.getFieldSymbols().type]: 'Signature',
                    id: this.Entity.getForeignFieldSymbols().linked_id,
                },
            },
        };
    }

    public async getLinkedItem(): Promise<MediaGroup | JobItemGroup | Document | Note | Action | Message | Signature> {
        const linkedAssociationName = this.getTypeAssociationName();
        return (await this.getAssociatedValue(linkedAssociationName))[0];
    }

    private getTypeAssociationName(): string {
        return Object.keys(this.constructor.allowedAssociations).find(associationName => {
            const currentAssociation = this.constructor.allowedAssociations[associationName];
            return currentAssociation.condition && currentAssociation.condition[DocumentItem.Entity.getFieldSymbols().type] === this.data.type;
        });
    }
}

export default DocumentItem;
