import ComplexData, { AssociationConfig, AssociationDefinitionSingle } from '../complexData';
import CustomerMessage from '../customerMessage';
import MessageTemplateEntity from './entity';
import modelProperties from './modelProperties';
import CustomerMessageEntity from '../customerMessage/entity';

interface MessageTemplateAssociations extends AssociationConfig<any, any> {
    message: AssociationDefinitionSingle<CustomerMessageEntity, CustomerMessage>
}

export default class MessageTemplate extends ComplexData<MessageTemplateEntity> {
    static Entity = MessageTemplateEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): MessageTemplateAssociations {
        return {
            message: {
                key: 'customerMessage',
                instance: CustomerMessage,
                entity: CustomerMessageEntity,
                single: true,
                condition: {
                    type: this.Entity.getForeignFieldSymbols().messageType,
                },
            },
        };
    }
}
