// eslint-disable-next-line max-classes-per-file
import { This, PropType, ChangeTypes } from '@powerednow/type-definitions';

export type DataObject = {
    [propName: string | symbol]: any;
};

export type DateOrString = 'DateOrString';

export type FieldHookCondition = {
    oldValue?: any;
    newValue?: any;
}

export type FieldHookDefinition = {
    name?: string;
    handler: string;
    condition?: FieldHookCondition;
    args?: string[];
    runAfterCommit?: boolean;
}

export type FieldHooksDefinition = {
    afterUpdate?: FieldHookDefinition[];
    afterCreate?: FieldHookDefinition[];
    afterDelete?: FieldHookDefinition[];
    beforeUpdate?: FieldHookDefinition[];
    beforeCreate?: FieldHookDefinition[];
    beforeDelete?: FieldHookDefinition[];
};

export type FieldDefinition<Keys = any> = {
    name: Keys,
    type: 'int' | 'string' | 'boolean' | 'decimal' | 'float' | 'date' | 'object' | 'stringArray' | 'mixed',
    autoIncrement?: boolean,
    codeName?: string,
    primaryKey?: boolean,
    unique?: boolean | string,
    index?: boolean,
    indexed?: boolean,
    allowNull?: boolean,
    serverOnly?: boolean,
    dbType?: string,
    defaultValue?: any,
    clientOnly?: boolean,
    isJSON?: boolean,
    hooks?: FieldHooksDefinition,
    minAppVersion?: string,
    preserveRequest?: boolean,
    embedded?: any[],
    isCalculated?: boolean,
    expires?: number,
    requiresRole?: object,
    convert?: any,
    excludeFromClientDownload?: boolean,
    processor?: (value: any) => any,
};

export class EntityMutedError extends Error {
    constructor(public details) {
        super(`${details.tableName}:${String(details.id)} entity is muted, can not update '${String(details.field)}' from '${String(details.from)}' to '${String(details.to)}'`);
    }
}

/* property decorator */
export function field(extraArgs: Omit<FieldDefinition<ModelFields<Entity>>, 'name'>): any {
    return function fieldDecorator(target: Entity, propertyKey: keyof ModelFields<Entity>, _descriptor: PropertyDescriptor) {
        const currentField: FieldDefinition<ModelFields<Entity>> = { ...extraArgs, name: propertyKey };
        target.constructor.fields = [...(target.constructor.fields || [])];
        target.constructor.fields.push(currentField);

        const symbol = Symbol(String(propertyKey));
        const foreignSymbol = Symbol(`f_${propertyKey}`);

        target.constructor._fieldSymbols = { ...(target.constructor._fieldSymbols || {}) };
        target.constructor._fieldSymbols[String(propertyKey)] = symbol;

        target.constructor._foreignFieldSymbols = { ...(target.constructor._foreignFieldSymbols || {}) };
        target.constructor._foreignFieldSymbols[String(propertyKey)] = foreignSymbol;

        target.constructor.fieldSymbolValues = { ...(target.constructor.fieldSymbolValues || {}) };
        target.constructor.fieldSymbolValues[symbol] = propertyKey;
        target.constructor.fieldSymbolValues[foreignSymbol] = propertyKey;
    };
}

type ModelFieldsToSymbolMap<T extends Entity> = {
    [ K in keyof ModelFields<T> ]: symbol;
};

export default class Entity {
    declare ['constructor']: typeof Entity;

    _self: Entity;

    public static EVENTS = {
        FIELD_CHANGED: 'fieldChanged',
    };

    public static fields: FieldDefinition<ModelFields<Entity['_self']>>[];

    public static _fieldSymbols: Record<string, symbol>;

    static getFieldSymbols<T extends This<typeof Entity>>(this: T): ModelFieldsToSymbolMap<InstanceType<T>> {
        return this._fieldSymbols as unknown as ModelFieldsToSymbolMap<InstanceType<T>>;
    }

    public static _foreignFieldSymbols: Record<string, symbol>;

    static getForeignFieldSymbols<T extends This<typeof Entity>>(this: T): ModelFieldsToSymbolMap<InstanceType<T>> {
        return this._foreignFieldSymbols as unknown as ModelFieldsToSymbolMap<InstanceType<T>>;
    }

    public static fieldSymbolValues: Record<symbol, string>;

    public static searchFieldSymbol = Symbol('searchField');

    public static listFilterSymbol = Symbol('listFilter');

    public isChanged: boolean = false;

    public changedFields: Partial<ModelFields<this>> = {};

    private suppressFire: boolean = false;

    private muted: boolean = false;

    private dataValues: ModelFields<this> = {} as ModelFields<this>;

    public onFieldChanged: <RT extends keyof ModelFields<this>>(key: RT, newValue: PropType<this, RT>, oldValue: PropType<this, RT>) => void = (key, newValue, oldValue) => {};

    public static hasField(fieldName: string): boolean {
        return this.fields.some(fieldToCheck => fieldToCheck.name === fieldName);
    }

    /**
     * Default constructor
     *
     * @param currentValues
     */
    constructor(currentValues) {
        if (currentValues instanceof Entity) {
            // eslint-disable-next-line no-constructor-return
            return currentValues;
        }

        const { fields } = this.constructor;

        if (!Array.isArray(fields)) {
            throw new Error('Entity class should define fields by @field descriptor');
        }
        //
        // Don't fire change event at initialisation
        //
        this.suppressEvents();
        //
        // Init the fields
        //
        [{ name: Entity.searchFieldSymbol }, { name: Entity.listFilterSymbol }, ...fields].forEach((fieldDefinition: FieldDefinition) => {
            Object.defineProperty(this, fieldDefinition.name, {
                set: this.setterProxy.bind(this, fieldDefinition.name, fieldDefinition.processor),
                get: this.getterProxy.bind(this, fieldDefinition.name),
            });
            this.dataValues[fieldDefinition.name] = Entity.getTypedFieldValue(fieldDefinition, currentValues[fieldDefinition.name]);
        });

        this.isChanged = false;
        this.unSuppressEvents();
    }

    static getTypedFieldValue(fieldDefinition: FieldDefinition, value) {
        if (fieldDefinition.isJSON && typeof value === 'string') {
            try {
                return JSON.parse(value);
            } catch (e) {
                return fieldDefinition.defaultValue || {};
            }
        }
        if (fieldDefinition.type === 'boolean' && value !== undefined) {
            return Boolean(value);
        }
        return value;
    }

    public set(valueObject: PartialModelFields<this>) {
        Object.assign(this, valueObject);
    }

    /**
     * Indicates whether the entity is new. An entity is
     * considered as new when its ID field's value is a symbol
     *
     * @returns {boolean}
     */
    public isNew(): boolean {
        return typeof (this.dataValues as any).id === 'symbol';
    }

    /**
     * Indicates whether the entity is updated. An entity is
     * considered as updated when any of its fields changed
     * and the entity itself is not new
     *
     * @returns {boolean}
     */
    public isUpdated(): boolean {
        return this.isChanged && !this.isNew();
    }

    public getDataValues(): ModelFields<this> {
        return this.dataValues;
    }

    /**
     * Get dataValues without any Symbol included. This is important, as we are cloning `dataValues` when creating new
     * {ComplexData} objects. The symbols may have references to deep object structures and to the {ExtCache}. This
     * could make the cloning process exponentially slower as we use the app and filling the {ExtCache}. Also looks like
     * quite a big memory hog.
     *
     * This function, getDataValues and any related functionality should be reviewed under card PN-6630.
     *
     */
    public getPureDataValues(): ModelFields<this> {
        return Object.keys(this.dataValues).reduce((values, key) => Object.assign(
            values,
            {
                [key]: this.dataValues[key],
            },
        ), {} as ModelFields<this>);
    }

    public getEntries(): any[] {
        return Object.entries(this.dataValues);
    }

    /**
     * Mute the entity making sure no changes allowed on it.
     * If a muted entity is being changed then an assertion is thrown
     */
    public mute(): void {
        this.muted = true;
    }

    /**
     * unmute the entity allowing changes on its data
     */
    public unmute(): void {
        this.muted = false;
    }

    /**
     * Checks whether the entity is muted
     *
     * @returns {boolean}
     */
    public isMuted(): boolean {
        return this.muted;
    }

    /**
     * Mute the entity making sure that change events
     * are not fired until it is restored by calling
     * unSuppressEvents()
     */
    suppressEvents(): void {
        this.suppressFire = true;
    }

    /**
     * Unmute the entity and makes sure change events
     * are fired
     */
    unSuppressEvents(): void {
        this.suppressFire = false;
    }

    /**
     * Setter function for object properties
     *
     * @param key
     * @param processor
     * @param newValue
     */
    private setterProxy(key, processor, newValue): void {
        const oldValue = this.dataValues[key];
        const processedValue = processor ? processor(newValue) : newValue;

        if (oldValue === processedValue) {
            return;
        }

        if (this.muted) {
            throw new EntityMutedError({
                tableName: (this.constructor as any).name,
                id: (this.dataValues as any).id,
                field: key,
                from: this.dataValues[key],
                to: processedValue,
            });
        }

        this.dataValues[key] = processedValue;

        if (this.isOriginalFieldName(key)) {
            this.isChanged = true;
            this.changedFields[key] = true;
        }

        if (this.suppressFire === false) {
            this.onFieldChanged(key, processedValue, oldValue);
        }
    }

    public getChangedFields(): Partial<ModelFields<this>> {
        return Object.keys(this.changedFields).reduce((result, key) => {
            result[key] = this.dataValues[key];
            return result;
        }, {});
    }

    private getterProxy(key: string) {
        return this.dataValues[key];
    }

    private isOriginalFieldName(key): boolean {
        const { fields } = this.constructor;
        return fields.some(fieldDef => fieldDef.name === key);
    }
}

export class EntityWithId extends Entity {
    public id?: number;
}

export class EntityWithCompanyId extends EntityWithId {
    public company_id: number;

    public isdeleted?: boolean;
}

export type ModelCreationFields<T extends Entity> = Omit<T, keyof Entity>;
export type ModelFields<T extends Entity> = Required<ModelCreationFields<T>>
export type ComplexModelFields<TEntity extends Entity> = ChangeTypes<ModelFields<TEntity>, DateOrString, Date | string>
export type PartialModelFields<T extends Entity> = Partial<ComplexModelFields<T>>;
