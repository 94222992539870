const modelProperties = {

    modelName: 'ArchiveBatch',

    tableName: 'ArchiveBatch',

    associations: [{
        type: 'belongsTo',
        serverOnly: true,
        model: 'Job',
        parameters: {
            as: 'Job',
            foreignKey: {
                name: 'job_id',
                fieldName: 'job_id',
            },
        },
    }, {
        type: 'hasMany',
        model: 'ArchiveBatchToItem',
        parameters: {
            as: 'ArchiveBatchToItems',
            foreignKey: {
                name: 'batch_id',
                fieldName: 'batch_id',
            },
        },
        followFor: ['Archive'],
    }],

    hooks: [],

    validations: {},
};

export default modelProperties;
