import Entity, { field } from '../entity';
    
export default class CurrencyEntity extends Entity {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'string' })
    public currency: string;
    
    @field({ type: 'string' })
    public iso: string;
    
    @field({ type: 'string' })
    public symbol: string;
    
    @field({ type: 'int' })
    public decimal: number;
    
    @field({ type: 'string', codeName: 'decimalSep' })
    public decimalsep: string;
    
    @field({ type: 'string', codeName: 'thousandSep' })
    public thousandsep: string;
    
    @field({ type: 'boolean' })
    public symbol_before: boolean;
}
