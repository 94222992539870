import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class JobEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;
    
    @field({ type: 'string' })
    public description: string;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: string;
    
    @field({
        type: 'string', dbType: 'date', defaultValue: null, allowNull: true, 
    })
    public dt_closed: string;
    
    @field({ type: 'decimal', codeName: 'totalQuoted' })
    public totalquoted: number;
    
    @field({ type: 'decimal', codeName: 'totalInvoiced' })
    public totalinvoiced: number;
    
    @field({ type: 'decimal', codeName: 'totalPaid' })
    public totalpaid: number;
    
    @field({ type: 'decimal', codeName: 'totalAccepted' })
    public totalaccepted: number;
    
    @field({ type: 'int' })
    public customer_id: number;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'int' })
    public user_id: number;
    
    @field({ type: 'int', hooks: { beforeUpdate: [{ handler: 'updateDtClosed' }] } })
    public status: number;
    
    @field({
        type: 'boolean', codeName: 'isDeleted', serverOnly: true, hooks: { afterUpdate: [{ handler: 'handleJobDelete', condition: { newValue: true } }] }, 
    })
    public isdeleted: boolean;
    
    @field({ type: 'boolean', codeName: 'isAutoCreated' })
    public is_autocreated: boolean;
    
    @field({ type: 'boolean', codeName: 'isArchived' })
    public is_archived: boolean;
    
    @field({ type: 'int' })
    public site_id: number;
    
    @field({ type: 'string', dbType: 'date', minAppVersion: '7.7.0' })
    public dueDate: string;
    
    @field({
        codeName: 'lastActivity', minAppVersion: '7.7.0', type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils), serverOnly: true, 
    })
    public lastActivity: DateOrString;
    
    @field({
        minAppVersion: '7.7.0', type: 'int', serverOnly: true, isCalculated: true, 
    })
    public outstanding: number;
    
    @field({ minAppVersion: '8.4.3', type: 'string' })
    public project_number: string;
}
