import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import ProductCategoryEntity from './entity';
import modelProperties from './modelProperties';

interface ProductCategoryAssociations extends AssociationConfig<any, any> {}

interface ProductCategory extends AutoGeneratedFunctions<ProductCategoryAssociations, ProductCategoryEntity, ComplexData<ProductCategoryEntity>> {}

// eslint-disable-next-line no-redeclare
class ProductCategory extends ComplexData<ProductCategoryEntity> {
    static Entity = ProductCategoryEntity;

    static modelProperties = modelProperties;
}

export default ProductCategory;
