const modelProperties = {
    modelName: 'ProductTier',
    tableName: 'ProductTier',
    minAppVersion: '9.0.0',
    global: true,

    associations: [{
        type: 'hasMany',
        model: 'ProductTierToFeature',
        parameters: { foreignKey: { name: 'product_tier_id', fieldName: 'product_tier_id' } },
    }],
    validations: { name: { type: 'length', max: 64 } },
};

export default modelProperties;
