import XeroIntegration from 'modules/xero/xeroIntegration';
import { complexDataMix } from '@powerednow/shared/modules/complexData/_decorator';

import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import CompanyIntegrationEntity from './entity';
import modelProperties from './modelProperties';
import Syncable from '../_mixin/syncable';

const constants = require('@powerednow/shared/constants').default;
const _ = require('lodash');
const moment = require('moment');

interface CompanyFormAssociations extends AssociationConfig<any, any> {}

interface CompanyIntegration extends Syncable, ComplexData<CompanyIntegrationEntity> {}

@complexDataMix(Syncable)
// eslint-disable-next-line no-redeclare
class CompanyIntegration extends ComplexData<CompanyIntegrationEntity> {
    static Entity = CompanyIntegrationEntity;

    static modelProperties = modelProperties;

    isDisconnected(): Boolean {
        return _.isEmpty(this.data.token);
    }

    getConnectionStatus(ACCESS_TOKEN_CLASS): number {
        if (this.isDisconnected()) {
            return constants.INTEGRATION.CONNECTION_STATUS.NOT_CONNECTED;
        }

        const accessToken = new ACCESS_TOKEN_CLASS(this.data.token);
        if (accessToken.isExpired()) {
            return constants.INTEGRATION.CONNECTION_STATUS.EXPIRED;
        }

        return constants.INTEGRATION.CONNECTION_STATUS.CONNECTED;
    }

    disconnect(): void {
        this.data.token = {};
        this.data.integration_data = null;
    }

    startInterfacing(): void {
        this.data.is_started = true;
    }

    isRunning(): boolean {
        return !_.isEmpty(this.data.token) && this.data.is_enabled && this.data.is_started && moment(this.data.interfacing_start_date).isSameOrBefore(new Date(), 'day');
    }

    isAutoSynced(): boolean {
        return this.isRunning() && this.data.settings.automaticInterfacing;
    }

    validateAccountCodeSettings(): boolean {
        return XeroIntegration.validateAccountCodeSettings(this);
    }

    validateBankAccountCodeSettings(): boolean {
        return XeroIntegration.validateBankAccountCodeSettings(this);
    }
}

export default CompanyIntegration;
