const modelProperties = {
    modelName: 'FormDocument',
    tableName: 'FormDocument',
    minAppVersion: '7.5.0',
    associations: [{
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'Job',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'Customer',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'User',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'user_id', sourceKeyName: 'user_id' } },
    }, {
        type: 'belongsTo',
        model: 'Form',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'form_id', fieldName: 'form_id' } },
    }, {
        type: 'hasMany',
        model: 'Message',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'document_id', fieldName: 'document_id' }, scope: { type: 'formDocument' } },
    }, {
        type: 'hasMany',
        model: 'DocumentLogo',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' }, targetKey: 'company_id' },
    }, {
        type: 'hasOne',
        model: 'Signature',
        parameters: { foreignKey: { fieldName: 'id', sourceKeyName: 'signature_id' } },
    }, {
        type: 'hasOne',
        model: 'MediaGroup',
        parameters: { foreignKey: { fieldName: 'id', sourceKeyName: 'mediagroup_id' } },
    }, {
        type: 'hasMany',
        model: 'Action',
        parameters: { foreignKey: { name: 'form_document_id', fieldName: 'form_document_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'CustomerMessage',
        parameters: { foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
    }, {
        type: 'belongsTo',
        cascadeDelete: false,
        model: 'ContactToAddress',
        parameters: { foreignKey: { name: 'site_id', fieldName: 'site_id' }, targetKeyName: 'id', targetKey: 'id' },
    }],
    validations: {},
};

export default modelProperties;
