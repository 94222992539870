import Entity, { EntityWithId, field } from '../entity';
    
export default class RolesEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'string' })
    public name: string;
    
    @field({ type: 'string' })
    public description: string;
    
    @field({ type: 'boolean', serverOnly: true })
    public system: boolean;
}
