export default {
    id: 8589934593,
    description: 'Demo Job',
    dt_created: '2013-06-08 11:03:57',
    totalquoted: 0,
    totalinvoiced: 0,
    totalpaid: 0,
    totalaccepted: 0,
    customer_id: 4294967297,
    user_id: 0,
    status: 0,
    is_autocreated: false,
    is_archived: false,
    site_id: 0,
    dueDate: '',
};
