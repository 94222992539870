import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import SubscriptionStatsEntity from './entity';
import modelProperties from './modelProperties';

interface SubscriptionStatsAssociations extends AssociationConfig<any, any> {}

interface SubscriptionStats extends AutoGeneratedFunctions<SubscriptionStatsAssociations, SubscriptionStatsEntity, ComplexData<SubscriptionStatsEntity>> {}

// eslint-disable-next-line no-redeclare
class SubscriptionStats extends ComplexData<SubscriptionStatsEntity> {
    static Entity = SubscriptionStatsEntity;

    static modelProperties = modelProperties;
}

export default SubscriptionStats;
