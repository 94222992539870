import constants from '@powerednow/shared/constants';
import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export interface Company {
    email: string;
    form: any;
    name: string;
    phone: string;
    logoUrl: null;
    address: Address;
}

export interface Address {
    address1: string;
    address2: string;
    city: string;
    state: string;
    postcode: string;
    plot?: string;
}

export interface EmailConfig {
    subject: string;
    body: string;
}

export interface CompanyForm {
    companyId: number;
    emailConfig: EmailConfig;
    numberingConfig: any;
    reminderConfig: ReminderConfig;
}

export interface ReminderConfig {
    subject: string;
    body: string;
    smsText: string;
    sendReminderAfterXDays: null;
    sendReminderByEmail: boolean;
    sendReminderByText: boolean;
    notifyUsersXDaysBefore: null;
}

export interface Site {
    fullName: string;
    address: Address;
}

export interface Customer {
    fullName: string;
    address: Address;
    site: Site;
}

export interface Role {
    id: number;
    name: string;
    description: string;
    selectable: boolean;
    internal: boolean;
    descriptionPrompt: string;
    namePrompt: string;
    accessLevel: number;
    roles: string;
}

export interface User {
    form: any;
    fullName: string;
    role: Role;
}

export interface FormDocument {
    displayNumber: string;
    form: FormDocumentForm;
}

export interface FormDocumentForm {
    title: string;
    name: string;
    version: number;
}

export interface FormData {
    user: User;
    company: Company;
    customer: Customer;
    form: any;
    formDocument: FormDocument;
    companyForm: CompanyForm;
}

export default class FormDocumentEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'int' })
    public form_id: number;

    @field({ type: 'string', isJSON: true, defaultValue: {} })
    public form_data: FormData;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'int' })
    public user_id: number;

    @field({ type: 'int' })
    public customer_id: number;

    @field({ type: 'int' })
    public signature_id: number;

    @field({
        type: 'int',
        hooks: {
            afterUpdate: [{
                handler: 'tagHubspot',
                condition: {
                    newValue: constants.FORMS.STATUSES.CONFIRMED,
                },
                args: ['created_certificate'],
            }, {
                handler: 'removeReminder',
            }],
        },
    })
    public status: number;

    @field({ type: 'int' })
    public document_number: number;

    @field({ type: 'string' })
    public display_number: string;

    @field({ type: 'string', dbType: 'date' })
    public issue_date: DateOrString;

    @field({ type: 'string' })
    public uuid: string;

    @field({ type: 'int' })
    public job_id: number;

    @field({ type: 'string' })
    public plot: string;

    @field({ type: 'int', codeName: 'mediaGroupId' })
    public mediagroup_id: number;

    @field({ type: 'boolean', codeName: 'isArchived', serverOnly: true })
    public is_archived: boolean;

    @field({ type: 'string', serverOnly: true, minAppVersion: '7.7.0' })
    public min_app_version: string;

    @field({ type: 'string', serverOnly: true, minAppVersion: '7.7.0' })
    public max_app_version: string;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_pinned?: boolean;

    @field({ type: 'boolean', minAppVersion: '8.5.0' })
    public is_marked?: boolean;

    @field({ type: 'int' })
    public site_id: number;
}
