const modelProperties = {
    modelName: 'UserToAddress',
    tableName: 'UserToAddress',
    minAppVersion: '9.0.0',
    associations: [{
        type: 'belongsTo',
        model: 'Address',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'address_id', fieldName: 'address_id' } },
    }, {
        type: 'belongsTo',
        model: 'User',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }],
    validations: {},
};

export default modelProperties;
