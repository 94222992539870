import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import UserSettingsEntity from './entity';
import modelProperties from './modelProperties';

interface UserSettingsAssociations extends AssociationConfig<any, any> {}

interface UserSettings extends AutoGeneratedFunctions<UserSettingsAssociations, UserSettingsEntity, ComplexData<UserSettingsEntity>> {}

// eslint-disable-next-line no-redeclare
class UserSettings extends ComplexData<UserSettingsEntity> {
    static Entity = UserSettingsEntity;

    static modelProperties = modelProperties;
}

export default UserSettings;
