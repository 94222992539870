import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import DocumentLogoEntity from './entity';
import modelProperties from './modelProperties';

interface DocumentLogoAssociations extends AssociationConfig<any, any> {}

interface DocumentLogo extends AutoGeneratedFunctions<DocumentLogoAssociations, DocumentLogoEntity, ComplexData<DocumentLogoEntity>> {}

// eslint-disable-next-line no-redeclare
class DocumentLogo extends ComplexData<DocumentLogoEntity> {
    static Entity = DocumentLogoEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): DocumentLogoAssociations {
        return {};
    }
}

export default DocumentLogo;
