import { EntityWithId, field } from '../entity';

export default class WhiteLabelEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'string' })
    public name: string;

    @field({ type: 'string' })
    public account_number_validation_rule: string;

    @field({ type: 'string' })
    public report_email: string;

    @field({ type: 'boolean' })
    public include_po_reporting: boolean;

    @field({ type: 'boolean' })
    public include_po_reporting_on_partner: boolean;

    @field({ type: 'string' })
    public desktop_url: string;

    @field({ type: 'int', allowNull: true, defaultValue: null })
    public fallback_whitelabel_id: string;

    @field({ type: 'int', defaultValue: 1, hooks: { afterUpdate: [{ handler: 'updateTestCompanies', condition: { newValue: 2 }, args: [] }] } })
    public whitelabel_status: number;
}
