import { EntityWithCompanyId, field } from '../entity';
    
export default class PaymentSettingEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'int' })
    public payment_id: number;
    
    @field({ type: 'boolean' })
    public enabled: boolean;
    
    @field({ type: 'boolean' })
    public enabled_supplier: boolean;
    
    @field({ type: 'boolean' })
    public enabled_expense: boolean;
    
    @field({ type: 'string' })
    public s1: string;
    
    @field({ type: 'string' })
    public s2: string;
    
    @field({ type: 'string' })
    public s3: string;
    
    @field({ type: 'boolean' })
    public testmode: boolean;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({
        type: 'boolean', codeName: 'isDeleted', allowNull: false, defaultValue: false, serverOnly: true, 
    })
    public isdeleted: boolean;

    @field({ type: 'decimal', minAppVersion: '8.5.0' })
    public fee?: number;

    @field({ type: 'boolean', defaultValue: false, minAppVersion: '9.0.4' })
    public availability_limit_enabled: boolean;
    
    @field({ type: 'decimal', defaultValue: 0, minAppVersion: '9.0.4' })
    public availability_limit: number;

    @field({ type: 'string', serverOnly: true, minAppVersion: '8.5.0' })
    public min_app_version: string;

    @field({ type: 'string', serverOnly: true, minAppVersion: '8.5.0' })
    public max_app_version: string;
}
