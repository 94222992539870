import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import modelProperties from './modelProperties';
import ActionsForCustomerEntity from './entity';

interface ActionsForCustomerAssociations extends AssociationConfig<any, any> {

}

interface ActionsForCustomer extends AutoGeneratedFunctions<ActionsForCustomerAssociations, ActionsForCustomerEntity, ComplexData<ActionsForCustomerEntity>> {}

// eslint-disable-next-line no-redeclare
class ActionsForCustomer extends ComplexData<ActionsForCustomerEntity> {
    static Entity = ActionsForCustomerEntity;

    _entity: ActionsForCustomerEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): ActionsForCustomerAssociations {
        return {

        };
    }
}

export default ActionsForCustomer;
