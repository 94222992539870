const modelProperties = {
    modelName: 'CompanyAddress',
    tableName: 'CompanyAddress',
    associations: [{
        type: 'belongsTo',
        model: 'Country',
        parameters: { foreignKey: { name: 'country_id', fieldName: 'country_id' } },
    }, {
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }],
    validations: {
        country: { type: 'length', max: 45 },
        state: { type: 'length', max: 45 },
        city: { type: 'length', max: 45 },
        address1: { type: 'length', max: 100 },
        address2: { type: 'length', max: 100 },
        postcode: { type: 'length', max: 45 },
        name: { type: 'length', max: 64 },
    },
};

export default modelProperties;
