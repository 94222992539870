import * as Bluebird from 'bluebird';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import PaymentToInvoice from '../paymentToInvoice';
import PaymentType from '../paymentType';
import Document from '../document';
import Customer from '../customer';
import ContactToAddress from '../contactToAddress';
import PaymentEntity from './entity';
import modelProperties from './modelProperties';
import PaymentToInvoiceEntity from '../paymentToInvoice/entity';
import PaymentTypeEntity from '../paymentType/entity';
import { CustomerMessageLinkedRecord } from '../interfaces';

interface PaymentAssociations extends AssociationConfig<any, any> {
    paymentToInvoice: AssociationDefinitionSingle<PaymentToInvoiceEntity, PaymentToInvoice>
    paymentType: AssociationDefinitionSingle<PaymentTypeEntity, PaymentType>
    cisPayment: AssociationDefinitionSingle<PaymentEntity, Payment>
}

interface Payment extends AutoGeneratedFunctions<PaymentAssociations, PaymentEntity, ComplexData<PaymentEntity>> {}

// eslint-disable-next-line no-redeclare
class Payment extends ComplexData<PaymentEntity> implements CustomerMessageLinkedRecord {
    static Entity = PaymentEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): PaymentAssociations {
        return {
            paymentToInvoice: {
                key: 'paymentToInvoice',
                instance: PaymentToInvoice,
                entity: PaymentToInvoiceEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    payment_id: this.Entity.getFieldSymbols().id,
                },
            },
            paymentType: {
                key: 'paymentType',
                instance: PaymentType,
                entity: PaymentTypeEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().type,
                },
            },
            cisPayment: {
                key: 'payment',
                instance: Payment,
                entity: PaymentEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().cis_payment_id,
                },
            },
        };
    }

    public async getDocument(): Promise<Document> {
        const paymentToInvoice = await this.getPaymentToInvoice();
        return paymentToInvoice.getDocument();
    }

    public async getCustomer(): Promise<Customer> {
        const document = await this.getDocument();
        return document.getCustomer();
    }

    public async getDefaultSite(): Promise<ContactToAddress> {
        const document = await this.getDocument();
        return document.getDefaultSite();
    }

    // @TODO the two functions below are not really working if the related complex document instance is not created
    public async getPaymentsOfSameType(): Promise<Payment[]> {
        const parentDocument = await this.getDocument();
        const siblingPayments = await parentDocument.getPayments();
        return siblingPayments.filter(payment => payment.data.type === this.data.type);
    }

    public async hasMatchingRefund(): Promise<boolean> {
        const sameTypePayments = await this.getPaymentsOfSameType();
        return sameTypePayments.some(payment => payment.data.is_refund && payment.data.reference === this.data.reference);
    }

    /**
     * Function used to emit data for the complex data proxy used in receivePaymentViewController
     *
     * @param document
     * @param isRefund
     */
    public async getAllAvailablePaymentsForDocumentType(document: Document, isRefund: boolean): Promise<PaymentType[]> {
        return Bluebird.filter(PaymentType.getAll(this), async paymentType => {
            //
            // If this is the passed in then no further check needed
            //
            if (this.data.id === paymentType.data.id) {
                return true;
            }
            //
            // If not selectable by type then nothing to do here
            //
            if (!paymentType.data.selectable) {
                return false;
            }

            return document.paymentCanBeRecorded(paymentType.data.id, isRefund);
        })
            .then(paymentTypes => paymentTypes.sort((a, b) => (a.data.sequence <= b.data.sequence ? -1 : 1)));
    }

    async getTemplateData(): Promise<object> {
        const documentData = await this.getDocument();
        return { ...this.data.getPureDataValues(), document: documentData.data.getPureDataValues() };
    }
}

export default Payment;
