const currentDate = new Date();
const currentDateString = currentDate.toISOString().split('T')[0];

export default {
    PAGE_TYPES: {
        MAIN: 'main',
        CHILD: 'child',
        RECURRING: 'recurring',
    },
    COMMON_PROPERTIES: {
        TYPE: {
            COMPANY: 'company',
            USER: 'user',
        },
    },
    ACTIONS: {
        COPY: 'copy',
        NEW: 'new',
        EDIT: 'edit',
    },
    PROPERTIES: {
        FORM_NAME: { value: 'formDocument.form.name', copyable: true },
        FORM_VERSION: { value: 'formDocument.form.version', copyable: true },
        SIGNED_BY_ENGINEER: { value: 'form.signedByEngineer', copyable: false, resetValue: false },
        SIGNED_BY_CLIENT: { value: 'form.signedByClient', copyable: false, resetValue: false },
        CLIENT_PRINT_NAME: { value: 'form.clientPrintName', copyable: false, resetValue: null },
        CLIENT_SIGNATURE_ID: { value: 'form.clientSignatureId', copyable: false, resetValue: null },
        CLIENT_SIGNATURE_URL: { value: 'form.clientSignatureUrl', copyable: false, resetValue: null },
        CLIENT_SIGNATURE_CREATED: { value: 'form.clientSignatureCreated', copyable: false, resetValue: null },
        MEDIA_GROUP_ID: { value: 'form.mediaGroupId', copyable: false, resetValue: null },
        MEDIA: { value: 'form.media', copyable: false, resetValue: null },
        ISSUE_DATE: { value: 'form.issueDate', copyable: false, resetValue: currentDateString },
        INSPECTION_DATE: { value: 'form.inspectionDate', copyable: false, resetValue: currentDateString },
        REMINDER_DATE: { value: 'form.reminderDate', copyable: true },
        NOTIFICATION_DATE: { value: 'form.notificationDate', copyable: true },
        ENGINEER_SIGNATURE_URL: { value: 'user.signatureUrl', copyable: true },
        NEXT_INSPECTION_DATE: { value: 'form.declaration.nextInspectionDate', copyable: false, resetValue: currentDateString },
        BOTTOM_DECLARATION_DATE: { value: 'form.declaration.date', copyable: false, resetValue: currentDateString },
        START_DATE: { value: 'form.installation.startDate', copyable: false, resetValue: currentDateString },
        END_DATE: { value: 'form.installation.endDate', copyable: false, resetValue: currentDateString },
        REPORT_ISSUED_BY_DATE: { value: 'form.declaration.engineerDate', copyable: false, resetValue: currentDateString },
        REPORT_RECEIVED_BY_DATE: { value: 'form.declaration.customerDate', copyable: false, resetValue: currentDateString },
        DECLARATION_DATE: { value: 'form.declarationDate', copyable: false, resetValue: currentDateString },
    },
    STATUSES: {
        DRAFT: 1,
        CONFIRMED: 2,
        SENT: 3,
    },
    FORM_DATA_FILTER: [
        'user',
        'company',
        'customer',
        'form',
        'formDocument',
        'companyForm',
    ],
};
