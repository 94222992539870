import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import NoteEntity from './entity';
import modelProperties from './modelProperties';

interface NoteAssociations extends AssociationConfig<any, any> {}

interface Note extends AutoGeneratedFunctions<NoteAssociations, NoteEntity, ComplexData<NoteEntity>> {}

// eslint-disable-next-line no-redeclare
class Note extends ComplexData<NoteEntity> {
    static Entity = NoteEntity;

    static modelProperties = modelProperties;
}

export default Note;
