import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import PaymentType from '../paymentType';
import Country from '../country';
import PaymentTypeToCountryEntity from './entity';
import modelProperties from './modelProperties';
import PaymentTypeEntity from '../paymentType/entity';
import CountryEntity from '../country/entity';

interface PaymentTypeToCountryAssociations extends AssociationConfig<any, any> {
    paymentType: AssociationDefinitionSingle<PaymentTypeEntity, PaymentType>
    country: AssociationDefinitionSingle<CountryEntity, Country>
}

interface PaymentTypeToCountry extends AutoGeneratedFunctions<PaymentTypeToCountryAssociations, PaymentTypeToCountryEntity, ComplexData<PaymentTypeToCountryEntity>> {}

// eslint-disable-next-line no-redeclare
class PaymentTypeToCountry extends ComplexData<PaymentTypeToCountryEntity> {
    static Entity = PaymentTypeToCountryEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): PaymentTypeToCountryAssociations {
        return {
            paymentType: {
                key: 'paymentType',
                instance: PaymentType,
                entity: PaymentTypeEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().paymenttype_id,
                },
            },
            country: {
                key: 'country',
                instance: Country,
                entity: CountryEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().country_id,
                },
            },
        };
    }
}

export default PaymentTypeToCountry;
