import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import LastMessageInThreadEntity from './entity';
import modelProperties from './modelProperties';
import CustomerMessage from '../customerMessage';
import CustomerMessageEntity from '../customerMessage/entity';

interface LastMessageInThreadAssociations extends AssociationConfig<any, any> {
    customerMessage: AssociationDefinitionSingle<CustomerMessageEntity, CustomerMessage>,
}

interface LastMessageInThread extends AutoGeneratedFunctions<LastMessageInThreadAssociations, LastMessageInThreadEntity, ComplexData<LastMessageInThreadEntity>> {}

// eslint-disable-next-line no-redeclare
class LastMessageInThread extends ComplexData<LastMessageInThreadEntity> {
    static Entity = LastMessageInThreadEntity;

    _entity: LastMessageInThreadEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): LastMessageInThreadAssociations {
        return {
            customerMessage: {
                key: 'customerMessage',
                instance: CustomerMessage,
                entity: CustomerMessageEntity,
                single: true,
                condition: {
                    id: this.Entity.getFieldSymbols().id,
                },
            },
        };
    }
}

export default LastMessageInThread;
