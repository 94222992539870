import Entity, { EntityWithId, field } from '../entity';
    
export default class UserTaxRateFilterEntity extends EntityWithId {
    @field({ primaryKey: true, type: 'int' })
    public id?: number;
    
    @field({ type: 'int' })
    public country_id: number;
    
    @field({ codeName: 'taxRateId', type: 'int' })
    public taxrate_id: number;
    
    @field({ type: 'int' })
    public tax_category_id: number;
    
    @field({ type: 'int' })
    public country_group_id: number;
    
    @field({ type: 'int' })
    public document_group_type: number;
    
    @field({ defaultValue: true, type: 'boolean' })
    public self_included: boolean;
    
    @field({ defaultValue: false, type: 'boolean' })
    public negate_group: boolean;
    
    @field({ type: 'int' })
    public calculation_type: number;
    
    @field({ type: 'boolean', minAppVersion: '8.0.0', defaultValue: true })
    public allowed_for_integration_type_2: boolean;
}
