// todo ? " move to the booking exception with loadGenericData to filter date interval
import Action from 'modules/complexData/action';
import {
    getDayName,
    hasCommonRange,
    getStartOfDay,
    getEndOfDay,
    isDateBetween,
    isOnSameDay,
    parse,
    patterns,
} from 'modules/utilities/date';
import { ACTION } from '../../constants';
import BookingDateException from '../complexData/bookingDateException';
import type { ComplexModelFields } from '../complexData/entity';
import ActionEntity from '../complexData/action/entity';
import AppointmentType from '../complexData/appointmentType';

export interface BookingSlotDayProps {
    enabled: boolean
    max: number
}

export interface BookingSlotProps {
    date: Date
    allow: boolean
    am: BookingSlotDayProps
    pm: BookingSlotDayProps
    ev: BookingSlotDayProps
}

export interface BookingAvailability {
    date: Date,
    am: boolean,
    pm: boolean,
    ev: boolean,
}

const getZonedDateAndData = (source: ComplexModelFields<ActionEntity>, timeShift): ComplexModelFields<ActionEntity> => {
    const zonedSource = { ...source };

    if (zonedSource.dt_start) {
        zonedSource.dt_start = parse(zonedSource.dt_start, patterns.ISO8601Long);
        zonedSource.dt_start.setHours(zonedSource.dt_start.getHours() + timeShift);
    }

    if (zonedSource.dt_end) {
        zonedSource.dt_end = parse(zonedSource.dt_end, patterns.ISO8601Long);
        zonedSource.dt_end.setHours(zonedSource.dt_end.getHours() + timeShift);
    }

    return zonedSource;
};

const hasValidDuration = duration_id => [
    ACTION.APPOINTMENT_DURATION.ALL_DAY, ACTION.APPOINTMENT_DURATION.AFTERNOON, ACTION.APPOINTMENT_DURATION.EVENING, ACTION.APPOINTMENT_DURATION.MORNING,
].includes(duration_id);

export default class BookingSlotManager {
    private readonly scheduleSettings;

    private readonly bookingExceptions: BookingDateException[];

    private readonly appointments: Action[];

    private readonly holidays: Action[];

    private readonly firstBookableDay = null;

    private readonly lastBookableDay = null;

    private readonly timeZoneShift: number = 0;

    constructor({
        scheduleSettings, bookingExceptions, appointments, holidays, timeZoneShift = 0, 
    }: { scheduleSettings: any, bookingExceptions: BookingDateException[], appointments: Action[], holidays: Action[], timeZoneShift: number }) {
        this.scheduleSettings = scheduleSettings;
        const { firstBookableDay, lastBookableDay } = BookingSlotManager.initBookableInterval(this.scheduleSettings);
        this.firstBookableDay = firstBookableDay;
        this.lastBookableDay = lastBookableDay;
        this.timeZoneShift = timeZoneShift;

        this.bookingExceptions = this.limitBookingElements(bookingExceptions);
        this.appointments = this.limitBookingElements(appointments);
        this.holidays = this.limitBookingElements(holidays);
    }

    public getFirstBookableDay(availableDays?: BookingAvailability[] | null) {
        let firstBookableDay = null;
        if (availableDays) {
            availableDays.forEach(availableDay => {
                const day = getStartOfDay(availableDay.date);
                const isBeforeFirst = firstBookableDay === null || (day < firstBookableDay);
                const isAvailable = (availableDay.am || availableDay.pm || availableDay.ev);

                if (isBeforeFirst && isAvailable) {
                    firstBookableDay = getStartOfDay(availableDay.date);
                }
            });
        } else {
            return this.firstBookableDay;
        }

        return firstBookableDay;
    }

    public getLastBookableDay(availableDays?: BookingAvailability[] | null) {
        let lastBookableDay = null;

        if (availableDays) {
            availableDays.forEach(availableDay => {
                const day = getStartOfDay(availableDay.date);
                const isAfterLast = lastBookableDay === null || (day > lastBookableDay);
                const isAvailable = (availableDay.am || availableDay.pm || availableDay.ev);

                if (isAfterLast && isAvailable) {
                    lastBookableDay = day;
                }
            });
        } else {
            return this.lastBookableDay;
        }

        return lastBookableDay;
    }

    /** identify first and last bookable days * */
    public static initBookableInterval(scheduleSettings): { firstBookableDay: Date, lastBookableDay: Date } {
        const firstBookableDay = getStartOfDay(new Date());
        const lastBookableDay = getEndOfDay(new Date());

        firstBookableDay.setDate(firstBookableDay.getDate() + scheduleSettings.firstBookableFromTodayInDays);
        lastBookableDay.setDate(lastBookableDay.getDate() + scheduleSettings.lastBookableFromTodayInDays);

        return {
            firstBookableDay,
            lastBookableDay,
        };
    }

    /** Remove appointments which are not in the bookable interval * */
    private limitBookingElements(items: any[]): any[] {
        return items.filter(item => {
            const { dt_start, dt_end } = getZonedDateAndData(item.data.dataValues, this.timeZoneShift);

            return hasCommonRange(
                this.firstBookableDay, 
                this.lastBookableDay,
                getStartOfDay(dt_start), 
                getEndOfDay(dt_end),
            );
        });
    }

    /** filter exceptions by appointmentType * */
    private getRelevantExceptionsByAppointmentTypeId(appointmentTypeId: number | null) {
        if (!appointmentTypeId) {
            return this.bookingExceptions;
        }

        return this.bookingExceptions.filter(
            exception => (
                exception.data.appointmentTypeId === appointmentTypeId
                || !exception.data.appointmentTypeId
            ),
        );
    }

    private applySlotFilters(appointmentTypeId: number): BookingSlotProps[] {
        const availableSlots = [];

        const dateIterator = new Date(this.firstBookableDay);
        const exceptions = this.getRelevantExceptionsByAppointmentTypeId(appointmentTypeId);
        // apply filters
        while (dateIterator < this.lastBookableDay) {
            let tmpSlot = this.applyExceptions(dateIterator, exceptions);
            if (!tmpSlot) {
                tmpSlot = this.applySettings(dateIterator);
            }
            tmpSlot = this.applyAppointments(dateIterator, tmpSlot);
            tmpSlot = this.applyHolidays(dateIterator, tmpSlot);
            availableSlots.push(tmpSlot);

            dateIterator.setDate(dateIterator.getDate() + 1);
        }
        return availableSlots;
    }

    /** Returns all days in the interval * */
    public getBookableDays(appointmentType: AppointmentType): BookingAvailability[] {
        const appointmentTypeId = appointmentType?.data.id;
        let availableSlots = this.applySlotFilters(appointmentTypeId);
        // remove non available items
        availableSlots = availableSlots.filter(slot => slot.allow
                && (slot.am.enabled || slot.pm.enabled || slot.ev.enabled)
                && (slot.am.max > 0 || slot.pm.max > 0 || slot.ev.max > 0));

        // convert to UI structure
        return availableSlots.map(slot => ({
            date: slot.date,
            am: slot.allow && slot.am.enabled && slot.am.max > 0 && (!appointmentType || appointmentType?.data.canBeMorning),
            pm: slot.allow && slot.pm.enabled && slot.pm.max > 0 && (!appointmentType || appointmentType?.data.canBeAfternoon),
            ev: slot.allow && slot.ev.enabled && slot.ev.max > 0 && (!appointmentType || appointmentType?.data.canBeEvening),
        }));
    }

    private removeDayPartFrom(dayParts, dayPartToRemove) {
        return dayParts.filter(dayPart => dayPart !== dayPartToRemove);
    }

    private getAffectedDayParts(day, startDate: null | Date | string, endDate: null | Date | string, duration_id: number): string[] {
        if (!isDateBetween({
            date: day,
            startDate: getStartOfDay(startDate),
            endDate: getEndOfDay(endDate),
            inclusivity: '[]',
        })) {
            return [];
        }

        const isOnStart = isOnSameDay(day, startDate);
        const isOnEnd = isOnSameDay(day, endDate);

        let affectedDayParts = ['am', 'pm', 'ev'];
        // appointment in only one day and in a specified daytime
        if (duration_id === ACTION.APPOINTMENT_DURATION.MORNING && isOnStart) {
            return ['am'];
        }

        if (duration_id === ACTION.APPOINTMENT_DURATION.AFTERNOON && isOnStart) {
            return ['pm'];
        }

        if (duration_id === ACTION.APPOINTMENT_DURATION.EVENING && isOnStart) {
            return ['ev'];
        }

        if (duration_id === ACTION.APPOINTMENT_DURATION.ALL_DAY || (!isOnStart && !isOnEnd)) {
            return affectedDayParts;
        }

        // ... when duration_id is null or n/a
        let startHour = new Date(startDate).getHours();
        let endHour = new Date(endDate).getHours();

        // start is not on the same day as the end
        if (isOnStart && !isOnEnd) {
            endHour = 23;
        }

        // end is not on the same day as the start
        if (isOnEnd && !isOnStart) {
            startHour = 1;
        }

        // appointment is specified with exact time
        if (startHour >= 12) {
            affectedDayParts = this.removeDayPartFrom(affectedDayParts, 'am');
        }
        if (startHour >= 18) {
            affectedDayParts = this.removeDayPartFrom(affectedDayParts, 'pm');
        }
        if (endHour <= 18) {
            affectedDayParts = this.removeDayPartFrom(affectedDayParts, 'ev');
        }
        if (endHour <= 12) {
            affectedDayParts = this.removeDayPartFrom(affectedDayParts, 'pm');
        }

        return affectedDayParts;
    }

    /** check availability by appointments of the day * */
    // if the logic are the same on holidays then this can be merged with the applyHolidays
    // todo : wait for explanation in later documentation
    private applyAppointments(day: Date, slot: BookingSlotProps, allAppointments = this.appointments): null | BookingSlotProps {
        const appointments = allAppointments.filter(appointment => {
            const { dt_start, dt_end, duration_id } = getZonedDateAndData(appointment.data.getDataValues(), this.timeZoneShift);

            const isOnDate = isDateBetween({
                date: day,
                startDate: getStartOfDay(dt_start),
                endDate: getEndOfDay(dt_end),
                inclusivity: '[]',
            });

            const isSameDay = isOnSameDay(day, dt_start);

            return isOnDate || (isSameDay && hasValidDuration(duration_id));
        });

        appointments.forEach(appointment => {
            const { dt_start, dt_end, duration_id } = getZonedDateAndData(appointment.data.getDataValues(), this.timeZoneShift);

            const dayParts = this.getAffectedDayParts(day, dt_start, dt_end, duration_id);
            dayParts.forEach(dayPart => {
                slot[dayPart].max = Math.max(0, slot[dayPart].max - 1);
            });
        });

        return slot;
    }

    /** check availability by holidays of the day * */
    // todo : wait for explanation in later documentation
    private applyHolidays(day: Date, slot: BookingSlotProps, allHolidays = this.holidays): null | BookingSlotProps {
        const holidays = allHolidays.filter(holiday => {
            const { dt_start, dt_end, duration_id } = getZonedDateAndData(holiday.data.getDataValues(), this.timeZoneShift);
            const startDate = duration_id === ACTION.APPOINTMENT_DURATION.ALL_DAY ? dt_start : getStartOfDay(dt_start);
            const endDate = duration_id === ACTION.APPOINTMENT_DURATION.ALL_DAY ? dt_start : getEndOfDay(dt_end);

            const isOnDate = isDateBetween({
                date: day,
                startDate,
                endDate,
                inclusivity: '[)',
            });

            const isSameDay = isOnSameDay(day, dt_start);

            return isOnDate || (isSameDay && hasValidDuration(duration_id));
        });

        holidays.forEach(holiday => {
            const { dt_start, dt_end, duration_id } = getZonedDateAndData(holiday.data.getDataValues(), this.timeZoneShift);
            const dayParts = this.getAffectedDayParts(day, dt_start, dt_end, duration_id);
            dayParts.forEach(dayPart => {
                slot[dayPart].max = Math.max(0, slot[dayPart].max - 1);
            });
        });

        return slot;
    }

    /** check availability by exceptions of a day * */
    private applyExceptions(day: Date, allExceptionsValue?): null | BookingSlotProps {
        let allExceptions = allExceptionsValue;
        if (!allExceptions || allExceptions.length === 0) {
            allExceptions = this.bookingExceptions;
        }

        const exceptions = allExceptions.filter(exception => {
            const { dt_start, dt_end } = getZonedDateAndData(exception.data.getDataValues(), this.timeZoneShift);
            return isDateBetween({
                date: day,
                startDate: getStartOfDay(dt_start),
                endDate: getEndOfDay(dt_end),
                inclusivity: '[]',
            });
        });

        if (exceptions.length < 1) {
            return null;
        }

        const slot: BookingSlotProps = {
            date: getStartOfDay(day),
            allow: false,
            am: { enabled: false, max: 0 },
            pm: { enabled: false, max: 0 },
            ev: { enabled: false, max: 0 },
        };

        exceptions.forEach(exception => {
            slot.allow = slot.allow || exception.data.allowBooking;
            slot.am.enabled = slot.am.enabled || exception.data.canBeMorning;
            slot.pm.enabled = slot.pm.enabled || exception.data.canBeAfternoon;
            slot.ev.enabled = slot.ev.enabled || exception.data.canBeEvening;
            slot.am.max = Math.max(slot.am.max, exception.data.morningMax);
            slot.pm.max = Math.max(slot.pm.max, exception.data.afternoonMax);
            slot.ev.max = Math.max(slot.ev.max, exception.data.eveningMax);
        });

        return slot;
    }

    /** check availability by schedule settings of a day * */
    private applySettings(day: Date): BookingSlotProps {
        const dayName = getDayName(day).toLowerCase();
        const daySettings = JSON.parse(JSON.stringify(this.scheduleSettings.dayValues[dayName]));

        return {
            date: getStartOfDay(day),
            allow: this.scheduleSettings.allowOnlineBooking,
            am: daySettings.am,
            pm: daySettings.pm,
            ev: daySettings.ev,
        };
    }
}
