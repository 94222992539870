export default {
    GROUP: {
        WHITE_LABEL: {
            ID: '_whiteLabelMetrics',
        },
        USAGE_STATISTICS: {
            ID: 'usageStatistics',
        },
        MONTHLY_REPORT: {
            ID: 'monthlyReport',
        },
    },

    STATUS: {
        SALE: 'SALE',
        NEW: 'NEW',
        CHURNBACK: 'CHURN BACK',
    },

    METHOD: {
        USAGEBYCUSTOMER: {
        },
        ALLSALES: {
            EXPANDREPORT: true,
        },
        NUMBEROFNEWSUBSCRIPTIONS: {
        },
        TOTALNUMOFACTIVESUBSCRIPTIONSAT1STOFMONTHBYCOMPANY: {
        },
        NUMOFNEWREGISTRATIONS: {
        },
    },
    REGEX: {
        TEST_EMAIL_MATCHER: '.*adammt.*|.*addammt.*|.*cdbarling.*|.*@rony.me|.*@powerednow.com|.*bendyer.*|.*zmagyar.*|^bdyer.*|.*emmatest.*|.*emmajanedyer.*|.*emmathorne.*|.*dyer.*test.*|.*b.endyer.*|.*@test.*',
        TEST_COMPANY_MATCHER: '.*Auron.*|.*Revi .*|.*PN-Test.*|.*PN Test.*|^test$|^test company$',
    },
    USAGE_HISTORY_GROUPS: {
        COMPANY_DATA: [
            'company_id',
            'company_name',
            'phone',
            'email',
            'business_type',
            'currency',
            'postcode',
            'users',
            'team_size',
            'time_logs',
            'checkins',
            'active_purchases',
            'expired_purchases',
            'active_subscription_tiers',
        ],
        COMPANY_SETTINGS: [
            'company_id',
            'company_name',
            'track_location',
            'auto_close_projects',
            'auto_archive_by_project',
            'auto_archive_by_item',
            'is_vat_registered',
            'is_cis_enabled',
            'is_disbursements_enabled',
            'mtd_enabled',
            'sumup_enabled',
            'sumup_online_enabled',
            'xero_interface_enabled',
            'xero_usage_enabled',
        ],
        COMPANY_ACTIVITY: [
            'company_id',
            'company_name',
            'customers',
            'customers_linked_to_pn',
            'jobs',
            'signatures',
            'media',
            'priced_items',
            'sites_count',
            'invoicing_addresses_count',
            'emails_sent',
            'emails_opened',
            'sms_messages_sent',
            'notes',
            'appointments',
            'reminders',
            'quotes',
            'job_sheets',
            'documents',
            'form_documents_count',
            'sales_credit_notes',
        ],
        COMPANY_SALES: [
            'company_id',
            'company_name',
            'currency',
            'sales_invoices',
            'sales_invoices_amount',
            'supplier_invoices_and_expenses',
            'supplier_invoices_and_expenses_total',
            'expenses',
            'expenses_total',
        ],
        COMPANY_PAYMENTS: [
            'company_id',
            'company_name',
            'currency',
            'payments',
            'payments_amount',
            'expense_payments_amount',
            'sumup_payments_count',
            'sumup_payments_amount',
            'sumup_online_payments_count',
            'sumup_online_payments_amount',
            'cash_payments_count',
            'cash_payments_amount',
            'cheque_payments_count',
            'cheque_payments_amount',
            'bank_transfer_payments_count',
            'bank_transfer_payments_amount',
            'paypal_standard_payments_count',
            'paypal_standard_payments_amount',
            'paypal_here_payments_count',
            'paypal_here_payments_amount',
            'paypal_checkin_payments_count',
            'paypal_checkin_payments_amount',
            'direct_debit_payments_count',
            'direct_debit_payments_amount',
            'standing_order_payments_count',
            'standing_order_payments_amount',
            'chip_and_pin_payments_count',
            'chip_and_pin_payments_amount',
            'credit_card_manual_payments_count',
            'credit_card_manual_payments_amount',
        ],
    },
};
