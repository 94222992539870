import Entity, { EntityWithCompanyId, field } from '../entity';
    
export default class ProductCategoryEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'string' })
    public name: string;
    
    @field({ type: 'string' })
    public description: string;
    
    @field({ type: 'int' })
    public parent_id: number;
    
    @field({ type: 'boolean' })
    public is_cis: boolean;
    
    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;
    
    @field({ type: 'boolean', codeName: 'isEnabled' })
    public isenabled: boolean;
    
    @field({ type: 'int', allowNull: true, minAppVersion: '7.8.0' })
    public tax_category_id: number;
}
