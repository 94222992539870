export default {
    TYPE: {
        FREE_PERIOD: 1,
        FREE: 2,
        SUBSCRIPTION: 3,
        CONSUMPTION: 4,
    },
    GROUPS: {
        STARTER: {
            text: 'All Starter',
            MATCH: '.*STARTER.*',
        },
        BUSINESS: {
            text: 'All Business',
            MATCH: '.*BUSINESS.*',
        },
        TEAM: {
            text: 'All Pro',
            MATCH: '.*TEAM.*|.*PN_MU.*|.*PN_PRO.*',
        },
    },
    CHANGE_DIRECTIONS: {
        UPGRADE: 'UPGRADE',
        DOWNGRADE: 'DOWNGRADE',
    },
    SUBTYPES: [

        {
            group: 'Starter',
            MATCH: /STARTER/i,
        },
        {
            group: 'Business',
            MATCH: /BUSINESS|^(PN|PN1Y|pn_monthly_1|pn_annual)$/i,
        },
        {
            group: 'Pro',
            MATCH: /TEAM|PN_MU|PN_PRO/i,
        },
        {
            group: 'WL',
            MATCH: /WHITELABEL|WL/i,
        },
    ],
    PERIODTYPES: {
        365: 'Annual',
        31: 'Monthly',
        730: '2yr',
    },

};
