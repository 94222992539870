import { ValueOf } from '@powerednow/type-definitions';

const SERVER_OPERATIONS = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    DOWNLOAD_TABLE: 'download_table',
} as const;

export type SERVER_OPERATIONS_Type = ValueOf<typeof SERVER_OPERATIONS>;

export default {
    MAX_BATCHED_REQUESTS: 500,
    GET_LIMIT: 500,
    OPERATIONS: {
        CLIENT: {
            CREATE: 'create',
            UPDATE: 'update',
            DELETE: 'destroy',
        },
        SERVER: SERVER_OPERATIONS,
    } as const,
    SYNC_ACTION_RESULT: {
        OK: 1,
        ERROR: 2,
    },
};
