const modelProperties = {
    modelName: 'MediaGroup',
    tableName: 'MediaGroup',
    associations: [{
        type: 'hasMany',
        model: 'Media',
        parameters: { foreignKey: { name: 'mediagroup_id', fieldName: 'mediagroup_id' } },
        followFor: ['Archive', 'DocumentClone'],
        includeRestores: ['MediaGroup'],
    }],
    validations: { title: { type: 'length', max: 60 } },
};

export default modelProperties;
