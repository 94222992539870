import { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class CompanyFormEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'int' })
    public form_type_id: number;

    @field({ type: 'string', isJSON: true })
    public numbering_config: {
        prefix: string,
        suffix: string,
        incrementalLength: number,
        allowOverride: boolean,
    };

    @field({ type: 'string', isJSON: true })
    public email_config: {
        subject: string;
        body: string;
        form_version: number,
    };

    @field({ type: 'boolean' })
    public is_enabled: boolean;

    @field({ type: 'string', isJSON: true })
    public reminder_config: {
        title: string;
        text: string;
        subject: string;
        notificationSubject: string;
        body: string;
        notificationBody: string;
        date: string;
        smsText: string;
        sendReminderAfterXDays: number;
        sendReminderByEmail: boolean;
        sendReminderByText: boolean;
        notifyUsersXDaysBefore: number;
        form_version: number,
    };
}
