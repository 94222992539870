export default {
    CATEGORIES: {
        GAS: {
            ID: 1,
            NAME: 'Gas',
        },
        ELECTRICIAL: {
            ID: 2,
            NAME: 'Electrical',
        },
        PLUMBING: {
            ID: 3,
            NAME: 'Plumbing',
        },
        OIL: {
            ID: 4,
            NAME: 'Oil',
        },
        FIRE_ALARM: {
            ID: 6,
            NAME: 'Fire Alarm',
        },
        PEST_CONTROL: {
            ID: 7,
            NAME: 'Pest Control',
        },
        CUSTOMIZABLE: {
            ID: 8,
            NAME: 'Customisable Forms',
        },
        HAZARDOUS_WASTE: {
            ID: 9,
            NAME: 'Hazardous Waste',
        },
        RISK_ASSESMENTS: {
            ID: 10,
            NAME: 'Risk Assessments',
        },
        RENEWABLE_ENERGY: {
            ID: 11,
            NAME: 'Renewable Energy',
        },

    },

};
