import Formatter, { AddressObject } from 'modules/utilities/formatter';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from 'modules/complexData/complexData';
import ContactToAddress from 'modules/complexData/contactToAddress';
import Country from 'modules/complexData/country';
import Company from 'modules/complexData/company';
import ValidationResult from 'modules/validation/validationResult';
import UserToAddress from 'modules/complexData/userToAddress';
import { COUNTRY, ROLES, VALIDATION } from '@powerednow/shared/constants';
import AddressEntity from './entity';
import modelProperties from './modelProperties';
import CountryEntity from '../country/entity';
import ContactToAddressEntity from '../contactToAddress/entity';
import UserToAddressEntity from '../userToAddress/entity';
import { ModelCreationFields } from '../entity';
import User from '../user';

export type AddressTemplateData = Required<ModelCreationFields<AddressEntity>> & {
    addressPlot: string,
    countryName: string,
    countryId: number,
    addressMerged: string,
    formattedAddress: string,
}

interface AddressAssociations extends AssociationConfig<any, any> {
    site: AssociationDefinition<ContactToAddressEntity, ContactToAddress>
    country: AssociationDefinitionSingle<CountryEntity, Country>
    userToAddress: AssociationDefinitionSingle<UserToAddressEntity, UserToAddress>
}

interface Address extends AutoGeneratedFunctions<AddressAssociations, AddressEntity, ComplexData<AddressEntity>> {}

// eslint-disable-next-line no-redeclare
class Address extends ComplexData<AddressEntity> {
    static Entity = AddressEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): AddressAssociations {
        return {
            site: {
                key: 'contactToAddress',
                instance: ContactToAddress,
                entity: ContactToAddressEntity,
                cascadeDelete: false,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().customer_id,
                    address_id: this.Entity.getFieldSymbols().id,
                },
            },

            country: {
                key: 'country',
                instance: Country,
                entity: CountryEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    code: this.Entity.getForeignFieldSymbols().country,
                },
            },
            userToAddress: {
                key: 'userToAddress',
                instance: UserToAddress,
                entity: UserToAddressEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    address_id: this.Entity.getFieldSymbols().id,
                },
            },
        };
    }

    /**
     * Extra initialisation - should be called after the object has been added to the DataWrapper events
     */
    public async initDefaultAssociatedItems(): Promise<void> {
        await super.initDefaultAssociatedItems();
        const companyCountry = await Company.getCompanyCountry(this);
        this.data.country = this.data.country || (companyCountry && companyCountry.code);
    }

    public async isUserAddress(): Promise<boolean> {
        const userToAddress = await this.getUserToAddress();
        return Boolean(userToAddress);
    }

    public async isBelongToUser(user: User): Promise<boolean> {
        const userToAddress = await this.getUserToAddress();
        return Boolean(userToAddress && userToAddress.data.user_id === user.data.id);
    }

    /**
     * Validation rules that can't be handled by
     *
     * @param params - object that holds information about the current validation environment (e.g. global objects, like current user)
     */
    protected async validateCustomRules(params: any): Promise<ValidationResult> {
        const validationResult = await super.validateCustomRules(params);
        const { currentUser } = params.globalObjects;
        let isValid = true;
        const isUserAddress = await this.isUserAddress();
        if (isUserAddress) {
            const currentUserCanManageCompanyData = await currentUser.hasRole(ROLES.PERMISSIONS.CAN_MANAGE_COMPANY_DATA);
            const currentUserCanManageOwnDetails = await currentUser.hasRole(ROLES.PERMISSIONS.CAN_EDIT_OWN_ACCOUNT_DETAILS);
            isValid = currentUserCanManageCompanyData || (await this.isBelongToUser(currentUser) && currentUserCanManageOwnDetails);
        } else {
            isValid = await currentUser.hasRole(ROLES.PERMISSIONS.CAN_EDIT_CUSTOMERS);
        }

        if (!isValid) {
            validationResult.errors.push(VALIDATION.CODES.ADDRESS.NO_CHANGE_PERMISSION);
        }

        return validationResult;
    }

    public getFormattedAddressLines(plot: string = null) {
        const addressPlot = plot ? `${plot} ` : '';
        const { address1 } = this.data;
        const { address2 } = this.data;
        let delimiter = '';
        if (address1 && address2) {
            delimiter = Number.isInteger(Number(address1)) ? ' ' : '<br />';
        }

        return Formatter.stringifyAddress({
            address: {
                address2,
                plottedAddress1: Formatter.stringifyAddress({
                    address: {
                        addressPlot,
                        address1,
                    },
                }, {
                    addressPlot: 0,
                    address1: 1,
                }, '<br />'),
            },
        }, {
            plottedAddress1: 0,
            address2: 1,
        }, delimiter);
    }

    public async getTemplateData(plot: string = null): Promise<AddressTemplateData> {
        const country = await this.getCountry();
        const data = {
            ...this.data.getPureDataValues(),
            addressPlot: plot,
            countryName: country ? country.data.name : '',
            countryId: country ? country.data.id : -1,
            addressMerged: this.getFormattedAddressLines(plot),
            formattedAddress: '',
        };
        data.formattedAddress = Formatter.stringifyAddress({
            plot,
            address: data,
            ...(country ? { country: country.data.name } : {}),
        });

        return data;
    }

    public async stringifyAddress(plot: string, includeCountry: boolean, format?: object): Promise<string> {
        return Formatter.stringifyAddress(await this.getDataForFormatter(plot, includeCountry), format);
    }

    private async getDataForFormatter(plot: string = null, includeCountry: boolean = true): Promise<AddressObject> {
        const country = includeCountry ? await this.getCountry() : null;
        return {
            address: this.data.getPureDataValues(),
            ...(country ? { country: country.data.name } : {}),
            ...(plot ? { plot } : {}),
        };
    }

    public async isFilled(addCountry: boolean = false): Promise<boolean> {
        return Formatter.stringifyAddress({
            address: this.data.getPureDataValues(),
            ...(addCountry ? {
                country: (await this.getCountry()).data.name,
            } : {}),
        }).trim() !== '';
    }

    public async getSearchableFields(): Promise<string[]> {
        return [
            this.data.address1,
            this.data.address2,
            this.data.city,
            this.data.state,
            this.data.county,
            this.data.postcode,
        ];
    }

    public getPropertiesToCopy() {
        const {
            id, isdefault, invoice, customer_id, company_id, is_registered, is_invoice, ...addressData
        } = this.data.getPureDataValues();
        return addressData;
    }

    public async copyData(item: Address) {
        Object.assign(this.data, item.getPropertiesToCopy());

        return this;
    }

    public async findCountryId(): Promise<number> {
        const country = await this.getCountry();
        if (!country) {
            return COUNTRY.IDS.UNITED_KINGDOM;
        }
        return country.data.id;
    }
}

export default Address;
