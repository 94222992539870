const ifInstanceOf = function ifInstanceOf(type, handler) {
    return function (err) {
        if (err instanceof type) {
            return handler.call(this, err);
        }
        throw err;
    };
};

const ifNotInstanceOf = function ifNotInstanceOf(type, handler) {
    return function (err) {
        if (!(err instanceof type)) {
            return handler.call(this, err);
        }
        throw err;
    };
};
module.exports = { ifInstanceOf, ifNotInstanceOf };
