const modelProperties = {
    modelName: 'SettingsKeys',
    tableName: 'SettingsKeys',
    minAppVersion: '7.9.2',
    associations: [{
        type: 'hasMany',
        model: 'CompanySettings',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'setting_key_id', fieldName: 'setting_key_id', sourceKeyName: 'id' } },
    }],
    global: true,
};

export default modelProperties;
