import SyncAction from '../../syncAction';

export default class Syncable {
    public setSyncAction(syncAction) {
        // @ts-ignore
        this.data.sync_action = syncAction.toData();
    }

    public resetSyncAction() {
        // @ts-ignore
        this.data.sync_action = null;
    }

    public getSyncAction() {
        // @ts-ignore
        return this.data.sync_action && SyncAction.fromData(this.data.sync_action);
    }
}
