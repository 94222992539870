import PAYMENT from '@powerednow/shared/constants/payment';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import PaymentType from '../paymentType';
import PaymentSettingEntity from './entity';
import modelProperties from './modelProperties';
import PaymentTypeEntity from '../paymentType/entity';

interface PaymentSettingAssociations extends AssociationConfig<any, any> {
    paymentType: AssociationDefinitionSingle<PaymentTypeEntity, PaymentType>
}

interface PaymentSetting extends AutoGeneratedFunctions<PaymentSettingAssociations, PaymentSettingEntity, ComplexData<PaymentSettingEntity>> {}

// eslint-disable-next-line no-redeclare
class PaymentSetting extends ComplexData<PaymentSettingEntity> {
    static Entity = PaymentSettingEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): PaymentSettingAssociations {
        return {
            paymentType: {
                key: 'paymentType',
                instance: PaymentType,
                entity: PaymentTypeEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().payment_id,
                },
            },
        };
    }

    public async isEnabledForDocumentPaymentCategory(documentPaymentCategory: number): Promise<boolean> {
        return this.data[PAYMENT.DOCUMENT_PAYMENT_CATEGORY_TO_ENABLED_PROPERTY[documentPaymentCategory]];
    }
}

export default PaymentSetting;
