import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import MessageEntity from './entity';
import modelProperties from './modelProperties';

interface MessageAssociations extends AssociationConfig<any, any> {
    parent: AssociationDefinitionSingle<MessageEntity, Message>
    children: AssociationDefinition<MessageEntity, Message>
}

interface Message extends AutoGeneratedFunctions<MessageAssociations, MessageEntity, ComplexData<MessageEntity>> {}

// eslint-disable-next-line no-redeclare
class Message extends ComplexData<MessageEntity> {
    static Entity = MessageEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): MessageAssociations {
        return {
            children: {
                key: 'message',
                instance: Message,
                entity: MessageEntity,
                condition: {
                    parent_id: this.Entity.getFieldSymbols().id,
                },
            },
            parent: {
                key: 'message',
                instance: Message,
                entity: MessageEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().parent_id,
                },
            },
        };
    }
}

export default Message;
