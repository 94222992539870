export default {
    TYPES: {
        CREATE: 'create',
        READ: 'read',
        UPDATE: 'update',
        DELETE: 'delete',
    },
    ADMIN_ROLE: 'admin',
    PROMPTS: {
        CREATE: 'Edit prompts',
        READ: 'View prompts',
        UPDATE: 'Update prompts',
        DELETE: 'Delete prompts',
    },
    DOCUMENTS: {
        CAN_SEE_FINANCIAL_DATA: 'Financial summary',
        CAN_VIEW_PRICING: 'View Pricing',
        CAN_SEE_PRICING_LINES: 'Pricings: full access both written and financial data',
    },
    PERMISSIONS: {
        CAN_LOGIN_TO_WEB: 1,
        CAN_ADMIN_PN: 2,
        CAN_CHANGE_SUBSCRIPTION: 3,
        CAN_MANAGE_USERS: 4,
        CAN_MANAGE_COMPANY_DATA: 5,
        CAN_IMPORT_CONTACTS: 6,
        CAN_CREATE_APPOINTMENT: 7,
        CAN_VIEW_OTHERS_APPOINTMENT: 8,
        CAN_CHANGE_APPOINTMENT: 9,
        CAN_SEE_FINANCIAL_DATA: 11,
        CAN_RECEIVE_DOCUMENT_NOTIFICATIONS: 13,
        CAN_EDIT_OTHER_RECORDS: 28,
        CAN_COPY_DOCUMENT: 43,
        CAN_EDIT_CUSTOMERS: 12,
        CAN_VIEW_CUSTOMERS: 50,
        CAN_CREATE_PROJECT: 10,
        CAN_VIEW_PROJECT: 15,
        CAN_CREATE_QUOTES: 16,
        CAN_VIEW_QUOTES: 17,
        CAN_CREATE_INVOICES: 18,
        CAN_VIEW_INVOICES: 19,
        CAN_CREATE_PAYMENTS: 20,
        CAN_VIEW_PAYMENTS: 21,
        CAN_CREATE_CREDITS: 22,
        CAN_VIEW_CREDITS: 23,
        CAN_CREATE_REFUNDS: 24,
        CAN_VIEW_REFUNDS: 25,
        CAN_CREATE_PRICING: 26,
        CAN_VIEW_PRICING: 27,
        CAN_CREATE_EXPENSE: 46,
        CAN_VIEW_EXPENSE: 47,
        CAN_APPROVE_EXPENSE: 48,
        CAN_EDIT_APPROVED_EXPENSE: 49,
        CAN_CREATE_SUPPLIER_INVOICE: 51,
        CAN_VIEW_SUPPLIER_INVOICE: 52,
        DOCUMENT_SETTINGS_VIEW_HEADER_FOOTER: 29,
        DOCUMENT_SETTINGS_VIEW_PRICEDITEM_LAYOUT: 30,
        DOCUMENT_SETTINGS_VIEW_ATTACHMENTS: 31,
        DOCUMENT_SETTINGS_VIEW_ENVELOPE: 32,
        DOCUMENT_SETTINGS_VIEW_LOGO: 33,
        DOCUMENT_SETTINGS_VIEW_TEMPLATE: 34,
        DOCUMENT_SETTINGS_VIEW_TERMINOLOGY: 35,
        DOCUMENT_SETTINGS_VIEW_NUMBERING_RULES: 39,
        GENERAL_SETTINGS_VIEW_GENERAL: 36,
        GENERAL_SETTINGS_VIEW_DIARY: 37,
        GENERAL_SETTINGS_VIEW_NOTIFICATIONS: 38,
        CAN_VIEW_LOCATIONS: 44,
        CAN_CREATE_DISBURSEMENTS: 45,
        CAN_VIEW_PRODUCTS: 53,
        CAN_EDIT_PRODUCTS: 54,
        CAN_CREATE_PRODUCTS: 55,
        CAN_EDIT_EMAIL_TERMINOLOGY: 56,
        CAN_EXPORT_CUSTOMERS: 57,
        CAN_MANAGE_FORMS: 58,
        CAN_EDIT_FORM_SETTINGS: 59,
        CAN_CREATE_CERTIFICATE: 60,
        CAN_VIEW_CERTIFICATE: 61,
        CAN_EDIT_CERTIFICATE: 62,
        GENERAL_SETTINGS_VIEW_PROJECTS: 63,
        GENERAL_SETTINGS_EDIT_AUTO_ARCHIVE: 64,
        MANAGE_VAT_RETURNS: 65,
        CAN_CREATE_RECURRING_APPOINTMENT: 66,
        MANAGE_ACCOUNTING_SETTINGS: 67,
        CREATE_ACCOUNTING_EXPORT: 68,
        SEND_MESSAGE_TO_CUSTOMER: 69,
        SEND_MESSAGE_TO_USER: 70,
        CAN_VIEW_APPOINTMENT_VALUE: 71,
        CAN_EDIT_APPOINTMENT_VALUE: 72,
        CAN_CREATE_APPOINTMENT_VALUE: 73,
        CAN_VIEW_APPOINTMENT_STATUS: 74,
        CAN_EDIT_APPOINTMENT_STATUS: 75,
        CAN_CREATE_APPOINTMENT_STATUS: 76,
        CAN_PIN_TIMELINE_ITEM: 77,
        CAN_MARK_TIMELINE_ITEM: 78,
        CAN_SEE_PRICING_LINES: 86,
        CAN_CREATE_PROMPTS: 40,
        CAN_VIEW_PROMPTS: 41,
        CAN_EDIT_PROMPTS: 42,
        // @TODO master-merge
        // Change these in DB if merge looks ok.
        CAN_VIEW_CUSTOMER_PORTAL: 79,
        CAN_LOGIN_TO_CUSTOMER_PORTAL: 80,
        CAN_CREATE_OWN_ABSENCE: 81,
        CAN_CREATE_ANY_ABSENCE: 82,

        CAN_EDIT_APPOINTMENT_TYPES: 83,
        CAN_EDIT_BOOKING_EXCEPTIONS: 84,
        CAN_EDIT_GENERAL_AVAILABILITY: 85,
        CAN_VIEW_RELATED_PROJECTS: 87,

        CAN_CHANGE_QUOTE_STATUS_ON_PORTAL: 100,
        CAN_DO_PAYMENT_ON_PORTAL: 101,
        CAN_SEND_MESSAGE_FROM_PORTAL: 102,
        CAN_MANAGE_ACCOUNT_ON_PORTAL: 103,
        CAN_BOOK_APPOINTMENT_ON_PORTAL: 104,

        CAN_USE_DIARY_SCHEDULE: 105,
        CAN_EDIT_OWN_ACCOUNT_DETAILS: 106,
        CAN_REPORT_OWN_HOLIDAY: 107,
        CAN_REPORT_ANY_HOLIDAY: 108,
        // Admin roles
        CAN_ACCESS_SYSTEM_PAGE: 109,
        CAN_ACCESS_REPORTS: 110,
        CAN_ACCESS_ANY_WHITELABEL: 111,
        CAN_CREATE_SUPERUSERS: 112,
        CAN_ACCESS_HOME: 113,
        
        CAN_SHOW_HIDE_FIELDS_ON_DOCUMENTS: 114,
        CAN_EDIT_TIME_LOGS: 115,
        CAN_SEE_OWN_PRICING_LINES: 116,
        CAN_CREATE_EDIT_SITES: 117,
        CAN_ENABLE_PLOTS: 118,
    },
    ERROR_MESSAGE: {
        MANAGE_COMPANY_DATA: 'You don\'t have permission to manage company related data!',
        SHOW_HIDE_FIELDS_ON_DOCUMENTS: 'You don\'t have permission to show/hide fields on documents!',
    },
};
