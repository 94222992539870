import UserTaxRate, { ERRORS, userTaxRateFilters } from 'modules/complexData/userTaxRate';
import ProductCategory from 'modules/complexData/productCategory';
import BUSINESS_CALCULATOR_CONSTANTS from 'constants/businessCalculator';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import JobItemEntity from './entity';
import modelProperties from './modelProperties';
import UserTaxRateEntity from '../userTaxRate/entity';
import ProductCategoryEntity from '../productCategory/entity';

const math = require('modules//utilities/math');

interface JobItemAssociations extends AssociationConfig<any, any> {
    userTaxRate: AssociationDefinitionSingle<UserTaxRateEntity, UserTaxRate>
    productCategory: AssociationDefinitionSingle<ProductCategoryEntity, ProductCategory>
}

interface JobItem extends AutoGeneratedFunctions<JobItemAssociations, JobItemEntity, ComplexData<JobItemEntity>> {}

// eslint-disable-next-line no-redeclare
class JobItem extends ComplexData<JobItemEntity> {
    static Entity = JobItemEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): JobItemAssociations {
        return {
            userTaxRate: {
                key: 'userTaxRate',
                instance: UserTaxRate,
                entity: UserTaxRateEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().vat_id,
                },
            },
            productCategory: {
                key: 'productCategory',
                instance: ProductCategory,
                entity: ProductCategoryEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().category_id,
                },
            },
        };
    }

    public async isAllowed({
        sourceCountryId,
        targetCountryId,
        documentGroupType,
    }: userTaxRateFilters): Promise<boolean> {
        if (!this.data.vat_id) {
            return true;
        }

        const userTaxRate = await this.getUserTaxRate();
        const taxCategoryId = await this.findTaxCategoryId();

        if (!userTaxRate) {
            throw Object.assign(new ERRORS.UserTaxRateNotFoundError(), {
                details: {
                    jobItemDataValues: this.data.getPureDataValues(),
                },
            });
        }

        return userTaxRate.isAllowed({
            sourceCountryId,
            targetCountryId,
            taxCategoryId,
            documentGroupType,
        });
    }

    private async findTaxCategoryId(): Promise<number | null> {
        const productCategory = await this.getProductCategory();
        return productCategory ? productCategory.data.tax_category_id : null;
    }

    /**
     * Calculations
     */

    calculate(options: Record<string, any> = {}): JobItemEntity {
        this.calculateCost();
        if ((typeof options.use_tax !== 'undefined' && options.use_tax) || this.data.use_tax) {
            this.setCalculatedTax(options);
        } else {
            this.setZeroTax();
        }
        this.calculateCIS(options);
        this.emit(BUSINESS_CALCULATOR_CONSTANTS.EVENTS.LINE_RECALCULATED, this.getData());
        return this.getData();
    }

    calculateCost(): void {
        this.data.cost = Math.round(this.data.unitcost * this.data.quantity * BUSINESS_CALCULATOR_CONSTANTS.PRECISION_MULTIPLIER)
            / BUSINESS_CALCULATOR_CONSTANTS.PRECISION_MULTIPLIER;
    }

    setCalculatedTax(options: Record<string, any> = {}): void {
        if (options.is_inclusive || this.data.is_inclusive) {
            this.data.vat = this.calculateIncludedTax();
            this.data.exclusive_cost = this.data.cost - this.data.vat;
        } else {
            this.data.vat = this.calculateTax();
            this.data.exclusive_cost = this.data.cost;
            this.data.cost = this.data.exclusive_cost + this.data.vat;
        }
    }

    calculateIncludedTax(): number {
        return math.calculateIncludedTax(this.data.cost, this.data.vat_rate);
    }

    calculateTax(): number {
        return math.calculateTax(this.data.cost, this.data.vat_rate);
    }

    setZeroTax(): void {
        this.data.vat = 0;
        this.data.vat_rate = 0;
        this.data.vat_id = undefined;
        this.data.exclusive_cost = this.data.cost;
    }

    calculateCIS(options: Record<string, any> = {}): void {
        if (this.data.is_cis || options.cisEnabled) {
            this.data.cis_value = Math.round(
                (this.data.exclusive_cost * (this.data.cis_rate / 100))
                * BUSINESS_CALCULATOR_CONSTANTS.PRECISION_MULTIPLIER,
            ) / BUSINESS_CALCULATOR_CONSTANTS.PRECISION_MULTIPLIER;
        }
    }
}

export default JobItem;
