const modelProperties = {
    modelName: 'UserProfileRoleCustom',
    minAppVersion: '5.0.0',
    tableName: 'userprofile_role_custom',
    associations: [{
        type: 'belongsTo',
        model: 'UserProfile',
        parameters: { foreignKey: { name: 'userprofile_id', fieldName: 'userprofile_id' } },
    }],
};

export default modelProperties;
