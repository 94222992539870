import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import CompanyFormEntity from './entity';
import modelProperties from './modelProperties';

const _ = require('lodash');

interface CompanyFormAssociations extends AssociationConfig<any, any> {}

interface CompanyForm extends AutoGeneratedFunctions<CompanyFormAssociations, CompanyFormEntity, ComplexData<CompanyFormEntity>> {}

// eslint-disable-next-line no-redeclare
class CompanyForm extends ComplexData<CompanyFormEntity> {
    static Entity = CompanyFormEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): CompanyFormAssociations {
        return {};
    }

    getRelated() {
        return _.cloneDeep(this.data.getPureDataValues());
    }
}

export default CompanyForm;
