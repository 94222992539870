const modelProperties = {
    modelName: 'Job',
    tableName: 'Job',
    associations: [{
        type: 'hasOne',
        model: 'JobStats',
        parameters: {
            as: 'JobStats',
            foreignKey: {
                name: 'job_id',
                fieldName: 'job_id',
            },
        },
    }, {
        type: 'belongsTo',
        cascadeDelete: false,
        model: 'Customer',
        parameters: { as: 'Customer', foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        cascadeDelete: false,
        model: 'JobStatus',
        parameters: { as: 'JobStatus', foreignKey: { name: 'status', fieldName: 'status' } },
    }, {
        type: 'belongsTo',
        cascadeDelete: false,
        model: 'ContactToAddress',
        parameters: { foreignKey: { name: 'site_id', fieldName: 'site_id' }, targetKeyName: 'id', targetKey: 'id' },
    }, {
        type: 'belongsTo',
        serverOnly: true,
        model: 'Company',
        parameters: { as: 'Company', foreignKey: { name: 'company_id', fieldName: 'company_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'User',
        parameters: { as: 'User', foreignKey: { name: 'user_id', fieldName: 'user_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'hasMany',
        model: 'Action',
        parameters: { foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'Action',
        parameters: { as: 'Appointments', foreignKey: { name: 'job_id', fieldName: 'job_id' }, scope: { type: 1 } },
    }, {
        type: 'hasMany',
        model: 'Action',
        parameters: { as: 'Reminders', foreignKey: { name: 'job_id', fieldName: 'job_id' }, scope: { type: 2 } },
    }, {
        type: 'hasMany',
        model: 'MediaGroup',
        parameters: { as: 'MediaGroup', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'Media',
        parameters: { foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'JobItemGroup',
        parameters: { foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'Note',
        parameters: { as: 'Notes', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'ArchiveBatch',
        parameters: { complexAssociationName: 'ArchiveBatch', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
    }, {
        type: 'hasMany',
        model: 'Document',
        parameters: { complexAssociationName: 'document', as: 'Documents', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'Document',
        parameters: { as: 'Invoices', foreignKey: { name: 'job_id', fieldName: 'job_id' }, scope: { type: 0 } },
    }, {
        type: 'hasMany',
        model: 'Document',
        parameters: { as: 'Quotes', foreignKey: { name: 'job_id', fieldName: 'job_id' }, scope: { type: 1 } },
    }, {
        type: 'hasMany',
        model: 'Document',
        parameters: { as: 'CreditNotes', foreignKey: { name: 'job_id', fieldName: 'job_id' }, scope: { type: 3 } },
    }, {
        type: 'hasMany',
        model: 'FormDocument',
        parameters: { foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'Signature',
        parameters: { foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'DocumentAction',
        parameters: { foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'TimeLog',
        parameters: { foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'CustomerMessage',
        parameters: { as: 'CustomerMessage', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'Message',
        parameters: { as: 'Message', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }, {
        type: 'hasMany',
        model: 'FileGroup',
        parameters: { as: 'FileGroup', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['Archive'],
    }],

    validations: {
        description: {
            type: 'length',
            min: 1,
            max: 120,
            bothOnlyMessagePromptId: 'ValidatorMessages.minMaxLengthMessage',
            bothOnlyMessagePromptDefault: 'Length must be between {min} and {max}',
        },
    },
};

export default modelProperties;
