const modelProperties = {
    modelName: 'Customer',
    tableName: 'Customer',
    associations: [{
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'hasOne',
        model: 'Contact',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
    }, {
        type: 'hasMany',
        model: 'Contact',
        cascadeDelete: false,
        parameters: {
            as: 'Contacts',
            complexAssociationName: 'contact',
            foreignKey: { name: 'customer_id', fieldName: 'customer_id' },
        },
    }, {
        type: 'hasMany',
        model: 'Address',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
    }, {
        type: 'hasMany',
        model: 'Document',
        parameters: { foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
    }, {
        type: 'hasMany',
        model: 'Job',
        parameters: { foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
    }, {
        type: 'hasMany',
        model: 'Message',
        parameters: { foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
    }, {
        type: 'hasMany',
        model: 'CustomerMessage',
        parameters: { foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
    }, {
        type: 'hasMany',
        model: 'LastMessageInThread',
        parameters: {
            as: 'LastMessageInThread',
            complexAssociationName: 'lastMessageInThread',
            foreignKey: { name: 'customer_id', fieldName: 'customer_id' },
        },
    }, {
        type: 'hasMany',
        model: 'ContactToAddress',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'customer_id', fieldName: 'customer_id' } },
    }, {
        type: 'hasOne',
        model: 'ContactToAddress',
        cascadeDelete: false,
        parameters: { as: 'MainSite', foreignKey: { name: 'customer_id', fieldName: 'customer_id' }, scope: { type: 1 } },
    }, {
        type: 'hasOne',
        model: 'CisRate',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'id', fieldName: 'id', sourceKeyName: 'cis_rate_id' } },
    }, {
        type: 'hasMany',
        model: 'FormDocument',
        cascadeDelete: false,
        parameters: {
            as: 'FormDocuments',
            complexAssociationName: 'formDocument',
            foreignKey: { name: 'customer_id', fieldName: 'customer_id' },
        },
    }, {
        type: 'belongsToMany',
        model: 'WhiteLabel',
        modelKeys: { primary: 'id', foreign: 'whitelabel_id' },
        parameters: {
            as: 'WhiteLabels',
            through: { model: 'WhiteLabelToCustomer', unique: false },
            foreignKey: { name: 'customer_id', fieldName: 'customer_id' },
            otherKey: { name: 'whitelabel_id', fieldName: 'whitelabel_id' },
        },
    }, {
        type: 'hasMany',
        model: 'WhiteLabelToCustomer',
        cascadeDelete: false,
        parameters: {
            as: 'WhiteLabelToCustomers',
            complexAssociationName: 'whiteLabelToCustomer',
            foreignKey: { name: 'customer_id', fieldName: 'customer_id' },
        },
    }, {
        type: 'hasMany',
        model: 'ActionsForCustomer',
        cascadeDelete: false,
        parameters: {
            as: 'ActionsForCustomer',
            complexAssociationName: 'actionsForCustomer',
            foreignKey: { name: 'customer_id', fieldName: 'customer_id' },
        },
    }, {
        type: 'hasMany',
        model: 'PaymentsForCustomer',
        cascadeDelete: false,
        parameters: {
            as: 'PaymentsForCustomer',
            complexAssociationName: 'paymentsForCustomer',
            foreignKey: { name: 'customer_id', fieldName: 'customer_id' },
        },
    }],
    validations: {
        company: { type: 'length', max: 120 },
        notes: { type: 'length' },
        utr: { type: 'length', max: 20 },
        insurance_number: { type: 'length', max: 60 },
        company_number: { type: 'length', max: 60 },
        tax_reference: { type: 'length', max: 60 },
        verification_number: { type: 'length', max: 60 },
        reference: { type: 'length', max: 320 },
        account_name: { type: 'length', max: 30 },
    },
};

export default modelProperties;
