import Entity, { DateOrString, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class PurchaseSetupEntity extends Entity {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'int' })
    public type: number;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;
    
    @field({ type: 'string' })
    public product_name: string;
    
    @field({ type: 'string' })
    public description_id: string;
    
    @field({ type: 'string' })
    public period_id: string;
    
    @field({ type: 'string' })
    public days_before_reminder: number;
    
    @field({ type: 'string' })
    public days_before_expires: number;
    
    @field({ type: 'string', unique: true })
    public ios_product_id: string;
    
    @field({ type: 'string' })
    public android_product_id: string;
    
    @field({ type: 'string', dbType: 'date' })
    public dt_valid_from: DateOrString;
    
    @field({ type: 'int' })
    public emails: number;
    
    @field({ type: 'int' })
    public sms: number;
    
    @field({ type: 'int' })
    public postcode: number;
    
    @field({ type: 'int' })
    public users: number;
    
    @field({ type: 'int' })
    public team_trial_enabled: number;
    
    @field({ type: 'boolean', minAppVersion: '6.8.0' })
    public team_enabled: boolean;
    
    @field({ type: 'int' })
    public soldOnITC: number;
    
    @field({ type: 'int' })
    public soldOnBT: number;
    
    @field({ type: 'string' })
    public feature_prompt_ids: string;
    
    @field({ codeName: 'documentCreation', type: 'int' })
    public documentCreation: number;
    
    @field({ codeName: 'documentSend', type: 'int' })
    public documentSend: number;
    
    @field({ codeName: 'customerLimit', type: 'int' })
    public customerLimit: number;
    
    @field({ codeName: 'inviteConstructor', type: 'int' })
    public inviteConstructor: number;
    
    @field({ codeName: 'deviceSwitch', type: 'int' })
    public deviceSwitch: number;
    
    @field({ type: 'string' })
    public min_app_version: string;
    
    @field({ type: 'string' })
    public max_app_version: string;
    
    @field({ type: 'int' })
    public suppliers: number;
    
    @field({ type: 'int' })
    public cis: number;
    
    @field({ codeName: 'canApproveDoc', type: 'boolean' })
    public canApproveDoc: boolean;
    
    @field({ type: 'boolean' })
    public products: boolean;
    
    @field({ type: 'boolean' })
    public sites: boolean;
    
    @field({ minAppVersion: '7.5.0', type: 'boolean' })
    public form: boolean;
    
    @field({ type: 'int' })
    public weight: number;
    
    @field({ minAppVersion: '7.7.3', type: 'boolean' })
    public tracking: boolean;
    
    @field({ minAppVersion: '7.8.0', type: 'boolean' })
    public tax_history: boolean;
    
    @field({ minAppVersion: '8.0.6', type: 'boolean' })
    public accounting_integration: boolean;
}
