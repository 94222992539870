import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import PurchaseActionEntity from './entity';
import modelProperties from './modelProperties';

interface PurchaseActionAssociations extends AssociationConfig<any, any> {}

interface PurchaseAction extends AutoGeneratedFunctions<PurchaseActionAssociations, PurchaseActionEntity, ComplexData<PurchaseActionEntity>> {}

// eslint-disable-next-line no-redeclare
class PurchaseAction extends ComplexData<PurchaseActionEntity> {
    static Entity = PurchaseActionEntity;

    static modelProperties = modelProperties;
}

export default PurchaseAction;
