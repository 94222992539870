import { EntityWithId, field } from '../entity';
    
export default class ProductTierToFeatureEntity extends EntityWithId {
    @field({
        type: 'int', primaryKey: true, indexed: true,
    })
    public id: number;

    @field({ type: 'int' })
    public product_feature_id: number;

    @field({ type: 'int' })
    public product_tier_id: number;

    @field({ type: 'int', allowNull: true })
    public value: number;
}
