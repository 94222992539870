import modelProperties from './modelProperties';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import Job from '../job';
import JobStatsEntity from './entity';
import JobEntity from '../job/entity';

interface JobStatsAssociations extends AssociationConfig<any, any> {
    job: AssociationDefinitionSingle<JobEntity, Job>
}

interface JobStats extends AutoGeneratedFunctions<JobStatsAssociations, JobStatsEntity, ComplexData<JobStatsEntity>> {}

// eslint-disable-next-line no-redeclare
class JobStats extends ComplexData<JobStatsEntity> {
    static Entity = JobStatsEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): JobStatsAssociations {
        return {
            job: {
                key: 'job',
                instance: Job,
                entity: JobEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().job_id,
                },
            },
        };
    }
}

export default JobStats;
