import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class JobItemEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'string' })
    public description: string;
    
    @field({ type: 'decimal', codeName: 'unitCost' })
    public unitcost: number;
    
    @field({ type: 'decimal' })
    public cost: number;
    
    @field({ type: 'decimal' })
    public quantity: number;
    
    @field({ type: 'int' })
    public user_id: number;
    
    @field({ type: 'int', codeName: 'jobItemGroupId' })
    public jobitemgroup_id: number;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;
    
    @field({ type: 'decimal' })
    public vat: number;
    
    @field({ type: 'decimal' })
    public vat_rate: number;
    
    @field({ type: 'int' })
    public vat_id: number;
    
    @field({ type: 'string' })
    public tax_name: string;
    
    @field({ type: 'boolean', allowNull: true })
    public use_tax: boolean;
    
    @field({ type: 'decimal' })
    public exclusive_cost: number;
    
    @field({ type: 'int' })
    public is_inclusive: number;
    
    @field({ type: 'int' })
    public is_category_required: number;
    
    @field({ type: 'int' })
    public category_id: number;
    
    @field({ type: 'boolean', defaultValue: false })
    public category_is_cis: boolean;
    
    @field({ type: 'boolean' })
    public is_cis: boolean;
    
    @field({ type: 'int' })
    public cis_id: number;
    
    @field({ type: 'string' })
    public cis_name: string;
    
    @field({ type: 'decimal' })
    public cis_rate: number;
    
    @field({ type: 'int' })
    public sequence: number;
    
    @field({ type: 'decimal' })
    public cis_value: number;
    
    @field({ type: 'string', indexed: true })
    public sku: string;
    
    @field({ codeName: 'partnerCatalogId', minAppVersion: '8.2.0', type: 'int' })
    public partnerCatalogId: number;
    
    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;
    
    @field({ type: 'boolean', codeName: 'isArchived', serverOnly: true })
    public is_archived: boolean;
}
