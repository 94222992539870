const modelProperties = {
    modelName: 'CountryGroup',
    tableName: 'CountryGroup',
    minAppVersion: '7.8.0',
    associations: [{
        type: 'belongsToMany',
        model: 'Country',
        cascadeDelete: false,
        modelKeys: { primary: 'id', foreign: 'country_id' },
        parameters: {
            through: { model: 'CountryToCountryGroup', unique: false },
            foreignKey: { name: 'country_group_id', fieldName: 'country_group_id' },
            otherKey: { name: 'country_id', fieldName: 'country_id' },
        },
    }, {
        type: 'hasMany',
        model: 'CountryToCountryGroup',
        cascadeDelete: false,
        parameters: { foreignKey: { name: 'country_group_id', fieldName: 'country_group_id' } },
    }],
    global: true,
};

export default modelProperties;
