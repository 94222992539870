export default {
    TAX_CATEGORY: {
        NONE: {
            ID: -1,
            NAME: 'N/A',
        },
        EU: {
            GOODS: {
                ID: 0,
                NAME: 'Goods',
            },
            SERVICES: {
                ID: 1,
                NAME: 'Services',
            },
        },
    },
    INITIAL_NAMES: {
        MATERIAL: 'Material',
        LABOUR: 'Labour',
        PERSONAL_EXPENSES: 'Personal expenses',
        EQUIPMENT_HIRE: 'Equipment hire',
    },
};
