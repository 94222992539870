// eslint-disable-next-line max-classes-per-file
import * as Bluebird from 'bluebird';

import UserTaxRateFilter from 'modules/complexData/userTaxRateFilter';
import CompanyIntegration from 'modules/complexData/companyIntegration';
import ComplexDataError from 'modules/complexData/complexDataError';
import ComplexData, { AssociationConfig, AssociationDefinition, AutoGeneratedFunctions } from '../complexData';
import UserTaxRateEntity from './entity';
import modelProperties from './modelProperties';
import UserTaxRateFilterEntity from '../userTaxRateFilter/entity';
import CompanyIntegrationEntity from '../companyIntegration/entity';

class UserTaxRateNotFoundError extends ComplexDataError {
    constructor() {
        super('User TaxRate Not found');
    }
}

const ERRORS = {
    UserTaxRateNotFoundError,
};

export { ERRORS };

export type userTaxRateFilters = {
    sourceCountryId?: number;
    targetCountryId?: number;
    taxCategoryId?: number;
    documentGroupType?: number;
};

export type taxRateRule = {
    fieldName: string,
    value: string | number | boolean,
};

interface UserTaxRateAssociations extends AssociationConfig<any, any> {
    userTaxRateFilter: AssociationDefinition<UserTaxRateFilterEntity, UserTaxRateFilter>
    companyIntegration: AssociationDefinition<CompanyIntegrationEntity, CompanyIntegration>
}

interface UserTaxRate extends AutoGeneratedFunctions<UserTaxRateAssociations, UserTaxRateEntity, ComplexData<UserTaxRateEntity>> {}

// eslint-disable-next-line no-redeclare
class UserTaxRate extends ComplexData<UserTaxRateEntity> {
    static Entity = UserTaxRateEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): UserTaxRateAssociations {
        return {
            userTaxRateFilter: {
                key: 'userTaxRateFilter',
                instance: UserTaxRateFilter,
                entity: UserTaxRateFilterEntity,
                cascadeDelete: false,
                condition: {
                    taxrate_id: this.Entity.getFieldSymbols().id,
                },
            },
            companyIntegration: {
                key: 'companyIntegration',
                instance: CompanyIntegration,
                entity: CompanyIntegrationEntity,
                cascadeDelete: false,
                condition: {
                    company_id: this.Entity.getFieldSymbols().company_id,
                },
            },
        };
    }

    static get ERRORS() {
        return { ...ComplexData.ERRORS, ...ERRORS };
    }

    public async getCalculationTypeId({
        sourceCountryId, targetCountryId, taxCategoryId, documentGroupType,
    }: userTaxRateFilters): Promise<number> {
        const taxRateFilter = await this.findTaxRateFilters({
            sourceCountryId, targetCountryId, taxCategoryId, documentGroupType,
        });
        if (taxRateFilter && taxRateFilter[0]) {
            return taxRateFilter[0].data.calculation_type;
        }
        return null;
    }

    public async isAllowed({
        sourceCountryId, targetCountryId, taxCategoryId, documentGroupType,
    }: userTaxRateFilters): Promise<boolean> {
        if (await this.noFilters()) {
            return true;
        }
        const taxRateFilters = await this.findTaxRateFilters({
            sourceCountryId, targetCountryId, taxCategoryId, documentGroupType,
        });
        return taxRateFilters.length > 0;
    }

    public async findTaxRateFilters({
        sourceCountryId, targetCountryId, taxCategoryId, documentGroupType,
    }: userTaxRateFilters): Promise<UserTaxRateFilter[]> {
        const integrationTypeRule = await this.getIntegrationTypeRule();

        const rules: taxRateRule[] = [{
            fieldName: 'country_id',
            value: sourceCountryId,
        }, {
            fieldName: 'tax_category_id',
            value: taxCategoryId,
        }, {
            fieldName: 'document_group_type',
            value: documentGroupType,
        }, ...(integrationTypeRule ? [integrationTypeRule] : [])];

        const allowedByProperties = await this.findTaxRateFiltersByProperties(rules);

        if (targetCountryId) {
            const taxRateFiltersForCountry = await this.filterTaxRateFiltersForTargetCountry(allowedByProperties, sourceCountryId, targetCountryId);
            if (this.hasMultipleTaxRateFilterDefinition({
                sourceCountryId, targetCountryId, taxCategoryId, documentGroupType,
            }, taxRateFiltersForCountry)) {
                throw new Error('userTaxRate should have only one calculation type on all criteria items are defined.');
            }
            return taxRateFiltersForCountry;
        }
        return allowedByProperties;
    }

    private async getIntegrationTypeRule(): Promise<taxRateRule> {
        const enabledIntegration: CompanyIntegration = await this.getEnabledIntegration();

        return enabledIntegration ? {
            fieldName: `allowed_for_integration_type_${enabledIntegration.data.integration_type_id}`,
            value: true,
        } : null;
    }

    private async getEnabledIntegration(): Promise<CompanyIntegration> {
        const companyIntegrations: CompanyIntegration[] = await this.getAllCompanyIntegration();

        return companyIntegrations.find(companyIntegration => companyIntegration.data.is_enabled);
    }

    private hasMultipleTaxRateFilterDefinition(criteria: userTaxRateFilters, taxRateFiltersForCountry): boolean {
        return (this.isAllCriteriaItemDefined(criteria)) && taxRateFiltersForCountry.length > 1;
    }

    private isAllCriteriaItemDefined(criteria: userTaxRateFilters): boolean {
        return Object.values(criteria).every(criteriaItemValue => criteriaItemValue !== null && criteriaItemValue !== undefined);
    }

    private async findTaxRateFiltersByProperties(rules: taxRateRule[]): Promise<UserTaxRateFilter[]> {
        const taxRateFilters = await this.getAllUserTaxRateFilter();
        return Bluebird.reduce(taxRateFilters, async (matchedFilters, taxRateFilter) => {
            const taxRateFilterMatching = rules.every((rule: taxRateRule) => {
                if (!UserTaxRate.ruleIsDefined(rule)
                    || !taxRateFilter.isFieldRequired(rule.fieldName)
                    || !taxRateFilter.isFieldAvailable(rule.fieldName)) {
                    return true;
                }
                return taxRateFilter.data[rule.fieldName] === rule.value;
            });
            if (taxRateFilterMatching) {
                matchedFilters.push(taxRateFilter);
            }
            return matchedFilters;
        }, []);
    }

    private async filterTaxRateFiltersForTargetCountry(taxRateFilters: UserTaxRateFilter[], sourceCountryId: number, targetCountryId: number): Promise<UserTaxRateFilter[]> {
        return Bluebird.reduce(taxRateFilters, async (allowedFilters, taxRateFilter) => {
            const isAllowed = await taxRateFilter.allowedForTargetCountry(sourceCountryId, targetCountryId);
            if (isAllowed) {
                allowedFilters.push(taxRateFilter);
            }
            return allowedFilters;
        }, []);
    }

    private async noFilters(): Promise<boolean> {
        const taxRateFilters = await this.getAllUserTaxRateFilter();
        return taxRateFilters.length === 0;
    }

    private static ruleIsDefined(rule: taxRateRule): boolean {
        return rule.value !== undefined && rule.value !== null;
    }
}

export default UserTaxRate;
