const BaseValidator = require('modules/validation/baseValidator');

class PhoneValidator extends BaseValidator {
    constructor(config = {}) {
        super(config);
        Object.assign(this.config, {
            message: 'notValidPhone',
            matcher: /^\+?\d{6,}$/i,
            maxLength: 25,
        });
    }

    validate(value) {
        const preparedValue = value || '';
        if (preparedValue.trim() === '') {
            return true;
        }

        const cleanedValue = preparedValue.replace(/[\s-/()]/g, '');
        if (cleanedValue.length > this.config.maxLength) {
            return this.config.message;
        }

        const { matcher } = this.config;
        const result = matcher && matcher.test(cleanedValue);

        if (!result) {
            return this.config.message;
        }

        return result;
    }
}

module.exports = PhoneValidator;
