import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import DocumentActionEntity from './entity';
import modelProperties from './modelProperties';

interface DocumentActionAssociations extends AssociationConfig<any, any> {}

interface DocumentAction extends AutoGeneratedFunctions<DocumentActionAssociations, DocumentActionEntity, ComplexData<DocumentActionEntity>> {}

// eslint-disable-next-line no-redeclare
class DocumentAction extends ComplexData<DocumentActionEntity> {
    static Entity = DocumentActionEntity;

    static modelProperties = modelProperties;
}

export default DocumentAction;
