import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import RolesEntity from './entity';
import modelProperties from './modelProperties';

interface RolesAssociations extends AssociationConfig<any, any> {}

interface Roles extends AutoGeneratedFunctions<RolesAssociations, RolesEntity, ComplexData<RolesEntity>> {}

// eslint-disable-next-line no-redeclare
class Roles extends ComplexData<RolesEntity> {
    static Entity = RolesEntity;

    static modelProperties = modelProperties;
}

export default Roles;
