const modelProperties = {
    modelName: 'Media',
    tableName: 'Media',
    validations: {
        name: { type: 'length', max: 60 },
        filename: { type: 'length', max: 255 },
        url: { type: 'length', max: 255 },
        status: { type: 'length', max: 32 },
    },
};

export default modelProperties;
