import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import TimeLogEntity from './entity';
import modelProperties from './modelProperties';

interface TimeLogAssociations extends AssociationConfig<any, any> {}

interface TimeLog extends AutoGeneratedFunctions<TimeLogAssociations, TimeLogEntity, ComplexData<TimeLogEntity>> {}

// eslint-disable-next-line no-redeclare
class TimeLog extends ComplexData<TimeLogEntity> {
    static Entity = TimeLogEntity;

    static modelProperties = modelProperties;
}

export default TimeLog;
