const modelProperties = {
    modelName: 'Purchases',
    tableName: 'Purchases',
    associations: [{
        type: 'hasOne',
        model: 'PurchaseAction',
        parameters: { as: 'PurchaseAction', foreignKey: { name: 'purchase_id', fieldName: 'purchase_id' } },
    }, {
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'belongsTo',
        model: 'PurchaseSetup',
        parameters: {
            as: 'PurchaseSetupIOS',
            complexAssociationName: 'PurchaseSetup',
            foreignKey: { name: 'product', fieldName: 'product' },
            targetKey: 'ios_product_id',
            targetKeyName: 'ios_product_id',
        },
        followFor: ['complexData-company-purchases'],
    }, {
        type: 'belongsTo',
        model: 'PurchaseSetup',
        parameters: {
            as: 'PurchaseSetupAndroid',
            complexAssociationName: 'PurchaseSetup',
            foreignKey: { name: 'product', fieldName: 'product' },
            targetKey: 'android_product_id',
            targetKeyName: 'android_product_id',
        },
        followFor: ['complexData-company-purchases'],
    }],
};

export default modelProperties;
