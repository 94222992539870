const modelProperties = {
    modelName: 'Attachment',
    tableName: 'Attachment',
    validations: {
        name: { type: 'length', max: 250 },
        url: { type: 'length', max: 250 },
        original_url: { type: 'length', max: 250 },
        status: { type: 'length', max: 12 },
    },
};

export default modelProperties;
