export default {
    MAIN_USER: 1,
    MANAGER: 2,
    ENGINEER: 3,
    ADMIN: 4,
    TRANSLATOR: 5,
    PORTAL_USER: 6,
    NO_APP_LOGIN: 7,
    SALES_CUSTOMER_SUCCESS: 8,
    WHITELABEL_ADMIN: 9,
};
