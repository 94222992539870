export default {
    NON_PRODUCTS: {
        DISABLED: 0,
        ENABLED: 1,
    },
    PRODUCTS: {
        DISABLED: 0,
        OFFER_PREVIOUS_ENTRY: 1,
        PRODUCT_CATALOG: 2,
    },
};
