import Entity, { DateOrString, EntityWithId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class FormTypeEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;
    
    @field({ type: 'string' })
    public name: string;
}
