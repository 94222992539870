import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import LogoCollectionCategoryEntity from './entity';
import modelProperties from './modelProperties';

interface LogoCollectionCategoryAssociations extends AssociationConfig<any, any> {}

interface LogoCollectionCategory extends AutoGeneratedFunctions<LogoCollectionCategoryAssociations, LogoCollectionCategoryEntity, ComplexData<LogoCollectionCategoryEntity>> {}

// eslint-disable-next-line no-redeclare
class LogoCollectionCategory extends ComplexData<LogoCollectionCategoryEntity> {
    static Entity = LogoCollectionCategoryEntity;

    static modelProperties = modelProperties;
}

export default LogoCollectionCategory;
