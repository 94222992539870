export default {
    EVENT_TYPES: {
        CHANGE_EXPIRES_DATE: 1,
        REFUND_SUBSCRIPTION: 2,
        DELETE_SUBSCRIPTION: 3,
        ADD_MARKETING_SUBSCRIPTION: 4,
        POST: 5,
        PUT: 6,
        DELETE: 7,
        DELETE_USER_DEVICE: 8,
        ADD_COMMENT: 9,
        EDIT_USER_ADMIN_ACCESS: 10,
    },
};
