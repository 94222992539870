export default {

    SCREENS: {
        VIEW_CUSTOMER: {
            NAME: 'viewcustomer',
            TABS: {
                PURCHASE_ORDER: 'purchaseOrder',
                PROJECTS: 'projects',
                MESSAGES: 'messages',
                QUOTES: 'quotes',
                INVOICES: 'invoices',
                PAYMENTS: 'payments',
                APPOINTMENTS: 'appointments',
                DETAILS: {
                    NAME: 'details',
                    TABS: {
                        MAIN_CONTACT: 'mainContact',
                        INVOICE_CONTACT: 'invoiceContact',
                        REGISTERED_ADDRESS: 'registeredAddress',
                        SITES: 'sites',
                    },
                },
                FORMS: 'forms',
            },

        },

        EDIT_CUSTOMER: 'editcustomer',
        CREATE_JOB: 'createjob',
        EDIT_SITE: 'editsite',
        INVITATION_ACCEPT: 'invitationAccept',
        SEND: 'send',
        QUICK_APPOINTMENT: 'quickappointment',
        HOME: 'home',
        FORM_DOCUMENT_OVERVIEW: 'formDocumentOverview',
        PROJECT: 'project',
        DIARY: 'diary',
    },

    ROUTE_PARAMS: {
        UNDEFINED_JOB: -1,
        IS_WIZARD: 'true',
        NO_WIZARD: 'false',
        CUSTOMER_FILTER: {
            SUPPLIERS: 'suppliers',
            CUSTOMERS: 'customers',
        },
    },
};
