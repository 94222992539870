export default {
    PDF: 'application/pdf',
    DOC: 'application/msword',
    XLS: 'application/vnd.ms-excel',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    CSV: 'text/csv',
    TXT: 'text/plain',
    KEYNOTE: 'application/x-iwork-keynote-sffkey',
    PAGES: 'application/x-iwork-pages-sffpages',
    NUMBERS: 'application/x-iwork-numbers-sffnumbers',
};
