const modelProperties = {
    modelName: 'UserUserProfile',
    tableName: 'Useruserprofile',
    associations: [{
        type: 'belongsTo',
        model: 'UserProfile',
        parameters: { as: 'Userprofile', foreignKey: { name: 'userprofile_id', fieldName: 'userprofile_id' } },
    }],
};

export default modelProperties;
