import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import UserActionEntity from './entity';
import modelProperties from './modelProperties';
import Action from '../action';
import User from '../user';
import ActionEntity from '../action/entity';
import UserEntity from '../user/entity';

interface UserActionAssociations extends AssociationConfig<any, any> {
    action: AssociationDefinitionSingle<ActionEntity, Action>
    user: AssociationDefinitionSingle<UserEntity, User>
}

interface UserAction extends AutoGeneratedFunctions<UserActionAssociations, UserActionEntity, ComplexData<UserActionEntity>> {}

// eslint-disable-next-line no-redeclare
class UserAction extends ComplexData<UserActionEntity> {
    static Entity = UserActionEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): UserActionAssociations {
        return {
            action: {
                key: 'action',
                instance: Action,
                entity: ActionEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().action_id,
                },
            },
            user: {
                key: 'user',
                instance: User,
                entity: UserEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().user_id,
                },
            },
        };
    }
}

export default UserAction;
