import Entity, { EntityWithId, field } from '../entity';
    
export default class PaymentTypeToCountryEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'int' })
    public paymenttype_id: number;
    
    @field({ type: 'int' })
    public country_id: number;

    @field({ type: 'string', serverOnly: true, minAppVersion: '8.5.0' })
    public min_app_version: string;

    @field({ type: 'string', serverOnly: true, minAppVersion: '8.5.0' })
    public max_app_version: string;
}
