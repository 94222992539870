// eslint-disable-next-line max-classes-per-file
import WhiteLabelModel from '@powerednow/shared/modules/complexData/whiteLabel';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AutoGeneratedFunctions,
} from '../complexData';
import PurchaseSetupEntity from './entity';
import modelProperties from './modelProperties';
import ProductTierToSubscriptionEntity from '../productTierToSubscription/entity';
import ProductTierToSubscription from '../productTierToSubscription';
import { WHITE_LABEL_IDS } from '../../../constants/subscription';

export class NoAssociatedTierError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'NoAssociatedTierError';
    }
}

interface PurchaseSetupAssociations extends AssociationConfig<any, any> {
    productTierToSubscription: AssociationDefinition<ProductTierToSubscriptionEntity, ProductTierToSubscription>
}

interface PurchaseSetup extends AutoGeneratedFunctions<PurchaseSetupAssociations, PurchaseSetupEntity, ComplexData<PurchaseSetupEntity>> {}

// eslint-disable-next-line no-redeclare
class PurchaseSetup extends ComplexData<PurchaseSetupEntity> {
    static Entity = PurchaseSetupEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): PurchaseSetupAssociations {
        return {
            productTierToSubscription: {
                key: 'productTierToSubscription',
                instance: ProductTierToSubscription,
                entity: ProductTierToSubscriptionEntity,
                condition: {
                    purchase_setup_id: this.Entity.getFieldSymbols().id,
                },
            },
        };
    }

    /**
     * In case of Grahams productTierToSubForWhitelabelId will be undefined since there are
     * no mappings added in the ProductTierToSubscription table.  We have let this one go,
     * as Grahams is no longer an active white-label project. However, if a new white-label
     * project comes up, mappings have to be added to the ProductTierToSubscription table;
     * otherwise, the registration process will fail with a 'No productTierToSubscription
     * for purchaseSetup' error message.
     * @param productTierToSubscriptions
     * @param whiteLabelId
     */
    private static findProductTierToSubForWhitelabelId(productTierToSubscriptions, whiteLabelId) {
        return productTierToSubscriptions.find(productTierToSubscription => {
            const validatedWhiteLabelId = WhiteLabelModel.validateWhiteLabelId(whiteLabelId);
            return productTierToSubscription.data.whitelabel_id === validatedWhiteLabelId;
        });
    }

    async getTier(whiteLabelId: number = WHITE_LABEL_IDS.POWEREDNOW) {
        const productTierToSubscriptions = await this.getAllProductTierToSubscription();
        const productTierToSubForWhitelabelId = PurchaseSetup.findProductTierToSubForWhitelabelId(productTierToSubscriptions, whiteLabelId);
        if (!productTierToSubForWhitelabelId) {
            throw new NoAssociatedTierError(`No productTierToSubscription for purchaseSetup: ${this.data.id}`);
        }
        return productTierToSubForWhitelabelId.getProductTier();
    }

    isSupportMultiUserMode() {
        return Boolean(this.data.users > 1 || this.data.team_enabled);
    }
}

export default PurchaseSetup;
export type PurchaseSetupType = PurchaseSetup;
