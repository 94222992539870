const modelProperties = {
    modelName: 'PurchaseSetup',
    tableName: 'PurchaseSetup',
    global: true,

    associations: [{
        type: 'hasMany',
        model: 'ProductTierToSubscription',
        parameters: { foreignKey: { name: 'purchase_setup_id', fieldName: 'purchase_setup_id' } },
    }],
};

export default modelProperties;
