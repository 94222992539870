import { DateOrString, EntityWithId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class FormEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'int' })
    public version: number;

    @field({ type: 'int' })
    public form_type_id: number;

    @field({ type: 'string' })
    public title: string;

    @field({ type: 'string' })
    public logo_url: string;

    @field({ type: 'int' })
    public category_id: number;

    @field({ type: 'int' })
    public country_id: number;

    @field({ type: 'string' })
    public output_template: string;

    @field({ type: 'string' })
    public form_template: string;

    @field({ type: 'int' })
    public importance: number;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;

    @field({ type: 'string', serverOnly: true })
    public min_app_version: string;

    @field({ type: 'string', serverOnly: true })
    public max_app_version: string;

    @field({ codeName: 'officialTitle', type: 'string', minAppVersion: '7.9.2' })
    public officialTitle: string;

    @field({ codeName: 'description', type: 'string', minAppVersion: '7.9.2' })
    public description: string;
}
