import { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class JobStatsEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'int' })
    public job_id: number;

    @field({ type: 'decimal', codeName: 'quoted' })
    public quoted?: number;
    
    @field({ type: 'decimal', codeName: 'accepted' })
    public accepted?: number;
    
    @field({ type: 'decimal', codeName: 'invoiced' })
    public invoiced?: number;
    
    @field({ type: 'decimal', codeName: 'invoiced_exVat' })
    public invoiced_exVat?: number;

    @field({ type: 'decimal', codeName: 'supplier_invoiced' })
    public supplier_invoiced?: number;

    @field({ type: 'decimal', codeName: 'supplier_invoiced_exVat' })
    public supplier_invoiced_exVat?: number;

    @field({ type: 'decimal', codeName: 'invoice_payments' })
    public invoice_payments?: number;

    @field({ type: 'decimal', codeName: 'supplier_invoice_payments' })
    public supplier_invoice_payments?: number;

    @field({ type: 'decimal', codeName: 'projected_profit' })
    public projected_profit?: number;

    @field({ type: 'decimal', codeName: 'projected_profit_exVat' })
    public projected_profit_exVat?: number;

    @field({ type: 'decimal', codeName: 'outstanding_invoice_payments' })
    public outstanding_invoice_payments?: number;

    @field({ type: 'decimal', codeName: 'outstanding_supplier_invoice_payments' })
    public outstanding_supplier_invoice_payments?: number;

    @field({ type: 'int', codeName: 'totalTime' })
    public totalTime?: number;

    @field({
        codeName: 'lastActivity', minAppVersion: '8.5.2', type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils), serverOnly: false,
    })
    public lastActivity?: DateOrString;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted?: boolean;
}
