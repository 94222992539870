const modelProperties = {
    modelName: 'PurchaseAction',
    tableName: 'PurchaseAction',
    associations: [{
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'belongsTo',
        model: 'PurchaseSetup',
        parameters: { as: 'PurchaseSetup', foreignKey: { name: 'product', fieldName: 'product' }, targetKey: 'id' },
    }, {
        type: 'belongsTo',
        model: 'Purchases',
        parameters: { as: 'Purchases', foreignKey: { name: 'purchase_id', fieldName: 'purchase_id' } },
    }, {
        type: 'belongsTo',
        model: 'Purchases',
        parameters: {
            as: 'PreviousPurchase',
            foreignKey: { name: 'previous_purchase_id', fieldName: 'previous_purchase_id' },
            scope: { company_id: { $col: 'PurchaseAction.company_id' } },
        },
    }, {
        type: 'belongsTo',
        model: 'PurchaseAction',
        parameters: {
            as: 'PurchaseAction2',
            foreignKey: { name: 'company_id', fieldName: 'company_id' },
            targetKey: 'company_id',
            scope: { company_id: { $col: 'PurchaseAction.company_id' } },
        },
    }],
};

export default modelProperties;
