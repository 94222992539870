import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class DocumentLogoEntity extends EntityWithCompanyId {
    @field({
        type: 'int',
        primaryKey: true,
        hooks: {
            afterCreate: [{
                handler: 'tagHubspot',
                args: ['added_logo'],
            }],
        },
    })
    public id?: number;
    
    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;
    
    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;
    
    @field({ type: 'string' })
    public url: string;
    
    @field({ type: 'int' })
    public top: number;
    
    @field({ type: 'int' })
    public left: number;
    
    @field({ type: 'int' })
    public height: number;
    
    @field({ type: 'int' })
    public width: number;
    
    @field({ type: 'int', allowNull: true })
    public type?: number;
    
    @field({ type: 'int' })
    public source: number;
    
    @field({ defaultValue: 'not_uploaded', type: 'string' })
    public status: string;
    
    @field({
        type: 'boolean', codeName: 'isDeleted', serverOnly: true, defaultValue: false, 
    })
    public isdeleted?: boolean;
    
    @field({ minAppVersion: '7.5.0', type: 'string' })
    public document_type: string;

    @field({ minAppVersion: '9.0.0', type: 'boolean' })
    public isCompanyLogo: boolean;
}
