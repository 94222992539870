export declare type AddressObject = {
    address: Record<string, any>,
    plot?: string,
    country?: string,
};

export default class Formatter {
    private static format: object = {
        postcode: 0,
        state: 1,
        county: 2,
        city: 3,
        plot: 4,
        address1: 5,
        address2: 6,
        country: 7,
    };

    static stringifyAddress(
        {
            address = {},
            plot = '',
            country = '',
        }: AddressObject,
        format?: object, 
        delimiter: string = ', ',
    ): string {
        const { state, county, ...addressFields } = address;

        const data = {
            ...addressFields,
            ...(state === county ? { county } : { county, state }),
            plot,
            country,
        };

        const formatToApply = format || this.format;
        const sortedKeys = Object.keys(formatToApply)
            .sort((a, b) => formatToApply[a] - formatToApply[b]);
        return [
            ...sortedKeys.reduce((accumulator, key) => {
                if (data[key] && data[key] !== undefined && data[key].trim() !== 'null') {
                    accumulator.push(data[key]);
                }
                return accumulator;
            }, [])].join(delimiter);
    }
}
