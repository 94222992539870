import Entity, { EntityWithId, field } from '../entity';

export default class CisRateEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'int' })
    public country_id: number;

    @field({ type: 'string' })
    public name: string;

    @field({ type: 'decimal' })
    public rate: number;
}
