import { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class UserMediaEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;

    @field({ type: 'int', primaryKey: true })
    public user_id: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created: DateOrString;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;

    @field({ type: 'int', codeName: 'status' })
    public status: boolean;

    @field({ type: 'string', codeName: 'description' })
    public description: string;

    @field({ type: 'int', codeName: 'type' })
    public type: number;

    @field({ type: 'string', codeName: 'url' })
    public url: string;
}
