import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import Job from '../job';
import ContactToAddress from '../contactToAddress';
import Company from '../company';
import Customer from '../customer';
import User from '../user';
import Form from '../form';
import Message from '../message';
import DocumentLogo from '../documentLogo';
import Signature from '../signature';
import MediaGroup from '../mediaGroup';
import FormDocumentManager from '../../form/FormDocumentManager';
import FormDocumentEntity from './entity';
import modelProperties from './modelProperties';
import JobEntity from '../job/entity';
import ContactToAddressEntity from '../contactToAddress/entity';
import CustomerEntity from '../customer/entity';
import UserEntity from '../user/entity';
import FormEntity from '../form/entity';
import MessageEntity from '../message/entity';
import DocumentLogoEntity from '../documentLogo/entity';
import SignatureEntity from '../signature/entity';
import MediaGroupEntity from '../mediaGroup/entity';
import CustomerMessageEntity from '../customerMessage/entity';
import CustomerMessage from '../customerMessage';
import { CustomerMessageLinkedRecord } from '../interfaces';
import CompanyEntity from '../company/entity';

const constants = require('@powerednow/shared/constants').default;
const _ = require('lodash');

interface FormDocumentAssociations extends AssociationConfig<any, any> {
    job: AssociationDefinitionSingle<JobEntity, Job>
    company: AssociationDefinitionSingle<CompanyEntity, Company>
    customer: AssociationDefinitionSingle<CustomerEntity, Customer>
    user: AssociationDefinitionSingle<UserEntity, User>
    form: AssociationDefinitionSingle<FormEntity, Form>
    message: AssociationDefinition<MessageEntity, Message>
    documentLogo: AssociationDefinitionSingle<DocumentLogoEntity, DocumentLogo>
    signature: AssociationDefinitionSingle<SignatureEntity, Signature>
    mediaGroup: AssociationDefinitionSingle<MediaGroupEntity, MediaGroup>
    customerMessage: AssociationDefinition<CustomerMessageEntity, CustomerMessage>
    site: AssociationDefinitionSingle<ContactToAddressEntity, ContactToAddress>
}

interface FormDocument extends AutoGeneratedFunctions<FormDocumentAssociations, FormDocumentEntity, ComplexData<FormDocumentEntity>> {}

// eslint-disable-next-line no-redeclare
class FormDocument extends ComplexData<FormDocumentEntity> implements CustomerMessageLinkedRecord {
    static Entity = FormDocumentEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): FormDocumentAssociations {
        return {
            company: {
                instance: Company,
                entity: CompanyEntity,
                key: 'company',
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().company_id,
                },
            },
            job: {
                key: 'job',
                instance: Job,
                entity: JobEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().job_id,
                },
            },
            site: {
                key: 'contactToAddress',
                instance: ContactToAddress,
                entity: ContactToAddressEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().site_id,
                },
            },
            customer: {
                key: 'customer',
                instance: Customer,
                entity: CustomerEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().customer_id,
                },
            },
            user: {
                key: 'user',
                instance: User,
                entity: UserEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().user_id,
                },
            },
            form: {
                key: 'form',
                instance: Form,
                entity: FormEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().form_id,
                },
            },
            message: {
                key: 'message',
                instance: Message,
                entity: MessageEntity,
                cascadeDelete: true,
                condition: {
                    document_id: this.Entity.getFieldSymbols().id,
                },
            },
            documentLogo: {
                key: 'documentLogo',
                instance: DocumentLogo,
                entity: DocumentLogoEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    document_type: constants.LOGO.DOCUMENT_TYPE.FORM_DOCUMENT,
                },
            },
            signature: {
                key: 'signature',
                instance: Signature,
                entity: SignatureEntity,
                cascadeDelete: false,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().signature_id,
                },
            },
            mediaGroup: {
                key: 'mediaGroup',
                instance: MediaGroup,
                entity: MediaGroupEntity,
                cascadeDelete: false,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().mediagroup_id,
                },
            },
            customerMessage: {
                key: 'customerMessage',
                instance: CustomerMessage,
                entity: CustomerMessageEntity,
                cascadeDelete: true,
                condition: {
                    linked_id: this.Entity.getFieldSymbols().id,
                    linked_type: constants.MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.FORMDOCUMENT,
                },
            },
        };
    }

    async getFormDocumentManager(globalObjects) {
        const form = await this.getForm();
        return new FormDocumentManager(await form.getRelated(), await this.getTemplateData(globalObjects));
    }

    public async getDefaultSite() {
        const job = await this.getJob();
        return job.findSite();
    }

    async inheritFrom(otherFormDocument, globalObjects) {
        const formDocumentManager = await this.getFormDocumentManager(globalObjects);
        const otherFormDocumentManager = await otherFormDocument.getFormDocumentManager(globalObjects);

        formDocumentManager.copyFrom(otherFormDocumentManager);

        this.data.form_data = formDocumentManager.getData();
    }

    async getRelated(globalObjects) {
        const data = { ...this.data.getPureDataValues() };
        const formDocumentData = await this.getTemplateData(globalObjects);
        return { ...formDocumentData, ...data };
    }

    async generateGlobalObjects(): Promise<{
        currentCompany: Company,
        currentUser: User,
    }> {
        const company: Company = await this.getCompany();
        const user: User = await this.getUser();
        return {
            currentCompany: company,
            currentUser: user,
        };
    }

    async getTemplateData(globalObjects = {}) {
        if (this.data.status !== constants.FORMS.STATUSES.DRAFT) {
            return this.data.form_data;
        }

        return this.getDefaultTemplateData(globalObjects);
    }

    async getDefaultTemplateData(globalObjects) {
        return {
            form: this.data.form_data.form || {},
            formDocument: await this.getFormDocumentData(),
            company: await this.getCompanyData(globalObjects),
            customer: await this.getCustomerData(),
            user: await this.getUserData(globalObjects),
            companyForm: await this.getCompanyFormData(),
        };
    }

    async getCompanyFormData() {
        const form = await this.getForm();
        const companyForm = await form.getCompanyForm();
        const companyFormData = await companyForm.getData();

        return {
            companyId: companyFormData.company_id,
            emailConfig: companyFormData.email_config,
            numberingConfig: companyFormData.numbering_config,
            reminderConfig: companyFormData.reminder_config,
        };
    }

    async updateTemplateData(globalObjects) {
        if (this.data.form_data.user) {
            const userData = await this.getUserData(globalObjects);
            this.data.form_data.user = _.merge({}, this.data.form_data.user, userData);
        }
    }

    private async getFormDocumentData() {
        const form = await this.getForm();
        const formType = await form.getFormType();

        return {
            displayNumber: this.data.display_number,
            form: {
                id: form.data.id,
                description: form.data.description,
                title: form.data.title,
                name: formType.data.name,
                version: form.data.version,
            },
        };
    }

    private async getCompanyData(globalObjects) {
        const documentLogo = await this.getDocumentLogo();
        const company = globalObjects.currentCompany;
        const companyAddress = await company.getCompanyAddress();
        const companyData = _.cloneDeep(company.data.getPureDataValues());
        const companyAddressData = _.cloneDeep(companyAddress.data.getPureDataValues());
        const documentLogoData = documentLogo ? _.cloneDeep(documentLogo.data.getPureDataValues()) : null;
        return {
            email: companyData.email,
            form: companyData.form_data || {},
            name: companyData.name,
            phone: companyData.phone,
            logoUrl: (documentLogoData && documentLogoData.url) ? documentLogoData.url : null,
            address: {
                address1: companyAddressData.address1,
                address2: companyAddressData.address2,
                city: companyAddressData.city,
                state: companyAddressData.state,
                postcode: companyAddressData.postcode,
            },
        };
    }

    private async getUserData(globalObjects) {
        const user = globalObjects.currentUser;
        const userRelatedData = await user.getRelated();
        const formData = _.isEmpty(userRelatedData.form_data) || !userRelatedData.form_data ? {
            competentPersonsScheme: 'None',
        } : userRelatedData.form_data;
        return {
            form: formData,
            fullName: userRelatedData.fullName,
            signatureUrl: userRelatedData.signatureUrl,
            role: userRelatedData.role,
        };
    }

    private async getCustomerData() {
        const customer = await this.getCustomer();
        const customerRelatedData = await customer.getTemplateData();
        const job = await this.getJob();
        const site = await this.findSite();
        const address = await site.getAddress();
        const contact = await site.getContact();
        const addressTemplateData = await address.getTemplateData(this.data.plot);
        const contactTemplateData = await contact.getTemplateData();
        const phone = (await contact.getPhone())?.data.value;

        return {
            jobId: job.data.id,
            id: customerRelatedData.id,
            fullName: customerRelatedData.fullName,
            address: {
                address1: customerRelatedData.address[0].address1,
                address2: customerRelatedData.address[0].address2,
                city: customerRelatedData.address[0].city,
                state: customerRelatedData.address[0].state,
                postcode: customerRelatedData.address[0].postcode,
            },
            site: {
                id: contactTemplateData.id,
                fullName: contactTemplateData.fullName,
                address: {
                    plot: addressTemplateData.addressPlot,
                    address1: addressTemplateData.address1,
                    address2: addressTemplateData.address2,
                    city: addressTemplateData.city,
                    state: addressTemplateData.state,
                    postcode: addressTemplateData.postcode,
                },
                phone,
            },
        };
    }

    getStatus() {
        return this.data.status;
    }

    async findSite() {
        return (await this.getSite()) || (await this.getJob()).findSite();
    }

    composeDisplayNumber(documentNumber, numberingConfig) {
        const { prefix } = numberingConfig;
        const { suffix } = numberingConfig;
        const { incrementalLength } = numberingConfig;

        let number = new Array(Number(incrementalLength)).join('0') + documentNumber;
        number = number.slice(-1 * Math.max(incrementalLength, (String(documentNumber)).length));

        return prefix + number + suffix;
    }
}

export default FormDocument;
