import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import CountryFeatureEntity from './entity';
import modelProperties from './modelProperties';

interface CountryFeatureAssociations extends AssociationConfig<any, any> {}

interface CountryFeature extends AutoGeneratedFunctions<CountryFeatureAssociations, CountryFeatureEntity, ComplexData<CountryFeatureEntity>> {}

// eslint-disable-next-line no-redeclare
class CountryFeature extends ComplexData<CountryFeatureEntity> {
    static Entity = CountryFeatureEntity;

    static modelProperties = modelProperties;

    public validateForType(type: number) {
        return this.data.type === type;
    }
}

export default CountryFeature;
