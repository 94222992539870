import { EntityWithCompanyId, field } from '../entity';

export default class UserUserProfileEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id?: number;

    @field({
        type: 'int',
        codeName: 'userProfileId',
        hooks: {
            afterUpdate: [{
                handler: 'onAfterUpdateUserUserProfile',
                condition: { newValue: 1 },
            }],
        },
    })
    public userprofile_id: number;

    @field({ type: 'int' })
    public user_id: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'string' })
    public user_uuid?: string;
}
