import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import UsageHistoryEntity from './entity';
import modelProperties from './modelProperties';

interface UsageHistoryAssociations extends AssociationConfig<any, any> {}

interface UsageHistory extends AutoGeneratedFunctions<UsageHistoryAssociations, UsageHistoryEntity, ComplexData<UsageHistoryEntity>> {}

// eslint-disable-next-line no-redeclare
class UsageHistory extends ComplexData<UsageHistoryEntity> {
    static Entity = UsageHistoryEntity;

    static modelProperties = modelProperties;
}

export default UsageHistory;
