const modelProperties = {
    modelName: 'UserProfile',
    tableName: 'Userprofile',
    associations: [{
        type: 'hasMany',
        model: 'UserUserProfile',
        parameters: { foreignKey: { name: 'userprofile_id', fieldName: 'userprofile_id' } },
    }, {
        type: 'hasMany',
        model: 'UserProfileRole',
        parameters: {
            foreignKey: {
                name: 'userprofile_id',
                fieldName: 'userprofile_id',
            },
        },
    }, {
        type: 'hasMany',
        model: 'UserProfileRoleCustom',
        parameters: { foreignKey: { name: 'userprofile_id', fieldName: 'userprofile_id' } },
    }, {
        type: 'belongsToMany',
        model: 'User',
        parameters: {
            through: { model: 'UserUserProfile', unique: false },
            foreignKey: { name: 'userprofile_id', fieldName: 'userprofile_id' },
        },
    }],
    global: true,
};

export default modelProperties;
