import * as Bluebird from 'bluebird';

import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AutoGeneratedFunctions,
} from 'modules/complexData/complexData';
import CountryToCountryGroup from 'modules/complexData/countryToCountryGroup';
import Country from 'modules/complexData/country';
import CountryGroupEntity from './entity';
import modelProperties from './modelProperties';
import CountryToCountryGroupEntity from '../countryToCountryGroup/entity';

interface CountryGroupAssociations extends AssociationConfig<any, any> {
    countryToCountryGroup: AssociationDefinition<CountryToCountryGroupEntity, CountryToCountryGroup>
}

interface CountryGroup extends AutoGeneratedFunctions<CountryGroupAssociations, CountryGroupEntity, ComplexData<CountryGroupEntity>> {}

// eslint-disable-next-line no-redeclare
class CountryGroup extends ComplexData<CountryGroupEntity> {
    static Entity = CountryGroupEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): CountryGroupAssociations {
        return {
            countryToCountryGroup: {
                key: 'countryToCountryGroup',
                instance: CountryToCountryGroup,
                entity: CountryToCountryGroupEntity,
                cascadeDelete: true,
                condition: {
                    country_group_id: this.Entity.getFieldSymbols().id,
                },
            },
        };
    }

    public async findCountries(): Promise<Country[]> {
        const countryToCountryGroups = this.getAllCountryToCountryGroup();
        return Bluebird.map(countryToCountryGroups, async (countryToCountryGroup: CountryToCountryGroup): Promise<Country> => (countryToCountryGroup as any).getCountry());
    }

    public async findCountry(countryId: number): Promise<Country> {
        const countries = await this.findCountries();
        return countries.filter((country: Country): boolean => country.data.id === countryId)[0];
    }
}

export default CountryGroup;
