import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import CurrencyEntity from './entity';
import modelProperties from './modelProperties';

interface CurrencyAssociations extends AssociationConfig<any, any> {}

interface Currency extends AutoGeneratedFunctions<CurrencyAssociations, CurrencyEntity, ComplexData<CurrencyEntity>> {}

// eslint-disable-next-line no-redeclare
class Currency extends ComplexData<CurrencyEntity> {
    static Entity = CurrencyEntity;

    static modelProperties = modelProperties;
}

export default Currency;
