import { EntityWithCompanyId, field } from '../entity';

export default class UserToAddressEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;

    @field({ type: 'int' })
    public user_id: number;

    @field({ type: 'int' })
    public address_id: number;

    @field({ type: 'int', primaryKey: true, serverOnly: true })
    public company_id: number;

    @field({ type: 'int' })
    public type: number;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;
}
