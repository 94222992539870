import Company from 'modules/complexData/company';
import { memoizeRunningPromise } from '@powerednow/shared/decorators';

import UserUserProfile from 'modules/complexData/userUserProfile';
import UserToAddress from 'modules/complexData/userToAddress';
import UserMedia from 'modules/complexData/userMedia';
import UserSettings from 'modules/complexData/userSettings';
import Signature from 'modules/complexData/signature';
import CONSTANTS, { MESSAGES } from '@powerednow/shared/constants';
import { DAY_NAMES, getDayNamesBetweenDates, getUnavailableDatesBetweenDates } from '@powerednow/shared/modules/utilities/date';
import arrayUtils from '@powerednow/shared/modules/utilities/array';
import UserEntity from './entity';
import modelProperties from './modelProperties';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
    FieldChangeEventHandlers,
} from '../complexData';
import Address from '../address';
import SettingsKeys from '../settingsKeys';
import CompanyEntity from '../company/entity';
import UserUserProfileEntity from '../userUserProfile/entity';
import SignatureEntity from '../signature/entity';
import UserToAddressEntity from '../userToAddress/entity';
import UserMediaEntity from '../userMedia/entity';
import UserSettingsEntity from '../userSettings/entity';
import ConnectedData from '../connectedData';
import ContactMethod from '../contactMethod';

const Bluebird = require('bluebird');

const _ = require('lodash');

interface UserAssociations extends AssociationConfig<any, any> {
    company: AssociationDefinitionSingle<CompanyEntity, Company>
    userUserProfile: AssociationDefinition<UserUserProfileEntity, UserUserProfile>
    signature: AssociationDefinitionSingle<SignatureEntity, Signature>
    userToAddress: AssociationDefinition<UserToAddressEntity, UserToAddress>
    userMedia: AssociationDefinition<UserMediaEntity, UserMedia>
    userProfilePicture: AssociationDefinitionSingle<UserMediaEntity, UserMedia>
    userMediaQualifications: AssociationDefinition<UserMediaEntity, UserMedia>
    userSettings: AssociationDefinition<UserSettingsEntity, UserSettings>
}

interface User extends AutoGeneratedFunctions<UserAssociations, UserEntity, ComplexData<UserEntity>> {}

// eslint-disable-next-line no-redeclare
class User extends ComplexData<UserEntity> {
    static Entity = UserEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): UserAssociations {
        return {
            company: {
                instance: Company,
                entity: CompanyEntity,
                key: 'company',
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getFieldSymbols().company_id,
                },
            },
            userUserProfile: {
                key: 'useruserprofile',
                instance: UserUserProfile,
                entity: UserUserProfileEntity,
                cascadeDelete: true,
                condition: {
                    user_id: this.Entity.getFieldSymbols().id,
                },
            },
            signature: {
                key: 'signature',
                instance: Signature,
                entity: SignatureEntity,
                single: true,
                cascadeDelete: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().signature_id,
                },
            },
            userToAddress: {
                key: 'userToAddress',
                instance: UserToAddress,
                entity: UserToAddressEntity,
                cascadeDelete: true,
                condition: {
                    user_id: this.Entity.getFieldSymbols().id,
                },
            },
            userMedia: {
                key: 'userMedia',
                instance: UserMedia,
                entity: UserMediaEntity,
                cascadeDelete: true,
                condition: {
                    user_id: this.Entity.getFieldSymbols().id,
                },
            },
            userProfilePicture: {
                key: 'userMedia',
                instance: UserMedia,
                entity: UserMediaEntity,
                single: true,
                condition: {
                    user_id: this.Entity.getFieldSymbols().id,
                    type: CONSTANTS.USER.MEDIA_TYPES.PROFILE_PICTURE,
                },
            },
            userMediaQualifications: {
                key: 'userMedia',
                instance: UserMedia,
                entity: UserMediaEntity,
                condition: {
                    user_id: this.Entity.getFieldSymbols().id,
                    type: CONSTANTS.USER.MEDIA_TYPES.QUALIFICATIONS,
                },
            },
            userSettings: {
                key: 'userSettings',
                instance: UserSettings,
                entity: UserSettingsEntity,
                cascadeDelete: false,
            },
        };
    }

    protected get fieldChangeEventHandlers(): FieldChangeEventHandlers {
        return {
            email: () => {
                this.data.is_valid = false;
            },
        };
    }

    getAllContactMethod(): ContactMethod[] {
        const methods = [
            new ContactMethod({
                value: this.data.email,
                contactmethodtype_id: MESSAGES.TYPES.EMAIL,
            }),
        ];
        return methods;
    }

    public async getUserProfiles() {
        const userUserProfiles = await this.getAllUserUserProfile();
        const companyComplex = await this.getCompany();
        const roles = await companyComplex.findRoles();

        return Bluebird.map(userUserProfiles, async userUserProfile => {
            const userProfile = await userUserProfile.getUserProfile();
            const userProfileRoles = roles.filter(role => role.data.userprofile_id === userProfile.data.id);
            const roleIds = userProfileRoles.map(userProfileRole => userProfileRole.data.role_id);
            userProfile.roles = roleIds;
            return userProfile;
        });
    }

    async getExternallyUsableUserUserProfiles() {
        const userUserProfiles = await this.getAllUserUserProfile();
        return Bluebird.filter(userUserProfiles, async userUserProfile => {
            const userProfile = await userUserProfile.getUserProfile();
            return !userProfile?.dataValues.internal;
        });
    }

    async setProfile(userProfileId) {
        const userUserProfile = await this.getExternallyUsableUserUserProfiles();

        if (userUserProfile.length === 0) {
            return this.addUserUserProfile({
                user_id: this.data.id,
                userprofile_id: userProfileId,
            });
        }

        if (userUserProfile.length > 1) {
            throw new Error('This code does not support n:m relation yet!');
        }

        userUserProfile[0].data.userprofile_id = userProfileId;
        return userUserProfile[0];
    }

    public async isMainUser() {
        const userProfiles = await this.getUserProfiles();
        return userProfiles.some(userProfile => userProfile.data.id === CONSTANTS.USER_PROFILE.MAIN_USER);
    }

    public async hasRole(roleId: number): Promise<boolean> {
        const userUserProfiles = await this.getAllUserUserProfile();

        let result = false;

        await Bluebird.each(userUserProfiles, async userUserProfile => {
            const roles = await this.findAllUserRoles(roleId, userUserProfile.data.userprofile_id);
            if (roles && roles.length > 0) {
                result = true;
            }
        });

        return result;
    }

    /**
     * Get the user's full name (firstname + lastname)
     * @returns {string}
     */
    getFullName() {
        // You can use this to get the user's full name (firstname + lastname)

        let fullName = '';

        if (this.data.firstname) {
            fullName += this.data.firstname;
        }

        if (this.data.lastname) {
            if (this.data.firstname) {
                // add space between firstname and lastname
                fullName += ' ';
            }
            fullName += this.data.lastname;
        }

        return fullName;
    }

    async getJobTitle(): Promise<string> {
        return (await this.getSettingValue(CONSTANTS.SETTINGS_KEYS.SERVICE_PERSON)) as unknown as string;
    }

    async getPhoneNumber(): Promise<string> {
        return (await this.getSettingValue(CONSTANTS.SETTINGS_KEYS.PHONE_NUMBER)) as unknown as string;
    }

    async getCompanyPhoneNumber(): Promise<string> {
        const company = await this.getCompany();
        return company.getPhone();
    }

    public async getAssigneeDetails(): Promise<string> {
        const jobTitle = await this.getJobTitle();
        const jobTitleText = jobTitle ? `(${jobTitle})` : '';
        const fullName = this.getFullName();

        return `${fullName} ${jobTitleText}`.trim();
    }

    /**
     * Get user and associated data to be used in setData calls to display user info
     *
     * @returns {any}
     */
    async getRelated() {
        const data = _.cloneDeep(this.data.getPureDataValues());

        data.fullName = this.getFullName();
        //
        // Add role data
        //
        const userUserProfiles = await this.getAllUserUserProfile();
        const userProfiles = await Bluebird.map(userUserProfiles, userUserProfile => userUserProfile.getUserProfile());
        data.role = { ...userProfiles[0].data.getPureDataValues() };
        //
        // Add signature URL
        //
        const signature = await this.getSignature();
        if (signature) {
            data.signatureUrl = signature.data.url;
        }

        return data;
    }

    @memoizeRunningPromise
    async findAddress() {
        let [userToAddress] = await this.getAllUserToAddress();
        if (!userToAddress) {
            userToAddress = new UserToAddress({ user_id: this.data.id });
            await this.addUserToAddress(userToAddress);
            const address = new Address({});
            await userToAddress.setAddress(address);
        }
        return userToAddress.getAddress();
    }

    async setSettingValue(settingId: number, value: string | number | object): Promise<UserSettings> {
        let setting = await this.findUserSetting(settingId);
        if (!setting) {
            setting = await this.addUserSettings({
                setting_key_id: settingId,
                setting_value: value,
                user_id: this.data.id,
            });
        } else {
            setting.data.setting_value = value;
        }
        return setting;
    }

    async getSettingValue(settingId: number): Promise<string | number | object> {
        const setting = await this.findUserSetting(settingId);
        if (!setting) {
            const defaultValue = await SettingsKeys.getDefaultSettingValue(settingId, this);
            await this.setSettingValue(settingId, defaultValue);

            return defaultValue;
        }
        return setting.data.setting_value;
    }

    async getColorCode() {
        let color = await this.getSettingValue(CONSTANTS.SETTINGS_KEYS.USER_COLOR);
        if (!color) {
            color = await this.getLegacyColor();
        }
        return `${(color[0] === '#' ? '' : '#')}${color}`;
    }

    async getLegacyColor() {
        const legacyUserColors = CONSTANTS.USER.LEGACY_COLORS;
        const usersRequest = await this.loadGenericData(<typeof ConnectedData>(User as unknown));
        const users = usersRequest && usersRequest.responseData;
        let userIndex = users.findIndex(user => user.id === this.data.id);

        if (userIndex < 0) {
            userIndex = users.length - 1;
        }
        return legacyUserColors[userIndex % Object.keys(legacyUserColors).length];
    }

    private async findAllUserRoles(roleId, userProfileId) {
        const companyComplex = await this.getCompany();
        const roles = await companyComplex.findRoles();

        return roles.filter(role => (role.data.role_id === roleId && role.data.userprofile_id === userProfileId));
    }

    private async findUserSetting(settingId: number): Promise<UserSettings> {
        const settings = await this.getAllUserSettings();
        return settings.find((settingsItem: UserSettings): boolean => {
            const sameKey = settingsItem.data.setting_key_id === settingId;
            const sameUser = settingsItem.data.user_id === this.data.id;
            return sameKey && sameUser;
        });
    }

    private async getAvailabilityForAppointments() {
        const settingValue = await this.getSettingValue(CONSTANTS.SETTINGS_KEYS.AVAILABILITY_APPOINTMENTS) as string;
        try {
            return JSON.parse(settingValue);
        } catch (e) {
            return {};
        }
    }

    private async getAvailableDays() {
        const availabilityForAppointments = await this.getAvailabilityForAppointments();
        return DAY_NAMES.filter(dayName => availabilityForAppointments[dayName].enabled);
    }

    private async getUnavailableDays() {
        const availabilityForAppointments = await this.getAvailabilityForAppointments();
        return DAY_NAMES.filter(dayName => !availabilityForAppointments[dayName].enabled);
    }

    async getDataForUnavailabilityWarning(startDate, endDate) {
        return {
            fullName: this.getFullName(),
            unavailableDates: await this.getUnavailableDates(startDate, endDate),
        };
    }

    async isAvailable(startDate, endDate) {
        const availableDays = await this.getAvailableDays();
        const daysToBook = getDayNamesBetweenDates(startDate, endDate);

        return arrayUtils.areAllElementsIncluded(availableDays, daysToBook);
    }

    async getUnavailableDates(startDate, endDate) {
        const unavailableDays = await this.getUnavailableDays();
        return getUnavailableDatesBetweenDates(startDate, endDate, unavailableDays);
    }
}

export default User;
export type UserType = User;
