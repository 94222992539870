import Address from 'modules/complexData/address';
import User from 'modules/complexData/user';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import UserToAddressEntity from './entity';
import modelProperties from './modelProperties';
import AddressEntity from '../address/entity';
import UserEntity from '../user/entity';

interface UserToAddressAssociations extends AssociationConfig<any, any> {
    address: AssociationDefinitionSingle<AddressEntity, Address>
    user: AssociationDefinitionSingle<UserEntity, User>
}

interface UserToAddress extends AutoGeneratedFunctions<UserToAddressAssociations, UserToAddressEntity, ComplexData<UserToAddressEntity>> {}

// eslint-disable-next-line no-redeclare
class UserToAddress extends ComplexData<UserToAddressEntity> {
    static Entity = UserToAddressEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): UserToAddressAssociations {
        return {
            address: {
                key: 'address',
                instance: Address,
                entity: AddressEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().address_id,
                },
            },
            user: {
                key: 'user',
                instance: User,
                entity: UserEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().user_id,
                },
            },

        };
    }
}

export default UserToAddress;
