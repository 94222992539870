import Entity, { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class ArchiveBatchEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true, autoIncrement: true })
    public id?: number;
    
    @field({ type: 'int', serverOnly: true })
    public company_id: number;
    
    @field({ type: 'int' })
    public job_id: number;

    @field({ type: 'string', dbType: 'date' })
    public dt_start?: DateOrString;

    @field({ type: 'string', dbType: 'date' })
    public dt_end?: DateOrString;

    @field({ type: 'boolean', codeName: 'isDeleted', serverOnly: true })
    public isdeleted: boolean;
}
