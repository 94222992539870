// eslint-disable-next-line max-classes-per-file
const LOGICS = {
    or: '||',
    and: '&&',
};

const COMPARATORS = {
    '<': '<',
    '<=': '<=',
    '=': '===',
    '>=': '>=',
    '>': '>',
    '!=': '!==',
};

class SimpleExpression {
    constructor(element, filterObj) {
        this.element = element;
        this.property = filterObj.property;
        this.value = JSON.stringify(filterObj.value);
        this.comparator = filterObj.operator;
    }

    /**
     * Returns a javascript statement based on filterObj.
     * What we want is e.g. element["property"] == value
     *
     * @returns {string}
     */
    toString() {
        const comparator = COMPARATORS[this.comparator];
        return `${this.element}["${this.property}"] ${comparator} ${this.value}`;
    }
}

class LikeExpression extends SimpleExpression {
    constructor(element, filterObj) {
        super(element, filterObj);
        this.negated = this.comparator.indexOf('not') > -1;
    }

    /**
     * Returns a javascript statement based on filterObj.
     * What we want is e.g. element["property"].indexOf(value.toLowerCase()) > -1
     *
     * @returns {string}
     */
    toString() {
        const comparator = this.negated ? '===' : '>';
        // Because ESLint 120 column limit.
        const { element } = this;
        const { property } = this;
        const { value } = this;
        return `Boolean(${element}["${property}"] && ${element}["${property}"].toLowerCase().indexOf(${value}.toLowerCase()) ${comparator} -1)`;
    }
}

class InExpression extends SimpleExpression {
    constructor(element, filterObj) {
        super(element, filterObj);
        this.negated = this.comparator.indexOf('not') > -1;
    }

    /**
     * Returns a javascript statement based on filterObj.
     * What we want is e.g. value.includes(element["property"])
     *
     * @returns {string}
     */
    toString() {
        return `${this.negated ? '!' : ''}${this.value}.includes(${this.element}["${this.property}"])`;
    }
}

/**
 * Factory class which decides what class to use to convert an expression.
 *
 */

class FilterExpression {
    constructor(root, filterObj) {
        const operatorMap = {
            '=': SimpleExpression,
            '!=': SimpleExpression,
            '<': SimpleExpression,
            '<=': SimpleExpression,
            '>': SimpleExpression,
            '>=': SimpleExpression,
            like: LikeExpression,
            notlike: LikeExpression,
            in: InExpression,
            notin: InExpression,
        };

        if (operatorMap[filterObj.operator]) {
            // eslint-disable-next-line no-constructor-return
            return new operatorMap[filterObj.operator](root, filterObj);
        }
        throw new Error(`Invalid operator used: ${filterObj.operator}`, filterObj);
    }
}

/**
 * Converts any Ext.util.Filter compatible configuration plus nested filter configurations to a javascript condition.
 *
 * @param filters
 * @param root
 * @returns {string}
 */
function processFilterObject(filters, root) {
    if (!Array.isArray(filters) || filters.length === 0) {
        return 'true';
    }
    const conditionStatement = filters.map((filter, index, array) => {
        if (Array.isArray(filter)) {
            let logic = '';
            if (index !== array.length - 1) {
                logic = LOGICS.and;
            }
            return `(${processFilterObject(filter, root)}) ${logic}`;
        }
        const logic = filter.logic ? filter.logic.toLowerCase() : 'and';
        const expression = new FilterExpression(root, filter);
        let logicCondition = '';
        if (index !== array.length - 1) {
            logicCondition = LOGICS[logic];
        }
        return `${expression} ${logicCondition}`;
    }).join(' ');

    return `${conditionStatement}`;
}

function createSorterFn(sorters) {
    if (!Array.isArray(sorters) || sorters.length === 0) {
        return (a, b) => 1;
    }
    return (a, b) => {
        let result = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const sorter of sorters) {
            const direction = sorter.sort.toLowerCase() === 'asc' ? 1 : -1;
            if (a[sorter.field] > b[sorter.field]) {
                result = 1 * direction;
                break;
            }
            if (a[sorter.field] < b[sorter.field]) {
                result = -1 * direction;
                break;
            }
        }
        return result;
    };
}

const filterCache = new Map();

module.exports = {
    /* eslint-disable no-new-func */
    createFilterFn: filters => {
        const cacheKey = JSON.stringify(filters);
        if (!filterCache.has(cacheKey)) {
            filterCache.set(cacheKey, new Function('element', `return ${processFilterObject(filters, 'element')}`));
        }
        return filterCache.get(cacheKey);
    },
    createSorterFn,
    FilterExpression,
    SimpleExpression,
    LikeExpression,
    InExpression,
};
