import CountryFeature from 'modules/complexData/countryFeature';
import UserProfileRole from 'modules/complexData/userProfileRole';
import {
    ACTION, COUNTRY, COUNTRY_FEATURE, LOGO, PARTNER_LOOKUP, SETTINGS_KEYS, CONSUMPTION_EXTRA,
} from '@powerednow/shared/constants';
import { TYPES_Type as PAYMENT_TYPES_Type } from '@powerednow/shared/constants/payment';
import UserProfileRoleCustom from 'modules/complexData/userProfileRoleCustom';
import USER_PROFILE_ROLE_CUSTOM from '@powerednow/shared/constants/userProfileRoleCustom';
import arrayUtils from 'modules/utilities/array';
import PaymentType from 'modules/complexData/paymentType';
import * as dateUtils from '@powerednow/shared/modules/utilities/date';
import { WHITE_LABEL_EXPIRED_MAP, WHITE_LABEL_TRIAL_MAP } from '@powerednow/shared/constants/subscription';
import Country from '../country';
import CompanyAddress from '../companyAddress';
import AppointmentType from '../appointmentType';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import Currency from '../currency';
import CompanyEntity, { NOTIFICATION } from './entity';
import modelProperties from './modelProperties';
import Syncable from '../_mixin/syncable';
import Purchases from '../purchases';
import BookingDateException from '../bookingDateException';
import BookingDateExceptionEntity from '../bookingDateException/entity';
import DocumentLogo from '../documentLogo';
import CompanySettings from '../companySettings';
import SettingsKeys from '../settingsKeys';
import PaymentSetting from '../paymentSetting';
import BookingSlotManager, { BookingAvailability } from '../../bookingSlotManager';
import Action from '../action';
import User from '../user';
import { EntityWithId } from '../entity';
import AppointmentTypeEntity from '../appointmentType/entity';
import ActionEntity from '../action/entity';
import UserEntity from '../user/entity';
import PaymentSettingEntity from '../paymentSetting/entity';
import CompanySettingsEntity from '../companySettings/entity';
import DocumentLogoEntity from '../documentLogo/entity';
import CurrencyEntity from '../currency/entity';
import UserProfileRoleCustomEntity from '../userProfileRoleCustom/entity';
import PurchasesEntity from '../purchases/entity';
import CompanyAddressEntity from '../companyAddress/entity';
import { complexDataMix } from '../_decorator';
import { ChannelTypes } from '../../../constants/customerEmailTemplateValues';
import converterUtils from '../../utilities/converter';
import CustomerEntity from '../customer/entity';
import Customer from '../customer';
import Job from '../job';
import JobEntity from '../job/entity';
import FeatureEntity from '../feature/entity';
import Feature from '../feature';
import ConsumptionExtra from '../consumptionExtra';
import ConsumptionExtraEntity from '../consumptionExtra/entity';
import SubscriptionInfo from './subscriptionInfo';
import ConsumptionStats from '../consumptionStats';
import ConsumptionStatsEntity from '../consumptionStats/entity';
import DocumentEntity from '../document/entity';
import Document from '../document';
import WhiteLabelEntity from '../whiteLabel/entity';
import WhiteLabel from '../whiteLabel';

interface CompanyAssociations extends AssociationConfig<any, any> {
    appointmentType: AssociationDefinition<AppointmentTypeEntity, AppointmentType>
    editableAppointmentType: AssociationDefinition<AppointmentTypeEntity, AppointmentType>
    externalEditableAppointmentType: AssociationDefinition<AppointmentTypeEntity, AppointmentType>
    internalEditableAppointmentType: AssociationDefinition<AppointmentTypeEntity, AppointmentType>
    bookingDateException: AssociationDefinition<BookingDateExceptionEntity, BookingDateException>
    appointment: AssociationDefinition<ActionEntity, Action>
    holiday: AssociationDefinition<ActionEntity, Action>
    purchases: AssociationDefinition<PurchasesEntity, Purchases>
    companyAddress: AssociationDefinitionSingle<CompanyAddressEntity, CompanyAddress>
    userProfileRoleCustom: AssociationDefinition<UserProfileRoleCustomEntity, UserProfileRoleCustom>
    currency: AssociationDefinitionSingle<CurrencyEntity, Currency>
    documentLogo: AssociationDefinition<DocumentLogoEntity, DocumentLogo>
    companyLogo: AssociationDefinitionSingle<DocumentLogoEntity, DocumentLogo>
    formLogo: AssociationDefinitionSingle<DocumentLogoEntity, DocumentLogo>
    companySettings: AssociationDefinition<CompanySettingsEntity, CompanySettings>
    paymentSetting: AssociationDefinition<PaymentSettingEntity, PaymentSetting>
    mainUser: AssociationDefinitionSingle<UserEntity, User>
    user: AssociationDefinition<UserEntity, User>
    customer: AssociationDefinition<CustomerEntity, Customer>
    job: AssociationDefinition<JobEntity, Job>
    document: AssociationDefinition<DocumentEntity, Document>
    feature: AssociationDefinitionSingle<FeatureEntity, Feature>
    consumptionExtra: AssociationDefinition<ConsumptionExtraEntity, ConsumptionExtra>
    consumptionStats: AssociationDefinition<ConsumptionStatsEntity, ConsumptionStats>
    extraUser: AssociationDefinition<ConsumptionExtraEntity, ConsumptionExtra>
    extraDocument: AssociationDefinition<ConsumptionExtraEntity, ConsumptionExtra>
    whiteLabel: AssociationDefinitionSingle<WhiteLabelEntity, WhiteLabel>

}

interface Company extends Syncable, AutoGeneratedFunctions<CompanyAssociations, CompanyEntity, ComplexData<CompanyEntity>> {}

@complexDataMix(Syncable)
// eslint-disable-next-line no-redeclare
class Company extends ComplexData<CompanyEntity> {
    static Entity = CompanyEntity;

    static NOTIFICATION = NOTIFICATION;

    static modelProperties = modelProperties;

    private subscriptionInfo: SubscriptionInfo = null;

    public static get allowedAssociations(): CompanyAssociations {
        return {
            appointmentType: {
                key: 'appointmentType',
                instance: AppointmentType,
                entity: AppointmentTypeEntity,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            editableAppointmentType: {
                key: 'appointmentType',
                instance: AppointmentType,
                entity: AppointmentTypeEntity,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                    editable: true,
                },
            },
            externalEditableAppointmentType: {
                key: 'appointmentType',
                instance: AppointmentType,
                entity: AppointmentTypeEntity,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                    editable: true,
                    isExternal: true,
                },
            },
            internalEditableAppointmentType: {
                key: 'appointmentType',
                instance: AppointmentType,
                entity: AppointmentTypeEntity,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                    editable: true,
                    isInternal: true,
                },
            },
            bookingDateException: {
                key: 'bookingDateException',
                instance: BookingDateException,
                entity: BookingDateExceptionEntity,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            appointment: {
                key: 'action',
                instance: Action,
                entity: ActionEntity,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                    type: ACTION.ACTION_TYPES.ACTION_TYPE_APPOINTMENT,
                },
            },
            holiday: {
                key: 'action',
                instance: Action,
                entity: ActionEntity,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                    type: ACTION.ACTION_TYPES.ACTION_TYPE_HOLIDAY_SICKNESS_OR_OTHER,
                },
            },
            purchases: {
                key: 'purchases',
                instance: Purchases,
                entity: PurchasesEntity,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            companyAddress: {
                key: 'companyAddress',
                instance: CompanyAddress,
                entity: CompanyAddressEntity,
                single: true,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                    is_registered: true,
                },
            },
            userProfileRoleCustom: {
                key: 'userProfileRoleCustom',
                instance: UserProfileRoleCustom,
                entity: UserProfileRoleCustomEntity,
                cascadeDelete: false,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            currency: {
                key: 'currency',
                instance: Currency,
                entity: CurrencyEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().currency_id,
                },
            },
            documentLogo: {
                key: 'documentLogo',
                instance: DocumentLogo,
                entity: DocumentLogoEntity,
                cascadeDelete: false,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                    document_type: LOGO.DOCUMENT_TYPE.DOCUMENT,
                },
            },
            companyLogo: {
                key: 'documentLogo',
                instance: DocumentLogo,
                entity: DocumentLogoEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                    document_type: LOGO.DOCUMENT_TYPE.CUSTOMER_PORTAL_LOGO,
                    isCompanyLogo: true,
                },
            },
            formLogo: {
                key: 'documentLogo',
                instance: DocumentLogo,
                entity: DocumentLogoEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                    document_type: LOGO.DOCUMENT_TYPE.FORM_DOCUMENT,
                },
            },
            companySettings: {
                key: 'companySettings',
                instance: CompanySettings,
                entity: CompanySettingsEntity,
                cascadeDelete: false,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            paymentSetting: {
                key: 'paymentSetting',
                instance: PaymentSetting,
                entity: PaymentSettingEntity,
                cascadeDelete: false,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            mainUser: {
                key: 'user',
                instance: User,
                entity: UserEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                    is_main: true,
                },
            },
            user: {
                key: 'user',
                instance: User,
                entity: UserEntity,
                cascadeDelete: false,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            customer: {
                key: 'customer',
                instance: Customer,
                entity: CustomerEntity,
                cascadeDelete: false,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            job: {
                key: 'job',
                instance: Job,
                entity: JobEntity,
                cascadeDelete: false,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            document: {
                key: 'document',
                instance: Document,
                entity: DocumentEntity,
                cascadeDelete: false,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            feature: {
                key: 'feature',
                instance: Feature,
                entity: FeatureEntity,
                single: true,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            consumptionExtra: {
                key: 'consumptionExtra',
                instance: ConsumptionExtra,
                entity: ConsumptionExtraEntity,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            consumptionStats: {
                key: 'consumptionStats',
                instance: ConsumptionStats,
                entity: ConsumptionStatsEntity,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                },
            },
            extraUser: {
                key: 'consumptionExtra',
                instance: ConsumptionExtra,
                entity: ConsumptionExtraEntity,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                    type: CONSUMPTION_EXTRA.TYPES.user,
                },
            },
            extraDocument: {
                key: 'consumptionExtra',
                instance: ConsumptionExtra,
                entity: ConsumptionExtraEntity,
                condition: {
                    company_id: this.Entity.getFieldSymbols().id,
                    type: CONSUMPTION_EXTRA.TYPES.document,
                },
            },
            whiteLabel: {
                single: true,
                key: 'whiteLabel',
                instance: WhiteLabel,
                entity: WhiteLabelEntity,
                condition: {
                    id: this.Entity.getFieldSymbols().whitelabel_id,
                },
            },
        };
    }

    public async hasEverSubscribed() {
        return (await this.getAllPurchases()).length > 0;
    }

    async hasBookableAppointmentSlots(): Promise<boolean> {
        const appointmentScheduleSettings = await this.getJSONCompanySetting(SETTINGS_KEYS.APPOINTMENTS_DAILY_SCHEDULE);
        return (appointmentScheduleSettings as any)?.allowOnlineBooking;
    }

    async getBookableAppointmentSlotsByAppointment(appointmentTypeId: number, timeZoneShift: number = 0): Promise<{
        firstBookableDay: Date
        lastBookableDay: Date
        availableSlots: BookingAvailability[]
    }> {
        const appointmentScheduleSettings = await this.getJSONCompanySetting(SETTINGS_KEYS.APPOINTMENTS_DAILY_SCHEDULE);
        const {
            firstBookableDay,
            lastBookableDay,
        } = BookingSlotManager.initBookableInterval(appointmentScheduleSettings);

        firstBookableDay.setHours(-timeZoneShift);
        lastBookableDay.setHours(-timeZoneShift);

        const allBookingExceptions = await this.getAllBookingDateException();
        const allAppointments = await this.getAllAppointment({
            filters: [{
                property: 'dt_start',
                operator: '<',
                value: lastBookableDay as unknown as string,
            }, {
                property: 'dt_end',
                operator: '>',
                value: firstBookableDay as unknown as string,
            }],
        });
        const allHolidays = await this.getAllHoliday();
        const slotManager = new BookingSlotManager({
            scheduleSettings: appointmentScheduleSettings,
            bookingExceptions: allBookingExceptions,
            appointments: allAppointments,
            holidays: allHolidays,
            timeZoneShift,
        });

        const appointmentType = await this.getAppointmentTypeById(appointmentTypeId);
        const availableSlots = slotManager.getBookableDays(appointmentType);

        return {
            availableSlots,
            firstBookableDay: slotManager.getFirstBookableDay(availableSlots),
            lastBookableDay: slotManager.getLastBookableDay(availableSlots),
        };
    }

    async isLookupCountryFeatureAvailable(lookupTypeCode: number): Promise<boolean> {
        const allowedLookupTypes = (await this.getAllowedLookupCountryFeaturesByCode([
            lookupTypeCode,
        ]));
        return Object.keys(allowedLookupTypes).length === 1;
    }

    async isCountryFeatureAvailable(type: number): Promise<boolean> {
        const allowedCountryFeatures = await this.getCountryFeaturesForType(type);
        return (allowedCountryFeatures || []).length > 0;
    }

    async getCountryFeaturesForType(type: number): Promise<CountryFeature[]> {
        const country = await this.findCountry();
        return country.getFeaturesForType(type);
    }

    async getAllowedLookupCountryFeaturesByCode(lookupTypeCodes: number[]) {
        return (await this.getCountryFeaturesForType(COUNTRY_FEATURE.TYPE.COMPANY_LOOKUP))
            .reduce((allowedFeatures, countryFeature) => {
                const typeDefinition = PARTNER_LOOKUP.TYPES[countryFeature.data.params];
                if (typeDefinition && lookupTypeCodes.includes(typeDefinition.CODE)) {
                    return {
                        ...allowedFeatures,
                        [typeDefinition.CODE]: countryFeature,
                    };
                }
                return allowedFeatures;
            }, {});
    }

    async getWhiteLabelId() {
        const whiteLabel = await this.getWhiteLabel();
        return Number(whiteLabel?.data?.fallback_whitelabel_id || whiteLabel?.data?.id) || null;
    }

    async getSubscriptionInfo(trialId?, expiredId?, whitelabelId?): Promise<SubscriptionInfo> {
        const finalWhitelabelId = whitelabelId || await this.getWhiteLabelId();
        const finalTrialId = trialId || WHITE_LABEL_TRIAL_MAP[finalWhitelabelId];
        const finalExpiredId = expiredId || WHITE_LABEL_EXPIRED_MAP[finalWhitelabelId];

        if (!this.subscriptionInfo) {
            this.subscriptionInfo = new SubscriptionInfo(this);
            if (finalTrialId) {
                this.subscriptionInfo.setTrialId(finalTrialId);
            }
            if (finalExpiredId) {
                this.subscriptionInfo.setExpiredId(finalExpiredId);
            }
            if (finalWhitelabelId) {
                this.subscriptionInfo.setWhiteLabelId(finalWhitelabelId);
            }
            await this.subscriptionInfo.init();
        }
        return this.subscriptionInfo;
    }

    public invalidateSubscriptionInfo(): void {
        this.subscriptionInfo = null;
    }

    async getSerializableSubscriptionInfo() {
        const original = await this.getSubscriptionInfo();
        const { company, ...serializable } = original;
        return serializable;
    }

    async isCISAvailable() {
        const country = await this.findCountry();
        return country.data.is_cis;
    }

    getPhone() {
        return this.dataValues?.phone || null;
    }

    async canUseCIS() {
        return this.data.cis_enabled && this.isCISAvailable();
    }

    async getLastPurchased(): Promise<Purchases[]> {
        return (await this.getAllPurchases())
            .sort((a, b) => (a.data.purchase_date <= b.data.purchase_date ? 1 : -1));
    }

    public static async getCompanyCountryId(complexObject: ComplexData<any>): Promise<number> {
        const response = await (complexObject as any).loadGenericData(CompanyAddress, { is_registered: true });
        const registeredCompanyAddress = response && response.responseData[0];
        return registeredCompanyAddress ? registeredCompanyAddress.country_id : COUNTRY.IDS.UNITED_KINGDOM;
    }

    public static async getCompanyCountry(complexObject: ComplexData<any>) {
        const companyCountryId = await Company.getCompanyCountryId(complexObject);
        const response = await (complexObject as any).loadGenericData(Country, { id: companyCountryId });
        const companyCountry = response && response.responseData[0];
        return companyCountry;
    }

    public async requiresCheckForMandatoryProductCategory(): Promise<boolean> {
        const companyCountry = await this.findCountry();
        return companyCountry.isUkOrIOM();
    }

    public async findRoles(): Promise<Record<string, any>[]> {
        const roles = await this.findDefaultRoles();
        return this.mergeCustomRoles(roles);
    }

    private async findDefaultRoles(): Promise<object[]> {
        const roles = [];
        const userProfileRoles = await this.findUserProfileRoles(this);
        userProfileRoles.forEach(userProfileRole => {
            roles.push(this.generateUserRole(userProfileRole.data.role_id, userProfileRole.data.userprofile_id));
        });
        return roles;
    }

    public async findUserProfileRoles(complexObject: ComplexData<CompanyEntity>) {
        const response = await (complexObject as any).loadGenericData(UserProfileRole);
        const userProfileRoleData = response && response.responseData;
        return userProfileRoleData.map(data => new UserProfileRole(data));
    }

    private generateUserRole(roleId, userProfileId) {
        return new UserProfileRole({
            role_id: roleId,
            userprofile_id: userProfileId,
        });
    }

    private async mergeCustomRoles(roles): Promise<object[]> {
        let newRoles = roles;
        const userProfileRoleCustoms = await this.getAllUserProfileRoleCustom();
        userProfileRoleCustoms.forEach(userProfileRoleCustom => {
            const role = this.generateUserRole(userProfileRoleCustom.data.role_id, userProfileRoleCustom.data.userprofile_id);
            if (userProfileRoleCustom.data.mode === USER_PROFILE_ROLE_CUSTOM.MODE.ADD) {
                newRoles = arrayUtils.deepUniquePush(newRoles, role);
            } else if (userProfileRoleCustom.data.mode === USER_PROFILE_ROLE_CUSTOM.MODE.REMOVE) {
                newRoles = arrayUtils.deepRemove(newRoles, role);
            }
        });
        return newRoles;
    }

    public async findCountryId(): Promise<number> {
        const country = await this.findCountry();
        return country.data.id;
    }

    public async findCountry(): Promise<Country> {
        const companyAddress = await this.getCompanyAddress();
        return companyAddress.getCountry();
    }

    public async getPaymentTypes(): Promise<PaymentType[]> {
        const country = await this.findCountry();
        return country.getAvailablePayments();
    }

    public async getCompanySettingOrNull(settingId: number) {
        const settingValue = await this.findCompanySetting(settingId);
        return settingValue?.data?.setting_value || null;
    }

    private async findCompanySetting(settingId: number): Promise<CompanySettings> {
        const allSettings = await this.getAllCompanySettings();
        return allSettings.find((settings: CompanySettings): boolean => settings.data.setting_key_id === settingId);
    }

    async getEveningHoursSettings(): Promise<object> {
        return this.getJSONCompanySetting(SETTINGS_KEYS.EVENING_HOURS);
    }

    async getLunchTimeSettings(): Promise<object> {
        return this.getJSONCompanySetting(SETTINGS_KEYS.LUNCH_TIME);
    }

    async getDefaultDurationLengthSettings(): Promise<number> {
        const defaultAppointmentDuration = Number(await this.getSettingValue(SETTINGS_KEYS.DEFAULT_APPOINTMENT_LENGTH));
        const defaultAppointmentDurationId = Number(await this.getSettingValue(SETTINGS_KEYS.DEFAULT_APPOINTMENT_DURATION_ID));
        const defaultDurations = ACTION.CUSTOM_APPOINTMENT_TYPE.DEFAULT_DURATION;
        // We must find the option based on id and value
        let selectedDefaultDuration = defaultDurations.find(defaultDuration => defaultDuration.duration_id === defaultAppointmentDurationId
            && defaultDuration.value === defaultAppointmentDuration);
        if (!selectedDefaultDuration) {
            selectedDefaultDuration = defaultDurations.find(defaultDuration => defaultDuration.value === defaultAppointmentDuration) || defaultDurations[0];
        }
        return selectedDefaultDuration.id;
    }

    async setDefaultDurationLengthSettings(durationLengthId): Promise<{defaultAppointmentDuration: CompanySettings, defaultAppointmentDurationId: CompanySettings }> {
        const defaultDurations = ACTION.CUSTOM_APPOINTMENT_TYPE.DEFAULT_DURATION;
        let selectedDefaultDuration = defaultDurations.find(defaultDuration => defaultDuration.id === durationLengthId);
        if (!selectedDefaultDuration) {
            [selectedDefaultDuration] = defaultDurations;
        }
        return {
            defaultAppointmentDuration: await this.setSettingValue(SETTINGS_KEYS.DEFAULT_APPOINTMENT_LENGTH, selectedDefaultDuration.value),
            defaultAppointmentDurationId: await this.setSettingValue(SETTINGS_KEYS.DEFAULT_APPOINTMENT_DURATION_ID, selectedDefaultDuration.duration_id),
        };
    }

    private async getJSONCompanySetting(settingId: number): Promise<object> {
        const setting = await this.getSettingValue(settingId);
        try {
            return JSON.parse((setting as string));
        } catch (e) {
            return {};
        }
    }

    public async getBooleanCompanySetting(settingId: number, defaultValue: boolean = true): Promise<boolean> {
        const setting = await this.getSettingValue(settingId);
        try {
            return Boolean(JSON.parse((setting as string)));
        } catch (e) {
            return defaultValue;
        }
    }

    async getSettingValue(settingId: number): Promise<string | number | object> {
        const setting = await this.findCompanySetting(settingId);
        if (!setting) {
            const defaultValue = await SettingsKeys.getDefaultSettingValue(settingId, this as unknown as ComplexData<EntityWithId>);
            await this.setSettingValue(settingId, defaultValue);

            return defaultValue;
        }
        return setting.data.setting_value;
    }

    async setSettingValue(settingId: number, value: string | number | object): Promise<CompanySettings> {
        let setting = await this.findCompanySetting(settingId);
        if (!setting) {
            setting = await this.addCompanySettings({
                setting_key_id: settingId,
                setting_value: value,
            });
        } else {
            setting.data.setting_value = value;
        }
        return setting;
    }

    showRemindersInDiary(): boolean {
        const { show_reminders_in_diary: showRemindersInDiary } = this.data.companySettings;
        const emptyValues = [undefined, null];
        if (emptyValues.includes(showRemindersInDiary)) {
            return true;
        }
        return showRemindersInDiary;
    }

    async getNotificationChannels(): Promise<ChannelTypes[]> {
        const useSms = converterUtils.stringToBoolean(await this.getSettingValue(SETTINGS_KEYS.CUSTOMER_APPOINTMENT_NOTIFICATION_BYTEXT));
        const useEmail = converterUtils.stringToBoolean(await this.getSettingValue(SETTINGS_KEYS.CUSTOMER_APPOINTMENT_NOTIFICATION_BYEMAIL));
        const useWhatsapp = converterUtils.stringToBoolean(await this.getSettingValue(SETTINGS_KEYS.CUSTOMER_APPOINTMENT_NOTIFICATION_BY_WHATSAPP));
        return [
            ...(useSms ? ['SMS'] : []),
            ...(useEmail ? ['EMAIL'] : []),
            ...(useWhatsapp ? ['WHATSAPP'] : []),
        ] as ChannelTypes[];
    }

    setNotifyData(data: object): void {
        const fields = [
            NOTIFICATION.NOTIFY_ON_QUOTE,
            NOTIFICATION.NOTIFY_ON_PAYMENT,
            NOTIFICATION.NOTIFY_ON_TASK,
            NOTIFICATION.NOTIFY_ON_READS,
            NOTIFICATION.NOTIFY_ON_EMAIL,
            NOTIFICATION.NOTIFY_ON_CHAT,
            NOTIFICATION.NOTIFY_MAIN_EMAILS,
            NOTIFICATION.INVITE_CUSTOMER_ON_APPOINTMENT_SYNC,
            NOTIFICATION.INVITE_ALLOCATED_USER_ON_APPOINTMENT_SYNC,
            NOTIFICATION.NOTIFY_ON_CUSTOMER_APPOINTMENT,
            NOTIFICATION.NOTIFY_ON_USER_LOGIN,
        ];
        const newNotificationSettings = fields.reduce((settings, field) => {
            if (typeof (data[field]) !== 'undefined') {
                return {
                    ...settings,
                    [field]: data[field],
                };
            }
            return settings;
        }, {});
        this.data.notify_settings = {
            ...this.data.notify_settings,
            ...newNotificationSettings,
        };
    }

    public async getPaymentSettingByPaymentTypeId(paymentTypeId: PAYMENT_TYPES_Type): Promise<PaymentSetting> {
        const paymentSettings: PaymentSetting[] = await this.getAllPaymentSetting();
        return paymentSettings.find(paymentSetting => paymentSetting.data.payment_id === paymentTypeId);
    }

    public workdayHalfTime() {
        const QUARTER_TIME = 15;

        const start = dateUtils.getTimeParts(this.dataValues.workday_start);
        const end = dateUtils.getTimeParts(this.dataValues.workday_end);

        const differenceInMinutes = end.fullMinutes - start.fullMinutes;

        const finalDate = new Date();
        finalDate.setHours(start.hour);
        finalDate.setMinutes(start.minutes + Math.round(differenceInMinutes / 2));

        const finalMinutes = finalDate.getMinutes();

        const quarterMinutes = Math.round(finalMinutes / QUARTER_TIME) * QUARTER_TIME;
        finalDate.setMinutes(quarterMinutes);

        return `${finalDate.getHours()}:${finalDate.getMinutes()}`;
    }

    public async getAppointmentTypeRelatedSettings() {
        const vatRegistered = this.data?.vat_registered;
        const displayPrice = (await this.getSettingValue(SETTINGS_KEYS.BOOK_APPOINTMENT_DISPLAY_PRICE)) === 'true';
        const country = await this.findCountry();
        const isUK = country?.data?.id === COUNTRY.IDS.UNITED_KINGDOM;

        let promptId = '';
        let promptValue = '';
        if (vatRegistered) {
            const taxInclusive = this.data?.tax_inclusive;

            if (taxInclusive) {
                if (isUK) {
                    promptId = 'Settings.IncludingVatShort';
                    promptValue = 'inc. VAT';
                } else {
                    promptId = 'Settings.IncludingTaxShort';
                    promptValue = 'inc. Tax';
                }
            } else if (isUK) {
                promptId = 'Settings.ExcludingVatShort';
                promptValue = 'exc. VAT';
            } else {
                promptId = 'Settings.ExcludingTaxShort';
                promptValue = 'exc. Tax';
            }
        }

        const promptValues = {
            id: promptId, value: promptValue,
        };

        return {
            vatRegistered, displayPrice, isUK, promptValues,
        };
    }

    public async getLocaleInfo() {
        const companyAddress = await this.getCompanyAddress();
        const { city } = companyAddress.data;
        const country = await companyAddress.getCountry();
        return { city, countryCode: country.data.code };
    }
}

export default Company;
export type CompanyType = Company;
