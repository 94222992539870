import Entity, { EntityWithId, field } from '../entity';
    
export default class LogoCollectionEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'int' })
    public category: number;
    
    @field({ type: 'string' })
    public name: string;
    
    @field({ type: 'string', codeName: 'CDNUrl' })
    public CDNUrl: string;
    
    @field({ type: 'int' })
    public type: number;
    
    @field({ type: 'int' })
    public source: number;
    
    @field({ type: 'int' })
    public country_id: number;
}
