import Entity, { EntityWithId, field } from '../entity';
    
export default class LogoCollectionCategoryEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'string' })
    public name: string;
    
    @field({ type: 'string', codeName: 'promptId' })
    public promptId: string;
    
    @field({ type: 'int', codeName: 'parentId' })
    public parentId: number;
}
