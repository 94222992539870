import Entity, { EntityWithId, field } from '../entity';
    
export default class TableVersionEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'string', codeName: 'tableName' })
    public tablename: string;
    
    @field({ type: 'int' })
    public version: number;
}
