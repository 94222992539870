import Entity, { EntityWithId, field } from '../entity';
    
export default class ContactMethodTypeEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'string' })
    public name: string;
    
    @field({ type: 'string' })
    public displayName: string;
    
    @field({ type: 'string' })
    public prompt: string;
    
    @field({ type: 'boolean', codeName: 'canReceiveDoc' })
    public canreceivedoc: boolean;
    
    @field({ type: 'boolean' })
    public is_regular: boolean;
    
    @field({ type: 'boolean' })
    public is_additional: boolean;
    
    @field({ type: 'boolean' })
    public can_call: boolean;
    
    @field({ type: 'boolean' })
    public can_text: boolean;
    
    @field({ type: 'boolean' })
    public can_email: boolean;
}
