import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import BusinessTypesEntity from './entity';
import modelProperties from './modelProperties';

interface BusinessTypesAssociations extends AssociationConfig<any, any> {}

interface BusinessTypes extends AutoGeneratedFunctions<BusinessTypesAssociations, BusinessTypesEntity, ComplexData<BusinessTypesEntity>> {}

// eslint-disable-next-line no-redeclare
class BusinessTypes extends ComplexData<BusinessTypesEntity> {
    static Entity = BusinessTypesEntity;

    static modelProperties = modelProperties;
}

export default BusinessTypes;
