import ComplexData, { AssociationConfig, AutoGeneratedFunctions } from '../complexData';
import FeatureEntity from './entity';
import modelProperties from './modelProperties';

interface FeatureAssociations extends AssociationConfig<any, any> {}

interface Feature extends AutoGeneratedFunctions<FeatureAssociations, FeatureEntity, ComplexData<FeatureEntity>> {}

// eslint-disable-next-line no-redeclare
class Feature extends ComplexData<FeatureEntity> {
    static Entity = FeatureEntity;

    static modelProperties = modelProperties;
}

export default Feature;
