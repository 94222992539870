const modelProperties = {
    modelName: 'PaymentSetting',
    tableName: 'PaymentSetting',
    associations: [{
        type: 'belongsTo',
        model: 'PaymentType',
        parameters: {
            foreignKey: {
                name: 'payment_id',
                fieldName: 'payment_id',
            },
        },
    }, {
        type: 'belongsTo',
        model: 'Company',
        parameters: {
            foreignKey: {
                name: 'company_id',
                fieldName: 'company_id',
            },
        },
    }],
    validations: {
        s1: { type: 'length', max: 256 },
        s2: { type: 'length', max: 256 },
        s3: { type: 'length', max: 256 },
    },
};

export default modelProperties;
