import Entity, { EntityWithId, field } from '../entity';
    
export default class UserProfileEntity extends EntityWithId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'string' })
    public name: string;
    
    @field({ type: 'string' })
    public description: string;
    
    @field({ type: 'boolean' })
    public selectable: boolean;
    
    @field({ type: 'boolean' })
    public internal: boolean;
    
    @field({ type: 'string', codeName: 'descriptionPrompt' })
    public descriptionPrompt: string;
    
    @field({ type: 'string', codeName: 'namePrompt' })
    public namePrompt: string;
    
    @field({ type: 'int', codeName: 'accessLevel' })
    public accessLevel: number;
    
    @field({ type: 'string', clientOnly: true, isJSON: true })
    public roles: number[];
}
