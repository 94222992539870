import Entity, { EntityWithCompanyId, field } from '../entity';
    
export default class WhiteLabelToCustomerEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'int', primaryKey: true })
    public whitelabel_id: number;
    
    @field({ codeName: 'customerId', type: 'int', primaryKey: true })
    public customer_id: number;
    
    @field({ type: 'int', primaryKey: true })
    public company_id: number;
}
