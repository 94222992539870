const RecursiveReplace = require('modules/utilities/recursiveReplace');

class ItemTemplate {
    /**
     *
     * @param config
     * @returns {RecursiveReplace}
     */
    constructor(config) {
        // eslint-disable-next-line no-constructor-return
        return new RecursiveReplace(itemValue => {
            if (!(typeof itemValue === 'string')) {
                return itemValue;
            }
            return itemValue.replace(/(\$\(([\w\d]*?)\))/g, (...args) => {
                const [, , key] = args;
                return config[key] || key;
            });
        }, config);
    }
}

module.exports = ItemTemplate;
