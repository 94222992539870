export default {
    SETTINGS: {
        DIARY: {
            CHANGED: 'diary_settings_changed',
        },
    },
    LOGIN: {
        USER_FOUND: 'any_login',
        COMPANY_FOUND: 'any_login_complete',
        COMPLETED: 'login_completed',
        AUTH_SUCCEEDED: 'auth_succeeded',
    },
    AUTH: {
        COMPANY_UPDATED: 'company_updated',
    },
    STORE: {
        USER: {
            UPDATED: 'user_updated_from_server',
        },
        COMPANY: {
            TRACKING_SETTINGS_CHANGED: 'tracking_settinngs_changed',
        },
    },
    SYNC: {
        BULK_SYNC_REQUEST_ARRIVED: 'bulk_sync_request_arrived',
    },
};
