const modelProperties = {
    modelName: 'MessageTemplate',
    tableName: 'MessageTemplate',
    minAppVersion: '9.0.0',
    associations: [

    ],
    validations: { },
};

export default modelProperties;
