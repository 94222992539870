import { Extensible } from '@powerednow/type-definitions';
import BaseData from './baseData';
import Cache from './cache';
import Entity, {
    DataObject, EntityWithId, ModelCreationFields, ModelFields,
} from './entity';

export type Filter<T extends Entity> = {
    operator: '=' | '!=' | '<' | '>' | '<=' | '>=' | 'in';
    property: keyof ModelFields<T>;
    value: T[keyof ModelFields<T>] | T[keyof ModelFields<T>][];
}

export type Sorter<T extends Entity> = {
    field: keyof ModelFields<T> extends string ? keyof ModelFields<T> : never;
    sort: 'asc' | 'desc';
}

export type GetAssociatedOptions<T extends Entity> = {
    filters?: Filter<T>[],
    limit?: number,
    skip?: number,
    sorters?: Sorter<T>[]
}

export type ConditionEntry<T extends Entity, K extends keyof ModelCreationFields<T> = keyof ModelCreationFields<T>> = [keyof T, T[K] & ['$or', Array<Partial<ModelCreationFields<T>>>]]

export type DataRequestParams<T extends EntityWithId> = {
    Source?: typeof ConnectedData,
    associatedValuesKey?: string;
    remoteOptions?: GetAssociatedOptions<T>;
}

type ExtensibleThisCollection<T> = Record<string, Extensible<T>>;

interface EventForwardingFunction {
    (eventArgs: any): void;
    isEventForwardingFunction?: boolean;
}

export default class ConnectedData<T extends EntityWithId> extends BaseData<T> {
    protected whereToForward: { [propname: string]: Set<ConnectedData<T>> } = {};

    public eventOrigins = new Set();

    public forwardedEventsRoot: ExtensibleThisCollection<this> = {};

    protected associatedValueItems: {} = {};

    protected processedEvents: Set<string> = new Set();

    protected cache: Cache;

    /**
     * Default constructor dealing with the initialisation of the instance
     *
     * @param {DataObject} data
     * @param listeners
     */
    public constructor(data: DataObject, listeners: { string?: Function | Function[] } = {}, cache = new Cache(), disposable = false) {
        super(data, null, null, disposable);
        this.cache = cache;
        this.addListenersFromMap(listeners);

        Object.values(ConnectedData.EVENTS).forEach(eventName => {
            const BOUND_FOR_FORWARDING = this.eventForwardingFunction(eventName);
            BOUND_FOR_FORWARDING.isEventForwardingFunction = true;
            this.on(eventName, BOUND_FOR_FORWARDING);
        });
    }

    public emit(event: string | symbol, eventArgs): boolean {
        if (eventArgs && !eventArgs.eventId) {
            // @ts-ignore
            eventArgs.eventId = this.constructor.generateRandomEventName();
        }
        return super.emit(event, eventArgs);
    }

    private eventForwardingFunction(eventName: string): EventForwardingFunction {
        return eventArgs => {
            if (!this.whereToForward[eventName]) {
                return;
            }
            if (!eventArgs.eventId) {
                // @ts-ignore
                eventArgs.eventId = this.constructor.generateRandomEventName();
            }
            //
            // Forward this event to all subscribers but only if they haven't got it already
            //
            this.processedEvents.add(eventArgs.eventId);
            this.whereToForward[eventName].forEach(forwardTarget => {
                if (!forwardTarget.processedEvents.has(eventArgs.eventId)) {
                    forwardTarget.processedEvents.add(eventArgs.eventId);
                    forwardTarget.emit(eventName, eventArgs);
                    forwardTarget.processedEvents.delete(eventArgs.eventId);
                }
            });
            this.processedEvents.delete(eventArgs.eventId);
        };
    }

    /**
     * Generate a random event name from the class name and three
     * random numbers what will be very likely unique.
     *
     * @returns {string}
     */
    private static generateRandomEventName(): string {
        return `${this.name}-${Math.random()}${Math.random()}${Math.random()}`;
    }

    /**
     * Returns true if the given event got an external event handler assigned.
     *
     * @param {string} eventName
     * @returns {Boolean}
     */
    protected isListenerBinded(eventName: string): Boolean {
        const rootEventHandler = typeof this.forwardedEventsRoot[eventName] !== 'undefined' ? this.forwardedEventsRoot[eventName] : this;
        const eventProps = (<any>rootEventHandler)._events[eventName];
        return typeof eventProps !== 'undefined'
            && (Array.isArray(eventProps) ? eventProps : [eventProps]).some(item => item.isEventForwardingFunction !== true);
    }

    public addListenersFromMap(listeners) {
        Object.entries(listeners).forEach(([eventName, eventHandler]) => {
            (Array.isArray(eventHandler) ? eventHandler : [eventHandler]).forEach(handler => {
                this.on(eventName, handler as any);
            });
        });
    }

    /**
     * Copy selected listeners from source to this
     *
     * @param {ConnectedData} source
     * @param {string[]} selectedEvents
     */
    protected copyListenersFrom(source: ConnectedData<any>, selectedEvents: string[] = []) {
        this.addListenersFromMap(source.getListenerMap(selectedEvents));
    }

    /**
     * Sets a new cache for this object
     *
     * @param {Cache} cache
     */
    public setCache(cache: Cache): void {
        this.cache = cache;
    }

    /**
     * Get an instance from the cache if it is already there.
     * Otherwise returns null.
     *
     * @param {DataObject} itemData
     * @param {string} modelName
     * @returns {any}
     */
    protected getInstanceFromCache(itemData: DataObject, modelName: string) {
        if (this.cache) {
            const fromCache = this.cache.get(modelName, itemData.id);
            return fromCache;
        }
        return null;
    }

    /**
     * Generate a map of listeners assigned to this object
     *
     * @param {string[]} selectedEvents
     * @returns {{}}
     */

    protected getListenerMap(selectedEvents: string[] = [], getListeners = event => this.listeners(event)) {
        const events = selectedEvents.length !== 0 ? selectedEvents : Object.keys((this as any)._events);
        return events.reduce((accumulator, event) => {
            const listeners = getListeners(event);
            if (listeners && listeners.length > 0) {
                accumulator[event] = listeners;
            }
            return accumulator;
        }, {});
    }

    protected getRootListenerMap(selectedEvents: string[] = []) {
        return this.getListenerMap(selectedEvents, event => {
            const listenersRoot = this.forwardedEventsRoot[event] || this;
            return listenersRoot.listeners(event);
        });
    }

    /**
     * Set up forwarded event between two instances. All events of the passed in
     * instance will be forwarded to this instance.
     *
     * @param {this} instance
     */
    protected setupEventForwarding(instance: ConnectedData<T>): void {
        this.eventOrigins.add(instance);
        Object.values(ConnectedData.EVENTS)
            .filter(eventName => !ConnectedData.NON_FORWARDING_EVENTS.includes(eventName))
            .forEach(eventName => {
                if (!instance.forwardedEventsRoot[eventName]) {
                    if (this.forwardedEventsRoot[eventName]) {
                        this.forwardedEventsRoot[eventName].eventOrigins.add(instance);
                        instance.forwardedEventsRoot[eventName] = this.forwardedEventsRoot[eventName];
                    } else {
                        instance.forwardedEventsRoot[eventName] = this;
                    }
                }
                instance.whereToForward[eventName] = instance.whereToForward[eventName] || new Set();
                instance.whereToForward[eventName].add(this);
            });
    }

    /**
     * Remove all event forwarding event handlers from the passed
     * in instance.
     *
     * @param {ConnectedData} instance
     */
    protected removeFromEventForwarding(instance: ConnectedData<any>): void {
        this.eventOrigins.delete(instance);

        Object.entries(instance.whereToForward).forEach(([eventName, itemsToForward]) => {
            if (instance.forwardedEventsRoot[eventName] === this) {
                delete instance.forwardedEventsRoot[eventName];
            }
            itemsToForward.delete(this);
        });

        [...this.eventOrigins].forEach((origin: ConnectedData<T>) => {
            if (instance.eventOrigins.has(origin)) {
                instance.eventOrigins.delete(origin);
                instance.setupEventForwarding(origin);
            }
        });
    }

    /**
     * Fire data event to be processed by the wrapper and wait for its pair response event
     *
     * @param {string} eventName
     * @param eventParams
     * @returns {Promise<any>}
     */
    protected async fireAndHandleEvent<E extends EntityWithId>(eventName: string, eventParams: DataRequestParams<E> = {}): Promise<any> {
        if (!this.isListenerBinded(eventName)) {
            return Promise.resolve();
        }

        // @ts-ignore
        const temporaryEventName = this.constructor.generateRandomEventName();

        const retPromise = new Promise((resolve, reject) => {
            this.once(temporaryEventName, eventResponse => {
                if (eventResponse instanceof Error) {
                    return reject(eventResponse);
                }
                return resolve(eventResponse);
            });
        });

        this.emit(eventName, {
            instance: this,
            responseEventName: temporaryEventName,
            ...eventParams,
        });

        return retPromise;
    }

    /**
     * Load external data from the attached wrapper if there is any.
     *
     * @param {string} associatedValuesKey
     * @param {GetAssociatedOptions} remoteOptions
     * @returns {Promise<{responseData?: DataObject[]}>}
     */
    protected loadAssociatedData<E extends EntityWithId>(associatedValuesKey: string, remoteOptions: GetAssociatedOptions<E> = {}): Promise<{ responseData?: DataObject[] }> {
        return this.fireAndHandleEvent(BaseData.EVENTS.MODEL_DATA_REQUEST, { associatedValuesKey, remoteOptions });
    }

    protected filterAssociatedData(associatedValuesKey: string, remoteOptions: GetAssociatedOptions<T> = {}): Promise<any> {
        return this.fireAndHandleEvent(BaseData.EVENTS.MODEL_DATA_REQUEST, {
            associatedValuesKey,
            remoteOptions,
        });
    }

    /**
     * Load external generic data by using the attached wrapper if there is any
     */
    protected loadGenericData(Source: typeof ConnectedData, filters: Partial<ModelCreationFields<T>> = {}): Promise<any> {
        const remoteOptions = {
            filters: this.conditionToFilter(filters),
        };
        return this.fireAndHandleEvent(BaseData.EVENTS.MODEL_DATA_REQUEST, { Source, remoteOptions });
    }

    protected conditionToFilter(conditions: Partial<ModelCreationFields<T>>): Filter<T>[] {
        return (Object.entries(conditions) as Array<ConditionEntry<T>>).reduce((acc, item) => {
            const [key, value] = item;
            if (key === '$or') {
                value.forEach(part => {
                    Object.entries(part).forEach(([orKey, orValue]) => {
                        acc.push({
                            logic: 'or',
                            operator: '=',
                            property: orKey,
                            value: (typeof orValue === 'symbol') ? this.data[this.constructor.Entity.fieldSymbolValues[orValue]] : value,
                        });
                    });
                });
            } else {
                acc.push({
                    operator: '=',
                    property: key,
                    value,
                });
            }
            return acc;
        }, []);
    }
}
