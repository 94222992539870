export default {
    VERSIONS: {
        V1: 'v1',
        V2: 'v2',
    },
    TYPES: {
        ALL: {
            CODE: 0,
            AUTH_REQUIRED: true,
            ORDER: 1,
        },
        DATABASE: {
            CODE: 1,
            AUTH_REQUIRED: true,
            ORDER: 3,
        },
        COMPANIES_HOUSE: {
            CODE: 2,
            AUTH_REQUIRED: false,
            ORDER: 2,
        },
    },
};
