export default {
    ARCHIVE_BATCH_PERIOD_IDS: {
        WEEK: 0,
        MONTH: 1,
    },
    COMPANY_LIMIT: 100,
    METHODS: {
        PROJECT: 0,
        PROJECT_CONTENT: 1,
    },
    ITEM_TYPES: {
        NOTE: 0,
        DOCUMENT: 1,
        ACTION: 2,
        FILEGROUP: 3,
        DOCUMENTITEM: 4,
        MEDIAGROUP: 5,
        MEDIA: 6,
        MESSAGE: 7,
        JOBITEMGROUP: 8,
        JOBITEM: 9,
        PAYMENT: 10,
        FORMDOCUMENT: 11,
        TIMELOG: 12,
        FILE: 13,
        PAYMENTTOINVOICE: 14,
        CHECKIN: 15,
        SIGNATURE: 16,
        USERACTION: 17,
        DOCUMENTACTION: 18,
        MESSAGEATTACHMENTS: 19,
        CUSTOMERMESSAGE: 20,
        MESSAGERECIPIENT: 21,
        CUSTOMERMESSAGEATTACHMENT: 22,
    },
    TABLE_NAMES: {
        0: 'Note',
        1: 'Document',
        2: 'Action',
        3: 'FileGroup',
        4: 'DocumentItem',
        5: 'MediaGroup',
        6: 'Media',
        7: 'Message',
        8: 'JobItemGroup',
        9: 'JobItem',
        10: 'Payment',
        11: 'FormDocument',
        12: 'TimeLog',
        13: 'File',
        14: 'PaymentToInvoice',
        15: 'Checkin',
        16: 'Signature',
        17: 'UserAction',
        18: 'DocumentAction',
        19: 'MessageAttchments',
    },
    ITEM_NAMES: {
        0: 'Note',
        1: 'Document',
        2: 'Appointment and reminder',
        3: 'File',
        5: 'Media',
        7: 'Message',
        8: 'Pricing',
        10: 'Payment',
        11: 'Form and certificate',
        12: 'Time log',
        13: 'File',
        15: 'Checkin',
        16: 'Signature',
        19: 'Attachment',
        20: 'Message',
    },
};
