export default {
    URLS: {
        AUTHORIZATION_URL: '/api/authorizationUrl',
        RENEW_TOKEN: '/api/renewToken',
        UPGRADE_TOKEN: '/api/upgradeToken',
        SETTINGS_URL: '/api/settings',
        VERSION_URL: '/api/version',
        CUSTOMERS_URL: (args, page?) => `/api/customers?args=${encodeURIComponent(JSON.stringify(args))}&page=${page}`,
        DOCUMENTS_URL: '/api/documents',
        PAYMENTS_URL: (args?, page?) => `/api/payments${args ? `?args=${encodeURIComponent(JSON.stringify(args))}` : ''}${page ? `&page=${page}` : ''}`,
        DISCONNECT_URL: '/api/disconnect',
    },

    INTEGRATION_API_STATUS_CODES: {
        STATUS_OK: 1,
        STATUS_PERMANENT_ERROR: 2,
        STATUS_TEMPORARY_ERROR: 3,
    },

    POWERED_NON_API_STATUS_CODES: {
        STATUS_OK: 1,
        STATUS_ERROR: 2,
    },

    POWERED_NON_API_ERROR_CODES: {
        GENERAL: 1,
        AUTHORISATION_MISMATCH: 2,
        ORGANISATION_ALREADY_INTEGRATED: 3,
    },

    OAUTH_ERRORS: {
        ALREADY_REGISTERED: 'already_registered',
        ACCESS_DENIED: 'access_denied',
        UNKNOWN: 'unknown',
    },

    ERROR_CODES: {
        GENERAL: 1,
        SERVICE_UNAVAILABLE: 2,
        INTEGRATION_UNAVAILABLE: 3,
        RATE_LIMIT_EXCEEDED: 4,
        TOKEN_EXPIRED: 5,
        CONNECTION_LIMIT_EXCEEDED: 6,
        TOKEN_REJECTED: 7,
        TIMEOUT: 8,
        TOKEN_UPGRADE_REQUIRED: 9,
        RESOURCE_NOT_FOUND: 10,
        INVALID_REFRESH_TOKEN: 11,
    },

    CONNECTION_STATUS: {
        NOT_CONNECTED: 1,
        CONNECTED: 2,
        EXPIRED: 3,
    },

    CONTACT_GROUP_NONE: {
        name: 'None',
        contactGroupId: '0',
    },

    ACCOUNT_CODE_NONE: {
        name: 'None',
        code: '0',
    },

    ACCOUNT_CODE_TYPES: {
        SALES: 1,
        SUPPLIER_INVOICES: 2,
        EXPENSES: 3,
        BANK: 4,
    },

    SYNC_TYPES: {
        CONTACTS_TO_POWERED_NOW: 1,
        CONTACTS_FROM_POWERED_NOW: 2,
        SYNC_ALL: 3,
        IMPORT_CONTACTS: 4,
        SYNC_CONTACT: 5,
        SYNC_DOCUMENT: 6,
        GET_AUTHORIZATION_URL: 7,
        UPDATE_SETTINGS: 8,
        PAYMENTS_TO_POWERED_NOW: 9,
    },

    INTEGRATION_MODULE_PLATFORM: {
        CLIENT: 1,
        SERVER: 2,
    },

    EXPORT_TYPE: {
        CSV: 1,
        ACCOUNTING_INTEGRATION: 2,
    },

    INTEGRATION_TYPE: {
        MTD_FOR_VAT: 1,
        XERO: 2,
    },

    IMPORT_CSV_COLUMNS: {
        POWERED_NOW_CUSTOMER_ID: 'Powered Now Customer Id',
        XERO_CONTACT_ID: 'Xero Contact Id',
        ACTION: 'Action',
        BUSINESS_NAME: 'Business Name',
        MAIN_CONTACT_NAME: 'Main Contact Name',
        PHONE_NUMBER: 'Phone Number',
        EMAIL_ADDRESS: 'Email Address',
        MAIN_ADDRESS: 'Main Address',
        POSTAL_CODE: 'Postal Code',
    },

    ACCOUNTING_REFERENCE_NO_SYNC: 'NO SYNC',

    SYNC_ITEM_COUNT: 10,

    UNDEFINED_BANK_ACCOUNT_CODE: '',

    TIMEOUT_RETRY_ATTEMPT_COUNT: 2,

    RATE_LIMIT_EXCEEDED_RETRY_ATTEMPT_COUNT: 10,
    RATE_LIMIT_WAIT_TIMEOUT: 60000,

    POWERED_NOW_PAYMENT_ID_PREFIX: 'PoweredNowID:',

    DOCUMENT_ERROR_CODES: {
        POWERED_NOW_VALIDATION_ERROR: 1,
        XERO_API_ERROR: 2,
        INVOICE_NOT_AVAILABLE_IN_XERO: 3,
        INVOICE_TOTAL_INCREASED_IN_XERO: 4,
        INVOICE_TOTAL_REDUCED_IN_XERO: 5,
        INVOICE_CREDITED_IN_XERO: 6,
        INVOICE_CREDIT_VOIDED_IN_XERO: 7,
        INVOICE_PAYMENT_PROBLEM: 8,
    },
};
